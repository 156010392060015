import React from 'react';
import equal from 'fast-deep-equal'

import Chart from 'chart.js';

import "../../../styles/common.progressbar.css";

export default class NewChart extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.updateChart = this.updateChart.bind(this);
        this.resizeChart = this.resizeChart.bind(this);

        this.chartInstance = undefined;
    }

    componentDidMount() {
        this.updateChart();
        window.addEventListener('resize', this.resizeChart)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeChart)
    }

    componentDidUpdate(prevProps) {
        if (!equal(this.props.config, prevProps.config)) {
            this.updateChart();
        }
    } 

    updateChart () {
        if (this.chartInstance) this.chartInstance.destroy();
        this.chartInstance = new Chart(this.node, this.props.config);        
    }

    resizeChart () {
        this.chartInstance.resize();
    }

    render() {
        return (
            <canvas id={this.props.id} ref={node => (this.node = node)} />
        )
    }
}


export class DonutChart extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.initChart = this.initChart.bind(this);
        this.updateChart = this.updateChart.bind(this);

        this.chartInstance = undefined;
    }

    componentDidMount() {
        this.initChart();
        window.addEventListener('resize', this.updateChart)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateChart)
    }

    updateChart() {
        this.chartInstance.resize();
    }

    initChart() {
        this.chartInstance = new Chart(this.node, this.props.config);
    }

    render() {

        return (
            <canvas id="donutChartCanvas" ref={node => (this.node = node)} />
        )
    }
}


export class StackedBarChart extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.initChart = this.initChart.bind(this);
        this.updateChart = this.updateChart.bind(this);

        this.chartInstance = undefined;
    }

    componentDidMount() {
        this.initChart();
        window.addEventListener('resize', this.updateChart)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateChart)
    }

    updateChart() {
        this.chartInstance.resize();
    }

    initChart() {
        this.chartInstance = new Chart(this.node, this.props.config);
    }

    render() {

        return (
            <canvas id="barChartCanvas" ref={node => (this.node = node)} />
        )
    }
}

export class LineChart extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.initChart = this.initChart.bind(this);
        this.updateChart = this.updateChart.bind(this);

        this.chartInstance = undefined;
    }

    componentDidMount() {
        this.initChart();
        window.addEventListener('resize', this.updateChart)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateChart)
    }

    updateChart() {
        this.chartInstance.resize();
    }

    initChart() {
        this.chartInstance = new Chart(this.node, this.props.config);
    }

    render() {

        return (
            <canvas id="lineChartCanvas" ref={node => (this.node = node)} />
        )
    }
}

export class ProgressBar extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.primaryProgressRef = React.createRef();
    }

    componentDidMount() {
        this.primaryProgressRef.current.style.transform = "scaleX(" + this.props.value/100 + ")";

    }

    componentWillUnmount() {}

    render() {
        return (
            <>
                <div className="layout horizontal">
                    {this.props.title}
                    <div className="flex"></div>
                    {this.props.value}%
                </div>
                <div className="height-10"></div>
                <div className="progress-container">
                    <div ref={this.primaryProgressRef} className="primary-progress"></div>
                </div>
            </>
        )
    }

}

export class ProgressBarMulti extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.primaryProgressRef = React.createRef();
    }

    componentDidMount() {
        this.primaryProgressRef.current.style.transform = "scaleX(" + this.props.value / 100 + ")";
    }

    componentDidUpdate(prevProps) {
        if (!equal(this.props.value, prevProps.value)) {
            this.primaryProgressRef.current.style.transform = "scaleX(" + this.props.value / 100 + ")";
        }
    }


    componentWillUnmount() { }

    render() {
        return (
            <>
                <div className="layout horizontal">
                    {this.props.title}
                    <div className="flex"></div>
                    <span className="opacity-54">{this.props.suffix} </span>
                </div>
                <div className="height-10"></div>
                <div style={{height: this.props.height || undefined}} className={"progress-container" + " " + this.props.className}>
                    <div ref={this.primaryProgressRef} style={{ height: this.props.height || undefined }} className="primary-progress"></div>
                </div>
            </>
        )
    }

}

export class ProgressBarMultiTemp extends React.Component {

    state = {
    }

    constructor(props, context) {
        super(props, context);
    }


    componentDidMount() {
    }

    componentWillUnmount() { }

    render() {
        return (
            <>
                <div className="layout horizontal">
                    {this.props.title}
                    <div className="flex"></div>
                    <span className="opacity-54">{this.props.suffix} </span>
                </div>
                <div className="height-10"></div>

                {/* <div className="multicolor-progress-container">
                    <div className="bars">
                        {bars == '' ? '' : bars}
                    </div>
                </div> */}
 
                <div className="multicolor-progress-container">
                    <div className="multicolor-progress-bars">
                        {
                            this.props.items && this.props.items.map( (item) => {
                                return (
                                    <div key={item.color} title={item.displayTitle} style={{ width: (item.value * 100) + "%", backgroundColor: item.color, zIndex: item.zIndex }} className="multicolor-progress-bar"></div>
                                )
                            })
                        }
                    </div>
                </div>
            </>
        )
    }

}