import React from 'react';
import { Route, Switch } from "react-router-dom";
import { connect } from 'react-redux'
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';

// Local imports

// Component imports
import MonitoringStatePage from "./MonitoringStatePage";
import MonitoringCountyPackage from './MonitoringCountyPackage';
import MonitoringGrantInformationEditPage from './MonitoringGrantInformationEditPage';
import MonitoringSiteVisitReviewEditPage from './MonitoringSiteVisitReviewEditPage';

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";


const mapStateToProps = (state) => {
    return {
        loading: state.rootReducer.loading,
        lempgUserType: state.rootReducer.lempgUserType,
    }
};

class ProgressHome extends React.Component {

    state = {
    }

    constructor(props, context) {
        super(props, context);
    }

    componentDidUpdate() { }

    render() {
        return (
            <Switch>

                <Route
                    exact
                    path="/monitoring/"
                    component={
                        (props) => this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading your application. Please wait</span>
                            </div>
                            : <MonitoringStatePage {...props} />
                    }
                />
                <Route
                    path="/monitoring/county/grantinformation/edit"
                    component={
                        (props) => this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading your application. Please wait</span>
                            </div>
                            : <MonitoringGrantInformationEditPage {...props} />
                    }
                />
                <Route
                    path="/monitoring/county/sitevisitreview/edit"
                    component={
                        (props) => this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading your application. Please wait</span>
                            </div>
                            : <MonitoringSiteVisitReviewEditPage {...props} />
                    }
                />

                <Route
                    path="/monitoring/county"
                    component={
                        (props) => this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading your application. Please wait</span>
                            </div>
                            : <MonitoringCountyPackage {...props} />
                    }
                />

                {/* <Route
                    path="/monitoring/county/sitevisitreview"
                    component={
                        (props) => this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading your application. Please wait</span>
                            </div>
                            : <MonitoringSiteVisitReview {...props} />
                    }
                /> */}

            </Switch>

        )
    }
}

export default connect(mapStateToProps, null)(ProgressHome);
