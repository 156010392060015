import React from 'react';

import "../../../styles/common.css";
import "../../../styles/common.status.css";

// Single title header component

/**
 * Usage
 * <StatusComponent text="In Progress" />
 */

 const Status2ClassObj = {
    "completed": "s-container s-green-1",
    "submitted": "s-container s-yellow-2",
    "approved": "s-container s-green-1",
    "awarded": "s-container s-green-1",
    "processed": "s-container s-green-3",
    "complete": "s-container s-green-3",
    "tasked": "s-container s-blue-2",
    "changes requested": "s-container s-blue-2",
    "cancelled": "s-container s-red-2",
    "draft": "s-container s-grey-1",
    "incomplete": "s-container s-grey-1",
    "not available": "s-container s-grey-1",
    "not started": "s-container s-black-2",
    'not participating': 's-container s-grey-3',
    'submitted with issues': "s-container s-blue-2",    
 };

class StatusComponent extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    sentenceCase = (text) => {
        return text ? text.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() }) : "";
    }


    lowercase = (text) => {
        return text ? text.toLowerCase() : "";
    }

    render() {

        // The properties should be defined as attribute during the defination of this component

        const { text } = this.props;

        return (
            <div className={Status2ClassObj[this.lowercase(text)] ? Status2ClassObj[this.lowercase(text)] : ""}>{text}</div>
        )
    }
}

export default StatusComponent;
