import React from 'react';
import { connect } from 'react-redux'
import IconButton from "@material-ui/core/IconButton";
import { MdArrowBack, MdInsertDriveFile, MdFileDownload } from 'react-icons/md';


// Local imports
import { DualTitleHeaderComponent } from "../layout/HeaderComponent.js";
import { HeaderButton } from "../styled-components/Buttons/Buttons";
import FileUpload from "../common/FileUpload";
import { CardComponentDownloadCard } from "../layout/CardComponent";
import { saveFileAppSync } from "../../redux/actions/index";
import { getFileUploadedObject } from "../common/FileUploadApi";


// CSS imports
import "../../styles/common.css";
import "../../styles/iron-flex-layout.css";
import "../../styles/iron-flex-layout-classes.css";

const mapStateToProps = state => {
    return {
        grantApplication: state.rootReducer.grantApplication,
        currentApplicationState: state.rootReducer.currentApplicationState,
        lempgUserType: state.rootReducer.lempgUserType,
        selectedYear: state.rootReducer.selectedYear,
        currentFiscalYear: state.rootReducer.currentFiscalYear,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveFileAppSync: (payload, fieldName, callback) => dispatch(saveFileAppSync(payload, fieldName, callback)),
    };
};

class NarrativesDocument extends React.Component {

    state = {
        file_id: "",
        refDownload: {
            originalObject: {
                downloadTitle: "Narratives.docx"
            },
            link: "https://s3.amazonaws.com/lempg-public-county-documents/Narratives.docx",
        }
    }

    constructor(props, context) {
        super(props, context);
        this.goBack = this.goBack.bind(this);
        this.fileToSave = this.fileToSave.bind(this);
        this.fileToDelete = this.fileToDelete.bind(this);
        this.saveFileCallback = this.saveFileCallback.bind(this);
    }

    async componentDidMount() {
        if (this.props.currentFiscalYear && this.props.currentFiscalYear.narrativesFile) {
            this.state.refDownload = await getFileUploadedObject(this.props.currentFiscalYear.narrativesFile)
        }
        this.setState({
            refDownload: this.state.refDownload
        });

        if (this.props.currentApplicationState.narrativesFile && this.props.currentApplicationState.narrativesFile.id) {

            this.state.file_id = this.props.currentApplicationState.narrativesFile.id;

            this.setState({
                file_id: this.state.file_id
            });
        }
    }


    goBack() {
        this.props.history.goBack();
    }

    /**
     * 
    */

    fileToSave(data) {
        this.setState({ file_id: data.data.createFile.id });
    }

    /**
     * 
    */


    fileToDelete() {
        this.setState({ file_id: null });
    }

    /**
     * 
    */


    saveFile(data) {
        this.props.saveFileAppSync(this.state, "grantApplicationNarrativesFileId", this.saveFileCallback)
    }

    /**
     * 
    */

    saveFileCallback(data) {
        this.goBack()
    }
    render() {

        const UploadedFiles = (
            <a target="_blank" download={this.state.refDownload.originalObject.downloadTitle} href={this.state.refDownload.link}>
                <CardComponentDownloadCard
                    icon={<MdInsertDriveFile className="icon-2" />}
                    title={<span>Narratives</span>}
                    suffix={<MdFileDownload className="icon-2" />}
                />
            </a>
        )

        const DownloadDocuments = (
            <>
                <div className="layout horizontal center section-title">
                    Download Documents
                </div>
                <div className="layout horizontal center opacity-87">
                    Download & print document, sign initials, then upload signed document.
                </div>
                <div className="layout horizontal center height-10">
                </div>

                {UploadedFiles}
            </>
        )

        return (

            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdArrowBack className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Narratives</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>{window.appHeaderTitle} • {this.props.grantApplication.group && this.props.grantApplication.group.groupName}</span>}

                    suffix={
                        <div className="form-margin-16">
                            <HeaderButton hidden={this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS"} onClick={() => this.saveFile()}>
                                Save
                            </HeaderButton>
                        </div>
                    }
                />

                {/* Only use form-container class when you are not using input fields in the form */}
                <div className="flex form-container">
                    {DownloadDocuments}

                    <div className="height-20"></div>

                    <FileUpload fileToSave={this.fileToSave} fileToDelete={this.fileToDelete}
                        uploadedFile={this.props.currentApplicationState && this.props.currentApplicationState.narrativesFile ? this.props.currentApplicationState.narrativesFile : null} singleupload="true" />
                </div>
            </div>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(NarrativesDocument);
