import React from 'react';

import NewChart from "./Charts";
import { connect } from 'react-redux'
import moment from 'moment';

import { calcQuarters } from '../../utilities/QuarterDates';

import "../../../styles/common.css";
import "../../../styles/common.charts.css";


const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        counties: state.rootReducer.counties,
        selectedCounty: state.rootReducer.selectedCounty,
        selectedYear: state.rootReducer.selectedYear
    };
};



class FinancialReportingStatusByCountyStackedBarGraph extends React.Component {

    state = {

    }

    checkQuarterBasedOnDate = (quarterStart, quarterEnd) => {
        var start = new Date(quarterStart);
        var current = new Date();
        current.setHours(0, 0, 0, 0);
        // var end = new Date(quarterEnd);
        // && current <= end
        // We need to allow users to test pass the current FY year
        return (start <= current);
    }

    checkWhichQuarters = () => {
        let obj = calcQuarters(this.props.selectedYear);

        var quarters = []

        if (this.checkQuarterBasedOnDate(obj.quarter1.start, obj.quarter1.end)) {
            quarters = ["quarter1"];
        }
        if (this.checkQuarterBasedOnDate(obj.quarter2.start, obj.quarter2.end)) {
            quarters = ["quarter1", "quarter2"];
        }
        if (this.checkQuarterBasedOnDate(obj.quarter3.start, obj.quarter3.end)) {
            quarters = ["quarter1", "quarter2", "quarter3"];
        }
        if (this.checkQuarterBasedOnDate(obj.quarter4.start, obj.quarter4.end)) {
            quarters = ["quarter1", "quarter2", "quarter3", "quarter4"];
        }

        return quarters;
    }


    calculateConfig = () => {

        let datasets = [{
            label: 'Processed',
            backgroundColor: "#19C922",
            borderWidth: 1,
            data: [0, 0, 0, 0]
        }, {
            label: 'Approved',
            backgroundColor: "#417505",
            borderWidth: 1,
            data: [0, 0, 0, 0]
        }, {
            label: 'Submitted',
            backgroundColor: "#c2b61b",
            borderWidth: 1,
            data: [0, 0, 0, 0]
        }, {
            label: 'Draft',
            backgroundColor: "#a1a1a1",
            borderWidth: 1,
            data: [0, 0, 0, 0]
        }, {
            label: 'Changes Requested',
            backgroundColor: "#4b97f2",
            borderWidth: 1,
            data: [0, 0, 0, 0]
        },{
            label: 'Not Started ',
            backgroundColor: "#4a4a4a",
            borderWidth: 1,
            data: [0, 0, 0, 0]
        }];

        let index = 0;
        let PROCESSED = index++;
        let APPROVED = index++;
        let SUBMITTED = index++;
        let DRAFT = index++;
        let CHANGES_REQUESTED = index++;
        let NOT_STARTED = index++;

        /**
         * Each stacked bar graph indicates how many counties are in `PROCESSED, APPROVED, DRAFT, SUBMITTED and NOT_STARTED` phase determined by which quarter we are in currently.
         * 
         */

        // ["Q1-Processed",  "Q2-Processed",  "Q3-Processed",  "Q4-Processed"]
        // ["Q1-Approved",   "Q2-Approved",   "Q3-Approved",   "Q4-Approved"]
        // ["Q1-Submitted",  "Q2-Submitted",  "Q3-Submitted",  "Q4-Submitted"]
        // ["Q1-Draft",      "Q2-Draft",      "Q3-Draft",      "Q4-Draft"]
        // ["Q1-Changes Requested",      "Q2-Changes Requested",      "Q3-Changes Requested",      "Q4-Changes Requested"]
        // ["Q1-NotStarted", "Q2-NotStarted", "Q3-NotStarted", "Q4-NotStarted"]

        let quarters = this.checkWhichQuarters();

        console.log(quarters)

        this.props.counties.forEach((county) => {
            if (county.financialreimbursement && county.financialreimbursement.items) {

                quarters.forEach((quarter) => {

                    // This will go from quarter1, quarter2, quarter3, quarter4 
                    let QUARTER_INDEX = parseInt(quarter.split("").reverse().join("")) - 1

                    county.financialreimbursement.items.forEach((item) => {
                        if(item.quarter == quarter) {
                            // We have to find the object in datasets array with the right quarter label and then push on the status index

                            if(item.status == "DRAFT") {
                                datasets[DRAFT].data[QUARTER_INDEX]++
                            } else if (item.status == "SUBMITTED") {
                                datasets[SUBMITTED].data[QUARTER_INDEX]++
                            } else if (item.status == "PROCESSED") {
                                datasets[PROCESSED].data[QUARTER_INDEX]++
                            } else if (item.status == "CHANGES_REQUESTED") {
                                datasets[CHANGES_REQUESTED].data[QUARTER_INDEX]++
                            } else if (item.status == "APPROVED") {
                                datasets[APPROVED].data[QUARTER_INDEX]++
                            } else if ((item.status == "NOT_STARTED") || (item.status == "NOT_AVAILABLE") ) {
                                datasets[NOT_STARTED].data[QUARTER_INDEX]++
                            }

                        }
                    })

                })

            }
        });

        // this.state.config =
        return {
            type: 'bar',
            data: {
                labels: ['Q1', 'Q2', 'Q3', 'Q4'],
                datasets: datasets
            },
            options: {
                scales: {
                    xAxes: [{
                        stacked: true,
                        gridLines: {
                            drawBorder: true,
                        },
                    }],
                    yAxes: [{
                        display: false,
                        stacked: true,
                        gridLines: {
                            drawBorder: false,
                            display: false
                        },
                    }]
                },
                legend: {
                    display: false,
                }
            },
        };

    }

    render() {
        return (
            <div className="pie-chart-container inline-grid">
                <div>
                    <span className="section-title form-margin-16">Financial reporting status by county</span>
                    <div className="height-10"></div>
                    <div className="layout horizontal form-margin-16 title-divider"></div>
                </div>
                <div className="height-20"></div>
                <div className="pie-chart">
                    <NewChart id="StackedBarGraph" config={this.calculateConfig()} />
                </div>
            </div>
        )
    }

}


export default connect(mapStateToProps, null)(FinancialReportingStatusByCountyStackedBarGraph);
