import React from 'react';
import { connect } from 'react-redux';
import IconButton from "@material-ui/core/IconButton";
import { MdArrowBack } from 'react-icons/md';
import ReactTable from "react-table";
import moment from 'moment';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

// Component imports
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import StatusComponent from "../../styled-components/Statuses/StausComponent";
import { PrimaryButton } from "../../styled-components/Buttons/Buttons.js";

// Custom Data imports

import { REMContactInfo } from "../../../redux/other/ProgressReportingREMContactInfo";

// CSS imports

import "react-table/react-table.css";
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

// Redux imports
// Redux imports
import { getNarrativeAndGroupNarrativeM2MData, setProgressReportingStateCountyTableSort, progressReportingNotifyREM } from "../../../redux/actions/index";

const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        lempgUserType: state.rootReducer.lempgUserType,
        isTaskingSlipGeneratedForQuarter: state.rootReducer.isTaskingSlipGeneratedForQuarter, 
        progressReportingQuarter: state.rootReducer.progressReportingQuarter,
        progressReportingStateCountyTableSort: state.rootReducer.progressReportingStateCountyTableSort,
        selectedYear: state.rootReducer.selectedYear,
        allREMContacts: state.rootReducer.allREMContacts,        
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getNarrativeAndGroupNarrativeM2MData: (payload, callback) => dispatch(getNarrativeAndGroupNarrativeM2MData(payload, callback)),
        setProgressReportingStateCountyTableSort: (payload) => dispatch(setProgressReportingStateCountyTableSort(payload)),
        progressReportingNotifyREM: (payload, callback) => dispatch(progressReportingNotifyREM(payload, callback))
        
    }
};



class ProgressReportCountyPageForState extends React.Component {

    state = {
        selectedQuarter: 0,
        progressReportingStateCountyTableSort: this.props.progressReportingStateCountyTableSort,
        remDialogOpen: false
    }

    constructor(props, context) {
        super(props, context);
        this.processAndSortItems = this.processAndSortItems.bind(this);
    }


    goBack = () => {
        this.props.history.goBack();
    }

    generateTaskingSlip = () => {
        this.props.history.push("/progressreport/county/taskingslip");
    }

    capitalize(str) {
        return str.substring(0, 1).toUpperCase() + str.substring(1);
    }

    notifyREM = () => {
        let payload = this.props.selectedCounty;
        payload.quarter = this.state.selectedQuarter;
        this.props.progressReportingNotifyREM(payload, (err, res) => {
            if (err) {
                console.log("Error:", err);
            } else {
                this.setState({ remDialogOpen: true });
            }
        })
    }    

    handleClose = (e) => {
        this.setState({ remDialogOpen: false });
    }

    /* State users cant see tasking slip unless financial reimbursements for the particular quarter is approved AND all narratives are approved */ 

    checkForApprovedNarratives = () => {

        let areAllNarrativesApproved = true;
        let finItems = this.props.selectedCounty && this.props.selectedCounty.financialreimbursement && this.props.selectedCounty.financialreimbursement.items;


        this.props.selectedCounty
            && this.props.selectedCounty.narratives
            && this.props.selectedCounty.narratives.items
            && this.props.selectedCounty.narratives.items.forEach((item) => {

                if (item.quarter == this.props.progressReportingQuarter) {

                    if((item.narrative.useFinancialReimbursementStatus)) {
                        // loop over finItems
                        finItems.forEach((finItem) => {
                            if(finItem.quarter === this.props.progressReportingQuarter) {
                                item.status = finItem.status;
                                if (item.status == "AWARDED") item.status = "APPROVED";
                                else if (item.status == "PROCESSED") item.status = "APPROVED";
                                else if (item.status == "NOT_AVAILABLE") item.status = "DRAFT";
                                else if (item.status == "NOT_STARTED") item.status = "DRAFT";
            
                            }
                        })
                    }
                    if(item.status) {
                        if (item.status && item.status != "APPROVED") {
                            areAllNarrativesApproved = false;
                        }
                    } else {
                        areAllNarrativesApproved = false;
                    }
                }
            })

        return areAllNarrativesApproved;
    }

    returnUpdatedPOCInformation = (narrative) => {
        if(narrative.usePalmettoForREMInformation) {
            this.props.allREMContacts.forEach((contact) => {
                if(contact.region == this.props.selectedCounty.region) {
                    narrative.narrativePOCFirstName = contact.firstName;
                    narrative.narrativePOCLastName = contact.lastName;
                    narrative.narrativePOCPhoneNumber = contact.phoneNumber;
                    narrative.narrativePOCEmailAddress = contact.emailAddress;
                    narrative.narrativePOCPositionTitle = contact.positionTitle;
                }
            })            
        } else {
            if ((narrative.narrativeTitle == "M-113") || (narrative.narrativeTitle == "M-107") || (narrative.narrativeTitle == "M-108") || (narrative.narrativeTitle == "M113") || (narrative.narrativeTitle == "M107") || (narrative.narrativeTitle == "M108")) {
                let contactInfo = REMContactInfo[narrative.narrativeTitle][this.props.selectedCounty.region];

                Object.keys(contactInfo).forEach((key) => {
                    narrative[key] = contactInfo[key];
                })
            }
        }
        return narrative
    }

    processAndSortItems(county) {

        var arr = [];

        let items = county && county.narratives && county.narratives.items;
        let finItems = county && county.financialreimbursement && county.financialreimbursement.items;


        items.forEach((item) => {
            if (item.quarter == this.props.progressReportingQuarter) {

                if((item.narrative.useFinancialReimbursementStatus)) {
                    // loop over finItems
                    finItems.forEach((finItem) => {
                        if(finItem.quarter === this.props.progressReportingQuarter) {
                            item.status = finItem.status;

                        }
                    })
                    item.updatedStatus = item.status ? item.status : "Draft";
                    if (item.status == "AWARDED") item.updatedStatus = "Approved";
                    if (item.status == "PROCESSED") item.updatedStatus = "Approved";
                    if (item.status == "NOT_AVAILABLE") item.updatedStatus = "Draft";
                    if (item.status == "NOT_STARTED") item.updatedStatus = "Draft";
                } 
            }
        })

        items.forEach((item) => {
            if (item.quarter == this.props.progressReportingQuarter) {
                item.updatedStatus = item.status ? item.status : "Draft";
                if (item.status == "NOT_AVAILABLE") item.updatedStatus = "Not Available";
                if (item.status == "NOT_STARTED") item.updatedStatus = "Not Started";
                if (item.status == "CHANGES_REQUESTED") item.updatedStatus = "Changes Requested";

                if(item.narrative) {
                    item.narrative = this.returnUpdatedPOCInformation(item.narrative)
                    item.narrativePOCFullName = ((item.narrative.narrativePOCFirstName || "") + " " + (item.narrative.narrativePOCLastName || ""))
                }

                if(item.currentExtension && item.currentExtension.status === "APPROVED" &&
                  item.currentExtension.dueDate) {
                    item.extensionDate = 
                       moment(item.currentExtension.dateAdjustedTo || item.currentExtension.dueDate, "YYYY-MM-DD").local().format("MM-DD-YYYY");
                }

                arr.push(item);
            }
        })

        return arr && arr.sort((a, b) => {
            return a.narrative.narrativeTitle.localeCompare(b.narrative.narrativeTitle);
        });

    }
    _convertToTitleCase = (str) => {
        str = str.toLowerCase();
        return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    }

    onRowClick = (state, rowInfo, column, instance) => {
        return {
            onClick: (e, handleOriginal) => {

                this.props.history.push("/progressreport/county/narrative");

                this.props.getNarrativeAndGroupNarrativeM2MData(rowInfo.original, () => {
                });

                if (handleOriginal) {
                    handleOriginal();
                }
            }
        };
    };

    onSortChange = (column) => {
        this.props.setProgressReportingStateCountyTableSort(column)
    }


    render() {
        return (

            <div className="layout vertical full-height">

                <Dialog
                    open={this.state.remDialogOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Notify REM</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            REM has been notified via email that {this.capitalize(this.props.selectedCounty.groupName)} County's work elements have been submitted and approved.
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Okay
                        </Button>
                    </DialogActions>

                </Dialog>

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdArrowBack className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Progress Reporting</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>{window.appHeaderTitle} • {this.props.selectedCounty && this.props.selectedCounty.groupName}</span>}
                />


                <div className="flex container-overflow-y">
                    <ReactTable
                        minRows={0}
                        data={this.processAndSortItems(this.props.selectedCounty)}
                        defaultPageSize={100}
                        NoDataComponent={() => null}
                        showPagination={false}
                        getTdProps={this.onRowClick}
                        onSortedChange={this.onSortChange}
                        defaultSorted={this.state.progressReportingStateCountyTableSort}
                        columns={[

                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">EMF</div>
                                ),
                                className: "body-cell",
                                accessor: "narrative.narrativeTitle"
                            },
                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">Status</div>
                                ),
                                className: "body-cell",
                                accessor: "updatedStatus",
                                Cell: row => (
                                    <StatusComponent text={this._convertToTitleCase(row.value)} />
                                )
                            },

                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">Frequency</div>
                                ),
                                className: "body-cell",
                                accessor: "narrative.narrativeFrequency",
                            },

                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">POC</div>
                                ),
                                className: "body-cell",
                                accessor: "narrativePOCFullName",
                            },

                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">Extension</div>
                                ),
                                className: "body-cell",
                                accessor: "extensionDate",
                            }

                        ]}
                    />

                </div>

                {/* State users cant see tasking slip unless financial reimbursements for the particular quarter is approved AND all narratives are approved */}
                
                {/* Supplemetal system dont need this case */}

                {/* {
                    this.props.isTaskingSlipGeneratedForQuarter ? "" :
                    this.props.lempgUserType
                        && this.props.lempgUserType != "LEMPG_ACCESS"
                        && this.checkForApprovedNarratives() 
                        ?
                        <div className="layout horizontal center-justified  center form-container" style={{ borderTop: "1px solid #E0E0E0" }}>
                            <PrimaryButton setwidth="true" onClick={() => this.generateTaskingSlip()}> Generate Tasking Slip </PrimaryButton>
                            <PrimaryButton setwidth="true" margin="6px" onClick={() => this.notifyREM()}> Notify REM </PrimaryButton>
                        </div>
                        : ""
                } */}


            </div>


        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressReportCountyPageForState);
