import React from 'react';
import { MdCheckCircle, MdCancel } from 'react-icons/md';

import moment from 'moment';

import "../../../styles/common.css";
import "../../../styles/common.charts.css";

// Parent here is the CountySplashScreen, which passes on the selectedYear
class GrantPackageDocumentProgress extends React.Component {

    state = {
        documents: {
            "acceptanceOfAuditReqFile": "Acceptance of Audit Requirements",
            "certificationDocumentFile": "Certification Document",
            "assurancesNonContructionFile": "Assurances Non-Construction",
            "lobbyingCertificationFile": "Lobbying Certification",
            "edmEquipmentPolicyFile": "Equipment Policy",
            "narrativesFile": "Narratives",
            "guidelinesFile": "Supplemental Guidelines",
        },
    }


    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (

            <div className="layout vertical pie-chart-container grant-package-document-container inline-grid">
                <div>
                    <span className="section-title form-margin-16">Grant Package Document Progress</span>
                    <div className="height-10"></div>
                    <div className="layout horizontal form-margin-16 title-divider"></div>
                </div>
                <div className="form-margin-16 flex">
                    <div className="height-10"></div>
                    {
                        Object.keys(this.state.documents).map( (document) => {
                            return (
                                <div key={document} class="layout horizontal height-32 center grant-document">
                                    {
                                        this.props.documents &&
                                        this.props.documents[document] ?
                                        <MdCheckCircle className="green-cirle-check" />
                                            : <MdCancel className="remove-outline" />

                                    }
                                    <div className="spacer-10"></div>{this.state.documents[document]}
                                </div>      
                            )
                        })
                    }
                </div>
            </div>
        )
    }

}


export default GrantPackageDocumentProgress;
