import React from 'react';
import { connect } from 'react-redux'
import { HeaderComponent } from "../../layout/HeaderComponent.js"
import IconButton from "@material-ui/core/IconButton";
import { MdArrowBack } from 'react-icons/md';
import { HeaderButton } from "../../styled-components/Buttons/Buttons.js";
import { NormalTextField } from "../../styled-components/TextFields/TextFields.js";
import FileUpload from "../../common/FileUpload.js";

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";


const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        currentNarrative: state.rootReducer.currentNarrative,
        currentGroupNarrativeM2M: state.rootReducer.currentGroupNarrativeM2M,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // updateStatusGroupNarrativeM2M: (payload, callback) => dispatch(updateStatusGroupNarrativeM2M(payload, callback)),

    }
}

class NarrativesSpecificView extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
        };

        this.tempNarrativeData = {
            "narrativeTitle": "M-101",
            "narrativeQuarter": "Q1",
            "narrativeShortDesc": "Short Description",
            "narrativeLongDesc": "Long Description",
            "narrativePocFirstName": "Jane",
            "narrativePocLastName": "Doe",
            "narrativePocPhone": "8031234567",
            "narrativePocEmail": "test@email.com"
        }
        Object.assign(this.state, this.tempNarrativeData);
    }

    goBack = () => {
        this.props.history.goBack()
    }

    showEditPage(e) {
        this.props.history.push("/narratives/edit");
    }

    render() {
        return (
            <div className="layout vertical full-height">

                <HeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdArrowBack className="icon" />
                        </IconButton>

                    }
                    title={<span>View entry</span>}
                    suffix={
                        <HeaderButton hidden={false} onClick={(e) => this.showEditPage(e)}>
                            EDIT
                        </HeaderButton>
                    }
                />

                <div className="flex form-shell container-overflow-y">
                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.narrativeTitle} id="narrativeTitle" label="Title" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />
                        <NormalTextField value={this.state.narrativeQuarter} id="narrativeQuarter" label="Quarter" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />
                    </div>
                    <div className="height-10"></div>
                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.narrativeShortDesc} id="narrativeShortDesc" label="Short description" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />
                        <NormalTextField value={this.state.narrativeLongDesc} id="narrativeLongDesc" label="Long Description" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />
                    </div>
                    <div className="height-10"></div>
                    <div className="layout horizontal center section-title form-margin">Point of Contact</div>
                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.narrativePocFirstName} id="narrativePocFirstName" label="First name" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />
                        <NormalTextField value={this.state.narrativePocLastName} id="narrativePocLastName" label="Last name" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />
                    </div>
                    <div className="height-10"></div>
                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.narrativePocPhone} id="narrativePocPhone" label="Phone number" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />
                        <NormalTextField value={this.state.narrativePocEmail} id="narrativePocEmail" label="Email address" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />
                    </div>
                    <div className="height-10"></div>
                    <div className="layout horizontal center section-title form-margin">File Attachments</div>
                    <div className="layout horizontal form-margin">
                        <FileUpload fileToSave={this.fileToSave} fileToDelete={this.fileToDelete} hideUploadContainer={this.state.isTaskingSlipGeneratedForQuarter} noEdit={this.state.isTaskingSlipGeneratedForQuarter} supportingTitle="Include supporting documentation" notitle="true" className="flex" uploadedFile={null} extraFiles={null} />
                    </div>
                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NarrativesSpecificView);
