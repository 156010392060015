import accounting from 'accounting';
import { returnQuarterForDate } from '../../utilities/QuarterDates';
import { getFileUploadedObject, } from '../../common/FileUploadApi';
import axios from 'axios';


/**
 * 
 * Calculates `amountType` -> `quarterAmount` or `matchAmount` for all the items in 
 * financial reimbursements for a given quarter
 * 
 * All items include personnel, equipment, contractualService, supply, travel, cert, other
 * 
 */

export const calculateAmountByAmountType = (type, currentQuarter, amountType) => {
    let amount = 0;
    currentQuarter[type] &&
        currentQuarter[type].items &&
        currentQuarter[type].items.forEach((item) => {
            if (!item.deleted) {
                item.type = type;
                if (item[amountType] && !isNaN(parseFloat(item[amountType]))) {
                    amount += parseFloat(item[amountType]);
                }
                // if (item.taxAmount && !isNaN(parseFloat(item.taxAmount))) {
                //     amount += parseFloat(item.taxAmount);
                // }
            }
        })

    return amount;
}

/**
 * 
 * Calculates amount for a financial reimbursements by amountType
 * 
 */

export const getQuarterAmountByAmountType = (currentQuarter, types, amountType) => {
    let totalAmount = 0;

    types.forEach((type) => {
        totalAmount += calculateAmountByAmountType(type, currentQuarter, amountType)
    })

    return totalAmount;
}

/**
 * 
 * Function splits `100.26` into `100` and `26`
 * 
 */

export const splitAmountIntoWholeNumberAndDecimal = (total) => {

    total = Number.parseFloat(total).toFixed(2)

    let totalWhole = (total + "").split(".")[0];
    let totalDecimal;

    if ((total + "").split(".")[1]) {
        totalDecimal = (total + "").split(".")[1];
    }

    return {
        whole: totalWhole,
        decimal: totalDecimal
    }
}

/**
 * This returns summation of amounts entered in one of the typed items.
 * 
 * @param {Array} items, default is empty array
 * @param {Object} entry 
 */


export const calculateAmount = (items, entry) => {
    let amount = 0;

    items && items.forEach((item) => {
        if (!item.deleted && (item.id === entry.id)) {
            if (item.quarterAmount && !isNaN(parseFloat(item.quarterAmount))) {
                amount += parseFloat(item.quarterAmount);
            }
            if (item.matchAmount && !isNaN(parseFloat(item.matchAmount))) {
                amount += parseFloat(item.matchAmount);
            }
            // if (item.taxAmount && !isNaN(parseFloat(item.taxAmount))) {
            //     amount += parseFloat(item.taxAmount);
            // }
        }
    })

    return Number.parseFloat(amount).toFixed(2);
}

/**
 * 
 * Returns non deleted items
 * 
 * @param {Array} items 
 */

export const getNonDeletedItems = (items) => {
    var arr = []

    items.forEach((item) => {
        if (!item.deleted) arr.push(item)
    })

    return arr.length;
}


/**
 * 
 * Award total based on the submitted grant application
 * 
 */

export const getAwardedTotal = (data, type) => {
    let total = 0;

    if (data[type] && data[type].items) {
        for (var i = 0; i < data[type].items.length; i++) {
            let item = data[type].items[i];
            if (!item.deleted) {
                total += (item.federal && !isNaN(item.federal) ? parseFloat(item.federal) : 0);
            }
        }
    }
    return total;
}

export const getApplicationAwardedTotal = (data, type) => {
    let totals = {
        federalTotal: 0,
        countyTotal: 0,
        countyInKindTotal: 0,
        total: 0
    };
    if (data[type] && data[type].items) {
        for (var i = 0; i < data[type].items.length; i++) {
            let item = data[type].items[i];
            if (!item.deleted) {
                let federal = item.federal && !isNaN(item.federal) ? parseFloat(item.federal) : 0;
                totals.federalTotal += federal;
                let county = item.county && !isNaN(item.county) ? parseFloat(item.county) : 0;
                totals.countyTotal += county;
                let countyInKind = item.countyInKind && !isNaN(item.countyInKind) ? parseFloat(item.countyInKind) : 0;
                totals.countyInKindTotal += countyInKind;
                let total = federal + county + countyInKind;
                totals.total += total;
            }
        }
    }
    return totals;
}

export const getDeobligationSums = (selectedCounty) => {
    // if there are funds to deobligate, return true
    const types = ['cert', 'contractualService', 'equipment', 'other', 'personnel', 'supply', 'travel'];
    const gaTypes = ['cert', 'contractualService', 'equipment', 'other', 'salary', 'supply', 'travel'];

    let quarters = selectedCounty.financialreimbursement.items;
    let ga = selectedCounty.grantapplication;
    let fedAmt = 0;
    let overmatchAmt = 0;
    let gaFed = ga.items[0].fbFederalFunding && !isNaN(ga.items[0].fbFederalFunding) ? parseFloat(ga.items[0].fbFederalFunding) : 0;

    let gaOvermatch = 0
    quarters.forEach((qtr) => {
        let qtrObj = getQuarter(qtr);
        types.forEach((type) => {
            fedAmt += parseFloat(qtrObj.totalFedExpendedQuarter && qtrObj.totalFedExpendedQuarter[type]) || 0;
        });
        overmatchAmt += (qtrObj.totalOvermatchQuarter) || 0;
    });

    for(var i = 0; i < gaTypes.length; i++) {
        var type = gaTypes[i];
        if (ga.items[0][type] && ga.items[0][type].items) {
    
            // For cert, if they have this option selected in grant application, we wont add the federal amount to the grant application
    
            // if (type == 'cert' && ga.items[0].deductCertFromFederal) continue;
    
            ga.items[0][type].items.forEach((item) => {
                if (!item.deleted) {
                    gaFed += parseFloat(item.federal) || 0;
                    gaOvermatch += parseFloat(item.county) || 0;
                }
            });
        }
    }
    return {
        financialFederal: convertNumberBasedOnAccountingRules(fedAmt),
        financialCountyOvermatch: convertNumberBasedOnAccountingRules(overmatchAmt),
        grantFederal: convertNumberBasedOnAccountingRules(gaFed),
        grantOvermatch: convertNumberBasedOnAccountingRules(gaOvermatch),
    }
}

export const convertNumberBasedOnAccountingRules = (val) => {
    return parseFloat(accounting.formatNumber(val, 2).replace(/,/g, ''));
}

/**
 * 
 * Total amount previously reimbursed.
 * 
 */

export const getPreviousReimbursementsAmount = (name, currentQuarter, quarters) => {
    let prevQuarterIndex = -1;
    switch (currentQuarter) {
        case 'quarter2':
            prevQuarterIndex = 0;
            break;
        case 'quarter3':
            prevQuarterIndex = 1;
            break;
        case 'quarter4':
            prevQuarterIndex = 2;
            break;
        default:
            break;
    }
    let amount = 0;
    for (let i = 0; i <= prevQuarterIndex; i++) {
        if (quarters[i] && quarters[i].quarterlyShareReimbursed &&
            quarters[i].quarterlyShareReimbursed[name]) {
            amount += quarters[i].quarterlyShareReimbursed[name];
        }
    }
    return amount;
}

export const getQuarter = (quarter) => {

    let types = [
        { name: 'personnel' },
        { name: 'contractualService' },
        { name: 'travel' },
        { name: 'equipment' },
        { name: 'supply' },
        { name: 'other' },
        { name: 'cert' }
    ];
    let matchAmounts = [];
    let totalMatchQuarter = [];
    totalMatchQuarter['personnel'] = totalMatchQuarter['contractualService'] =
        totalMatchQuarter['travel'] = totalMatchQuarter['equipment'] =
        totalMatchQuarter['supply'] = totalMatchQuarter['other'] =
        totalMatchQuarter['cert'] = 0;
    let totalFedExpendedQuarter = [];
    totalFedExpendedQuarter['personnel'] = totalFedExpendedQuarter['contractualService'] =
        totalFedExpendedQuarter['travel'] = totalFedExpendedQuarter['equipment'] =
        totalFedExpendedQuarter['supply'] = totalFedExpendedQuarter['other'] =
        totalFedExpendedQuarter['cert'] = 0;

    for (let i = 0; i < types.length; i++) {
        let type = types[i];
        if (quarter[type.name] && quarter[type.name].items) {
            for (let j = 0; j < quarter[type.name].items.length; j++) {
                let item = quarter[type.name].items[j];
                if (item.deleted) {
                    continue;
                }
                if (item.matchAmount) {
                    matchAmounts.push({ title: item.positionTitle, name: type.name, amount: item.matchAmount });
                    //	continue;
                }
                totalFedExpendedQuarter[type.name] = parseFloat(item.quarterAmount.trim()) +
                    (!totalFedExpendedQuarter[type.name] ? 0 : totalFedExpendedQuarter[type.name]);
                if (item.taxAmount && !isNaN(item.taxAmount) && parseFloat(item.taxAmount) > 0) {
                    totalFedExpendedQuarter[type.name] = parseFloat(item.taxAmount.trim()) +
                        (!totalFedExpendedQuarter[type.name] ? 0 : totalFedExpendedQuarter[type.name]);
                }
            }
        }
    }
    for (let i = 0; i < matchAmounts.length; i++) {
        let item = matchAmounts[i];
        totalMatchQuarter[item.name] = parseFloat(item.amount.trim()) +
            (!totalMatchQuarter[item.name] ? 0 : totalMatchQuarter[item.name]);
    }
    let federalShare = [];
    let quarterlyShareReimbursed = [];
    let quarterlyOvermatch = [];
    let totalQuarterlyOvermatch = 0;
    for (let i = 0; i < types.length; i++) {
        let type = types[i];
        quarterlyShareReimbursed[type.name] = federalShare[type.name] = totalFedExpendedQuarter[type.name];
        quarterlyOvermatch[type.name] = totalMatchQuarter[type.name] - quarterlyShareReimbursed[type.name];
        totalQuarterlyOvermatch += quarterlyOvermatch[type.name];
    }
    return {
        quarter: quarter.quarter,
        totalFedExpendedQuarter: totalFedExpendedQuarter,
        totalMatchQuarter: totalMatchQuarter,
        totalOvermatchQuarter: totalQuarterlyOvermatch,
        federalShare: federalShare,
        quarterlyShareReimbursed: quarterlyShareReimbursed,
        quarterlyOvermatch: quarterlyOvermatch,

    };

}

// Send a financial reimbursement quarter object which cak

export const getRemainingQuarterlyOvermatch = (financialQuarters, quarterToGetOvermatchFor) => {

    let quarters = [4];
    if (financialQuarters) {
        for (let i = 0; i < financialQuarters.length; i++) {
            let tmpQuarter = getQuarter(financialQuarters[i]);
            switch (tmpQuarter.quarter) {
                case 'quarter1':
                    quarters[0] = tmpQuarter;
                    break;
                case 'quarter2':
                    quarters[1] = tmpQuarter;
                    break;
                case 'quarter3':
                    quarters[2] = tmpQuarter;
                    break;
                case 'quarter4':
                    quarters[3] = tmpQuarter;
                    break;
            }
        }
    }


    let previousRemainingOvermatch = 0;
    let remainingQuarterlyOvermatch = 0;
    let totalOvermatchQuarters = [];
    if (quarterToGetOvermatchFor !== 'quarter1') {
        for (let i = 0; i < quarters.length; i++) {
            totalOvermatchQuarters[quarters[i].quarter] = quarters[i].totalOvermatchQuarter;
        }
        switch (quarterToGetOvermatchFor) {
            case 'quarter2':
                previousRemainingOvermatch = totalOvermatchQuarters['quarter1'];
                remainingQuarterlyOvermatch = previousRemainingOvermatch + totalOvermatchQuarters['quarter2'];
                break;
            case 'quarter3':
                previousRemainingOvermatch = totalOvermatchQuarters['quarter1'] + totalOvermatchQuarters['quarter2'];
                remainingQuarterlyOvermatch = previousRemainingOvermatch + totalOvermatchQuarters['quarter3'];
                break;
            case 'quarter4':
                previousRemainingOvermatch = totalOvermatchQuarters['quarter1'] + totalOvermatchQuarters['quarter2'] + totalOvermatchQuarters['quarter3'];
                remainingQuarterlyOvermatch = previousRemainingOvermatch + totalOvermatchQuarters['quarter4'];
                break;

        }
    }

    return remainingQuarterlyOvermatch;

}

export const getUnobligatedUnexpendedForQuarter = (props, quarter) => {
    if (props.selectedCounty.financialreimbursement) {
        let types = ['cert', 'contractualService', 'equipment', 'other', 'personnel', 'supply', 'travel'];
        let fbFederalTotal = props.currentApplicationState.fbFederalFunding && !isNaN(props.currentApplicationState.fbFederalFunding) ?
            parseFloat(props.currentApplicationState.fbFederalFunding) : 0;

        let financialQuarters = [];

        if (props.selectedCounty.financialreimbursement.items && props.selectedCounty.financialreimbursement.items.length) {
            props.selectedCounty.financialreimbursement.items.sort((a, b) => {
                return a.quarter.localeCompare(b.quarter)
            })
        }

        financialQuarters[0] = props.selectedCounty.financialreimbursement.items && props.selectedCounty.financialreimbursement.items.length > 0 ? getQuarter(props.selectedCounty.financialreimbursement.items[0]) : null;
        financialQuarters[1] = props.selectedCounty.financialreimbursement.items && props.selectedCounty.financialreimbursement.items.length > 1 ? getQuarter(props.selectedCounty.financialreimbursement.items[1]) : null;
        financialQuarters[2] = props.selectedCounty.financialreimbursement.items && props.selectedCounty.financialreimbursement.items.length > 2 ? getQuarter(props.selectedCounty.financialreimbursement.items[2]) : null;
        financialQuarters[3] = props.selectedCounty.financialreimbursement.items && props.selectedCounty.financialreimbursement.items.length > 3 ? getQuarter(props.selectedCounty.financialreimbursement.items[3]) : null;

        let totalAwarded = 0;
        types.forEach(function (type) {
            totalAwarded += type === 'personnel' ? (getApplicationAwardedTotal(props.currentApplicationState, 'salary').federalTotal + fbFederalTotal) :
                getApplicationAwardedTotal(props.currentApplicationState, type).federalTotal;
        });

        let quarters = [];
        quarters[0] = quarters[1] = quarters[2] = quarters[3] = 0;
        for (let i = 0; i < financialQuarters.length; i++) {
            switch (financialQuarters[i].quarter) {
                case 'quarter1':
                    types.forEach(function (type) {
                        quarters[0] += financialQuarters[i].quarterlyShareReimbursed[type];
                    });
                    break;
                case 'quarter2':
                    types.forEach(function (type) {
                        quarters[1] += financialQuarters[i].quarterlyShareReimbursed[type];
                    });
                    break;
                case 'quarter3':
                    types.forEach(function (type) {
                        quarters[2] += financialQuarters[i].quarterlyShareReimbursed[type];
                    });
                    break;
                case 'quarter4':
                    types.forEach(function (type) {
                        quarters[3] += financialQuarters[i].quarterlyShareReimbursed[type];
                    });
                    break;
            }
        }
        switch (quarter) {
            case 'quarter1':
                return totalAwarded - quarters[0];
            case 'quarter2':
                return totalAwarded - quarters[0] - quarters[1];
            case 'quarter3':
                return totalAwarded - quarters[2] - quarters[1] - quarters[0];
            case 'quarter4':
                return totalAwarded - quarters[3] - quarters[2] - quarters[1] - quarters[0];
        }
    }
    return 0;
}

export const calculateRemainingFederal = (props) => {
    let types = ['cert', 'contractualService', 'equipment', 'other', 'personnel', 'supply', 'travel'];
    let fedRemainingTotal = 0;
    let fedRemainingTypes = [];
    let fedOverdrawn = false;

    let fbFederalTotal = props.currentApplicationState.fbFederalFunding && !isNaN(props.currentApplicationState.fbFederalFunding) ?
        parseFloat(props.currentApplicationState.fbFederalFunding) : 0;
    let quarters = [];

    if (props.selectedCounty.financialreimbursement) {

        if (props.selectedCounty.financialreimbursement.items && props.selectedCounty.financialreimbursement.items.length) {
            props.selectedCounty.financialreimbursement.items.sort((a, b) => {
                return a.quarter.localeCompare(b.quarter)
            })
        }

        quarters[0] = props.selectedCounty.financialreimbursement.items && props.selectedCounty.financialreimbursement.items.length > 0 ? getQuarter(props.selectedCounty.financialreimbursement.items[0]) : null;
        quarters[1] = props.selectedCounty.financialreimbursement.items && props.selectedCounty.financialreimbursement.items.length > 1 ? getQuarter(props.selectedCounty.financialreimbursement.items[1]) : null;
        quarters[2] = props.selectedCounty.financialreimbursement.items && props.selectedCounty.financialreimbursement.items.length > 2 ? getQuarter(props.selectedCounty.financialreimbursement.items[2]) : null;
        quarters[3] = props.selectedCounty.financialreimbursement.items && props.selectedCounty.financialreimbursement.items.length > 3 ? getQuarter(props.selectedCounty.financialreimbursement.items[3]) : null;
    }

    for (let i = 0; i < types.length; i++) {
        let federalSpentThisQuarter = getQuarterAmountByAmountType(props.currentQuarter, [types[i]], "quarterAmount");
        let type = types[i];
        let award = type === 'personnel' ?
            (getApplicationAwardedTotal(props.currentApplicationState, 'salary').federalTotal + fbFederalTotal) :
            getApplicationAwardedTotal(props.currentApplicationState, type).federalTotal;
        let previousReimbursementsAmounts = getPreviousReimbursementsAmount(type, props.currentQuarter.quarter, quarters);
        // Calculate total fed remaining by subtracting each types award amount from amount previously submitted.
        fedRemainingTypes[type] = award - previousReimbursementsAmounts - federalSpentThisQuarter;
        fedOverdrawn = (!fedOverdrawn && fedRemainingTypes[type] < 0) ? true : fedOverdrawn;
        fedRemainingTotal += fedRemainingTypes[type];
    }

    return {
        total: fedRemainingTotal,
        types: fedRemainingTypes,
        overdrawn: fedOverdrawn
    }
}

export const calculateTotalsForQuarter = (props) => {
    let types = ['cert', 'contractualService', 'equipment', 'other', 'personnel', 'supply', 'travel'];
    let quarterlyOvermatchTotal = 0;
    let unobligatedExpendedTotal = 0;
    let fedExpendedTotal = 0;
    let matchTotal = 0;
    let remainingTotals = [];

    let fbFederalTotal = props.currentApplicationState.fbFederalFunding && !isNaN(props.currentApplicationState.fbFederalFunding) ?
        parseFloat(props.currentApplicationState.fbFederalFunding) : 0;
    let quarters = [];

    if (props.selectedCounty.financialreimbursement) {

        if (props.selectedCounty.financialreimbursement.items && props.selectedCounty.financialreimbursement.items.length) {
            props.selectedCounty.financialreimbursement.items.sort((a, b) => {
                return a.quarter.localeCompare(b.quarter)
            })
        }

        quarters[0] = props.selectedCounty.financialreimbursement.items && props.selectedCounty.financialreimbursement.items.length > 0 ? getQuarter(props.selectedCounty.financialreimbursement.items[0]) : null;
        quarters[1] = props.selectedCounty.financialreimbursement.items && props.selectedCounty.financialreimbursement.items.length > 1 ? getQuarter(props.selectedCounty.financialreimbursement.items[1]) : null;
        quarters[2] = props.selectedCounty.financialreimbursement.items && props.selectedCounty.financialreimbursement.items.length > 2 ? getQuarter(props.selectedCounty.financialreimbursement.items[2]) : null;
        quarters[3] = props.selectedCounty.financialreimbursement.items && props.selectedCounty.financialreimbursement.items.length > 3 ? getQuarter(props.selectedCounty.financialreimbursement.items[3]) : null;
    }

    for (let i = 0; i < types.length; i++) {
        let type = types[i];
        let fedExpendedQuarter = 0;
        let matchAmountsQuarter = 0;
        let award = type === 'personnel' ?
            (getApplicationAwardedTotal(props.currentApplicationState, 'salary').federalTotal + fbFederalTotal) :
            getApplicationAwardedTotal(props.currentApplicationState, type).federalTotal;
        if (props.currentQuarter[type] && props.currentQuarter[type].items.length > 0) {
            for (let j = 0; j < props.currentQuarter[type].items.length; j++) {
                let item = props.currentQuarter[type].items[j];
                if (item.deleted) {
                    continue;
                }
                if (item.quarterAmount && !isNaN(item.quarterAmount)) {
                    fedExpendedQuarter += parseFloat(item.quarterAmount);
                }
                if (item.taxAmount && !isNaN(item.taxAmount)) {
                    fedExpendedQuarter += parseFloat(item.taxAmount);
                }
                if (item.matchAmount && !isNaN(item.matchAmount)) {
                    matchAmountsQuarter += parseFloat(item.matchAmount.trim());
                }
            }
        }
        let previousReimbursementsAmounts = getPreviousReimbursementsAmount(type, props.currentQuarter.quarter, quarters);
        // Calculate total fed remaining by subtracting each types award amount from amount previously submitted.
        remainingTotals[type] = award - previousReimbursementsAmounts;
        quarterlyOvermatchTotal += matchAmountsQuarter - fedExpendedQuarter;
        fedExpendedTotal += fedExpendedQuarter;
        matchTotal += matchAmountsQuarter;
    }
    unobligatedExpendedTotal = getUnobligatedUnexpendedForQuarter(props, props.currentQuarter.quarter);
    return {
        total: Number.parseFloat(fedExpendedTotal + matchTotal).toFixed(2),
        federal: Number.parseFloat(fedExpendedTotal).toFixed(2),
        overmatch: Number.parseFloat(quarterlyOvermatchTotal).toFixed(2),
        unobligated: Number.parseFloat(unobligatedExpendedTotal).toFixed(2),
        remaining: remainingTotals
    }
}

export const getRemainingOvermatch = (props) => {
    let quarters = [];

    if (props.selectedCounty.financialreimbursement.items && props.selectedCounty.financialreimbursement.items.length) {
        props.selectedCounty.financialreimbursement.items.sort((a, b) => {
            return a.quarter.localeCompare(b.quarter)
        })
    }


    quarters[0] = props.selectedCounty.financialreimbursement.items && props.selectedCounty.financialreimbursement.items.length > 0 ? getQuarter(props.selectedCounty.financialreimbursement.items[0]) : null;
    quarters[1] = props.selectedCounty.financialreimbursement.items && props.selectedCounty.financialreimbursement.items.length > 1 ? getQuarter(props.selectedCounty.financialreimbursement.items[1]) : null;
    quarters[2] = props.selectedCounty.financialreimbursement.items && props.selectedCounty.financialreimbursement.items.length > 2 ? getQuarter(props.selectedCounty.financialreimbursement.items[2]) : null;
    quarters[3] = props.selectedCounty.financialreimbursement.items && props.selectedCounty.financialreimbursement.items.length > 3 ? getQuarter(props.selectedCounty.financialreimbursement.items[3]) : null;

    let previousRemainingOvermatch = 0;
    let remainingQuarterlyOvermatch = 0;
    let totalOvermatchQuarters = [];
    for (let i = 0; i < quarters.length; i++) {
        totalOvermatchQuarters[quarters[i].quarter] = quarters[i].totalOvermatchQuarter;
    }
    switch (props.currentQuarter.quarter) {

        case 'quarter1':
            previousRemainingOvermatch = 0;
            remainingQuarterlyOvermatch = previousRemainingOvermatch + totalOvermatchQuarters['quarter1'];
            break;

        case 'quarter2':
            previousRemainingOvermatch = totalOvermatchQuarters['quarter1'];
            remainingQuarterlyOvermatch = previousRemainingOvermatch + totalOvermatchQuarters['quarter2'];
            break;
        case 'quarter3':
            previousRemainingOvermatch = totalOvermatchQuarters['quarter1'] + totalOvermatchQuarters['quarter2'];
            remainingQuarterlyOvermatch = previousRemainingOvermatch + totalOvermatchQuarters['quarter3'];
            break;
        case 'quarter4':
            previousRemainingOvermatch = totalOvermatchQuarters['quarter1'] + totalOvermatchQuarters['quarter2'] + totalOvermatchQuarters['quarter3'];
            remainingQuarterlyOvermatch = previousRemainingOvermatch + totalOvermatchQuarters['quarter4'];
            break;

    }

    return {
        previous: parseFloat(previousRemainingOvermatch.toFixed(2)),
        current: parseFloat(remainingQuarterlyOvermatch.toFixed(2))
    }
}

export const getInformationalCardInformation = (props) => {
    let informationalCards = [];
    let count = 0;
    let totals = calculateTotalsForQuarter(props);
    let remainingOvermatch = getRemainingOvermatch(props);
    let { whole, decimal } = splitAmountIntoWholeNumberAndDecimal(totals.total);
    informationalCards.push({
        id: count++,
        title: "TOTAL EXPENDED THIS QTR",
        amount1: whole,
        amount2: decimal ? ("." + decimal) : "",
        class: "informational-card-text-color-safe",
    })

    let { whole: federalQtrShareReimbWhole, decimal: federalQtrShareReimbDecimal } = splitAmountIntoWholeNumberAndDecimal(totals.federal);
    informationalCards.push({
        id: count++,
        title: "FEDERAL/QTR SHARE REIMB.",
        amount1: federalQtrShareReimbWhole,
        amount2: federalQtrShareReimbDecimal ? ("." + federalQtrShareReimbDecimal) : "",
        class: "informational-card-text-color-safe",
    })

    let { whole: overmatchWhole, decimal: overmatchDecimal } = splitAmountIntoWholeNumberAndDecimal(remainingOvermatch.current);
    informationalCards.push({
        id: count++,
        title: "CURRENT OVERMATCH",
        amount1: overmatchWhole,
        amount2: overmatchDecimal ? ("." + overmatchDecimal) : "",
        class: remainingOvermatch.current >= 0 ? "informational-card-text-color-safe" : "informational-card-text-color-danger"
    })

    let { whole: unobligatedWhole, decimal: unobligatedDecimal } = splitAmountIntoWholeNumberAndDecimal(totals.unobligated);
    informationalCards.push({
        id: count++,
        title: "UNOBLIGATED/UNEXPENDED",
        amount1: unobligatedWhole,
        amount2: unobligatedDecimal ? ("." + unobligatedDecimal) : "",
        class: "informational-card-text-color-safe",
    })

    return informationalCards;
}

function formatNumberAsDollar(num) {
    return num ? " $ " + num.toFixed(2) : " $ 0.00";
}

function capitalize(str) {
    return str[0].toUpperCase() + str.substring(1);
}

// Generates YTD Spending Report Data for a county
// Counties are of type Group in the GraphQL Schema
export function generateYTDSpendingReportData(county) {
    let categories = ["personnel", "contractualServices", "travel", "equipment", "supply", "cert", "other"];
    let GAcategories = ["salary", "contractualServices", "travel", "equipment", "supply", "cert", "other"];
    let toRet = {
        "groupName": capitalize(county.groupName),
        "total": 0,
        "overmatch": 0,
        "unexpended": 0
    };
    let catTotals = {};
    categories.forEach((cat) => {
        toRet[cat] = 0;
    });
    GAcategories.forEach((cat) => {
        catTotals[cat] = 0;
    });

    // Generate the Totals (Amount Awarded)
    if (county.grantapplication && county.grantapplication.items && county.financialreimbursement && county.financialreimbursement.items) {
        county.grantapplication.items.forEach((grantApp) => {
            GAcategories.forEach((gaType) => {
                if (grantApp[gaType] && grantApp[gaType].items) {
                    grantApp[gaType].items.forEach((item) => {
                        if (!item.deleted && parseFloat(item.federal) && !isNaN(parseFloat(item.federal))) {
                            // Only doing Federal category for now
                            toRet.total += parseFloat(item.federal);
                            catTotals[gaType] = parseFloat(item.federal);
                        }
                    });
                }
            });
        });

        let prevReimbursed = 0;
        let prevUnexpended = 0;

        // Handle Quarters, calculate unexpended
        // Sometimes the quarters are out of order, so sort them
        county.financialreimbursement.items.sort((a, b) => a.quarter.localeCompare(b.quarter));
        county.financialreimbursement.items.forEach((item) => {
            if (!item.deleted && item.status === "PROCESSED") {
                let currUnexpended = 0;
                let currFedShare = 0;
                // Handle Categories
                categories.forEach((cat) => {
                    if (item[cat] && item[cat].items.length) item[cat].items.forEach((cItem) => {
                        if (cItem.quarterAmount && !isNaN(parseFloat(cItem.quarterAmount))) {
                            toRet[cat] += parseFloat(cItem.quarterAmount);
                            currFedShare += parseFloat(cItem.quarterAmount);
                        }
                        if (cItem.matchAmount && !isNaN(parseFloat(cItem.matchAmount))) {
                            toRet[cat] += parseFloat(cItem.matchAmount);
                        }
                    });
                    let currGAcol = cat;
                    if (cat === "personnel") currGAcol = "salary"; // These are the same category, but we call it different things between Grant Applications and Financial Reimbursements
                    let catAwarded = catTotals[currGAcol];
                    currUnexpended = catAwarded - currFedShare - prevReimbursed;
                });
                prevUnexpended = currUnexpended;
                prevReimbursed = currFedShare;
            }
        });

        toRet.unexpended = prevUnexpended;
    }

    // Reformat the numbers as dollar amounts
    Object.keys(toRet).forEach((key) => {
        if (typeof toRet[key] == 'number') {
            toRet[key] = formatNumberAsDollar(toRet[key]);
        }
    });
    return toRet;
}

// The purpose of this function is to return the grant application object that was approved in a financial quarter.

// Note: A GA approved in Q1 can be used in Q4 provided there are no revisions. If there are revisions then the last approved IN THAT QUARTER OR NEWER will be used

export const getMostRecentGrantApplicationForAQuarter = async (currentQuarter, currentApplicationState, selectedYear) => {

    // check for attachedGrantApplication

    // if (currentQuarter.attachedGrantApplication) {
    //     var gaLink = await getFileUploadedObject(currentQuarter.attachedGrantApplication, "Grant Application");
    //     let a = await axios(gaLink.link)
    //     return a.data
    // }

    let mostUpdatedGrantApplication = currentApplicationState;

    if(currentQuarter.status !== 'AWARDED' && currentApplicationState.status == 'AWARDED') {
        return mostUpdatedGrantApplication;
    }

    // If the currentApplicationState is not in DRAFT, we then have to look for the last revision for the quarter - UPDATE This logic might not work everytime

    // If the application goes in DRAFT then the revision will be there

    // Now lets say for an  Q4, if there is no revision in progress, we have to look for latest grant application which is not in draft, meaning it should in AWARDED

    let correctGAFound = false;
    let quarterWeAreIn = returnQuarterForDate(new Date(), selectedYear);
    var currentQuarterNumber = currentQuarter.quarter;
    currentQuarterNumber = parseInt(currentQuarter.quarter.split('').reverse().join(''))
    let currentQuarterNumberTemp = currentQuarterNumber;


    if (currentApplicationState && (currentApplicationState.status == 'AWARDED') && quarterWeAreIn == currentQuarterNumber ) {
        return mostUpdatedGrantApplication;
    }

   
    // Look for the last revision in that quarter 

    // Check grant revision items
    let items = currentApplicationState && currentApplicationState.grantApplicationRevision && currentApplicationState.grantApplicationRevision.items;


    var revisionsPerQuarter = {
        '1': [],
        '2': [],
        '3': [],
        '4': [],
    }

    if (items && items.length) {
        items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        
        items.forEach((item) => {
            let q = returnQuarterForDate(item.createdAt, selectedYear);
            if (q) {
                revisionsPerQuarter[q].push(item);
            }
        });

        while (currentQuarterNumber > 0) {
            if (revisionsPerQuarter[currentQuarterNumber].length) {
                revisionsPerQuarter[currentQuarterNumber].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));


                let rev = revisionsPerQuarter[currentQuarterNumber][0];
                var revisionLink = await getFileUploadedObject(rev.revision, "Grant Application");
                let a = await axios(revisionLink.link)
                mostUpdatedGrantApplication = a.data;
                correctGAFound = true;
                break;
            }

            currentQuarterNumber--;
        }
    }

    if(!correctGAFound) {
        // Lets say for Q2 financial reim, we dont have an approved revision up until Q3, we now have to look for quarters greater than Q2 and then first approved GA

        while (currentQuarterNumberTemp < 5) {
            if (revisionsPerQuarter[currentQuarterNumberTemp].length) {
                revisionsPerQuarter[currentQuarterNumberTemp].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));


                let rev = revisionsPerQuarter[currentQuarterNumberTemp][0];
                var revisionLink = await getFileUploadedObject(rev.revision, "Grant Application");
                let a = await axios(revisionLink.link)
                mostUpdatedGrantApplication = a.data;
                correctGAFound = true;
                break;
            }

            currentQuarterNumberTemp++;
        }

    }

    return mostUpdatedGrantApplication;
    
}