import React from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import { NumberFormatCustom, getValue } from "../utilities/NumberFormat";

import { DualTitleHeaderComponent } from "../layout/HeaderComponent.js"
import { PrimaryButton, HeaderButton } from "../styled-components/Buttons/Buttons";
import { CardComponentSummaryCard } from "../layout/CardComponent";
import { NormalTextField, RequiredTextField } from "../styled-components/TextFields/TextFields";
import ErrorBox from "../common/ErrorBox";

import IconButton from "@material-ui/core/IconButton";
import { MdClear } from 'react-icons/md';
import { checkIfAmountEnteredIsBelowAmountReimbursed, checkIfAmountHasBeenReimbursed } from './GrantApplicationApi';

// Redux imports

import { saveOther } from "../../redux/actions/OtherAction.js";

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        grantApplication: state.rootReducer.grantApplication,
        currentApplicationState: state.rootReducer.currentApplicationState,
        selectedYear: state.rootReducer.selectedYear,
        selectedCounty: state.rootReducer.selectedCounty, 
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveOther: (payload) => dispatch(saveOther(payload)),
    }
};
class Other extends React.Component {

    modelType = 'other'

    state = {
        id: "",
        tempId: "",
        name: "",
        deleted: false,
        updatedBy: "",
        federal: "",
        county: "",
        countyInKind: "",
        errors: [],
        validate: false,
    };

    constructor(props, context) {
        super(props, context);
        this.goBack = this.goBack.bind(this);

        if (this.props.match && this.props.match.params && this.props.match.params.id) {
            // we are looking into an existing entry
            var isTempEntry = false;
            if (this.props.match.params.id.indexOf("temp_") >= 0) {
                isTempEntry = true;
            }
            this.props.currentApplicationState.other && this.props.currentApplicationState.other.items &&
                this.props.currentApplicationState.other.items.forEach((item) => {
                    if (isTempEntry) {
                        if (("temp_" + item.tempId) == this.props.match.params.id) {
                            this.state = item;
                        }
                    } else {
                        if (item.id == this.props.match.params.id) {
                            this.state = item;
                        }
                    }
                this.state.errors = [];
                this.state.validate = false;

                })
        }
    }



    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    saveOther(event) {

        // Check all the documents
        this.state.errors = [];
        this.state.validate = false;
        this.setState({
            errors: this.state.errors
        });

        this.setState({
            validate: this.state.validate
        });

        if (!this.state.name) {
            this.state.errors.push({
                text: "Please enter name"
            })
        }


        let amountCheck = checkIfAmountEnteredIsBelowAmountReimbursed(this.props.selectedCounty, this.state, this.modelType);

        if (amountCheck) {
            this.state.errors.push({
                text: amountCheck.text
            })
        }

        if (this.state.errors.length > 0) {

            this.state.validate = true;

            this.setState({
                errors: this.state.errors
            });

            this.setState({
                validate: this.state.validate
            });

            return;
        }


        this.props.saveOther(this.state);
        this.goBack();
    }


    goBack() {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdClear className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>{this.state.id || this.state.tempId ? "Edit other" : "Add other"}</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>{window.appHeaderTitle} • {this.props.grantApplication.group && this.props.grantApplication.group.groupName}</span>}
                    suffix={
                        this.state.id || this.state.tempId ?
                            <div className="form-margin-16">
                                <HeaderButton hidden={this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS"} onClick={() => this.saveOther()}>
                                    Save
                                </HeaderButton>
                            </div>
                            : ""
                    }

                />

                {
                    this.state.errors.length ?
                        <div className="margin-10">
                            <ErrorBox errors={this.state.errors} />
                        </div>
                        : ""
                }


                <div className="flex form-shell container-overflow-y">
                    <div className="layout horizontal">
                        <RequiredTextField error={this.state.name.length == 0 && this.state.validate ? true : false} id="otherName" label="Name" custommargin="true" placeholder="Enter name" className="flex" value={this.state.name} onChange={this.handleChange('name')} margin="normal" InputProps={{ readOnly: (this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS") || checkIfAmountHasBeenReimbursed(this.props.selectedCounty, this.state.name, this.modelType) }} InputLabelProps={{ shrink: true, }} />
                    </div>

                    <div className="layout horizontal">
                        <NormalTextField id="otherFederalFunding" label="Federal funding" custommargin="true" placeholder="Enter federal funding" className="flex" value={this.state.federal} onChange={this.handleChange('federal')} margin="normal" InputProps={{ inputComponent: NumberFormatCustom, readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />
                    </div>

                    <div className="layout horizontal">
                        <NormalTextField id="otherCountyFunding" label="County cash funding" custommargin="true" placeholder="Enter county cash funding" className="flex" value={this.state.county} onChange={this.handleChange('county')} margin="normal" InputProps={{ inputComponent: NumberFormatCustom, readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />
                    </div>

                    <div className="layout horizontal">
                        <NormalTextField id="otherCountyInKindFunding" label="County in-kind funding" custommargin="true" placeholder="Enter county in-kind funding" className="flex" value={this.state.countyInKind} onChange={this.handleChange('countyInKind')} margin="normal" InputProps={{ inputComponent: NumberFormatCustom, readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />
                    </div>

                    <div className="height-20"></div>

                    <div className="layout horizontal form-margin">
                        <div className="flex"></div>
                        <CardComponentSummaryCard
                            cardText={
                                <NumberFormat value={getValue([this.state.federal, this.state.county, this.state.countyInKind])} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                            }
                            cardTitle={<span>Total</span>}
                        />
                    </div>
                    <div className="height-20"></div>
                    {
                        this.state.id || this.state.tempId ?
                            "" :
                            <div className="layout horizontal form-margin">
                                <div className="flex"></div>
                                <PrimaryButton onClick={() => this.saveOther()}>
                                    Add
                                </PrimaryButton>
                            </div>
                    }

                </div>

            </div>
        );
    }
}

// export default Other;

export default connect(mapStateToProps, mapDispatchToProps)(Other);


