import React from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import IconButton from "@material-ui/core/IconButton";
import { MdClear, MdMonetizationOn, MdPlaylistAddCheck } from 'react-icons/md';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MonitoringGrantInformationPage from "./MonitoringGrantInformationPage";
import MonitoringSiteVisitReview from './MonitoringSiteVisitReview.js';
// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        token: state.rootReducer.token,
        counties: state.rootReducer.counties,
        selectedYear: state.rootReducer.selectedYear
    };
};


class MonitoringCountyPackage extends React.Component {

    state = {
        selectedTab: 0,
        smallScreen: false,
    }

    constructor(props, context) {
        super(props, context);
    }

    selectTab = (index) => {
        this.setState({ selectedTab: index })
    }

    goBack = () => {
        this.props.history.goBack()
    }

    render() {
        return (


            <div className="layout vertical full-height">
                <div className="flex">
                    {
                        this.state.selectedTab == 1 ? 
                        <>
                            <MonitoringSiteVisitReview  {...this.props} />
                        </>
                        : 
                        <>
                            <MonitoringGrantInformationPage  {...this.props} />
                        </>
                    }

                </div>

                <Tabs className="layout horizontal center"
                    value={this.state.selectedTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    classes={{
                        root: "tabs-root",
                        flexContainer: "flex",
                        indicator: "tab-indicator-color",
                    }}
                    // onChange={handleChange}
                    aria-label="disabled tabs example"
                >
                    <Tab
                        className="flex"
                        icon={<MdMonetizationOn style={{ width: 24, height: 24}} />}
                        classes={{
                            root: "tab-root",
                            selected: "tab-selected",
                        }}
                        onClick={() => this.selectTab(0)}
                        label={<span style={{ fontSize: 10}}>Grant information</span>}
                    />

                    <Tab
                        className="flex"
                        icon={<MdPlaylistAddCheck style={{ width: 24, height: 24}} />}
                        classes={{
                            root: "tab-root",
                            selected: "tab-selected",
                        }}
                        onClick={() => this.selectTab(1)}
                        label={<span style={{ fontSize: 10}}>Site visit review</span>}
                    />
                </Tabs>


            </div>
        )
    }
}

// export default MonitoringCountyPackage;
export default connect(mapStateToProps, )(MonitoringCountyPackage);
