/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateFile = /* GraphQL */ `
  subscription OnCreateFile {
    onCreateFile {
      id
      bucket
      title
      size
      downloadTitle
      createdAt
      updatedAt
      deleted
      updatedBy
    }
  }
`;
export const onUpdateFile = /* GraphQL */ `
  subscription OnUpdateFile {
    onUpdateFile {
      id
      bucket
      title
      size
      downloadTitle
      createdAt
      updatedAt
      deleted
      updatedBy
    }
  }
`;
export const onDeleteFile = /* GraphQL */ `
  subscription OnDeleteFile {
    onDeleteFile {
      id
      bucket
      title
      size
      downloadTitle
      createdAt
      updatedAt
      deleted
      updatedBy
    }
  }
`;
export const onCreateSetting = /* GraphQL */ `
  subscription OnCreateSetting {
    onCreateSetting {
      id
      updatedAt
      createdAt
      updatedBy
      type
      value
      userId
    }
  }
`;
export const onUpdateSetting = /* GraphQL */ `
  subscription OnUpdateSetting {
    onUpdateSetting {
      id
      updatedAt
      createdAt
      updatedBy
      type
      value
      userId
    }
  }
`;
export const onDeleteSetting = /* GraphQL */ `
  subscription OnDeleteSetting {
    onDeleteSetting {
      id
      updatedAt
      createdAt
      updatedBy
      type
      value
      userId
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment {
    onCreateComment {
      id
      comment
      createdAt
      updatedAt
      deleted
      updatedBy
      updatedByFirstName
      updatedByLastName
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment {
    onUpdateComment {
      id
      comment
      createdAt
      updatedAt
      deleted
      updatedBy
      updatedByFirstName
      updatedByLastName
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment {
    onDeleteComment {
      id
      comment
      createdAt
      updatedAt
      deleted
      updatedBy
      updatedByFirstName
      updatedByLastName
    }
  }
`;
export const onCreateEmailRecord = /* GraphQL */ `
  subscription OnCreateEmailRecord {
    onCreateEmailRecord {
      id
      updatedAt
      createdAt
      updatedBy
      payload
      for
      userId
      status
      extraInformation
    }
  }
`;
export const onUpdateEmailRecord = /* GraphQL */ `
  subscription OnUpdateEmailRecord {
    onUpdateEmailRecord {
      id
      updatedAt
      createdAt
      updatedBy
      payload
      for
      userId
      status
      extraInformation
    }
  }
`;
export const onDeleteEmailRecord = /* GraphQL */ `
  subscription OnDeleteEmailRecord {
    onDeleteEmailRecord {
      id
      updatedAt
      createdAt
      updatedBy
      payload
      for
      userId
      status
      extraInformation
    }
  }
`;
export const onCreateExtension = /* GraphQL */ `
  subscription OnCreateExtension {
    onCreateExtension {
      id
      updatedAt
      createdAt
      updatedBy
      year
      grant
      type
      group
      status
      for
      quarterFrom
      quarterTo
      dueDate
      reasonForExtension
      dateAdjustedTo
      reasonForAdjustment
      reasonForDeniedExtension
    }
  }
`;
export const onUpdateExtension = /* GraphQL */ `
  subscription OnUpdateExtension {
    onUpdateExtension {
      id
      updatedAt
      createdAt
      updatedBy
      year
      grant
      type
      group
      status
      for
      quarterFrom
      quarterTo
      dueDate
      reasonForExtension
      dateAdjustedTo
      reasonForAdjustment
      reasonForDeniedExtension
    }
  }
`;
export const onDeleteExtension = /* GraphQL */ `
  subscription OnDeleteExtension {
    onDeleteExtension {
      id
      updatedAt
      createdAt
      updatedBy
      year
      grant
      type
      group
      status
      for
      quarterFrom
      quarterTo
      dueDate
      reasonForExtension
      dateAdjustedTo
      reasonForAdjustment
      reasonForDeniedExtension
    }
  }
`;
export const onCreateFinancialReimbursement = /* GraphQL */ `
  subscription OnCreateFinancialReimbursement {
    onCreateFinancialReimbursement {
      id
      quarter
      quarterStartDate
      quarterEndDate
      updatedBy
      year
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        awardAmount
        year
        grant
        deleted
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      createdAt
      updatedAt
      deleted
      status
      awarded
      checkNumber
      checkAmount
      checkMailDate
      checksReissued {
        items {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        nextToken
      }
      currentReissuedCheck {
        id
        deleted
        updatedBy
        createdAt
        updatedAt
        checkNumber
        checkAmount
        checkMailDate
        reason
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        reason2
      }
      additionalChecks {
        items {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        nextToken
      }
      additionalCheckInProgress {
        id
        deleted
        updatedBy
        createdAt
        updatedAt
        checkNumber
        checkAmount
        checkMailDate
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        reason
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        reason2
      }
      stateSceisDocumentNumber
      stateSceisSubmissionDate
      extensions {
        items {
          id
          updatedAt
          createdAt
          updatedBy
          year
          grant
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        nextToken
      }
      currentExtension {
        id
        updatedAt
        createdAt
        updatedBy
        year
        grant
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      reason2
      personnel {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      contractualService {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      travel {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      equipment {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      supply {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      cert {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      other {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      deobligatedFunds
      deobligatedFundsReason
    }
  }
`;
export const onUpdateFinancialReimbursement = /* GraphQL */ `
  subscription OnUpdateFinancialReimbursement {
    onUpdateFinancialReimbursement {
      id
      quarter
      quarterStartDate
      quarterEndDate
      updatedBy
      year
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        awardAmount
        year
        grant
        deleted
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      createdAt
      updatedAt
      deleted
      status
      awarded
      checkNumber
      checkAmount
      checkMailDate
      checksReissued {
        items {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        nextToken
      }
      currentReissuedCheck {
        id
        deleted
        updatedBy
        createdAt
        updatedAt
        checkNumber
        checkAmount
        checkMailDate
        reason
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        reason2
      }
      additionalChecks {
        items {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        nextToken
      }
      additionalCheckInProgress {
        id
        deleted
        updatedBy
        createdAt
        updatedAt
        checkNumber
        checkAmount
        checkMailDate
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        reason
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        reason2
      }
      stateSceisDocumentNumber
      stateSceisSubmissionDate
      extensions {
        items {
          id
          updatedAt
          createdAt
          updatedBy
          year
          grant
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        nextToken
      }
      currentExtension {
        id
        updatedAt
        createdAt
        updatedBy
        year
        grant
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      reason2
      personnel {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      contractualService {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      travel {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      equipment {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      supply {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      cert {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      other {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      deobligatedFunds
      deobligatedFundsReason
    }
  }
`;
export const onDeleteFinancialReimbursement = /* GraphQL */ `
  subscription OnDeleteFinancialReimbursement {
    onDeleteFinancialReimbursement {
      id
      quarter
      quarterStartDate
      quarterEndDate
      updatedBy
      year
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        awardAmount
        year
        grant
        deleted
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      createdAt
      updatedAt
      deleted
      status
      awarded
      checkNumber
      checkAmount
      checkMailDate
      checksReissued {
        items {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        nextToken
      }
      currentReissuedCheck {
        id
        deleted
        updatedBy
        createdAt
        updatedAt
        checkNumber
        checkAmount
        checkMailDate
        reason
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        reason2
      }
      additionalChecks {
        items {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        nextToken
      }
      additionalCheckInProgress {
        id
        deleted
        updatedBy
        createdAt
        updatedAt
        checkNumber
        checkAmount
        checkMailDate
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        reason
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        reason2
      }
      stateSceisDocumentNumber
      stateSceisSubmissionDate
      extensions {
        items {
          id
          updatedAt
          createdAt
          updatedBy
          year
          grant
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        nextToken
      }
      currentExtension {
        id
        updatedAt
        createdAt
        updatedBy
        year
        grant
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      reason2
      personnel {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      contractualService {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      travel {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      equipment {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      supply {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      cert {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      other {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      deobligatedFunds
      deobligatedFundsReason
    }
  }
`;
export const onCreateChecksReissued = /* GraphQL */ `
  subscription OnCreateChecksReissued {
    onCreateChecksReissued {
      id
      deleted
      updatedBy
      createdAt
      updatedAt
      checkNumber
      checkAmount
      checkMailDate
      reason
      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      reason2
    }
  }
`;
export const onUpdateChecksReissued = /* GraphQL */ `
  subscription OnUpdateChecksReissued {
    onUpdateChecksReissued {
      id
      deleted
      updatedBy
      createdAt
      updatedAt
      checkNumber
      checkAmount
      checkMailDate
      reason
      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      reason2
    }
  }
`;
export const onDeleteChecksReissued = /* GraphQL */ `
  subscription OnDeleteChecksReissued {
    onDeleteChecksReissued {
      id
      deleted
      updatedBy
      createdAt
      updatedAt
      checkNumber
      checkAmount
      checkMailDate
      reason
      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      reason2
    }
  }
`;
export const onCreateAdditionalChecks = /* GraphQL */ `
  subscription OnCreateAdditionalChecks {
    onCreateAdditionalChecks {
      id
      deleted
      updatedBy
      createdAt
      updatedAt
      checkNumber
      checkAmount
      checkMailDate
      stateSceisDocumentNumber
      stateSceisSubmissionDate
      reason
      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      reason2
    }
  }
`;
export const onUpdateAdditionalChecks = /* GraphQL */ `
  subscription OnUpdateAdditionalChecks {
    onUpdateAdditionalChecks {
      id
      deleted
      updatedBy
      createdAt
      updatedAt
      checkNumber
      checkAmount
      checkMailDate
      stateSceisDocumentNumber
      stateSceisSubmissionDate
      reason
      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      reason2
    }
  }
`;
export const onDeleteAdditionalChecks = /* GraphQL */ `
  subscription OnDeleteAdditionalChecks {
    onDeleteAdditionalChecks {
      id
      deleted
      updatedBy
      createdAt
      updatedAt
      checkNumber
      checkAmount
      checkMailDate
      stateSceisDocumentNumber
      stateSceisSubmissionDate
      reason
      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      reason2
    }
  }
`;
export const onCreateFimPersonnel = /* GraphQL */ `
  subscription OnCreateFimPersonnel {
    onCreateFimPersonnel {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      file
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFimPersonnel = /* GraphQL */ `
  subscription OnUpdateFimPersonnel {
    onUpdateFimPersonnel {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      file
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFimPersonnel = /* GraphQL */ `
  subscription OnDeleteFimPersonnel {
    onDeleteFimPersonnel {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      file
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFimContractualService = /* GraphQL */ `
  subscription OnCreateFimContractualService {
    onCreateFimContractualService {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      file
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFimContractualService = /* GraphQL */ `
  subscription OnUpdateFimContractualService {
    onUpdateFimContractualService {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      file
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFimContractualService = /* GraphQL */ `
  subscription OnDeleteFimContractualService {
    onDeleteFimContractualService {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      file
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFimTravel = /* GraphQL */ `
  subscription OnCreateFimTravel {
    onCreateFimTravel {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      file
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFimTravel = /* GraphQL */ `
  subscription OnUpdateFimTravel {
    onUpdateFimTravel {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      file
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFimTravel = /* GraphQL */ `
  subscription OnDeleteFimTravel {
    onDeleteFimTravel {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      file
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFimEquipment = /* GraphQL */ `
  subscription OnCreateFimEquipment {
    onCreateFimEquipment {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      file
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFimEquipment = /* GraphQL */ `
  subscription OnUpdateFimEquipment {
    onUpdateFimEquipment {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      file
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFimEquipment = /* GraphQL */ `
  subscription OnDeleteFimEquipment {
    onDeleteFimEquipment {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      file
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFimSupply = /* GraphQL */ `
  subscription OnCreateFimSupply {
    onCreateFimSupply {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      file
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFimSupply = /* GraphQL */ `
  subscription OnUpdateFimSupply {
    onUpdateFimSupply {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      file
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFimSupply = /* GraphQL */ `
  subscription OnDeleteFimSupply {
    onDeleteFimSupply {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      file
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFimCert = /* GraphQL */ `
  subscription OnCreateFimCert {
    onCreateFimCert {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      file
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFimCert = /* GraphQL */ `
  subscription OnUpdateFimCert {
    onUpdateFimCert {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      file
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFimCert = /* GraphQL */ `
  subscription OnDeleteFimCert {
    onDeleteFimCert {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      file
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFimOther = /* GraphQL */ `
  subscription OnCreateFimOther {
    onCreateFimOther {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      file
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFimOther = /* GraphQL */ `
  subscription OnUpdateFimOther {
    onUpdateFimOther {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      file
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFimOther = /* GraphQL */ `
  subscription OnDeleteFimOther {
    onDeleteFimOther {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      file
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGrantApplication = /* GraphQL */ `
  subscription OnCreateGrantApplication {
    onCreateGrantApplication {
      id
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        awardAmount
        year
        grant
        deleted
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      updatedBy
      version
      versionName
      createdAt
      updatedAt
      year
      deleted
      status
      awarded
      deductCertFromFederal
      salary {
        items {
          id
          deleted
          updatedBy
          name
          positionTitle
          federal
          county
          countyInKind
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          createdAt
          updatedAt
        }
        nextToken
      }
      salaryNarrative
      fringeBenefit {
        id
        deleted
        updatedBy
        federal
        county
        countyInKind
        createdAt
        updatedAt
      }
      fringeBenefitNarrative
      fbFederalFunding
      fbCountyCashFunding
      fbCountyInkindFunding
      contractualService {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      contractualServiceNarrative
      travel {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      travelNarrative
      equipment {
        items {
          id
          name
          femaItemNumber
          quantity
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      equipmentNarrative
      supply {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      supplyNarrative
      other {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      otherNarrative
      cert {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      certNarrative
      acceptanceOfAuditReqFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      certificationDocumentFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      assurancesNonContructionFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      lobbyingCertificationFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      edmEquipmentPolicyFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      positionDescFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      acknowledgementFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      otherFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      scopeOfWorkFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      narrativesFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      extraFile
      awardDocumentsUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardCoverLetterUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardDocumentUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardSpecialInstructionsUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardDocumentsUploadedByCounty {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      changesRequestedOnApplication
      name
      address
      address2
      city
      state
      county
      zipcode
      latitude
      longitude
      enableReverseGeocoding
      implementingAgency
      phoneNumber
      faxNumber
      currentApplicationRevisionId
      grantApplicationRevision {
        items {
          id
          createdAt
          updatedAt
          grantApplicationLastUpdatedAt
          updatedBy
        }
        nextToken
      }
      extensions {
        items {
          id
          updatedAt
          createdAt
          updatedBy
          year
          grant
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        nextToken
      }
      currentExtension {
        id
        updatedAt
        createdAt
        updatedBy
        year
        grant
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
    }
  }
`;
export const onUpdateGrantApplication = /* GraphQL */ `
  subscription OnUpdateGrantApplication {
    onUpdateGrantApplication {
      id
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        awardAmount
        year
        grant
        deleted
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      updatedBy
      version
      versionName
      createdAt
      updatedAt
      year
      deleted
      status
      awarded
      deductCertFromFederal
      salary {
        items {
          id
          deleted
          updatedBy
          name
          positionTitle
          federal
          county
          countyInKind
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          createdAt
          updatedAt
        }
        nextToken
      }
      salaryNarrative
      fringeBenefit {
        id
        deleted
        updatedBy
        federal
        county
        countyInKind
        createdAt
        updatedAt
      }
      fringeBenefitNarrative
      fbFederalFunding
      fbCountyCashFunding
      fbCountyInkindFunding
      contractualService {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      contractualServiceNarrative
      travel {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      travelNarrative
      equipment {
        items {
          id
          name
          femaItemNumber
          quantity
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      equipmentNarrative
      supply {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      supplyNarrative
      other {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      otherNarrative
      cert {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      certNarrative
      acceptanceOfAuditReqFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      certificationDocumentFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      assurancesNonContructionFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      lobbyingCertificationFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      edmEquipmentPolicyFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      positionDescFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      acknowledgementFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      otherFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      scopeOfWorkFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      narrativesFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      extraFile
      awardDocumentsUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardCoverLetterUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardDocumentUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardSpecialInstructionsUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardDocumentsUploadedByCounty {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      changesRequestedOnApplication
      name
      address
      address2
      city
      state
      county
      zipcode
      latitude
      longitude
      enableReverseGeocoding
      implementingAgency
      phoneNumber
      faxNumber
      currentApplicationRevisionId
      grantApplicationRevision {
        items {
          id
          createdAt
          updatedAt
          grantApplicationLastUpdatedAt
          updatedBy
        }
        nextToken
      }
      extensions {
        items {
          id
          updatedAt
          createdAt
          updatedBy
          year
          grant
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        nextToken
      }
      currentExtension {
        id
        updatedAt
        createdAt
        updatedBy
        year
        grant
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
    }
  }
`;
export const onDeleteGrantApplication = /* GraphQL */ `
  subscription OnDeleteGrantApplication {
    onDeleteGrantApplication {
      id
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        awardAmount
        year
        grant
        deleted
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      updatedBy
      version
      versionName
      createdAt
      updatedAt
      year
      deleted
      status
      awarded
      deductCertFromFederal
      salary {
        items {
          id
          deleted
          updatedBy
          name
          positionTitle
          federal
          county
          countyInKind
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          createdAt
          updatedAt
        }
        nextToken
      }
      salaryNarrative
      fringeBenefit {
        id
        deleted
        updatedBy
        federal
        county
        countyInKind
        createdAt
        updatedAt
      }
      fringeBenefitNarrative
      fbFederalFunding
      fbCountyCashFunding
      fbCountyInkindFunding
      contractualService {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      contractualServiceNarrative
      travel {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      travelNarrative
      equipment {
        items {
          id
          name
          femaItemNumber
          quantity
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      equipmentNarrative
      supply {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      supplyNarrative
      other {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      otherNarrative
      cert {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      certNarrative
      acceptanceOfAuditReqFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      certificationDocumentFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      assurancesNonContructionFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      lobbyingCertificationFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      edmEquipmentPolicyFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      positionDescFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      acknowledgementFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      otherFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      scopeOfWorkFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      narrativesFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      extraFile
      awardDocumentsUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardCoverLetterUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardDocumentUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardSpecialInstructionsUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardDocumentsUploadedByCounty {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      changesRequestedOnApplication
      name
      address
      address2
      city
      state
      county
      zipcode
      latitude
      longitude
      enableReverseGeocoding
      implementingAgency
      phoneNumber
      faxNumber
      currentApplicationRevisionId
      grantApplicationRevision {
        items {
          id
          createdAt
          updatedAt
          grantApplicationLastUpdatedAt
          updatedBy
        }
        nextToken
      }
      extensions {
        items {
          id
          updatedAt
          createdAt
          updatedBy
          year
          grant
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        nextToken
      }
      currentExtension {
        id
        updatedAt
        createdAt
        updatedBy
        year
        grant
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
    }
  }
`;
export const onCreateGrantApplicationRevision = /* GraphQL */ `
  subscription OnCreateGrantApplicationRevision {
    onCreateGrantApplicationRevision {
      id
      createdAt
      updatedAt
      grantApplicationLastUpdatedAt
      revision {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      updatedBy
    }
  }
`;
export const onUpdateGrantApplicationRevision = /* GraphQL */ `
  subscription OnUpdateGrantApplicationRevision {
    onUpdateGrantApplicationRevision {
      id
      createdAt
      updatedAt
      grantApplicationLastUpdatedAt
      revision {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      updatedBy
    }
  }
`;
export const onDeleteGrantApplicationRevision = /* GraphQL */ `
  subscription OnDeleteGrantApplicationRevision {
    onDeleteGrantApplicationRevision {
      id
      createdAt
      updatedAt
      grantApplicationLastUpdatedAt
      revision {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      updatedBy
    }
  }
`;
export const onCreateSalary = /* GraphQL */ `
  subscription OnCreateSalary {
    onCreateSalary {
      id
      deleted
      updatedBy
      name
      positionTitle
      federal
      county
      countyInKind
      fbFederalFunding
      fbCountyCashFunding
      fbCountyInkindFunding
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSalary = /* GraphQL */ `
  subscription OnUpdateSalary {
    onUpdateSalary {
      id
      deleted
      updatedBy
      name
      positionTitle
      federal
      county
      countyInKind
      fbFederalFunding
      fbCountyCashFunding
      fbCountyInkindFunding
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSalary = /* GraphQL */ `
  subscription OnDeleteSalary {
    onDeleteSalary {
      id
      deleted
      updatedBy
      name
      positionTitle
      federal
      county
      countyInKind
      fbFederalFunding
      fbCountyCashFunding
      fbCountyInkindFunding
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFringeBenefit = /* GraphQL */ `
  subscription OnCreateFringeBenefit {
    onCreateFringeBenefit {
      id
      deleted
      updatedBy
      federal
      county
      countyInKind
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFringeBenefit = /* GraphQL */ `
  subscription OnUpdateFringeBenefit {
    onUpdateFringeBenefit {
      id
      deleted
      updatedBy
      federal
      county
      countyInKind
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFringeBenefit = /* GraphQL */ `
  subscription OnDeleteFringeBenefit {
    onDeleteFringeBenefit {
      id
      deleted
      updatedBy
      federal
      county
      countyInKind
      createdAt
      updatedAt
    }
  }
`;
export const onCreateContractualService = /* GraphQL */ `
  subscription OnCreateContractualService {
    onCreateContractualService {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateContractualService = /* GraphQL */ `
  subscription OnUpdateContractualService {
    onUpdateContractualService {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteContractualService = /* GraphQL */ `
  subscription OnDeleteContractualService {
    onDeleteContractualService {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTravel = /* GraphQL */ `
  subscription OnCreateTravel {
    onCreateTravel {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTravel = /* GraphQL */ `
  subscription OnUpdateTravel {
    onUpdateTravel {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTravel = /* GraphQL */ `
  subscription OnDeleteTravel {
    onDeleteTravel {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEquipment = /* GraphQL */ `
  subscription OnCreateEquipment {
    onCreateEquipment {
      id
      name
      femaItemNumber
      quantity
      deleted
      updatedBy
      federal
      county
      countyInKind
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEquipment = /* GraphQL */ `
  subscription OnUpdateEquipment {
    onUpdateEquipment {
      id
      name
      femaItemNumber
      quantity
      deleted
      updatedBy
      federal
      county
      countyInKind
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEquipment = /* GraphQL */ `
  subscription OnDeleteEquipment {
    onDeleteEquipment {
      id
      name
      femaItemNumber
      quantity
      deleted
      updatedBy
      federal
      county
      countyInKind
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSupply = /* GraphQL */ `
  subscription OnCreateSupply {
    onCreateSupply {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSupply = /* GraphQL */ `
  subscription OnUpdateSupply {
    onUpdateSupply {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSupply = /* GraphQL */ `
  subscription OnDeleteSupply {
    onDeleteSupply {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOther = /* GraphQL */ `
  subscription OnCreateOther {
    onCreateOther {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOther = /* GraphQL */ `
  subscription OnUpdateOther {
    onUpdateOther {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOther = /* GraphQL */ `
  subscription OnDeleteOther {
    onDeleteOther {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCert = /* GraphQL */ `
  subscription OnCreateCert {
    onCreateCert {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCert = /* GraphQL */ `
  subscription OnUpdateCert {
    onUpdateCert {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCert = /* GraphQL */ `
  subscription OnDeleteCert {
    onDeleteCert {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFiscalYear = /* GraphQL */ `
  subscription OnCreateFiscalYear {
    onCreateFiscalYear {
      id
      year
      grant
      availableToCounties
      createdAt
      updatedAt
      updatedBy
      acceptanceOfAuditReqFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      certificationDocumentFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      assurancesNonContructionFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      lobbyingCertificationFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      edmEquipmentPolicyFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      positionDescFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      acknowledgementFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      otherFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      scopeOfWorkFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      narrativesFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      guidelinesFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      extraFiles {
        items {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        nextToken
      }
    }
  }
`;
export const onUpdateFiscalYear = /* GraphQL */ `
  subscription OnUpdateFiscalYear {
    onUpdateFiscalYear {
      id
      year
      grant
      availableToCounties
      createdAt
      updatedAt
      updatedBy
      acceptanceOfAuditReqFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      certificationDocumentFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      assurancesNonContructionFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      lobbyingCertificationFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      edmEquipmentPolicyFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      positionDescFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      acknowledgementFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      otherFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      scopeOfWorkFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      narrativesFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      guidelinesFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      extraFiles {
        items {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        nextToken
      }
    }
  }
`;
export const onDeleteFiscalYear = /* GraphQL */ `
  subscription OnDeleteFiscalYear {
    onDeleteFiscalYear {
      id
      year
      grant
      availableToCounties
      createdAt
      updatedAt
      updatedBy
      acceptanceOfAuditReqFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      certificationDocumentFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      assurancesNonContructionFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      lobbyingCertificationFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      edmEquipmentPolicyFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      positionDescFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      acknowledgementFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      otherFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      scopeOfWorkFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      narrativesFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      guidelinesFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      extraFiles {
        items {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        nextToken
      }
    }
  }
`;
export const onCreateGroup = /* GraphQL */ `
  subscription OnCreateGroup {
    onCreateGroup {
      id
      groupName
      createdAt
      updatedAt
      region
      palmettoId
      narratives {
        items {
          id
          completed
          createdAt
          updatedAt
          deleted
          updatedBy
          status
          notes
          extraFile
          quarter
          updatedByFirstName
          updatedByLastName
          year
          grant
        }
        nextToken
      }
      quarterlyprogressreporting {
        items {
          id
          quarter
          taskingslipstatus
          updatedAt
          updatedBy
          updatedByRemName
          createdAt
        }
        nextToken
      }
      awardAmount
      year
      grant
      deleted
      monitoringgrantinformation {
        id
        updatedAt
        createdAt
        updatedBy
        monitoringType
        monitoringDate
        subRecipientAgency
        grantProjectTitle
        grantNumber
        approvedGrantStartDate
        approvedGrantEndDate
        extensionEndDate
        name
        title
        projectDirectorName
        projectDirectorTitle
        personnelName
        personnelTitle
      }
      monitoringsitevisitreview {
        id
        updatedAt
        createdAt
        updatedBy
        gaOnFile
        gaSupportingDocumentationOnFile
        gaComments
        personnelFunded
        personnelDocumentationOnFile
        personnelRecordsOnFile
        personnelComments
        contractualServiceOnFile
        contractualServiceExecutedContractsOnFile
        contractualServiceComments
        travelOnFile
        travelDocumentationOnFile
        travelComments
        equipmentProperlySheltered
        equipmentTransferred
        equipmentComments
        suppliesOnFile
        suppliesTransferred
        suppliesComments
        otherOnFile
        otherComments
        summaryComments
        monitoringReportSubmittedBy
        monitoringReportSubmittedAt
        scemdPOCReviewedBy
        scemdPOCReviewedAt
        monitoringFiles {
          nextToken
        }
        status
      }
      grantapplication {
        items {
          id
          updatedBy
          version
          versionName
          createdAt
          updatedAt
          year
          deleted
          status
          awarded
          deductCertFromFederal
          salaryNarrative
          fringeBenefitNarrative
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          contractualServiceNarrative
          travelNarrative
          equipmentNarrative
          supplyNarrative
          otherNarrative
          certNarrative
          extraFile
          changesRequestedOnApplication
          name
          address
          address2
          city
          state
          county
          zipcode
          latitude
          longitude
          enableReverseGeocoding
          implementingAgency
          phoneNumber
          faxNumber
          currentApplicationRevisionId
        }
        nextToken
      }
      financialreimbursement {
        items {
          id
          quarter
          quarterStartDate
          quarterEndDate
          updatedBy
          year
          createdAt
          updatedAt
          deleted
          status
          awarded
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
          deobligatedFunds
          deobligatedFundsReason
        }
        nextToken
      }
    }
  }
`;
export const onUpdateGroup = /* GraphQL */ `
  subscription OnUpdateGroup {
    onUpdateGroup {
      id
      groupName
      createdAt
      updatedAt
      region
      palmettoId
      narratives {
        items {
          id
          completed
          createdAt
          updatedAt
          deleted
          updatedBy
          status
          notes
          extraFile
          quarter
          updatedByFirstName
          updatedByLastName
          year
          grant
        }
        nextToken
      }
      quarterlyprogressreporting {
        items {
          id
          quarter
          taskingslipstatus
          updatedAt
          updatedBy
          updatedByRemName
          createdAt
        }
        nextToken
      }
      awardAmount
      year
      grant
      deleted
      monitoringgrantinformation {
        id
        updatedAt
        createdAt
        updatedBy
        monitoringType
        monitoringDate
        subRecipientAgency
        grantProjectTitle
        grantNumber
        approvedGrantStartDate
        approvedGrantEndDate
        extensionEndDate
        name
        title
        projectDirectorName
        projectDirectorTitle
        personnelName
        personnelTitle
      }
      monitoringsitevisitreview {
        id
        updatedAt
        createdAt
        updatedBy
        gaOnFile
        gaSupportingDocumentationOnFile
        gaComments
        personnelFunded
        personnelDocumentationOnFile
        personnelRecordsOnFile
        personnelComments
        contractualServiceOnFile
        contractualServiceExecutedContractsOnFile
        contractualServiceComments
        travelOnFile
        travelDocumentationOnFile
        travelComments
        equipmentProperlySheltered
        equipmentTransferred
        equipmentComments
        suppliesOnFile
        suppliesTransferred
        suppliesComments
        otherOnFile
        otherComments
        summaryComments
        monitoringReportSubmittedBy
        monitoringReportSubmittedAt
        scemdPOCReviewedBy
        scemdPOCReviewedAt
        monitoringFiles {
          nextToken
        }
        status
      }
      grantapplication {
        items {
          id
          updatedBy
          version
          versionName
          createdAt
          updatedAt
          year
          deleted
          status
          awarded
          deductCertFromFederal
          salaryNarrative
          fringeBenefitNarrative
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          contractualServiceNarrative
          travelNarrative
          equipmentNarrative
          supplyNarrative
          otherNarrative
          certNarrative
          extraFile
          changesRequestedOnApplication
          name
          address
          address2
          city
          state
          county
          zipcode
          latitude
          longitude
          enableReverseGeocoding
          implementingAgency
          phoneNumber
          faxNumber
          currentApplicationRevisionId
        }
        nextToken
      }
      financialreimbursement {
        items {
          id
          quarter
          quarterStartDate
          quarterEndDate
          updatedBy
          year
          createdAt
          updatedAt
          deleted
          status
          awarded
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
          deobligatedFunds
          deobligatedFundsReason
        }
        nextToken
      }
    }
  }
`;
export const onDeleteGroup = /* GraphQL */ `
  subscription OnDeleteGroup {
    onDeleteGroup {
      id
      groupName
      createdAt
      updatedAt
      region
      palmettoId
      narratives {
        items {
          id
          completed
          createdAt
          updatedAt
          deleted
          updatedBy
          status
          notes
          extraFile
          quarter
          updatedByFirstName
          updatedByLastName
          year
          grant
        }
        nextToken
      }
      quarterlyprogressreporting {
        items {
          id
          quarter
          taskingslipstatus
          updatedAt
          updatedBy
          updatedByRemName
          createdAt
        }
        nextToken
      }
      awardAmount
      year
      grant
      deleted
      monitoringgrantinformation {
        id
        updatedAt
        createdAt
        updatedBy
        monitoringType
        monitoringDate
        subRecipientAgency
        grantProjectTitle
        grantNumber
        approvedGrantStartDate
        approvedGrantEndDate
        extensionEndDate
        name
        title
        projectDirectorName
        projectDirectorTitle
        personnelName
        personnelTitle
      }
      monitoringsitevisitreview {
        id
        updatedAt
        createdAt
        updatedBy
        gaOnFile
        gaSupportingDocumentationOnFile
        gaComments
        personnelFunded
        personnelDocumentationOnFile
        personnelRecordsOnFile
        personnelComments
        contractualServiceOnFile
        contractualServiceExecutedContractsOnFile
        contractualServiceComments
        travelOnFile
        travelDocumentationOnFile
        travelComments
        equipmentProperlySheltered
        equipmentTransferred
        equipmentComments
        suppliesOnFile
        suppliesTransferred
        suppliesComments
        otherOnFile
        otherComments
        summaryComments
        monitoringReportSubmittedBy
        monitoringReportSubmittedAt
        scemdPOCReviewedBy
        scemdPOCReviewedAt
        monitoringFiles {
          nextToken
        }
        status
      }
      grantapplication {
        items {
          id
          updatedBy
          version
          versionName
          createdAt
          updatedAt
          year
          deleted
          status
          awarded
          deductCertFromFederal
          salaryNarrative
          fringeBenefitNarrative
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          contractualServiceNarrative
          travelNarrative
          equipmentNarrative
          supplyNarrative
          otherNarrative
          certNarrative
          extraFile
          changesRequestedOnApplication
          name
          address
          address2
          city
          state
          county
          zipcode
          latitude
          longitude
          enableReverseGeocoding
          implementingAgency
          phoneNumber
          faxNumber
          currentApplicationRevisionId
        }
        nextToken
      }
      financialreimbursement {
        items {
          id
          quarter
          quarterStartDate
          quarterEndDate
          updatedBy
          year
          createdAt
          updatedAt
          deleted
          status
          awarded
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
          deobligatedFunds
          deobligatedFundsReason
        }
        nextToken
      }
    }
  }
`;
export const onCreateMonitoringGrantInformation = /* GraphQL */ `
  subscription OnCreateMonitoringGrantInformation {
    onCreateMonitoringGrantInformation {
      id
      updatedAt
      createdAt
      updatedBy
      monitoringType
      monitoringDate
      subRecipientAgency
      grantProjectTitle
      grantNumber
      approvedGrantStartDate
      approvedGrantEndDate
      extensionEndDate
      name
      title
      projectDirectorName
      projectDirectorTitle
      personnelName
      personnelTitle
    }
  }
`;
export const onUpdateMonitoringGrantInformation = /* GraphQL */ `
  subscription OnUpdateMonitoringGrantInformation {
    onUpdateMonitoringGrantInformation {
      id
      updatedAt
      createdAt
      updatedBy
      monitoringType
      monitoringDate
      subRecipientAgency
      grantProjectTitle
      grantNumber
      approvedGrantStartDate
      approvedGrantEndDate
      extensionEndDate
      name
      title
      projectDirectorName
      projectDirectorTitle
      personnelName
      personnelTitle
    }
  }
`;
export const onDeleteMonitoringGrantInformation = /* GraphQL */ `
  subscription OnDeleteMonitoringGrantInformation {
    onDeleteMonitoringGrantInformation {
      id
      updatedAt
      createdAt
      updatedBy
      monitoringType
      monitoringDate
      subRecipientAgency
      grantProjectTitle
      grantNumber
      approvedGrantStartDate
      approvedGrantEndDate
      extensionEndDate
      name
      title
      projectDirectorName
      projectDirectorTitle
      personnelName
      personnelTitle
    }
  }
`;
export const onCreateMonitoringSiteVisitReview = /* GraphQL */ `
  subscription OnCreateMonitoringSiteVisitReview {
    onCreateMonitoringSiteVisitReview {
      id
      updatedAt
      createdAt
      updatedBy
      gaOnFile
      gaSupportingDocumentationOnFile
      gaComments
      personnelFunded
      personnelDocumentationOnFile
      personnelRecordsOnFile
      personnelComments
      contractualServiceOnFile
      contractualServiceExecutedContractsOnFile
      contractualServiceComments
      travelOnFile
      travelDocumentationOnFile
      travelComments
      equipmentProperlySheltered
      equipmentTransferred
      equipmentComments
      suppliesOnFile
      suppliesTransferred
      suppliesComments
      otherOnFile
      otherComments
      summaryComments
      monitoringReportSubmittedBy
      monitoringReportSubmittedAt
      scemdPOCReviewedBy
      scemdPOCReviewedAt
      monitoringFiles {
        items {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        nextToken
      }
      status
    }
  }
`;
export const onUpdateMonitoringSiteVisitReview = /* GraphQL */ `
  subscription OnUpdateMonitoringSiteVisitReview {
    onUpdateMonitoringSiteVisitReview {
      id
      updatedAt
      createdAt
      updatedBy
      gaOnFile
      gaSupportingDocumentationOnFile
      gaComments
      personnelFunded
      personnelDocumentationOnFile
      personnelRecordsOnFile
      personnelComments
      contractualServiceOnFile
      contractualServiceExecutedContractsOnFile
      contractualServiceComments
      travelOnFile
      travelDocumentationOnFile
      travelComments
      equipmentProperlySheltered
      equipmentTransferred
      equipmentComments
      suppliesOnFile
      suppliesTransferred
      suppliesComments
      otherOnFile
      otherComments
      summaryComments
      monitoringReportSubmittedBy
      monitoringReportSubmittedAt
      scemdPOCReviewedBy
      scemdPOCReviewedAt
      monitoringFiles {
        items {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        nextToken
      }
      status
    }
  }
`;
export const onDeleteMonitoringSiteVisitReview = /* GraphQL */ `
  subscription OnDeleteMonitoringSiteVisitReview {
    onDeleteMonitoringSiteVisitReview {
      id
      updatedAt
      createdAt
      updatedBy
      gaOnFile
      gaSupportingDocumentationOnFile
      gaComments
      personnelFunded
      personnelDocumentationOnFile
      personnelRecordsOnFile
      personnelComments
      contractualServiceOnFile
      contractualServiceExecutedContractsOnFile
      contractualServiceComments
      travelOnFile
      travelDocumentationOnFile
      travelComments
      equipmentProperlySheltered
      equipmentTransferred
      equipmentComments
      suppliesOnFile
      suppliesTransferred
      suppliesComments
      otherOnFile
      otherComments
      summaryComments
      monitoringReportSubmittedBy
      monitoringReportSubmittedAt
      scemdPOCReviewedBy
      scemdPOCReviewedAt
      monitoringFiles {
        items {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        nextToken
      }
      status
    }
  }
`;
export const onCreateQuarterlyProgressReporting = /* GraphQL */ `
  subscription OnCreateQuarterlyProgressReporting {
    onCreateQuarterlyProgressReporting {
      id
      quarter
      taskingslipstatus
      updatedAt
      updatedBy
      updatedByRemName
      createdAt
    }
  }
`;
export const onUpdateQuarterlyProgressReporting = /* GraphQL */ `
  subscription OnUpdateQuarterlyProgressReporting {
    onUpdateQuarterlyProgressReporting {
      id
      quarter
      taskingslipstatus
      updatedAt
      updatedBy
      updatedByRemName
      createdAt
    }
  }
`;
export const onDeleteQuarterlyProgressReporting = /* GraphQL */ `
  subscription OnDeleteQuarterlyProgressReporting {
    onDeleteQuarterlyProgressReporting {
      id
      quarter
      taskingslipstatus
      updatedAt
      updatedBy
      updatedByRemName
      createdAt
    }
  }
`;
export const onCreateNarrative = /* GraphQL */ `
  subscription OnCreateNarrative {
    onCreateNarrative {
      id
      narrativeTitle
      narrativeCategory
      narrativeShortDescription
      narrativeLongDescription
      narrativeFrequency
      usePalmettoForREMInformation
      useFinancialReimbursementStatus
      narrativePOCFirstName
      narrativePOCLastName
      narrativePOCPhoneNumber
      narrativePOCEmailAddress
      narrativePOCPositionTitle
      quarter
      deleted
      createdAt
      updatedAt
      updatedBy
      year
      grant
      uploadedFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      groups {
        items {
          id
          completed
          createdAt
          updatedAt
          deleted
          updatedBy
          status
          notes
          extraFile
          quarter
          updatedByFirstName
          updatedByLastName
          year
          grant
        }
        nextToken
      }
    }
  }
`;
export const onUpdateNarrative = /* GraphQL */ `
  subscription OnUpdateNarrative {
    onUpdateNarrative {
      id
      narrativeTitle
      narrativeCategory
      narrativeShortDescription
      narrativeLongDescription
      narrativeFrequency
      usePalmettoForREMInformation
      useFinancialReimbursementStatus
      narrativePOCFirstName
      narrativePOCLastName
      narrativePOCPhoneNumber
      narrativePOCEmailAddress
      narrativePOCPositionTitle
      quarter
      deleted
      createdAt
      updatedAt
      updatedBy
      year
      grant
      uploadedFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      groups {
        items {
          id
          completed
          createdAt
          updatedAt
          deleted
          updatedBy
          status
          notes
          extraFile
          quarter
          updatedByFirstName
          updatedByLastName
          year
          grant
        }
        nextToken
      }
    }
  }
`;
export const onDeleteNarrative = /* GraphQL */ `
  subscription OnDeleteNarrative {
    onDeleteNarrative {
      id
      narrativeTitle
      narrativeCategory
      narrativeShortDescription
      narrativeLongDescription
      narrativeFrequency
      usePalmettoForREMInformation
      useFinancialReimbursementStatus
      narrativePOCFirstName
      narrativePOCLastName
      narrativePOCPhoneNumber
      narrativePOCEmailAddress
      narrativePOCPositionTitle
      quarter
      deleted
      createdAt
      updatedAt
      updatedBy
      year
      grant
      uploadedFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      groups {
        items {
          id
          completed
          createdAt
          updatedAt
          deleted
          updatedBy
          status
          notes
          extraFile
          quarter
          updatedByFirstName
          updatedByLastName
          year
          grant
        }
        nextToken
      }
    }
  }
`;
export const onCreateGroupNarrativeM2M = /* GraphQL */ `
  subscription OnCreateGroupNarrativeM2M {
    onCreateGroupNarrativeM2M {
      id
      narrative {
        id
        narrativeTitle
        narrativeCategory
        narrativeShortDescription
        narrativeLongDescription
        narrativeFrequency
        usePalmettoForREMInformation
        useFinancialReimbursementStatus
        narrativePOCFirstName
        narrativePOCLastName
        narrativePOCPhoneNumber
        narrativePOCEmailAddress
        narrativePOCPositionTitle
        quarter
        deleted
        createdAt
        updatedAt
        updatedBy
        year
        grant
        uploadedFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        groups {
          nextToken
        }
      }
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        awardAmount
        year
        grant
        deleted
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      completed
      createdAt
      updatedAt
      deleted
      updatedBy
      status
      notes
      uploadedFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      extraFile
      quarter
      updatedByFirstName
      updatedByLastName
      year
      grant
      extensions {
        items {
          id
          updatedAt
          createdAt
          updatedBy
          year
          grant
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        nextToken
      }
      currentExtension {
        id
        updatedAt
        createdAt
        updatedBy
        year
        grant
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
      m117CompletedDocument {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      comments {
        items {
          id
          comment
          createdAt
          updatedAt
          deleted
          updatedBy
          updatedByFirstName
          updatedByLastName
        }
        nextToken
      }
    }
  }
`;
export const onUpdateGroupNarrativeM2M = /* GraphQL */ `
  subscription OnUpdateGroupNarrativeM2M {
    onUpdateGroupNarrativeM2M {
      id
      narrative {
        id
        narrativeTitle
        narrativeCategory
        narrativeShortDescription
        narrativeLongDescription
        narrativeFrequency
        usePalmettoForREMInformation
        useFinancialReimbursementStatus
        narrativePOCFirstName
        narrativePOCLastName
        narrativePOCPhoneNumber
        narrativePOCEmailAddress
        narrativePOCPositionTitle
        quarter
        deleted
        createdAt
        updatedAt
        updatedBy
        year
        grant
        uploadedFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        groups {
          nextToken
        }
      }
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        awardAmount
        year
        grant
        deleted
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      completed
      createdAt
      updatedAt
      deleted
      updatedBy
      status
      notes
      uploadedFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      extraFile
      quarter
      updatedByFirstName
      updatedByLastName
      year
      grant
      extensions {
        items {
          id
          updatedAt
          createdAt
          updatedBy
          year
          grant
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        nextToken
      }
      currentExtension {
        id
        updatedAt
        createdAt
        updatedBy
        year
        grant
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
      m117CompletedDocument {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      comments {
        items {
          id
          comment
          createdAt
          updatedAt
          deleted
          updatedBy
          updatedByFirstName
          updatedByLastName
        }
        nextToken
      }
    }
  }
`;
export const onDeleteGroupNarrativeM2M = /* GraphQL */ `
  subscription OnDeleteGroupNarrativeM2M {
    onDeleteGroupNarrativeM2M {
      id
      narrative {
        id
        narrativeTitle
        narrativeCategory
        narrativeShortDescription
        narrativeLongDescription
        narrativeFrequency
        usePalmettoForREMInformation
        useFinancialReimbursementStatus
        narrativePOCFirstName
        narrativePOCLastName
        narrativePOCPhoneNumber
        narrativePOCEmailAddress
        narrativePOCPositionTitle
        quarter
        deleted
        createdAt
        updatedAt
        updatedBy
        year
        grant
        uploadedFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        groups {
          nextToken
        }
      }
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        awardAmount
        year
        grant
        deleted
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      completed
      createdAt
      updatedAt
      deleted
      updatedBy
      status
      notes
      uploadedFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      extraFile
      quarter
      updatedByFirstName
      updatedByLastName
      year
      grant
      extensions {
        items {
          id
          updatedAt
          createdAt
          updatedBy
          year
          grant
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        nextToken
      }
      currentExtension {
        id
        updatedAt
        createdAt
        updatedBy
        year
        grant
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
      m117CompletedDocument {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      comments {
        items {
          id
          comment
          createdAt
          updatedAt
          deleted
          updatedBy
          updatedByFirstName
          updatedByLastName
        }
        nextToken
      }
    }
  }
`;
export const onCreateRemContact = /* GraphQL */ `
  subscription OnCreateRemContact {
    onCreateREMContact {
      id
      firstName
      lastName
      emailAddress
      phoneNumber
      region
      deleted
      createdAt
      updatedAt
      updatedBy
      positionTitle
      year
    }
  }
`;
export const onUpdateRemContact = /* GraphQL */ `
  subscription OnUpdateRemContact {
    onUpdateREMContact {
      id
      firstName
      lastName
      emailAddress
      phoneNumber
      region
      deleted
      createdAt
      updatedAt
      updatedBy
      positionTitle
      year
    }
  }
`;
export const onDeleteRemContact = /* GraphQL */ `
  subscription OnDeleteRemContact {
    onDeleteREMContact {
      id
      firstName
      lastName
      emailAddress
      phoneNumber
      region
      deleted
      createdAt
      updatedAt
      updatedBy
      positionTitle
      year
    }
  }
`;
