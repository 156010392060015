 
import React from 'react';
import { connect } from 'react-redux';
import IconButton from "@material-ui/core/IconButton";
import { MdAdd, MdClear } from 'react-icons/md';
import { PaperFabButton } from "../../styled-components/Buttons/Buttons.js";
import ReactTable from "react-table";

// Local imports

// Component imports
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"

// CSS imports

import "react-table/react-table.css";
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

// Redux imports
import { resetREMContact, setCurrentREMContact } from "../../../redux/actions/index";

const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        selectedQuarterProgress: state.rootReducer.selectedQuarterProgress,
        selectedYear: state.rootReducer.selectedYear,
        allREMContacts: state.rootReducer.allREMContacts,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetREMContact: () => dispatch(resetREMContact()),
        setCurrentREMContact: (payload) => dispatch(setCurrentREMContact(payload)),
    }
};

class REMContactHome extends React.Component {

    state = {
        anchorEl: null,

    }

    constructor(props, context) {
        super(props, context);
    }

    onRowClick = (state, rowInfo, column, instance) => {
        return {
            onClick: (e, handleOriginal) => {
                this.props.history.push("/narratives/rem/edit");
                this.props.setCurrentREMContact(rowInfo.original);
                if (handleOriginal) {
                    handleOriginal();
                }
            }
        };
    };    

    goBack = (event) => {
        this.props.history.goBack();
    }

    openNewPage = () => {
        this.props.resetREMContact();
        this.props.history.push("/narratives/rem/new")
    }

    render() {

        return (

            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdClear className="icon" />
                        </IconButton>
                    }
                    titleBig={<span>Update REM Contact</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>LEMPG • {this.props.selectedYear}</span>}
                />

                <div className="flex container-overflow-y">
                    <ReactTable
                        minRows={0}
                        data={this.props.allREMContacts}
                        defaultPageSize={100}
                        NoDataComponent={() => null}
                        showPagination={false}
                        getTdProps={this.onRowClick}
                        columns={[
                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">Region</div>
                                ),
                                className: "body-cell",
                                accessor: "region",
                                width: 200,
                                Cell: row => (
                                    <span className="truncate">R{row.value && parseInt(row.value.split("").reverse().join(""))}</span>
                                )                                
                            },
                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">First Name</div>
                                ),
                                className: "body-cell",
                                accessor: "firstName",
                                width: 200,
                            },
                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">Last Name</div>
                                ),
                                className: "body-cell",
                                accessor: "lastName", 
                                width: 200,
 
                            },
                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">Email Address</div>
                                ),
                                className: "body-cell",
                                accessor: "emailAddress",
                                width: 200,
                            },
                            
                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">Phone Number</div>
                                ),
                                className: "body-cell",
                                accessor: "phoneNumber",

                            }
                        ]}
                    />
                    <PaperFabButton onClick={() => this.openNewPage()} aria-label="add">
                        <MdAdd className="icon" />
                    </PaperFabButton>
                </div>
            </div>


        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(REMContactHome);