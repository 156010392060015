const DrawerItems = [
    {
        key: "home",
        route: "/",
        title: "Home",
        seenBy: ["*"],
    },
    {
        key: "prefiscalyear",
        route: "/prefiscalyear",
        title: "Pre-fiscal Year",
        seenBy: ["LEMPG_STATE_ACCESS"],
        hidden: true, 
    },
    {
        key: "narratives",
        route: "/narratives",
        title: "Narratives",
        seenBy: ["LEMPG_STATE_ACCESS", "LEMPG_SME", "LEMPG_REGION_1", "LEMPG_REGION_2", "LEMPG_REGION_3", "LEMPG_REGION_4", "LEMPG_REGION_5", "LEMPG_REGION_6"],
        // disabled: true,
    },
    // {
    //     key: "scopeofwork",
    //     route: "/",
    //     title: "Scope of Work",
    //     seenBy: ["LEMPG_STATE_ACCESS", "LEMPG_ACCESS", "LEMPG_SME", "LEMPG_REGION_1", "LEMPG_REGION_2", "LEMPG_REGION_3", "LEMPG_REGION_4", "LEMPG_REGION_5", "LEMPG_REGION_6"],
    //     disabled: true,
    // },
    {
        key: "application",
        route: "/application/",
        title: "Application",
        seenBy: ["LEMPG_STATE_ACCESS", "LEMPG_ACCESS", "LEMPG_REGION_1", "LEMPG_REGION_2", "LEMPG_REGION_3", "LEMPG_REGION_4", "LEMPG_REGION_5", "LEMPG_REGION_6"],
    },
    {
        key: "progressreport",
        route: "/progressreport/county",
        title: "Progress Reporting",
        seenBy: ["LEMPG_STATE_ACCESS", "LEMPG_ACCESS", "LEMPG_SME", "LEMPG_REGION_1", "LEMPG_REGION_2", "LEMPG_REGION_3", "LEMPG_REGION_4", "LEMPG_REGION_5", "LEMPG_REGION_6"],
        // disabled: true,
    },
    {
        key: "financialreimbursement",
        route: "/financialreimbursement/county",
        title: "Financial Reimbursement",
        seenBy: ["LEMPG_STATE_ACCESS", "LEMPG_ACCESS", "LEMPG_REGION_1", "LEMPG_REGION_2", "LEMPG_REGION_3", "LEMPG_REGION_4", "LEMPG_REGION_5", "LEMPG_REGION_6"],
        // disabled: true,
    },
    {
        key: "extensions",
        route: "/extensions/",
        title: "Extensions",
        seenBy: ["LEMPG_STATE_ACCESS", "LEMPG_SME", "LEM PG_REGION_1", "LEMPG_REGION_2", "LEMPG_REGION_3", "LEMPG_REGION_4", "LEMPG_REGION_5", "LEMPG_REGION_6"],
    },
    {
        key: "financialmonitoring",
        route: "/monitoring",
        title: "Monitoring",
        seenBy: ["LEMPG_STATE_ACCESS", "LEMPG_SME", "LEMPG_REGION_1", "LEMPG_REGION_2", "LEMPG_REGION_3", "LEMPG_REGION_4", "LEMPG_REGION_5", "LEMPG_REGION_6"],
    },
    {
        key: "filelibrary",
        route: "/filelibrary/county",
        title: "Documents",
        seenBy: ["*"],
    }
];

export function GetDrawerItems(userType, yearInfo) {
    if(!userType) {
        return [
            {
                route: "/",
                title: "Home",
            }
        ]
    }

    let items = []
    DrawerItems.forEach(item => {

        if(userType !== "LEMPG_ACCESS") {
            if(item.key == "application") {
                item.route = "/application/county/"
            }
            if (item.key == "financialreimbursement") {
                item.route = "/financialreimbursement/"
            }
            if (item.key == "progressreport") {
                item.route = "/progressreport/"
            }
            if (item.key == "filelibrary") {
                item.route = "/filelibrary/state"
            }

            if(item.key == "narratives" && yearInfo.year == "2019") {
                item.disabled = true;
                item.route = "/"
            }
        }

        if (userType === "LEMPG_STATE_ACCESS") {
            if (item.key == "prefiscalyear" && yearInfo && yearInfo.id && !yearInfo.availableToCounties) {
                console.log("Setting item to false")
                item.hidden = false;
            }
        }

        if(item.seenBy.indexOf("*") >= 0) {
            items.push(item)
        } else if (item.seenBy.indexOf(userType) >= 0) {
            items.push(item)
        } 
    });


    return items;
}
