import React from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import IconButton from "@material-ui/core/IconButton";
import { MdArrowBack, MdChevronRight, MdInsertDriveFile, MdFileDownload } from 'react-icons/md';


// Local imports
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js";
import { HeaderButton } from "../../styled-components/Buttons/Buttons";
import FileUpload from "../../common/FileUpload";
import { CardComponentDownloadCard } from "../../layout/CardComponent";
import { saveFileForNewFiscalYear } from "../../../redux/actions/index";


// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = state => {
    return {
        currentFiscalYear: state.rootReducer.currentFiscalYear,
        lempgUserType: state.rootReducer.lempgUserType,
        selectedYear: state.rootReducer.selectedYear
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveFileForNewFiscalYear: (payload, fieldName, callback) => dispatch(saveFileForNewFiscalYear(payload, fieldName, callback)),
    };
};

class NarrativesDocument extends React.Component {

    state = {
        file_id: "",
    }

    constructor(props, context) {
        super(props, context);
        this.goBack = this.goBack.bind(this);
        this.fileToSave = this.fileToSave.bind(this);
        this.fileToDelete = this.fileToDelete.bind(this);
        this.saveFileCallback = this.saveFileCallback.bind(this);
    }

    componentDidMount() {
        if (this.props.currentFiscalYear.narrativesFile && this.props.currentFiscalYear.narrativesFile.id) {

            this.state.file_id = this.props.currentFiscalYear.narrativesFile.id;

            this.setState({
                file_id: this.state.file_id
            });
        }
    }


    goBack() {
        this.props.history.goBack();
    }

    /**
     * 
    */

    fileToSave(data) {
        this.setState({ file_id: data.data.createFile.id });
    }

    /**
     * 
    */


    fileToDelete() {
        this.setState({ file_id: null });
    }

    /**
     * 
    */


    saveFile(data) {
        this.props.saveFileForNewFiscalYear(this.state, "fiscalYearNarrativesFileId", this.saveFileCallback)
    }

    /**
     * 
    */

    saveFileCallback(data) {
        this.goBack()
    }
    render() {

        return (

            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdArrowBack className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Narratives</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>{window.appHeaderTitle}</span>}

                    suffix={
                        <div className="form-margin-16">
                            <HeaderButton onClick={() => this.saveFile()}>
                                Save
                            </HeaderButton>
                        </div>
                    }
                />

                {/* Only use form-container class when you are not using input fields in the form */}
                <div className="flex form-container">

                    <div className="height-20"></div>

                    <FileUpload fileToSave={this.fileToSave} fileToDelete={this.fileToDelete} uploadedFile={this.props.currentFiscalYear && this.props.currentFiscalYear.narrativesFile ? this.props.currentFiscalYear.narrativesFile : null} singleupload="true" allowStateToEdit="true" />
                </div>
            </div>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(NarrativesDocument);
