import React from 'react';
import ReactDOM from 'react-dom';

import { NormalKeyboardDatePicker } from "../styled-components/TextFields/TextFields.js";

export class PalmettoDatePicker extends React.Component {

    state = {
        openDatePicker: false
    }
    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        //This will return the container of WrappedComponent
        ReactDOM.findDOMNode(this).onclick = this.toggleDatePicker
    }

    toggleDatePicker = () => {
        this.setState({"openDatePicker": !this.state.openDatePicker});
    }

    closeDatePicker = () => {
        this.setState({"openDatePicker": false});
    }

    render() {
        return (
            <React.Fragment>
                <NormalKeyboardDatePicker
                    margin={this.props.margin}
                    id={this.props.id}
                    label={this.props.label}
                    placeholder={this.props.placeholder}
                    className={this.props.className}
                    mandatory={this.props.mandatory} 
                    // custommargin="true"
                    autoComplete={false}
                    onChange={this.props.onChange}
                    onClose={this.closeDatePicker}
                    open={this.state.openDatePicker}
                    minDate={this.props.minDate || undefined}
                    autoOk={true}
                    format='MMM dd, yyyy'
                    invalidDateMessage=""
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    {...this.props}
                    value={this.props.value || null}
                />

            </React.Fragment>
        );
    }
}
