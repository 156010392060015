import React from 'react';
import { connect } from 'react-redux'
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import IconButton from "@material-ui/core/IconButton";
import { MdArrowBack, MdAccessTime } from 'react-icons/md';


const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        currentQuarter: state.rootReducer.currentQuarter,
        grantApplication: state.rootReducer.grantApplication,
        selectedCounty: state.rootReducer.selectedCounty,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

class FinancialReimbursementPendingApproval extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    goBack = () => {
        this.props.history.goBack()
    }

    render() {
        const quarterSubheaderTitle = "Q" + parseInt(this.props.currentQuarter.quarter.split("").reverse())

        return (
            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdArrowBack className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Financial Reimbursement</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>{quarterSubheaderTitle}  • {this.props.selectedCounty && this.props.selectedCounty.groupName}</span>}
                />

                <div className="flex layout vertical center vertical-center container-overflow-y">

                    <MdAccessTime className="icon-2 application-status-icon" />
                    <div className="height-20"></div>
                    <span className="layout horizontal center center-justified application-status-title-small">Pending Approval</span>
                    <div className="height-20"></div>

                    <span style={{ padding: "0 16px", maxWidth: "456px" }} className="text-center title-big opacity-54">Your Financial Reimbursement for {quarterSubheaderTitle} {this.props.currentQuarter && this.props.currentQuarter.year} is under review by the State. Check in 2-3 weeks for progress.</span>

                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialReimbursementPendingApproval);
