import { API, graphqlOperation } from "aws-amplify";
import { GetFinancialReimbursement } from "../../queries/GetFinancialReimbursement";
import { updateFinancialReimbursement } from "../../mutations/updateFinancialReimbursement";
import { APPLICATION_LOADING_STATE, CURRENT_QUARTER, } from "../../constants/action-types";

import * as mutations from '../../../graphql/mutations';

export function saveEquipment(payload, callback) {
    return async function (dispatch, getState) {
        const { currentQuarter, user } = getState().rootReducer;

        let type, equipmentFilter;
        
        // return
        if (payload.id) {
            // Update an existing entry
            equipmentFilter = {
                input: {
                    id: payload.id,
                    deleted: payload.deleted || false,
                    updatedBy: user.username,
                    positionTitle: payload.positionTitle,
                    quarterAmount: payload.quarterAmount || null,
                    matchAmount: payload.matchAmount || null,
                    taxAmount: payload.taxAmount || null,
                    file: JSON.stringify(payload.files),
                    financialReimbursementEquipmentId: currentQuarter.id
                }
            }

            type = mutations.updateFimEquipment;
        } else {
            // create a new entry
            equipmentFilter = {
                input: {
                    deleted: payload.deleted || false,
                    updatedBy: user.username,
                    positionTitle: payload.positionTitle,
                    quarterAmount: payload.quarterAmount || null,
                    matchAmount: payload.matchAmount || null,
                    taxAmount: payload.taxAmount || null,
                    file: JSON.stringify(payload.files),
                    financialReimbursementEquipmentId: currentQuarter.id
                }
            }
            type = mutations.createFimEquipment;

        }

        try {

            dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

            let res = await API.graphql(graphqlOperation(type, equipmentFilter))

            let msg = payload.deleted ? "Equipment deleted successfully" : "Equipment saved successfully";
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: msg } }))

            // If the status of the quarter application is NOT_STARTED change it to DRAFT

            if ((currentQuarter.status === "NOT_STARTED") || (currentQuarter.status === "NOT_AVAILABLE") ) {
                let updateQuarterFilter = {
                    input: {
                        id: currentQuarter.id,
                        status: "DRAFT",
                    }
                };

                const quarterDraftStatus = await API.graphql(graphqlOperation(updateFinancialReimbursement, updateQuarterFilter));
            }
            

            // Updating Financial Reim form to generate an update to all users

            await API.graphql(graphqlOperation(updateFinancialReimbursement, {
                input: {
                    id: currentQuarter.id,
                    updatedAt: new Date(),
                }
            }));            

            let appSyncFilter = {
                id: currentQuarter.id
            };


            const quarterRes = await API.graphql(graphqlOperation(GetFinancialReimbursement, appSyncFilter));
            

            dispatch({ type: CURRENT_QUARTER, payload: quarterRes.data.getFinancialReimbursement });
            dispatch({ type: APPLICATION_LOADING_STATE, payload: false });

            if (callback) { callback(null, res); }

        } catch (e) {
            console.error("Error occured", e)
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Error saving equipment' } }))
            dispatch({ type: APPLICATION_LOADING_STATE, payload: false });

            if (callback) { callback(e); }
        }


    }
}