 
import React from 'react';
import { connect } from 'react-redux';
import IconButton from "@material-ui/core/IconButton";
import { MdMenu, MdMoreVert, MdAdd } from 'react-icons/md';
import { PaperFabButton } from "../../styled-components/Buttons/Buttons.js";
import ReactTable from "react-table";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// Local imports

// Component imports
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"

// CSS imports

import "react-table/react-table.css";
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

// Redux imports
import { resetNarrative, setCurrentNarrative } from "../../../redux/actions/index";

const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        selectedQuarterProgress: state.rootReducer.selectedQuarterProgress,
        selectedYear: state.rootReducer.selectedYear,
        allNarratives: state.rootReducer.allNarratives,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetNarrative: () => dispatch(resetNarrative()),
        setCurrentNarrative: (payload) => dispatch(setCurrentNarrative(payload)),
    }
};

class NarrativesStatePage extends React.Component {

    state = {
        displayQuarter: {
            "quarter1": "Quarter 1",
            "quarter2": "Quarter 2",
            "quarter3": "Quarter 3",
            "quarter4": "Quarter 4",
            "all": "Recurring",
        },
        anchorEl: null,        
    }

    constructor(props, context) {
        super(props, context);
    }

    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    _convertToTitleCase = (str) => {
        str = str.toLowerCase();
        return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    }

    updateREMContactInformation = () => {
        this.props.history.push("/narratives/rem")
    }

    getAllNarratives = (narratives) => {
        var arr = []

        narratives.forEach( (data) => {
            arr.push({
                ...data,
                ...{
                    narrativeTitle: data.narrativeTitle,
                    narrativeQuarter: data.quarter,
                    narrativeQuarterDisplay: this.state.displayQuarter[data.quarter],
                    narrativeDescription: data.narrativeShortDescription,
                    narrativeFileAttached: data.uploadedFile ? "Yes" : "",
                }
            })
        })
        return arr.sort( (a, b) => a.narrativeTitle.localeCompare(b.narrativeTitle));
    }

    onRowClick = (state, rowInfo, column, instance) => {
        return {
            onClick: (e, handleOriginal) => {
                this.props.history.push("/narratives/edit");

                this.props.setCurrentNarrative(rowInfo.original);

                if (handleOriginal) {
                    handleOriginal();
                }
            }
        };
    };    

    handleMenuClick = (event) => {
        window.dispatchEvent(new CustomEvent("toggle-sidebar"))
    }

    openNewPage = () => {
        this.props.resetNarrative();
        this.props.history.push("/narratives/new")
    }

    render() {

        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return (

            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.handleMenuClick}
                        >
                            <MdMenu className="icon" />
                        </IconButton>
                    }
                    titleBig={<span>Narratives</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>{window.appHeaderTitle}</span>}
                    suffix={
                        <div>
                            <IconButton
                                aria-label="More"
                                aria-haspopup="true"
                                aria-owns={open ? 'application-menu' : undefined}
                                onClick={this.handleIconDropdownClick}
                            >
                                <MdMoreVert className="icon" />
                            </IconButton>

                            <Menu
                                id="application-menu"
                                // anchorEl={anchorEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                                open={open}
                                onClose={this.handleClose}
                            >
                                <MenuItem key="updateRemContact" onClick={() => this.updateREMContactInformation()}>
                                    Update REM Contact Information
                                </MenuItem>
                            </Menu>
                        </div>

                    } 

                />

                <div className="flex container-overflow-y">
                    <ReactTable
                        minRows={0}
                        data={this.getAllNarratives(this.props.allNarratives)}
                        defaultPageSize={100}
                        NoDataComponent={() => null}
                        showPagination={false}
                        getTdProps={this.onRowClick}
                        columns={[

                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">Title</div>
                                ),
                                className: "body-cell",
                                accessor: "narrativeTitle",
                                width: 200,
                            },
                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">Quarter</div>
                                ),
                                className: "body-cell",
                                accessor: "narrativeQuarterDisplay", 
                                width: 200,
 
                            },
                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">File Attached</div>
                                ),
                                className: "body-cell",
                                accessor: "narrativeFileAttached",
                                width: 200,
                            },
                            
                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">Description</div>
                                ),
                                // style: { 
                                //     'whiteSpace': 'nowrap',
                                //     'overflow': 'hidden',
                                //     'textOverflow': 'ellipsis',
                                //     'minWidth': 0
                                // },
                                className: "body-cell",
                                accessor: "narrativeDescription",
                                Cell: row => (
                                    <span className="truncate">{row.value}</span>
                                )
                            }
                        ]}
                    />
                    <PaperFabButton onClick={() => this.openNewPage()} aria-label="add">
                        <MdAdd className="icon" />
                    </PaperFabButton>
                </div>
            </div>


        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NarrativesStatePage);
