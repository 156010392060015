export const updateFinancialReimbursement = `mutation UpdateFinancialReimbursement(
  $input: UpdateFinancialReimbursementInput!
) {
  updateFinancialReimbursement(input: $input) {
    id
    quarter
    quarterStartDate
    quarterEndDate
    updatedBy
    year
    group {
      id
      groupName
      createdAt
      updatedAt
      region
      palmettoId
      narratives {
        nextToken
      }
      quarterlyprogressreporting {
        nextToken
      }
      deleted
      grantapplication {
        nextToken
      }
      financialreimbursement {
        nextToken
      }
    }
    createdAt
    updatedAt
    deleted
    status
    awarded
    checkNumber
    checkAmount
    checkMailDate
    stateSceisDocumentNumber
    stateSceisSubmissionDate

    checkNumber2
    checkAmount2
    checkMailDate2
    stateSceisDocumentNumber2
    stateSceisSubmissionDate2

    checksReissued(limit: 50) {
      items {
        id
        deleted
        updatedBy
        createdAt
        updatedAt
        checkNumber
        checkAmount
        checkMailDate
        reason
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        reason2        
      }
      nextToken
    }
    currentReissuedCheck {
      id
      deleted
      updatedBy
      createdAt
      updatedAt
      checkNumber
      checkAmount
      checkMailDate
      reason
      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      reason2      
    }
    additionalChecks {
      items {
        id
        deleted
        updatedBy
        createdAt
        updatedAt
        checkNumber
        checkAmount
        checkMailDate
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        reason
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        reason2        
      }
      nextToken
    }
    additionalCheckInProgress {
      id
      deleted
      updatedBy
      createdAt
      updatedAt
      checkNumber
      checkAmount
      checkMailDate
      stateSceisDocumentNumber
      stateSceisSubmissionDate
      reason
      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      reason2      
    }

    extensions(limit: 50) {
      items {
        id
        updatedAt
        createdAt
        updatedBy
        year
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
      nextToken
    }
    currentExtension {
      id
      updatedAt
      createdAt
      updatedBy
      year
      type
      group
      status
      for
      quarterFrom
      quarterTo
      dueDate
      reasonForExtension
      dateAdjustedTo
      reasonForAdjustment
      reasonForDeniedExtension
    }

    personnel(limit: 50) {
      items {
        id
        deleted
        updatedBy
        positionTitle
        quarterAmount
        matchAmount
        taxAmount
        file
      }
      nextToken
    }
    contractualService(limit: 50) {
      items {
        id
        deleted
        updatedBy
        positionTitle
        quarterAmount
        matchAmount
        taxAmount
        file
      }
      nextToken
    }
    travel(limit: 50) {
      items {
        id
        deleted
        updatedBy
        positionTitle
        quarterAmount
        matchAmount
        taxAmount
        file
      }
      nextToken
    }
    equipment(limit: 50) {
      items {
        id
        deleted
        updatedBy
        positionTitle
        quarterAmount
        matchAmount
        taxAmount
        file
      }
      nextToken
    }
    supply(limit: 50) {
      items {
        id
        deleted
        updatedBy
        positionTitle
        quarterAmount
        matchAmount
        taxAmount
        file
      }
      nextToken
    }
    cert(limit: 50) {
      items {
        id
        deleted
        updatedBy
        positionTitle
        quarterAmount
        matchAmount
        taxAmount
        file
      }
      nextToken
    }
    other(limit: 50) {
      items {
        id
        deleted
        updatedBy
        positionTitle
        quarterAmount
        matchAmount
        taxAmount
        file
      }
      nextToken
    }
    deobligatedFunds
    deobligatedFundsReason
  }
}
`;
