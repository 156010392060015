
import React from 'react';
import { connect } from 'react-redux'
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import IconButton from "@material-ui/core/IconButton";
import { MdArrowBack, MdWarning, MdAccessTime } from 'react-icons/md';
import { SecondaryButton } from "../../styled-components/Buttons/Buttons.js";

import { updateStatusGroupNarrativeM2M } from "../../../redux/actions/index";


const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        currentQuarter: state.rootReducer.currentQuarter,
        selectedCounty: state.rootReducer.selectedCounty,
        currentNarrative: state.rootReducer.currentNarrative,
        currentGroupNarrativeM2M: state.rootReducer.currentGroupNarrativeM2M,
        selectedYear: state.rootReducer.selectedYear
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateStatusGroupNarrativeM2M: (payload, callback) => dispatch(updateStatusGroupNarrativeM2M(payload, callback)),

    }
}

class ProgressReportNarrativeCountyStatusPage extends React.Component {

    state = {
        narrativeTitle: (this.props.currentNarrative && this.props.currentNarrative.narrativeTitle) || "",
        currentQuarter: (this.props.currentNarrative && this.props.currentNarrative.quarter) || "",
    }

    constructor(props, context) {
        super(props, context);
    }

    goBack = () => {
        this.props.history.goBack()
    }

    updateStatusGroupNarrativeM2M = () => {
        this.props.updateStatusGroupNarrativeM2M({
            id: this.props.currentGroupNarrativeM2M.id, 
            status: 'DRAFT',
        }, this.goBack)
    }

    render() {

        const quarterSubheaderTitle = "Q" + parseInt(this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.quarter.split("").reverse())

        const ChangesRequested = (props) => {
            return (
                <div className="flex layout vertical center vertical-center container-overflow-y">

                    <MdWarning className="icon-2 application-status-icon" />
                    <div className="height-20"></div>
                    <span className="layout horizontal center center-justified application-status-title-small">Changes Requested</span>
                    <div className="height-20"></div>

                    <span style={{ padding: "0 16px", maxWidth: "456px" }} className="text-center title-big opacity-54">The state found issue with your {this.state.narrativeTitle} narrative for {quarterSubheaderTitle} {this.props.selectedYear}. If you have any questions, reach out to your State contact.</span>

                    <div className="height-20"></div>

                    <div className="layout horizontal center-justified">
                        <SecondaryButton onClick={() => this.updateStatusGroupNarrativeM2M()}>Edit Narrative</SecondaryButton>
                    </div>


                </div>

            )
        }

        const Submitted = (props) => {
            return (
                <div className="flex layout vertical center vertical-center container-overflow-y">

                    <MdAccessTime className="icon-2 application-status-icon" />
                    <div className="height-20"></div>
                    <span className="layout horizontal center center-justified application-status-title-small">Pending Approval</span>
                    <div className="height-20"></div>

                    <span style={{ padding: "0 16px", maxWidth: "456px" }} className="text-center title-big opacity-54">The {this.state.narrativeTitle} narrative for {quarterSubheaderTitle} {this.props.selectedYear} is under review by the State. Check in soon for progress.</span>

                </div>
            )
        }


        return (
            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdArrowBack className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>{this.state.narrativeTitle}</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>Progress Reporting  • {this.props.selectedCounty && this.props.selectedCounty.groupName}</span>}
                />

                {
                    (this.props.status == "SUBMITTED") ? <Submitted /> : (this.props.status == "CHANGES_REQUESTED") ? <ChangesRequested /> : ""
                }

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressReportNarrativeCountyStatusPage);
