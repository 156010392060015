import React from 'react';
import { connect } from 'react-redux'
import IconButton from "@material-ui/core/IconButton";
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { MdClear } from 'react-icons/md';

// Local imports

// Component imports
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import { PrimaryButton, HeaderButton, DeleteButton } from "../../styled-components/Buttons/Buttons.js";
import { DecimalFormatCustom } from "../../utilities/NumberFormat.js";
import { RequiredDropdown } from "../../styled-components/Dropdowns/Dropdown.js";
import { NormalTextField, RequiredTextField } from "../../styled-components/TextFields/TextFields.js";
import FileUpload from "../../common/FileUpload.js";
import ErrorBox from "../../common/ErrorBox.js";

// Redux imports
import { saveOther } from "../../../redux/actions/financial_reimbursements/FinancialOtherAction.js";


// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = (state) => {
    
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        counties: state.rootReducer.counties,
        currentQuarter: state.rootReducer.currentQuarter,
        grantApplication: state.rootReducer.grantApplication,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveOther: (payload, callback) => dispatch(saveOther(payload, callback)),
    }
};

const DropdownMenuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
    },
    PaperProps: {
        style: {
            minWidth: "200px",
        },
    },
};



class FinancialOtherEditPage extends React.Component {

    state = {
        other: (this.props.currentQuarter && this.props.currentQuarter.other && this.props.currentQuarter.other && this.props.currentQuarter.other.items) || [],
        positionTitle: this.props.positionTitle || "",
        quarterAmount: this.props.quarterAmount || "",
        matchAmount: this.props.matchAmount || "",
        taxAmount: this.props.taxAmount || "",
        id: this.props.id || "",
        files: this.props.files || [],
        errors: [],
        results: {},
        validate: false,

    }

    constructor(props, context) {
        super(props, context);
        this.fileToSave = this.fileToSave.bind(this);
        this.fileToDelete = this.fileToDelete.bind(this);
        this.saveFileCallback = this.saveFileCallback.bind(this);
    }

    showItems = () => {
        this.props.showItems();
    }

    componentDidUpdate() { }

    deleteOther = () => {
        this.state.deleted = true;
        this.props.saveOther(this.state, this.callback)
    }
    callback = (err, data) => {
        if (err) {
            return;
        }

        this.showItems();
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    saveOther = () => {

        this.state.validate = false;
        this.state.errors = [];
        this.setState({ validate: this.state.validate });
        this.setState({ errors: this.state.errors });

        if (!this.state.positionTitle) {
            this.state.errors.push({
                text: "Please select a other"
            });
        }

        if (!this.state.quarterAmount) {
            this.state.errors.push({
                text: "Please enter quarter amount"
            });
        }

        // if (!this.state.taxAmount) {
        //     this.state.errors.push({
        //         text: "Please enter tax amount"
        //     });
        // }

        if (this.state.errors.length > 0) {
            this.state.validate = true;
            this.setState({ validate: this.state.validate });
            this.setState({ errors: this.state.errors });
            return;
        }

        this.props.saveOther(this.state, this.callback)
    }


    /**
     * File uploading functions
     * 
     */


    fileToSave(data) {
        this.state.files.push({
            file: data.data.createFile.id,
            key: data.data.createFile.title,
            title: data.data.createFile.title,
            size: data.data.createFile.size,
        });
    }

    fileToDelete(file) {
        var files = this.state.files;
        for (let i = files.length; i--;) {
            var _file = files[i];
            if (file.key === _file.key) {
                files.splice(i, 1);
                break;
            }
        }
        this.state.files = files
    }

    saveFileCallback(data) { }

    render() {

        const quarterSubheaderTitle = "Q" + parseInt(this.props.currentQuarter.quarter.split("").reverse())

        return (
            <div className="layout vertical full-height">
                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.showItems}
                        >
                            <MdClear className="icon" />
                        </IconButton>

                    }
                    titleBig={this.state.id ? <span> Edit Other </span> : <span> Add Other </span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>{quarterSubheaderTitle}  • {this.props.selectedCounty && this.props.selectedCounty.groupName}</span>}

                    suffix={
                        this.state.id ?
                            <div className="form-margin-16">
                                <HeaderButton onClick={() => this.saveOther()}>
                                    Save
                                    </HeaderButton>
                            </div> : ""
                    }
                />

                <div className="flex form-shell container-overflow-y">

                    {
                        this.state.errors.length ?
                            <div className="margin-10">
                                <ErrorBox errors={this.state.errors} />
                            </div>
                            : ""
                    }


                    <div className="layout horizontal center section-title form-margin">Other Info</div>

                    <div className="height-10"></div>

                    <div className="layout horizontal">

                        {
                            this.state.id ?
                                <RequiredTextField value={this.state.positionTitle} id="otherpositionTitle" label="Other" custommargin="true" className="flex" InputProps={{
                                    readOnly: true,
                                }} InputLabelProps={{
                                    shrink: true,
                                }} />
                                :
                                <FormControl className="flex" error={this.state.positionTitle.length == 0 && this.state.validate ? true : false}>
                                    <InputLabel style={{ margin: "0 6px" }} shrink htmlFor="other-select-position"> Other <span className="required"></span> </InputLabel>
                                    <RequiredDropdown
                                        value={this.state.positionTitle}
                                        onChange={this.handleChange('positionTitle')}
                                        input={<Input name="positionTitle" id="other-select-position" />}
                                        MenuProps={DropdownMenuProps}
                                        custommargin="true"
                                        displayEmpty
                                        name="positionTitle"
                                        className="required-dropdown"
                                    >
                                        <MenuItem value="">
                                            <span className="placeholder">Select other</span>
                                        </MenuItem>
                                        {
                                            this.props.getItems('other').map((item) => {
                                                return (
                                                    <MenuItem key={item.id} value={item.name}>{item.name}</MenuItem>
                                                )

                                            })
                                        }
                                    </RequiredDropdown>
                                </FormControl>
                        }

                    </div>

                    <div className="layout horizontal">
                        {/*  */}
                        <RequiredTextField value={this.state.quarterAmount} onChange={this.handleChange('quarterAmount')} id="otherQuarterAmount" label="Quarter Amount (Federal)" error={!this.state.quarterAmount && this.state.validate ? true : false} custommargin="true" placeholder="Enter amount" className="flex" margin="normal"
                            InputProps={{ inputComponent: DecimalFormatCustom }}
                            InputLabelProps={{
                                shrink: true,
                            }} />
                    </div>

                    {/* <div className="layout horizontal">
                        <RequiredTextField value={this.state.taxAmount} onChange={this.handleChange('taxAmount')} id="otherTaxAmount" label="Tax Amount" error={!this.state.taxAmount && this.state.validate ? true : false} custommargin="true" placeholder="Enter amount" className="flex" margin="normal"
                            InputProps={{ inputComponent: DecimalFormatCustom }}
                            InputLabelProps={{
                                shrink: true,
                            }} />
                    </div> */}

                    <div className="layout horizontal">
                        <NormalTextField value={this.state.matchAmount} onChange={this.handleChange('matchAmount')} id="otherMatchAmount" label="Match Amount (County)" custommargin="true" placeholder="Enter amount" className="flex" margin="normal"
                            InputProps={{ inputComponent: DecimalFormatCustom }}
                            InputLabelProps={{
                                shrink: true,
                            }} />
                    </div>

                    <div className="layout horizontal end section-title form-margin">File Attachments</div>
                    <div className="layout horizontal form-margin">
                        <FileUpload allowStateToEdit="true"  fileToSave={this.fileToSave} fileToDelete={this.fileToDelete} supportingTitle="Include supporting documentation" notitle="true" className="flex" extraFiles={this.state.files} />
                    </div>

                    <div className="height-20"></div>

                    {
                        this.state.id ?
                            <div className="layout horizontal form-margin end-justified">
                                <DeleteButton setwidth="true" onClick={() => this.deleteOther()}> Delete </DeleteButton>
                            </div>
                            :
                            <div className="layout horizontal form-margin end-justified">
                                <PrimaryButton setwidth="true" onClick={() => this.saveOther()}> Submit </PrimaryButton>
                            </div>
                    }


                </div>
            </div>

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialOtherEditPage);
