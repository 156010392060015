import React from 'react';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import NumberFormat from 'react-number-format';

// Component imports

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

import {
    setPreGrantSelectedCounty
} from "../../../redux/actions/index";

const mapStateToProps = (state) => {
    return {
        counties: state.rootReducer.counties,
        selectedYear: state.rootReducer.selectedYear
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPreGrantSelectedCounty: (payload) => dispatch(setPreGrantSelectedCounty(payload)),
    }
};

class PreGrantOverview extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            smallScreen: false,
        }
        this.tableHeaders = [
            {
                Header: () => (
                    <div className="layout horizontal center body-header">County</div>
                ),
                className: "body-cell",
                accessor: "county"
            },
            {
                Header: () => (
                    <div className="layout horizontal center body-header">Award Amount</div>
                ),
                className: "body-cell",
                accessor: "awardAmount",
                Cell: row => (
                    <NumberFormat value={row.value} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                )                                
            }
        ]
    }

    // Builds data to be displayed in the Awards Table
    getAwardData(counties) {
        let toRet = [];
        if (counties) {
            counties.forEach((item) => {
                toRet.push({
                    "id": item.id,
                    "year": item.year,
                    "palmettoId": item.palmettoId,
                    "county": this._convertToTitleCase(item.groupName),
                    "awardAmount": item.awardAmount || 0
                })
            });
        }
        return toRet;
    }

    onSortChange() {
    }

    _convertToTitleCase = (str) => {
        str = str.toLowerCase();
        return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    }

    onRowClick = (state, rowInfo, column, instance) => {
        return {
            onClick: (e, handleOriginal) => {
                console.log(rowInfo.original)
                this.props.setPreGrantSelectedCounty(rowInfo.original)
                this.props.history.push("/prefiscalyear/pregrantawards/new");
                if (handleOriginal) {
                    handleOriginal();
                }
            }
        }
    }

    render() {
        return (
            <div className="layout vertical full-height">
                <div className="flex container-overflow-y">
                    <ReactTable
                        minRows={0}
                        data={this.getAwardData(this.props.counties)}
                        defaultPageSize={100}
                        NoDataComponent={() => null}
                        showPagination={false}
                        onSortedChange={this.onSortChange}
                        getTdProps={this.onRowClick}
                        columns={this.tableHeaders}
                    />
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreGrantOverview);