import React from 'react';
import { connect } from 'react-redux'
import ReactTable from "react-table";
import IconButton from "@material-ui/core/IconButton";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import NumberFormat from 'react-number-format';
import { MdMenu, MdMoreVert } from 'react-icons/md';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';

// Local imports
import { getFileUploadedLinkFromBucket } from '../../common/FileUploadApi';
import { checkIfQuarterIsStarted } from '../../utilities/QuarterDates';
import { generateYTDSpendingReportData } from './FinancialCommonApi';
import { getFormattedNumberForPrint } from '../../utilities/NumberFormat';

// Component imports
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import StatusComponent from "../../styled-components/Statuses/StausComponent";

// Redux

import { 
        getFinancialQuarterlyData, 
        getCountyAction, 
        selectQuarterFinancialReimbursement, setFinancialReimbursementStateCountiesTableSort,
        printTable,
    } from "../../../redux/actions/index";


// CSS imports
import "react-table/react-table.css";
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        selectedQuarterFinancial: state.rootReducer.selectedQuarterFinancial,
        counties: state.rootReducer.counties,
        financialReimbursementStateCountiesTableSort: state.rootReducer.financialReimbursementStateCountiesTableSort,
        selectedYear: state.rootReducer.selectedYear
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFinancialQuarterlyData: (payload, callback) => dispatch(getFinancialQuarterlyData(payload, callback)),
        getCountyAction: (payload, callback) => dispatch(getCountyAction(payload, callback)),
        selectQuarterFinancialReimbursement: (payload) => dispatch(selectQuarterFinancialReimbursement(payload)),
        setFinancialReimbursementStateCountiesTableSort: (payload) => dispatch(setFinancialReimbursementStateCountiesTableSort(payload)),
        printTable: (type, payload, callback) => dispatch(printTable(type, payload, callback)),
    }
};

class FinancialStatePage extends React.Component {

    state = {
        counties: this.props.counties || [],
        selectedQuarter: this.props.selectedQuarterFinancial || 0,
        anchorEl: null,
        financialReimbursementStateCountiesTableSort: this.props.financialReimbursementStateCountiesTableSort,
    }

    constructor(props, context) {
        super(props, context);
        this.selectedCountyCallback = this.selectedCountyCallback.bind(this);
        this.checkIfQuarterIsStarted = checkIfQuarterIsStarted.bind(this);
    }

    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    selectTab = (index) => {
        this.state.selectedQuarter = index;
        this.setState({
            "selectedQuarter": this.state.selectedQuarter
        });
        this.props.selectQuarterFinancialReimbursement(this.state.selectedQuarter)

    }

    printTable = () => {
        var data = this.getQuarterData(this.state.counties, this.state.selectedQuarter)
        var payload = {
            items: [],
            columns: [
                {
                    title: "County",
                    path: "groupName",
                    checked: true,
                },
                {
                    title: "Status",
                    path: "updatedStatus",
                    checked: true,
                },
                {
                    title: "Total Amount Expended",
                    path: "totalAmount",
                    checked: true,
                },
                {
                    title: "Amount Reimbursed",
                    path: "checkAmount",
                    checked: true,
                },
                {
                    title: "Last Updated At",
                    path: "updatedAtDisplay",
                    checked: true,
                },
                {
                    title: "Quarter",
                    path: "quarter",
                    checked: true,
                },
            ],
            timezone: "America/New_York"
        }
        data.forEach( (item) => {
            payload.items.push({
                groupName: " " + item.groupName,
                updatedStatus: " " + item.updatedStatus,
                totalAmount: " $" + getFormattedNumberForPrint(item.totalAmount),
                updatedAtDisplay: " " + item.updatedAtDisplay,
                quarter: " Quarter " + (parseInt(this.state.selectedQuarter) + 1),
                reimbursed: item.reimbursed ? ` $${item.reimbursed}` : " "
            })
        });
        this.props.printTable("financialreimbursement", payload);
        this.handleClose();
    }

    printYTDReport = () => {
        let data = [];
        let payload = {
            items: [],
            columns: [
                {
                    title: "County",
                    path: "groupName",
                    checked: true,
                },
                {
                    title: "Total Grant Award",
                    path: "total",
                    checked: true,
                },
                {
                    title: "Personnel",
                    path: "personnel",
                    checked: true,
                },
                {
                    title: "Contractual Services",
                    path: "contractualServices",
                    checked: true,
                },
                {
                    title: "Travel",
                    path: "travel",
                    checked: true,
                },
                {
                    title: "Equipment",
                    path: "equipment",
                    checked: true,
                },
                {
                    title: "Supplies",
                    path: "supply",
                    checked: true,
                },
                {
                    title: "CERT",
                    path: "cert",
                    checked: true,
                },
                {
                    title: "Other",
                    path: "other",
                    checked: true,
                },
                {
                    title: "Available County Overmatch",
                    path: "overmatch",
                    checked: true,
                },
                {
                    title: "Unexpended",
                    path: "unexpended",
                    checked: true,
                }
            ],
            timezone: "America/New_York"
        }
        this.state.counties.forEach((county) => {
            data.push(generateYTDSpendingReportData(county));
        }); 
        payload.items = data;
        this.props.printTable("financialreimbursement-YTD_spending_report-state", payload);
        this.handleClose();
    }

    _convertToTitleCase = (str) => {
        str = str.toLowerCase();
        return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    }

    calculateAmount = (type, currentQuarter) => {
        let totalAmount = 0;
        currentQuarter[type] &&
            currentQuarter[type].items &&
            currentQuarter[type].items.forEach((item) => {
                if (!item.deleted) {
                    item.type = type;
                    if (item.quarterAmount && !isNaN(parseFloat(item.quarterAmount))) {
                        totalAmount += parseFloat(item.quarterAmount);
                    }
                    if (item.matchAmount && !isNaN(parseFloat(item.matchAmount))) {
                        totalAmount += parseFloat(item.matchAmount);
                    }
                    // if (item.taxAmount && !isNaN(parseFloat(item.taxAmount))) {
                    //     totalAmount += parseFloat(item.taxAmount);
                    // }
                }
            })

            return totalAmount;
    }

    getQuarterAmount = (currentQuarter) => {
        let totalAmount = 0;

        let types = [
            "personnel",
            "equipment",
            "contractualService",
            "supply",
            "travel",
            "cert",
            "other"
        ]

        types.forEach((type) => {
            totalAmount += this.calculateAmount(type, currentQuarter)
        })

        return totalAmount;
    }

    checkForRevision = (application) => {
        // issue when the first revision is still in progress, we still wanna allow counties to work with their progress reporting items
        if(application.currentApplicationRevisionId || (application && application.grantApplicationRevision && application.grantApplicationRevision.items && application.grantApplicationRevision.items.length)) {
            return true;
        }
        return false;
    }

    getQuarterData = (counties, selectedQuarter) => {
        let quarter = "quarter" + (selectedQuarter + 1);
        let GAcategories = ["salary","contractualServices","travel","equipment","supply","cert","other"];

        var arr = [];

        counties.forEach((county) => {

            let reimbursed = 0;
            let isCountyParticipating = true;

            // Calculate Amount Awarded from Grant Application
            if (county.grantapplication && county.grantapplication.items && county.financialreimbursement && county.financialreimbursement.items) {
                county.grantapplication.items.forEach((grantApp) => {

                    // A county is not participating if the parent grant application is in DRAFT status. If the revision exists then the county is def participating
                    if(grantApp.status == 'DRAFT' && !this.checkForRevision(grantApp)) isCountyParticipating = false;

                    GAcategories.forEach((gaType) => {
                        if (grantApp[gaType] && grantApp[gaType].items) {
                            grantApp[gaType].items.forEach((item) => {
                                if (!item.deleted && parseFloat(item.federal) && !isNaN(parseFloat(item.federal))) {
                                    // Only doing Federal category for now
                                    reimbursed += parseFloat(item.federal);
                                }
                            });
                        }
                    });
                });
            }

            if (isCountyParticipating && county.financialreimbursement && county.financialreimbursement.items) {
                county.financialreimbursement.items.forEach((item) => {
                    if(item.quarter === quarter) {
                        item.updatedStatus = item.status;
                        if (item.status == "NOT_AVAILABLE") item.updatedStatus = "Not Started";
                        if (item.status == "NOT_STARTED") item.updatedStatus = "Not Started";
                        if (item.status == "CHANGES_REQUESTED") item.updatedStatus = "Changes Requested";

                        item.updatedStatus = this._convertToTitleCase(item.updatedStatus);
                        item.totalAmount = Number.parseFloat(this.getQuarterAmount(item)).toFixed(2);
                        if ((item.status == "NOT_STARTED") || (item.status == "NOT_AVAILABLE") ) {
                            item.updatedAtDisplay = ""
                        } else {
                            item.updatedAtDisplay = moment(item.updatedAt).format("MM/DD/YYYY HH:mm:ss")
                        }
                        item.reimbursed = reimbursed || "";
                        if (item.currentExtension) {
                            item.extensionDate = moment(item.currentExtension.dateAdjustedTo || item.currentExtension.dueDate).format("MM/DD/YYYY");
                        } else {
                            item.extensionDate = "";
                        }
                        arr.push(
                            {
                                ...{ 
                                    groupName: this._convertToTitleCase(county.groupName),
                                    palmettoId: county.palmettoId,
                                 }, 
                                ...item 
                            }
                        )
                    }
                    
                })
            } else {
                // probably county not participating
                arr.push(
                    {
                        ...{
                            groupName: this._convertToTitleCase(county.groupName),
                            palmettoId: county.palmettoId,
                        }, 
                        updatedStatus: 'Not Participating'
                    }
                )

            }
            
        });

        return arr;
    }

    // Only enable tabs if the Quarter has been started, and there is at least one entry from the selected quarter in the financial reimbursement items from a county
    // disableTabIfQuarterIsNotStarted = (counties, selectedQuarter) => {
    //     let quarter = "quarter" + (selectedQuarter + 1);

    //     let disabled = true;

    //     counties.forEach((county) => {
    //         if (county.financialreimbursement && county.financialreimbursement.items) {
    //             console.log("County Items:", county.financialreimbursement.items);
    //             county.financialreimbursement.items.forEach((item) => {
    //                 if ((item.quarter === quarter) && this.checkIfQuarterIsStarted(selectedQuarter, this.props.selectedYear)) {
    //                     disabled = false;
    //                 }

    //             })
    //         }

    //     });

    //     return disabled;
    // }

    onRowClick = (state, rowInfo, column, instance) => {
        return {
            onClick: (e, handleOriginal) => {

                if (rowInfo.original.updatedStatus === 'Not Participating') return;

                this.props.history.push("/financialreimbursement/state/county/quarter")
                this.props.getCountyAction({ selectedGroupID: parseInt(rowInfo.original.palmettoId) }, () => {

                    this.props.getFinancialQuarterlyData(rowInfo.original, (data) => {})
                });

                if (handleOriginal) {
                    handleOriginal();
                }
            }
        };
    };

    _convertToTitleCase = (str) => {
        str = str.toLowerCase();
        return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    }

    selectedCountyCallback(...args) {}


    handleMenuClick = (event) => {
        window.dispatchEvent(new CustomEvent("toggle-sidebar"))
    }

    downloadFinancialReimbursementUserGuide = async () => {
        var a = await getFileUploadedLinkFromBucket({ key: "LEMPG-FinancialReimbursement.pdf" }, "palmetto-lempg-global-documents");
        window.open(a);
    }

    onSortChange = (column) => {
        this.props.setFinancialReimbursementStateCountiesTableSort(column)
    }

    _getColorForCountyName = (row) => {
        return (row.original && row.original.updatedStatus) === 'Not Participating' ? "#a1a1a1" : 'black'
    }


    render() {

        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);


        return (

            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.handleMenuClick}
                        >
                            <MdMenu className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Financial Reimbursement</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>{window.appHeaderTitle} </span>}

                    suffix={
                        <div>
                            <IconButton
                                aria-label="More"
                                aria-haspopup="true"
                                aria-owns={open ? 'application-menu' : undefined}
                                onClick={this.handleIconDropdownClick}
                            >
                                <MdMoreVert className="icon" />
                            </IconButton>

                            <Menu
                                id="application-menu"
                                // anchorEl={anchorEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                                open={open}
                                onClose={this.handleClose}
                            >
                                <MenuItem key="printTable" onClick={() => this.printTable()}>
                                    Print Table
                                </MenuItem>
                                <MenuItem key="printYTDReport" onClick={() => this.printYTDReport()}>
                                    Print YTD Spending Report
                                </MenuItem>
                                <MenuItem key="progressReportingHelp" onClick={() => this.downloadFinancialReimbursementUserGuide()}>
                                    Help
                                </MenuItem>
                            </Menu>
                        </div>

                    }   
                />


                <Tabs className="layout horizontal center"
                    value={this.state.selectedQuarter}
                    indicatorColor="primary"
                    textColor="primary"
                    classes={{
                        root: "tabs-root",
                        flexContainer: "flex",
                        indicator: "tab-indicator-color",
                    }}
                    // onChange={handleChange}
                    aria-label="disabled tabs example"
                >
                    <Tab 
                        className="flex" 
                        classes={{
                            root: "tab-root",
                            selected: "tab-selected",
                        }}
                        onClick={() => this.selectTab(0)}
                        label="Q1" 
                    />

                    <Tab
                        className="flex"
                        classes={{
                            root: "tab-root",
                            selected: "tab-selected",
                        }}
                        onClick={() => this.selectTab(1)}
                        label="Q2"
                    />
                    <Tab
                        className="flex"
                        classes={{
                            root: "tab-root",
                            selected: "tab-selected",
                        }}
                        onClick={() => this.selectTab(2)}
                        label="Q3"
                    />
                    <Tab
                        className="flex"
                        classes={{
                            root: "tab-root",
                            selected: "tab-selected",
                        }}
                        onClick={() => this.selectTab(3)}
                        label="Q4"
                    />

                </Tabs>


                <div className="flex container-overflow-y">
                    <ReactTable
                        minRows={0}
                        data={this.getQuarterData(this.state.counties, this.state.selectedQuarter)}
                        defaultPageSize={100}
                        NoDataComponent={() => null} 
                        showPagination={false}
                        onSortedChange={this.onSortChange}
                        defaultSorted={this.state.financialReimbursementStateCountiesTableSort} 
                        getTdProps={this.onRowClick}
                        columns={[

                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">County</div>
                                ),
                                className: "body-cell",
                                accessor: "groupName",
                                Cell: row => (
                                    <span style={{ color: this._getColorForCountyName(row)}}>{row.value}</span>
                                )                                
                            },
                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">Status</div>
                                ),
                                className: "body-cell",
                                accessor: "updatedStatus",
                                Cell: row => (
                                    <StatusComponent text={this._convertToTitleCase(row.value)} />
                                )                                

                            },
                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">Total amount expended</div>
                                ),
                                className: "body-cell",
                                accessor: "totalAmount",
                                Cell: row => (
                                    <NumberFormat value={row.value} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                )                                
                            },
                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">Amount Reimbursed</div>
                                ),
                                className: "body-cell",
                                accessor: "checkAmount",
                                Cell: row => (
                                    <NumberFormat value={row.value} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                )                                
                            },
                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">Last Updated At</div>
                                ),
                                className: "body-cell",
                                accessor: "updatedAtDisplay"
                            },
                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">Extension</div>
                                ),
                                className: "body-cell",
                                accessor: "extensionDate"
                            },
                        ]}
                    />
                </div>

            </div>
            
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialStatePage);
