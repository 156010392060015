import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';

export const NormalCheckbox = styled(Checkbox)`
    && {

        min-width: 25px;
        margin-left: 0;
        margin-right: 0};


        // we dont want to highlight our labels
        & > label {
            line-height: 1.33;
            color: var(--default-label-color);
        }

        // This styling will override default hover color of material ui textfield
        & > div:hover:before {
            border-bottom: 1px solid var(--default-label-color) !important;
        }
    }
`;
