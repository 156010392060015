import React from 'react';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";

// Local imports

import { checkIfQuarterHasStartedOrPassed } from "../utilities/QuarterDates";

// Component imports

import DrawerComponent from "../layout/DrawerComponent";
import GrantApplicationPackage from "./GrantApplicationPackage";
import StatePreFiscalYearScreen from "./StatePreFiscalYearScreen";
import SplashScreen from "../pages/SplashScreen";
import CountySplashScreen from "../pages/CountySplashScreen";
import StateSplashScreen from "../pages/StateSplashScreen";
import FinancialHome from "./financial_reimbursements/FinancialHome";
import ProgressHome from "./progress_reporting/ProgressHome";
import FileLibrary from "./file_library/FileLibrary";
import FileLibraryState from "./file_library/FileLibraryState";
import ExtensionsHome from  "./extensions/ExtensionsHome";
import PreGrantHome from "./pre_grant_awards/PreGrantHome";
import PreFiscalYearHome from "./pre_fiscal_year/PreFiscalYearHome";
// import NarrativesHome from "./extensions/NarrativesHome";
import NarrativesHome from "./narratives/NarrativesHome";
import MonitoringHome from './monitoring/MonitoringHome';


// CSS imports
import "../../styles/common.css";
import "../../styles/iron-flex-layout.css";
import "../../styles/iron-flex-layout-classes.css";

class Home extends React.Component {

    state = {
    }

    constructor(props, context) {
        super(props, context);
    }

    checkIfFirstQuarterHasStartedForCurrentFiscalYear = () => {
        if (this.props.grantApplication && this.props.grantApplication.currentExtension && this.props.grantApplication.currentExtension.status === "APPROVED") {
            let now = new Date();
            let extension = new Date(this.props.grantApplication.currentExtension.dueDate);
            if (now <= extension) {
                return false;
            }
        }
        return checkIfQuarterHasStartedOrPassed(0, this.props.selectedYear)
    }

    componentDidUpdate() { }

    render() {
        return (

            <div id='appHome' className="layout vertical app-home full-height">

                <HashRouter>
                    <DrawerComponent />
                    <Switch>

                        <Route
                            exact 
                            path="/" 
                            component={() =>
                                this.props.lempgUserType == "LEMPG_ACCESS" ? 
                                    this.checkIfFirstQuarterHasStartedForCurrentFiscalYear() ? <CountySplashScreen /> : <SplashScreen /> 
                                 : 
                                    this.props.currentFiscalYear.availableToCounties ? this.checkIfFirstQuarterHasStartedForCurrentFiscalYear() ? <StateSplashScreen /> : <SplashScreen />  : <StatePreFiscalYearScreen />
                            }
                        />
                        <Route 
                            path="/narratives" 
                            component={(props) => <NarrativesHome {...props} /> }
                        />
                        
                        <Route
                            path="/application"
                            component={(props) => <GrantApplicationPackage {...props} />}
                        />

                        <Route
                            path="/filelibrary/county"
                            component={(props) => <FileLibrary {...props} />}
                        />
                        <Route
                            path="/filelibrary/state"
                            component={(props) => <FileLibraryState {...props} />}
                        /> 

                        <Route
                            path="/financialreimbursement"
                            component={(props) => <FinancialHome {...props} />}
                        />
                        <Route
                            path="/progressreport"
                            component={(props) => <ProgressHome {...props} />}
                        />
                        
                        <Route
                            path="/extensions"
                            component={(props) => <ExtensionsHome {...props} />}
                        />
                       
                        <Route
                            path="/pregrantawards/"
                            component={(props) => <PreGrantHome {...props} />}
                        />
                        <Route
                            path="/prefiscalyear/"
                            component={(props) => <PreFiscalYearHome {...props} />}
                        />
                        <Route
                            path="/monitoring/"
                            component={(props) => <MonitoringHome {...props} />}
                        />                        
                        
                    </Switch>
                </HashRouter>


            </div>

        )
    }
}

export default Home;
