import React from 'react';
import { connect } from 'react-redux'
import { DualTitleHeaderComponent } from "../layout/HeaderComponent.js"
import IconButton from "@material-ui/core/IconButton";
import { MdArrowBack, MdCheckCircle, MdMoreVert } from 'react-icons/md';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import FileUpload from "../common/FileUpload";
import { getFileUploadedLink } from '../common/FileUploadApi';
import { PrimaryButton, SecondaryButton } from "../styled-components/Buttons/Buttons";
import { HeaderButton } from "../styled-components/Buttons/Buttons";
import ErrorBox from "../common/ErrorBox";
import { saveFileAppSync, printGrantApplicationForm } from "../../redux/actions/index";


const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        grantApplication: state.rootReducer.grantApplication,
        currentApplicationState: state.rootReducer.currentApplicationState,
        selectedYear: state.rootReducer.selectedYear
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveFileAppSync: (payload, fieldName, callback) => dispatch(saveFileAppSync(payload, fieldName, callback)),
        printGrantApplicationForm: (payload) => dispatch(printGrantApplicationForm(payload)),        
    };
};

class CountyApplicationApproved extends React.Component {

    state = {
        coverLetterFileLink: "",
        awardLetterFileLink: "",
        specialInstructionsFileLink: "",
        file_id: this.props.currentApplicationState && this.props.currentApplicationState.awardDocumentsUploadedByCounty && this.props.currentApplicationState.awardDocumentsUploadedByCounty.id || "",
        errors: [],
        anchorEl: null,

    }

    constructor(props, context) {
        super(props, context);
        this.goBack = this.goBack.bind(this);
        this.saveFile = this.saveFile.bind(this);
        this.fileToSave = this.fileToSave.bind(this);
        this.fileToDelete = this.fileToDelete.bind(this);
        this.saveFileCallback = this.saveFileCallback.bind(this);
        this.printGrantApplicationForm = this.printGrantApplicationForm.bind(this);

    }


    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };


    printGrantApplicationForm() {
        this.handleClose();
        this.props.printGrantApplicationForm(this.props.currentApplicationState)
    }

    goBack() {
        this.props.history.goBack();
    }

    saveFile() {

        this.state.errors = [];
        this.setState({
            errors: this.state.errors
        });

        if (!this.state.file_id) {
            this.state.errors.push({
                text: "Please attach award documents"
            })
        }

        if (this.state.errors.length > 0) {
            this.setState({
                errors: this.state.errors
            });
            return;
        }

        this.props.saveFileAppSync(this.state, "grantApplicationAwardDocumentsUploadedByCountyId", this.saveFileCallback)

    }


    fileToSave(data) {
        this.setState({ file_id: data.data.createFile.id });
    }

    fileToDelete() {
        this.setState({ file_id: null });
    }

    saveFileCallback(data) {
        this.props.history.push("/application")
    }

    async componentDidMount() {
        let coverLetterFileLink = await getFileUploadedLink(this.props.grantApplication && this.props.grantApplication.awardCoverLetterUploadedByState);

        let awardLetterFileLink = await getFileUploadedLink(this.props.grantApplication && this.props.grantApplication.awardDocumentUploadedByState);

        let specialInstructionsFileLink = await getFileUploadedLink(this.props.grantApplication && this.props.grantApplication.awardSpecialInstructionsUploadedByState);

        this.setState({
            coverLetterFileLink: coverLetterFileLink
        })

        this.setState({
            awardLetterFileLink: awardLetterFileLink
        })

        specialInstructionsFileLink && this.setState({
            specialInstructionsFileLink: specialInstructionsFileLink
        })
    }

    downloadForms = () => {
        this.state.coverLetterFileLink && window.open(this.state.coverLetterFileLink);
        this.state.awardLetterFileLink && window.open(this.state.awardLetterFileLink);
        this.state.specialInstructionsFileLink && window.open(this.state.specialInstructionsFileLink);
    }

    render() {

        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return (
            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdArrowBack className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Application</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>{window.appHeaderTitle} • {this.props.grantApplication.group && this.props.grantApplication.group.groupName}</span>}

                    suffix={
                        <div>
                            <IconButton
                                aria-label="More"
                                aria-haspopup="true"
                                aria-owns={open ? 'application-menu' : undefined}
                                onClick={this.handleIconDropdownClick}
                            >
                                <MdMoreVert className="icon" />
                            </IconButton>

                            <Menu
                                id="application-menu"
                                // anchorEl={anchorEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                                open={open}
                                onClose={this.handleClose}
                            >
                                <MenuItem key="printApplicationForm" onClick={() => this.printGrantApplicationForm()}>
                                    Print Grant Application Form
                                    </MenuItem>
                            </Menu>
                        </div>

                    }    


                    />

                {
                    this.state.errors.length ?
                        <ErrorBox errors={this.state.errors} />
                        : ""
                }

                <div className="flex layout vertical center vertical-center splash-screen">

                    <MdCheckCircle className="icon-2 application-status-icon color-green" />
                    <div className="height-20"></div>
                    <span className="layout horizontal center center-justified application-status-title-small">Approved</span>
                    <div className="height-20"></div>

                    <span style={{ padding: "0 16px", maxWidth: "280px" }} className="text-center title-big opacity-54">You’ve been approved.</span>

                    <div className="height-20"></div>
                    <span> Waiting on state to upload award letters. </span>
                    {/* {
                        this.props.grantApplication && this.props.grantApplication.awardCoverLetterUploadedByState ?
                            <SecondaryButton onClick={this.downloadForms} setwidth="true">
                                Download forms
                        </SecondaryButton>
                            : <span> Waiting on state to upload award letters. </span>

                    }

                    <div className="height-20"></div>
                    {
                        this.props.grantApplication && this.props.grantApplication.awardCoverLetterUploadedByState ?
                            <FileUpload uploadedFile={this.props.currentApplicationState && this.props.currentApplicationState.awardDocumentsUploadedByCounty ? this.props.currentApplicationState.awardDocumentsUploadedByCounty : null} fileToSave={this.fileToSave} fileToDelete={this.fileToDelete} primarybutton="true" className="layout horizontal center-justified" singleupload="true" nocontainer="true" notitle="true" />

                            : <span>  </span>

                    } */}


                </div>

            </div>
        );
    }
}

export default connect(null, mapDispatchToProps)(CountyApplicationApproved);
