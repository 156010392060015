import React from 'react';
import { connect } from 'react-redux'
import ReactTable from "react-table";
import IconButton from "@material-ui/core/IconButton";
import { MdMenu, MdMoreVert } from 'react-icons/md';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import moment from 'moment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Local imports
import { getFileUploadedLinkFromBucket } from '../../common/FileUploadApi';
import { checkIfQuarterIsStarted } from '../../utilities/QuarterDates';

// Component imports
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import StatusComponent from "../../styled-components/Statuses/StausComponent";

// Redux

import {
    getCountyGroupNarrativeDataM2M,
    printSOWTracker,
    selectQuarterProgressReporting,
    setProgressReportingStateCountiesTableSort,
    printTable,
} from "../../../redux/actions/index";


// CSS imports
import "react-table/react-table.css";
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        counties: state.rootReducer.counties,
        selectedQuarterProgress: state.rootReducer.selectedQuarterProgress,
        progressReportingStateCountiesTableSort: state.rootReducer.progressReportingStateCountiesTableSort,
        selectedYear: state.rootReducer.selectedYear
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCountyGroupNarrativeDataM2M: (payload, callback) => (dispatch(getCountyGroupNarrativeDataM2M(payload, callback))),
        printSOWTracker: (payload, callback) => (dispatch(printSOWTracker(payload, callback))),
        selectQuarterProgressReporting: (payload) => (dispatch(selectQuarterProgressReporting(payload))),
        setProgressReportingStateCountiesTableSort: (payload) => (dispatch(setProgressReportingStateCountiesTableSort(payload))),
        printTable: (type, payload, callback) => dispatch(printTable(type, payload, callback)),

    }
};

class ProgressReportStatePage extends React.Component {

    state = {
        counties: this.props.counties || [],
        selectedQuarter: this.props.selectedQuarterProgress || 0,
        anchorEl: null,
        progressReportingStateCountiesTableSort: this.props.progressReportingStateCountiesTableSort
    }

    constructor(props, context) {
        super(props, context);
        this.selectedCountyCallback = this.selectedCountyCallback.bind(this);
        this.checkIfQuarterIsStarted = checkIfQuarterIsStarted;
    }

    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    selectTab = (index) => {
        this.state.selectedQuarter = index;
        this.setState({
            "selectedQuarter": this.state.selectedQuarter
        });
        this.props.selectQuarterProgressReporting(this.state.selectedQuarter)
    }

    printTable = () => {
        var data = this.getQuarterData(this.state.counties, this.state.selectedQuarter)
        var payload = {
            items: [],
            columns: [
                {
                    title: "County",
                    path: "updatedGroupName",
                    checked: true,
                },
                {
                    title: "Completion",
                    path: "completion",
                    checked: true,
                },
                {
                    title: "Region",
                    path: "region",
                    checked: true,
                },
                {
                    title: "Tasking Slip Status",
                    path: "updatedProgressReportingTaskingSlipStatus",
                    checked: true,
                },
                {
                    title: "Quarter",
                    path: "quarter",
                    checked: true,
                },
            ],
            timezone: "America/New_York"
        }
        data.forEach((item) => {
            payload.items.push({
                updatedGroupName: " " + item.updatedGroupName,
                updatedProgressReportingTaskingSlipStatus: " " + item.updatedProgressReportingTaskingSlipStatus,
                completion: " " + item.completion + "%",
                region: " " + item.region,
                quarter: " Quarter " + (parseInt(this.state.selectedQuarter) + 1),
            })
        })
        this.props.printTable("progressreporting", payload);
        this.handleClose()
    }    

    _convertToTitleCase = (str) => {
        str = str.toLowerCase();
        return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    }

    getGroupNarrativesCompletion = (narratives, finItems) => {

        if(narratives.items && narratives.items.length) {

            let narrativesForSelectedQuarter = 0;

            narratives.items.forEach((item) => {
                if ((parseInt(item.quarter.split("").reverse().join("")) == (this.state.selectedQuarter + 1))) {
                    narrativesForSelectedQuarter++;
                }
            })


            let approvedNarratives = 0;
            narratives.items.forEach( (item) => {
                if ((parseInt(item.quarter.split("").reverse().join("")) == (this.state.selectedQuarter + 1))) {

                    if(item.narrative && (item.narrative.useFinancialReimbursementStatus) && finItems && finItems.items && finItems.items.length) {
                        finItems.items.forEach((finItem) => {
                            if((parseInt(finItem.quarter.split("").reverse().join("")) == (this.state.selectedQuarter + 1))) {
                                item.status = finItem.status;
                                if (item.status == "AWARDED") item.status = "APPROVED";
                                else if (item.status == "PROCESSED") item.status = "APPROVED";
                                else if (item.status == "NOT_AVAILABLE") item.status = "DRAFT";
                                else if (item.status == "NOT_STARTED") item.status = "DRAFT";
                            }
                        })
                    }
                    
                    if(item.status && item.status === "APPROVED") {
                        approvedNarratives++;
                    }
                }
            })

            return Math.floor(((approvedNarratives / narrativesForSelectedQuarter) * 100))
        }

        return 0;
    }

    getGroupTaskingSlipStatus = (payload, quarter) => {
        var status = "Incomplete";
        // if (payload.quarterlyprogressreporting
        //     && payload.quarterlyprogressreporting.items
        //     && payload.quarterlyprogressreporting.items.length) {

        //     payload.quarterlyprogressreporting.items.forEach((item) => {
        //         if (item.quarter == quarter) {
        //             status = "Complete";
        //         }
        //     })
        // }

        if (payload.narratives
        && payload.narratives.items
        && payload.narratives.items.length) {

            let narrativesCount = 0;
            let approvedNarrativesCount = 0;

            payload.narratives.items.forEach((item) => {
                if(item.quarter === quarter) {
                    narrativesCount++;
                    if (item.status === 'APPROVED') {
                        approvedNarrativesCount++;
                    }
                }
            })

            if (narrativesCount > 0 && approvedNarrativesCount > 0 &&  (narrativesCount === approvedNarrativesCount)) {
                return 'Complete';
            }

        }


        return status;

    }

    checkForRevision = (application) => {
        // issue when the first revision is still in progress, we still wanna allow counties to work with their progress reporting items
        if(application.currentApplicationRevisionId || (application && application.grantApplicationRevision && application.grantApplicationRevision.items && application.grantApplicationRevision.items.length)) {
            return true;
        }
        return false;
    }


    getQuarterData = (counties, selectedQuarter) => {
        let quarter = "quarter" + (selectedQuarter + 1);

        var arr = [];
        counties.forEach((county) => {

            let isCountyParticipating = true;

            // Calculate Amount Awarded from Grant Application
            if (county.grantapplication && county.grantapplication.items && county.financialreimbursement && county.financialreimbursement.items) {
                county.grantapplication.items.forEach((grantApp) => {
                    // A county is not participating if the parent grant application is in DRAFT status. If the revision exists then the county is def participating
                    if(grantApp.status == 'DRAFT' && !this.checkForRevision(grantApp)) isCountyParticipating = false;                    
                });
            }

            if(isCountyParticipating) {
                arr.push(
                    {
                        ...county,
                        ...{
                            updatedGroupName: this._convertToTitleCase(county.groupName),
                            completion: this.getGroupNarrativesCompletion(county.narratives, county.financialreimbursement),
                            updatedProgressReportingTaskingSlipStatus: this.getGroupTaskingSlipStatus(county, quarter),
                        },
                    }
                )
            } else {
                arr.push(
                    {
                        ...{
                            updatedGroupName: this._convertToTitleCase(county.groupName),
                            palmettoId: county.palmettoId,
                        },
                        updatedProgressReportingTaskingSlipStatus: 'Not Participating'
                    }
                )                
            }

        });

        return arr;
    }

    onRowClick = (state, rowInfo, column, instance) => {
        return {
            onClick: (e, handleOriginal) => {

                if (rowInfo.original.updatedProgressReportingTaskingSlipStatus === 'Not Participating') return;

                this.props.history.push("/progressreport/state/county/")
                rowInfo.original.quarter = "quarter" + (this.state.selectedQuarter + 1);
                
                this.props.getCountyGroupNarrativeDataM2M(rowInfo.original);

                if (handleOriginal) {
                    handleOriginal();
                }
            }
        };
    };

    _convertToTitleCase = (str) => {
        str = str.toLowerCase();
        return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    }

    handleMenuClick = (event) => {
        window.dispatchEvent(new CustomEvent("toggle-sidebar"))
    }


    downloadProgressReportingUserGuide = async () => {
        this.handleClose();
        var a = await getFileUploadedLinkFromBucket({ key: "LEMPG-ProgressReporting.pdf" }, "palmetto-lempg-global-documents");
        window.open(a);
    }

    printSOWTracker = () => {
        this.handleClose();
        this.props.printSOWTracker()
    }

    onSortChange = (column) => {
        this.props.setProgressReportingStateCountiesTableSort(column)
    }

    selectedCountyCallback(...args) { }

    _getColorForCountyName = (row) => {
        return (row.original && row.original.updatedProgressReportingTaskingSlipStatus) === 'Not Participating' ? "#a1a1a1" : 'black'
    }


    render() {

        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);


        return (

            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.handleMenuClick}
                        >
                            <MdMenu className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Progress Reporting</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>{window.appHeaderTitle} </span>}
                    suffix={
                        <div>
                            <IconButton
                                aria-label="More"
                                aria-haspopup="true"
                                aria-owns={open ? 'application-menu' : undefined}
                                onClick={this.handleIconDropdownClick}
                            >
                                <MdMoreVert className="icon" />
                            </IconButton>

                            <Menu
                                id="application-menu"
                                // anchorEl={anchorEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                                open={open}
                                onClose={this.handleClose}
                            >
                                <MenuItem key="printTable" onClick={() => this.printTable()}>
                                    Print Table
                                </MenuItem>
                                <MenuItem key="sowTracker" onClick={() => this.printSOWTracker()}>
                                    Print SOW Tracker
                                </MenuItem>
                                <MenuItem key="progressReportingHelp" onClick={() => this.downloadProgressReportingUserGuide()}>
                                    Help
                                </MenuItem>
                            </Menu>
                        </div>

                    }                       
                />


                <Tabs className="layout horizontal center"
                    value={this.state.selectedQuarter}
                    indicatorColor="primary"
                    textColor="primary"
                    classes={{
                        root: "tabs-root",
                        flexContainer: "flex",
                        indicator: "tab-indicator-color",
                    }}
                    // onChange={handleChange}
                    aria-label="disabled tabs example"
                >
                    <Tab
                        className="flex"
                        classes={{
                            root: "tab-root",
                            selected: "tab-selected",
                        }}
                        onClick={() => this.selectTab(0)}
                        label="Q1"
                    />

                    <Tab
                        className="flex"
                        classes={{
                            root: "tab-root",
                            selected: "tab-selected",
                        }}
                        onClick={() => this.selectTab(1)}
                        label="Q2"
                    />
                    <Tab
                        className="flex"
                        classes={{
                            root: "tab-root",
                            selected: "tab-selected",
                        }}
                        onClick={() => this.selectTab(2)}
                        label="Q3"
                    />
                    <Tab
                        className="flex"
                        classes={{
                            root: "tab-root",
                            selected: "tab-selected",
                        }}
                        onClick={() => this.selectTab(3)}
                        label="Q4"
                    />

                </Tabs>


                <div className="flex container-overflow-y">
                    <ReactTable
                        minRows={0}
                        data={this.getQuarterData(this.state.counties, this.state.selectedQuarter)}
                        defaultPageSize={100}
                        NoDataComponent={() => null}
                        showPagination={false}
                        onSortedChange={this.onSortChange}
                        defaultSorted={this.state.progressReportingStateCountiesTableSort}
                        getTdProps={this.onRowClick}
                        columns={[

                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">County</div>
                                ),
                                className: "body-cell",
                                accessor: "updatedGroupName",
                                Cell: row => (
                                    <span style={{ color: this._getColorForCountyName(row) }}>{row.value}</span>
                                )
                            },
                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">Completion</div>
                                ),
                                className: "body-cell",
                                accessor: "completion",
                                Cell: row => (
                                    <span>{row.value}{typeof row.value !== 'undefined' ? '%' : ''}</span>
                                )
                            },
                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">Region</div>
                                ),
                                className: "body-cell",
                                accessor: "region",
                            },
                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">Tasking slip</div>
                                ),
                                className: "body-cell",
                                accessor: "updatedProgressReportingTaskingSlipStatus",
                                Cell: row => (
                                    <StatusComponent text={this._convertToTitleCase(row.value)} />
                                )                                

                            },
                        ]}
                    />
                </div>

               
            </div>

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressReportStatePage);
