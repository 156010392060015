import React from 'react';
import { Link, Prompt } from "react-router-dom";
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import { NumberFormatCustom, getValue, isNumber, parseFloatWithPrecision } from "../utilities/NumberFormat";
import ErrorBox from "../common/ErrorBox";


import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import IconButton from "@material-ui/core/IconButton";
import { MdArrowBack, MdClear, MdMoreVert } from 'react-icons/md';
import { DualTitleHeaderComponent } from "../layout/HeaderComponent.js"
import { PrimaryButton, SecondaryButton, HeaderButton, DeleteButton } from "../styled-components/Buttons/Buttons";
import { CardComponentSummaryCards, CardComponent2R1C } from "../layout/CardComponent";
import { NormalTextField } from "../styled-components/TextFields/TextFields";
import { NormalCheckbox } from "../styled-components/Checkboxes/Checkboxes";
import { checkIfAmountHasBeenReimbursed } from './GrantApplicationApi';

// Redux imports

import { setCurrentApplicationState, saveApplication, submitApplication, requestChangesInApplication, awardApplicationAndLinkRevision, printGrantApplicationForm } from "../../redux/actions/index";
import { saveSalary } from "../../redux/actions/SalaryAction.js";
import { saveContractualService } from "../../redux/actions/ContractualServiceAction.js";
import { saveTravel } from "../../redux/actions/TravelAction.js";
import { saveSupply } from "../../redux/actions/SupplyAction.js";
import { saveEquipment } from "../../redux/actions/EquipmentAction.js";
import { saveCert } from "../../redux/actions/CertAction.js";
import { saveOther } from "../../redux/actions/OtherAction.js";

const NavigationBlocker = (props) => {
    window.onbeforeunload = () => true
    return (
      <Prompt
        message ={location => props.saved === true ||
                        props.access !== true ||
                        location.pathname.indexOf("salary") !== -1 ||
                        location.pathname.indexOf("contractualservice") !== -1 ||
                        location.pathname.indexOf("equipment") !== -1 ||
                        location.pathname.indexOf("travel") !== -1 ||
                        location.pathname.indexOf("supply") !== -1 ||
                        location.pathname.indexOf("other") !== -1 ||
                        location.pathname.indexOf("cert") !== -1 ? true : 
                            `You have unsaved changes. Are you sure you want to discard changes?`} 
        />
    )
};
  
const mapStateToProps = state => {
    
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        token: state.rootReducer.token,
        grantApplication: state.rootReducer.grantApplication,
        currentApplicationState: state.rootReducer.currentApplicationState,
        selectedYear: state.rootReducer.selectedYear,
        grantApplicationShowCertCheckbox: state.rootReducer.grantApplicationShowCertCheckbox,
        grantApplicationAutoDeductCertAmount: state.rootReducer.grantApplicationAutoDeductCertAmount,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentApplicationState: (state) => dispatch(setCurrentApplicationState(state)),
        saveApplication: (callback) => dispatch(saveApplication(callback)),
        saveSalary: (payload) => dispatch(saveSalary(payload)),
        saveContractualService: (payload) => dispatch(saveContractualService(payload)),
        saveTravel: (payload) => dispatch(saveTravel(payload)),
        saveSupply: (payload) => dispatch(saveSupply(payload)),
        saveEquipment: (payload) => dispatch(saveEquipment(payload)),
        saveCert: (payload) => dispatch(saveCert(payload)),
        saveOther: (payload) => dispatch(saveOther(payload)),

        submitApplication: () => dispatch(submitApplication()),
        requestChangesInApplication: () => dispatch(requestChangesInApplication()),
        awardApplicationAndLinkRevision: () => dispatch(awardApplicationAndLinkRevision()),
        printGrantApplicationForm: (payload) => dispatch(printGrantApplicationForm(payload)),

    };
};

class ApplicationForm extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.goBack = this.goBack.bind(this);
        this.saveApplication = this.saveApplication.bind(this);
        this.saveApplicationState = this.saveApplicationState.bind(this);
        this.filterItems = this.filterItems.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.saveApplicationCallback = this.saveApplicationCallback.bind(this);
    }
 
    state = {
        errors: [], 
        saved: this.props.currentApplicationState && this.props.currentApplicationState.saved || false,
        salary: this.props.currentApplicationState && this.props.currentApplicationState.salary || {},
        contractualService: this.props.currentApplicationState && this.props.currentApplicationState.contractualService || {},
        travel: this.props.currentApplicationState && this.props.currentApplicationState.travel || {},
        equipment: this.props.currentApplicationState && this.props.currentApplicationState.equipment || {},
        supply: this.props.currentApplicationState && this.props.currentApplicationState.supply || {},
        other: this.props.currentApplicationState && this.props.currentApplicationState.other || {},
        cert: this.props.currentApplicationState && this.props.currentApplicationState.cert || {},
        award: this.props.currentApplicationState && this.props.currentApplicationState.award || {},
        deductCertFromFederal: this.props.currentApplicationState && this.props.currentApplicationState.deductCertFromFederal || !this.props.grantApplicationShowCertCheckbox && this.props.grantApplicationAutoDeductCertAmount || false,
        salaryNarrative: this.props.currentApplicationState && this.props.currentApplicationState.salaryNarrative || "",
        fbFederalFunding: this.props.currentApplicationState && this.calculateFBFromSubsections(this.props.currentApplicationState, 'fbFederalFunding') || '',
        fbCountyCashFunding: this.props.currentApplicationState && this.calculateFBFromSubsections(this.props.currentApplicationState, 'fbCountyCashFunding') || '',
        fbCountyInkindFunding: this.props.currentApplicationState && this.calculateFBFromSubsections(this.props.currentApplicationState, 'fbCountyInkindFunding') || '',
        contractualServiceNarrative: this.props.currentApplicationState && this.props.currentApplicationState.contractualServiceNarrative || "",
        travelNarrative: this.props.currentApplicationState && this.props.currentApplicationState.travelNarrative || "",
        equipmentNarrative: this.props.currentApplicationState && this.props.currentApplicationState.equipmentNarrative || "",
        supplyNarrative: this.props.currentApplicationState && this.props.currentApplicationState.supplyNarrative || "",
        otherNarrative: this.props.currentApplicationState && this.props.currentApplicationState.otherNarrative || "",
        certNarrative: this.props.currentApplicationState && this.props.currentApplicationState.certNarrative || "",
        name: this.props.currentApplicationState && this.props.currentApplicationState.name || "",
        address: this.props.currentApplicationState && this.props.currentApplicationState.address || "",
        address2: this.props.currentApplicationState && this.props.currentApplicationState.address2 || "",
        city: this.props.currentApplicationState && this.props.currentApplicationState.city || "",
        state: this.props.currentApplicationState && this.props.currentApplicationState.state || "",
        county: this.props.currentApplicationState && this.props.currentApplicationState.county || "",
        zipcode: this.props.currentApplicationState && this.props.currentApplicationState.zipcode || "",
        latitude: this.props.currentApplicationState && this.props.currentApplicationState.latitude || "",
        longitude: this.props.currentApplicationState && this.props.currentApplicationState.longitude || "",
        enableReverseGeocoding: this.props.currentApplicationState && this.props.currentApplicationState.enableReverseGeocoding || "",
        implementingAgency: this.props.currentApplicationState && this.props.currentApplicationState.implementingAgency || "",
        phoneNumber: this.props.currentApplicationState && this.props.currentApplicationState.phoneNumber || "",
        faxNumber: this.props.currentApplicationState && this.props.currentApplicationState.faxNumber || "",

        anchorEl: null,
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
        this.props.currentApplicationState[name] = event.target.value;
        // this.props.setCurrentApplicationState(name, event.target.value)
    }

    handleCheckboxChange = name => event => {
        // Added space cause if value was blank, it was not saved. Also, had to do
        // both setState and currentApplicationState in order to have value be saved as blank(?).
        this.setState({ [name]: event.target.checked !== undefined ? event.target.checked : (event.target.value || ' ') });
        this.props.currentApplicationState[name] = event.target.checked !== undefined ? event.target.checked : (event.target.value || ' ');
        //this.props.setCurrentApplicationState(name, event.target.value)
    }

    printGrantApplicationForm() {
        // implement rob stuff here
        this.handleClose();
        this.props.printGrantApplicationForm(this.props.currentApplicationState)
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    
    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    saveApplication (event) {
        this.state.saved = true;
        this.saveApplicationState();
        this.props.saveApplication(this.saveApplicationCallback);
    }

    saveApplicationCallback() {
        this.goBack();
    }

    goBack() {
        this.props.history.goBack();
    }

    saveApplicationState() {
        this.props.setCurrentApplicationState(this.state);
    }

    filterItems (items, model) {
        var filteredItems = []
        items[model].items.forEach( (item) => {
            if(!item.deleted) {
                filteredItems.push(item)
            }
        })

        return filteredItems;
    }

    deleteItem (event, payload, method) {
        payload.deleted = true;
        this.props[method](payload);
        event.preventDefault();
        event.stopPropagation();
    }

    calculateFBFromSubsections(application, type) {

        let amount = 0;

        if(application && application['salary'] && application['salary'].items && application['salary'].items.length) {
            application['salary'].items.forEach(function (item) {
                if (!item.deleted) {
                    amount += isNumber(item[type]) ? Number(item[type]) : 0;
                }
            })
        }

        return amount;
    }    

    calculateAmount (items, type) {
        var total = 0;
        if (items && items.items) {
            items.items.forEach((item) => {
                if(!item.deleted) {
                    var types = (type !== undefined && type !== null && 
                        (type === 'federal' || type === 'county')) ? 
                            type === 'federal' ? [item.federal] : [item.county, item.countyInKind] :
                                [item.federal, item.county, item.countyInKind];
                    total += getValue(types)
                }
            })
        }
        return total;
    }
    
    // If fed or county entry has value > 0, there needs to be corresponding narrative.
    // 'mandatory' is true/false for the red dot stays on whenever value > 0.
    isNarrativeError (appType, mandatory) {
        var narrative = appType !== undefined && appType && appType !== '' ?
            this.props.currentApplicationState[appType + 'Narrative'] : null;
        var error = false;
        
        if (appType !== undefined && appType === 'salary') {
            error = ((isNumber(this.props.currentApplicationState.fbFederalFunding) &&
                        parseInt(this.props.currentApplicationState.fbFederalFunding) > 0) ||
                    (isNumber(this.props.currentApplicationState.fbCountyCashFunding) &&
                        parseInt(this.props.currentApplicationState.fbCountyCashFunding) > 0) || 
                    (isNumber(this.props.currentApplicationState.fbCountyInkindFunding) &&
                        parseInt(this.props.currentApplicationState.fbCountyInkindFunding) > 0)) &&
                    (!narrative || narrative.trim().length === 0 || mandatory) ? true : false;
        }
        this.props.currentApplicationState[appType].items.forEach(function(item) {
            if (!item.deleted) {
                if (((isNumber(item.countyInKind) && parseInt(item.countyInKind) > 0) ||
                    (isNumber(item.county) && parseInt(item.county) > 0) ||
                        (isNumber(item.federal) && parseInt(item.federal) > 0)) &&
                            (!narrative || narrative.trim().length === 0 || mandatory)) {
                    error = true;
                }
            }
        }.bind(this));
        return error;
    }
      
    calculateAwardStyle (type) {
        var style = 'application-subheader-cell';
        switch (type) {
            case 'amount':
            case 'amount-cert':
                break;    
            case 'remaining':
            case 'federal':
                style = this.state.award.federal > this.state.award.amount ?
                    'application-subheader-cell-error' : style;
                break;
            case 'county':
                style = this.state.award.county < this.state.award.federal ?
                    'application-subheader-cell-error' : style;
                break;
            default:
        }
        return style;
    }
    calculateAwardAmount(type) {
        if (!this.props.currentApplicationState) return
        if (!this.props.currentApplicationState.award) return
        this.props.currentApplicationState.award.county = 0;
        this.props.currentApplicationState.award.federal = 0;
        this.props.currentApplicationState.award.remaining =
            isNumber(this.props.currentApplicationState.award.amount) ?
                Number(this.props.currentApplicationState.award.amount) : 0;

        var applicationTypes = ['contractualService', 'equipment', 'other', 'salary', 'supply', 'travel'];
        if (this.props.currentApplicationState.deductCertFromFederal) {
            applicationTypes.push('cert');
        }
        applicationTypes.forEach(function (appType) {
            this.props.currentApplicationState[appType].items.forEach(function (item) {
                if (!item.deleted) {
                    this.props.currentApplicationState.award.county +=
                        (isNumber(item.countyInKind) ?
                            Number(item.countyInKind) : 0) +
                        (isNumber(item.county) ?
                            Number(item.county) : 0);
                    this.props.currentApplicationState.award.federal +=
                        isNumber(item.federal) ?
                            Number(item.federal) : 0;

                    this.props.currentApplicationState.award.remaining -=
                        isNumber(item.federal) ?
                            Number(item.federal) : 0;

                    this.props.currentApplicationState.award.remaining = parseFloatWithPrecision(this.props.currentApplicationState.award.remaining);

                    this.props.currentApplicationState.award.federal = parseFloatWithPrecision(this.props.currentApplicationState.award.federal);

                    this.props.currentApplicationState.award.county = parseFloatWithPrecision(this.props.currentApplicationState.award.county);

                }
            }.bind(this));
        }.bind(this));

        this.props.currentApplicationState.award.remaining -=
            isNumber(this.props.currentApplicationState.fbFederalFunding) ?
                Number(this.props.currentApplicationState.fbFederalFunding) : 0;
        this.props.currentApplicationState.award.federal +=
            isNumber(this.props.currentApplicationState.fbFederalFunding) ?
                Number(this.props.currentApplicationState.fbFederalFunding) : 0;
        this.props.currentApplicationState.award.county +=
            isNumber(this.props.currentApplicationState.fbCountyCashFunding) ?
                Number(this.props.currentApplicationState.fbCountyCashFunding) : 0;
        this.props.currentApplicationState.award.county +=
            isNumber(this.props.currentApplicationState.fbCountyInkindFunding) ?
                Number(this.props.currentApplicationState.fbCountyInkindFunding) : 0;


        return !type || type === undefined ? 0 :
            type === 'county' ? parseFloatWithPrecision(this.props.currentApplicationState.award.county) :
                type === 'federal' ? parseFloatWithPrecision(this.props.currentApplicationState.award.federal) :
                    type === 'remaining' ? parseFloatWithPrecision(this.props.currentApplicationState.award.remaining) :
                        type === 'amount' ? parseFloatWithPrecision(this.props.currentApplicationState.award.amount) : 0;
    }

    submitGrantApplication = () => {
        let errors = [];
        this.setState({
            errors: errors
        });

        if (this.props.currentApplicationState && this.calculateAwardAmount('federal') > this.calculateAwardAmount('amount')) {
            this.state.errors.push({
                text: "Total federal application amount cannot be greater than award."
            });
        }

        if (this.props.currentApplicationState && this.calculateAwardAmount('county') < this.calculateAwardAmount('federal')) {
            this.state.errors.push({
               text: "Total county application amount cannot be less than federal."
            });
        }

        if (this.props.currentApplicationState && this.calculateAwardAmount('remaining') > 0) {
            this.state.errors.push({
               text: "Total remaining amount cannot be greater than 0"
            });
        }        

        if(this.state.errors.length > 0) {
            this.setState({
                errors: this.state.errors
            });
            return;
        }


        if (this.props.revision) {
            // Submitting in the revision flow
            let map = {}
            // Check for blank narratives.
            var applicationTypes = ['cert', 'contractualService', 'equipment', 'other', 'salary', 'supply', 'travel'];
            applicationTypes.forEach(function (appType) {
                var narrative = appType !== undefined && appType && appType !== '' ?
                    this.props.currentApplicationState[appType + 'Narrative'] : null;
                var narrativeName =
                    appType === 'contractualService' ? 'contractual service' :
                        appType === 'salary' ? 'personal budget' : appType;
                narrativeName = "The " + narrativeName + " narrative cannot be blank."
                this.props.currentApplicationState[appType].items.forEach(function (item) {
                    if (!item.deleted && !map[narrativeName]) {
                        if (((isNumber(item.countyInKind) && parseFloat(item.countyInKind) > 0) ||
                            (isNumber(item.county) && parseFloat(item.county) > 0) ||
                            (isNumber(item.federal) && parseFloat(item.federal) > 0)) &&
                            (!narrative || narrative.trim().length === 0)) {
                            map[narrativeName] = 1;
                            errors.push({
                                text: narrativeName
                            });
                        }
                    }
                }.bind(this));
            }.bind(this));

            if (errors.length > 0) {
                this.setState({
                    errors: errors
                });

                document.querySelector('#applicationFormContainer') && (document.querySelector('#applicationFormContainer').scrollTop = 0);
                return;

            }


            this.state.saved = true;
            this.saveApplicationState();
            this.props.saveApplication(() => {
                this.props.submitApplication();
            });

        } else {
            this.props.submitApplication();
        }

    }

    requestChangesInGrantApplication = () => {
        this.props.requestChangesInApplication({}, () => {
            this.goBack()
        });
    }

    awardApplicationAndLinkRevision = () => {
        this.props.awardApplicationAndLinkRevision();
    }

    render() {

        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return (
            <div className="layout vertical full-height">

                <NavigationBlocker
                    access={!(this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS")}
                    saved={this.state.saved} />
                <DualTitleHeaderComponent
                    icon={

                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            {
                                this.props.revision ? <MdClear className="icon" /> : <MdArrowBack className="icon" />
                            }
                        </IconButton>

                    }
                    titleBig={<span>Application</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>{window.appHeaderTitle} • {this.props.grantApplication.group && this.props.grantApplication.group.groupName}</span>}
                    suffix={
                        
                        <div className="layout horizontal center form-margin-16">
                            <HeaderButton hidden={this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS"} onClick={() => this.saveApplication()}>
                                Save
                            </HeaderButton>
                            {
                                this.props.revision ? 
                                    <>
                                        {/* <IconButton
                                            aria-label="More"
                                            aria-haspopup="true"
                                            aria-owns={open ? 'application-menu' : undefined}
                                            onClick={this.handleIconDropdownClick}
                                        >
                                        </IconButton> */}
                                        <MdMoreVert className="icon" onClick={this.handleIconDropdownClick} />

                                        <Menu
                                            id="application-menu"
                                            // anchorEl={anchorEl}
                                            getContentAnchorEl={null}
                                            anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                            transformOrigin={{ vertical: "top", horizontal: "center" }}
                                            open={open}
                                            onClose={this.handleClose}
                                        >
                                            <MenuItem key="printApplicationForm" onClick={() => this.printGrantApplicationForm()}>
                                                Print Grant Application Form
                                            </MenuItem>
                                        </Menu>
                                    </>                                
                                
                                : <></>
                            }
                        </div>
                    }
                />
                <div className="layout horizontal wrap application-subheader">
                    <div className="layout horizontal flex application-subheader-cells">
                        <div className="layout horizontal flex">
                            <div className={this.calculateAwardStyle('amount')}>
                                Award: <NumberFormat fixedDecimalScale={true} decimalScale={2}  value={this.calculateAwardAmount('amount')} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                            </div>
                            <div hidden={this.props.currentApplicationState.deductCertFromFederal || this.calculateAmount(this.props.currentApplicationState.cert) === 0} className={this.calculateAwardStyle('amount-cert')}>
                                CERT: <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAmount(this.props.currentApplicationState.cert)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                            </div>
                        </div>
                        <div className={this.calculateAwardStyle('remaining')}>
                            Remain: <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAwardAmount('remaining')} displayType={'text'} thousandSeparator={true} prefix={'$'} />                            
                        </div>
                    </div>
                    <div className="layout horizontal flex application-subheader-cells">
                            <div className={this.calculateAwardStyle('federal')}>
                                Federal: <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAwardAmount('federal')} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                            </div>
                            <div className={this.calculateAwardStyle('county')}>
                                County Match: <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAwardAmount('county')} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                            </div>
                    </div>
                </div> 


                <div  id='applicationFormContainer' className="flex form-shell container-overflow-y">
                    {
                        this.state.errors.length ?
                            <div className="margin-10">
                                <ErrorBox errors={this.state.errors} />
                            </div>
                            : ""
                    }

                    {/* Salary section */}

                    <div className="layout horizontal center section-title form-margin">
                        I. (a) Personnel: Salary
                    </div>

                    <div className="layout horizontal wrap">
                        {
                            this.props.currentApplicationState.salary && this.props.currentApplicationState.salary.items && 
                            this.filterItems(this.props.currentApplicationState, "salary").map( (item) => {
                                return (

                                    <Link to={"/application/application_id/salary/" + (item.id ? item.id : "temp_" + item.tempId) }  key={item.id || item.tempId} title={checkIfAmountHasBeenReimbursed(this.props.selectedCounty, item.name, 'personnel') ? 'Item cannot be deleted because the amount for it has been reimbursed' : ''}>
                                        <CardComponent2R1C className="set-width card-shell" 
                                            title={<span>{item.name}</span>}
                                            title2={<span>{item.positionTitle}</span>}
                                            suffix={
                                                checkIfAmountHasBeenReimbursed(this.props.selectedCounty, item.name, 'personnel') ? '' : 
                                                <IconButton
                                                    aria-label="close"
                                                    hidden={this.props.lempgUserType && this.props.lempgUserType!="LEMPG_ACCESS"}
                                                    onClick={(event) => this.deleteItem(event, item, "saveSalary")}
                                                >
                                                    <MdClear className="icon-2" />
                                                </IconButton>
                                            }
                                        />
                                    </Link>
                                )
                            })
                        }

                    </div>

                    <div className="height-16"></div>

                    <div hidden={this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS"} className="layout horizontal form-margin">
                        <div className="flex"></div>
                        <SecondaryButton onClick={() => this.saveApplicationState()}>
                            <Link to="/application/application_id/salary">
                                Add Salary
                            </Link>
                        </SecondaryButton>
                    </div>

                    <div className="height-20"></div>

                    <div className="layout summary-cards form-margin">
                        <CardComponentSummaryCards
                            cards = { [
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAmount(this.props.currentApplicationState.salary, 'federal')} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>Federal</span>
                                },
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAmount(this.props.currentApplicationState.salary, 'county')} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>County Match</span>
                                },
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAmount(this.props.currentApplicationState.salary)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>Total</span>
                                }
                            ] }
                        />
                    </div>

                    <div className="height-16"></div>
                    {/* Fringe Benefits section */}

                    <div className="layout horizontal center section-title form-margin">
                        I. (b) Personnel: Fringe Benefits
                    </div>

                    <div className="layout summary-cards form-margin">
                        <CardComponentSummaryCards
                            cards={[
                                {
                                    text:
                                        <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.state.fbFederalFunding ? this.state.fbFederalFunding : 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    ,
                                    title: <span>Federal</span>
                                },
                                {
                                    text:
                                        <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.state.fbCountyCashFunding ? this.state.fbCountyCashFunding : 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    ,
                                    title: <span>County Cash</span>
                                },
                                {
                                    text:
                                        <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.state.fbCountyInkindFunding ? this.state.fbCountyInkindFunding : 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                    ,
                                    title: <span>County In-Kind</span>
                                }
                            ]}
                        />
                    </div>

                    {/* <div className="layout horizontal center wrap">
                        <NormalTextField id="fbFederalFunding" label="Federal funding" custommargin="true" placeholder="Enter federal funding" className="flex" value={this.props.currentApplicationState.fbFederalFunding ? this.props.currentApplicationState.fbFederalFunding.trim() : ''} onChange={this.handleChange('fbFederalFunding')} margin="normal" InputProps={{ inputComponent: NumberFormatCustom, readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />

                        <NormalTextField id="fbCountyCashFunding" label="County cash funding" custommargin="true" placeholder="Enter county cash funding" className="flex" value={this.props.currentApplicationState.fbCountyCashFunding ? this.props.currentApplicationState.fbCountyCashFunding.trim() : ''} onChange={this.handleChange('fbCountyCashFunding')} margin="normal" InputProps={{ inputComponent: NumberFormatCustom, readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />

                        <NormalTextField id="fbCountyInkindFunding" label="County in-kind" custommargin="true" placeholder="Enter county in-kind funding" className="flex" value={this.props.currentApplicationState.fbCountyInkindFunding ? this.props.currentApplicationState.fbCountyInkindFunding.trim() : ''} onChange={this.handleChange('fbCountyInkindFunding')} margin="normal" InputProps={{ inputComponent: NumberFormatCustom, readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />
                    </div> */}
                                     
                    <div className="height-16"></div>

                    <div className="layout horizontal center section-title form-margin">
                        I. (c) Personnel: Totals
                    </div>

                    <div className="height-16"></div>

                    <div className="layout summary-cards form-margin">
                        <CardComponentSummaryCards
                            cards = { [
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={getValue([this.calculateAmount(this.props.currentApplicationState.salary, 'federal'), this.state.fbFederalFunding])} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>Federal</span>
                                },
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={getValue([this.calculateAmount(this.props.currentApplicationState.salary, 'county'), this.state.fbCountyCashFunding, this.state.fbCountyInkindFunding])} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>County Match</span>
                                },
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={getValue([this.calculateAmount(this.props.currentApplicationState.salary), this.state.fbFederalFunding, this.state.fbCountyCashFunding, this.state.fbCountyInkindFunding])} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>Total</span>
                                }
                            ] }
                        />
                    </div>
               
                    {/* Contractual services section */}

                    <div className="height-16"></div>
                    <div className="layout horizontal">
                        <NormalTextField id="salaryNarrative" multiline label="Personnel budget narrative" custommargin="true" placeholder="Enter narrative" className="flex" error={this.isNarrativeError('salary')} mandatory={this.isNarrativeError('salary', true).toString()} value={this.props.currentApplicationState.salaryNarrative || ''}  onChange={this.handleChange('salaryNarrative')} margin="normal" InputProps={{  readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />
                    </div>

                    <div className="height-20"></div>

                    <div className="layout horizontal center section-title form-margin">
                        II. Contractural Services Itemize
                    </div>

                    <div className="layout horizontal wrap">
                        {
                            this.props.currentApplicationState.contractualService && this.props.currentApplicationState.contractualService.items &&
                            this.filterItems(this.props.currentApplicationState, "contractualService").map((item) => {
                                return (

                                    <Link to={"/application/application_id/contractualService/" + (item.id ? item.id : "temp_" + item.tempId)}  key={item.id || item.tempId} title={checkIfAmountHasBeenReimbursed(this.props.selectedCounty, item.name, 'contractualService') ? 'Item cannot be deleted because the amount for it has been reimbursed' : ''}>
                                        <CardComponent2R1C className="set-width card-shell"
                                            title={<span>{item.name}</span>}
                                            title2={
                                                <NumberFormat fixedDecimalScale={true} decimalScale={2} value={getValue([item.federal, item.county, item.countyInKind])} displayType={'text'} thousandSeparator={true} prefix={'$'} /> 
                                            }
                                            suffix={
                                                checkIfAmountHasBeenReimbursed(this.props.selectedCounty, item.name, 'contractualService') ? '' : 
                                                <IconButton
                                                    aria-label="close"
                                                    hidden={this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS"} 
                                                    onClick={(event) => this.deleteItem(event, item, "saveContractualService")}          
                                                >
                                                    <MdClear className="icon-2" />
                                                </IconButton>
                                            }
                                        />
                                    </Link>
                                )
                            })
                        }

                    </div>

                    <div className="layout horizontal form-margin">
                        <div className="flex"></div>
                        <SecondaryButton hidden={this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS"} onClick={() => this.saveApplicationState()}>
                            <Link to="/application/application_id/contractualservice">
                                Add Contractual Services
                                    </Link>
                        </SecondaryButton>
                    </div>

                    <div className="height-16"></div>

                    <div className="layout summary-cards form-margin">
                        <CardComponentSummaryCards
                            cards = { [
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAmount(this.props.currentApplicationState.contractualService, 'federal')} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>Federal</span>
                                },
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAmount(this.props.currentApplicationState.contractualService, 'county')} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>County Match</span>
                                },
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAmount(this.props.currentApplicationState.contractualService)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>Total</span>
                                }
                            ] }
                        />
                    </div>

                    <div className="layout horizontal">
                        <NormalTextField id="contractualServiceNarrative" multiline label="Contractual services narrative" custommargin="true" placeholder="Enter narrative" className="flex" error={this.isNarrativeError('contractualService')} mandatory={this.isNarrativeError('contractualService', true).toString()} value={this.props.currentApplicationState.contractualServiceNarrative || ''} onChange={this.handleChange('contractualServiceNarrative')} margin="normal" InputProps={{  readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />
                    </div>

                    {/* Travel section */}

                    <div className="height-20"></div>

                    <div className="layout horizontal center section-title form-margin">
                        III. Travel
                    </div>

                    <div className="layout horizontal wrap">
                        {
                            this.props.currentApplicationState.travel && this.props.currentApplicationState.travel.items &&
                            this.filterItems(this.props.currentApplicationState, "travel").map((item) => {
                                return (

                                    <Link to={"/application/application_id/travel/" + (item.id ? item.id : "temp_" + item.tempId)}  key={item.id || item.tempId} title={checkIfAmountHasBeenReimbursed(this.props.selectedCounty, item.name, 'travel') ? 'Item cannot be deleted because the amount for it has been reimbursed' : ''}>
                                        <CardComponent2R1C className="set-width card-shell"
                                            title={<span>{item.name}</span>}
                                            title2={
                                                <NumberFormat fixedDecimalScale={true} decimalScale={2} value={getValue([item.federal, item.county, item.countyInKind])} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                            }

                                            suffix={
                                                checkIfAmountHasBeenReimbursed(this.props.selectedCounty, item.name, 'travel') ? '' : 
                                                <IconButton
                                                    aria-label="close"
                                                    hidden={this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS"} 
                                                    onClick={(event) => this.deleteItem(event, item, "saveTravel")}         
                                                >
                                                    <MdClear className="icon-2" />
                                                </IconButton>
                                            }
                                        />
                                    </Link>
                                )
                            })
                        }
                    </div>

                    <div hidden={this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS"} className="layout horizontal form-margin">
                        <div className="flex"></div>
                        <SecondaryButton onClick={() => this.saveApplicationState()}>
                            <Link to="/application/application_id/travel">
                                Add Travel
                                </Link>
                        </SecondaryButton>
                    </div>

                    <div className="height-16"></div>

                    <div className="layout summary-cards form-margin">
                        <CardComponentSummaryCards
                            cards = { [
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAmount(this.props.currentApplicationState.travel, 'federal')} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>Federal</span>
                                },
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAmount(this.props.currentApplicationState.travel, 'county')} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>County Match</span>
                                },
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAmount(this.props.currentApplicationState.travel)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>Total</span>
                                }
                            ] }
                        />
                    </div>


                    <div className="layout horizontal">
                        <NormalTextField id="travelNarrative" label="Travel narrative" multiline custommargin="true" placeholder="Enter narrative" className="flex" error={this.isNarrativeError('travel')} mandatory={this.isNarrativeError('travel', true).toString()} value={this.props.currentApplicationState.travelNarrative || ''} onChange={this.handleChange('travelNarrative')} margin="normal" InputProps={{  readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />
                    </div>

                    {/* Equipment section */}

                    <div className="height-20"></div>

                    <div className="layout horizontal center section-title form-margin">
                        IV. Equipment
                    </div>

                    <div className="layout horizontal wrap">
                        {
                            this.props.currentApplicationState.equipment && this.props.currentApplicationState.equipment.items &&
                            this.filterItems(this.props.currentApplicationState, "equipment").map((item) => {
                                return (

                                    <Link to={"/application/application_id/equipment/" + (item.id ? item.id : "temp_" + item.tempId)}  key={item.id || item.tempId} title={checkIfAmountHasBeenReimbursed(this.props.selectedCounty, item.name, 'equipment') ? 'Item cannot be deleted because the amount for it has been reimbursed': ''}>
                                        <CardComponent2R1C className="set-width card-shell"
                                            title={<span>{item.name}</span>}
                                            title2={
                                                <NumberFormat fixedDecimalScale={true} decimalScale={2} value={getValue([item.federal, item.county, item.countyInKind])} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                            }

                                            suffix={
                                                checkIfAmountHasBeenReimbursed(this.props.selectedCounty, item.name, 'equipment') ? '' : 
                                                <IconButton
                                                    aria-label="close"
                                                    hidden={this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS"} 
                                                    onClick={(event) => this.deleteItem(event, item, "saveEquipment")}         
                                                >
                                                    <MdClear className="icon-2" />
                                                </IconButton>
                                            }
                                        />
                                    </Link>
                                )
                            })
                        }
                    </div>

                    <div hidden={this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS"} className="layout horizontal form-margin">
                        <div className="flex"></div>
                        <SecondaryButton onClick={() => this.saveApplicationState()}>
                            <Link to="/application/application_id/equipment">
                                Add Equipment
                                </Link>
                        </SecondaryButton>
                    </div>

                    <div className="height-16"></div>

                    <div className="layout summary-cards form-margin">
                        <CardComponentSummaryCards
                            cards = { [
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAmount(this.props.currentApplicationState.equipment, 'federal')} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>Federal</span>
                                },
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAmount(this.props.currentApplicationState.equipment, 'county')} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>County Match</span>
                                },
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAmount(this.props.currentApplicationState.equipment)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>Total</span>
                                }
                            ] }
                        />
                    </div>


                    <div className="layout horizontal">
                        <NormalTextField id="equipmentNarrative" label="Equipment narrative" multiline custommargin="true" placeholder="Enter narrative" className="flex" error={this.isNarrativeError('equipment')} mandatory={this.isNarrativeError('equipment', true).toString()} value={this.props.currentApplicationState.equipmentNarrative || ''} onChange={this.handleChange('equipmentNarrative')} margin="normal" InputProps={{  readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />
                    </div>

                    {/* Supplies section */}

                    <div className="height-20"></div>

                    <div className="layout horizontal center section-title form-margin">
                        V. Supplies
                    </div>

                    <div className="layout horizontal wrap">
                        {
                            this.props.currentApplicationState.supply && this.props.currentApplicationState.supply.items &&
                            this.filterItems(this.props.currentApplicationState, "supply").map((item) => {
                                return (

                                    <Link to={"/application/application_id/supply/" + (item.id ? item.id : "temp_" + item.tempId)}  key={item.id || item.tempId} title={checkIfAmountHasBeenReimbursed(this.props.selectedCounty, item.name, 'supply') ? 'Item cannot be deleted because the amount for it has been reimbursed' : ''}>
                                        <CardComponent2R1C className="set-width card-shell"
                                            title={<span>{item.name}</span>}
                                            title2={
                                                <NumberFormat fixedDecimalScale={true} decimalScale={2} value={getValue([item.federal, item.county, item.countyInKind])} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                            }

                                            suffix={
                                                checkIfAmountHasBeenReimbursed(this.props.selectedCounty, item.name, 'supply') ? '' : 
                                                <IconButton
                                                    aria-label="close"
                                                    hidden={this.props.lempgUserType && this.props.lempgUserType!="LEMPG_ACCESS"} 
                                                    onClick={(event) => this.deleteItem(event, item, "saveSupply")}         
                                                >
                                                    <MdClear className="icon-2" />
                                                </IconButton>
                                            }
                                        />
                                    </Link>
                                )
                            })
                        }
                    </div>

                    <div hidden={this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS"} className="layout horizontal form-margin">
                        <div className="flex"></div>
                        <SecondaryButton onClick={() => this.saveApplicationState()}>
                            <Link to="/application/application_id/supply">
                                Add Supplies
                                </Link>
                        </SecondaryButton>
                    </div>

                    <div className="height-16"></div>

                    <div className="layout summary-cards form-margin">
                        <CardComponentSummaryCards
                            cards = { [
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAmount(this.props.currentApplicationState.supply, 'federal')} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>Federal</span>
                                },
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAmount(this.props.currentApplicationState.supply, 'county')} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>County Match</span>
                                },
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAmount(this.props.currentApplicationState.supply)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>Total</span>
                                }
                            ] }
                        />
                    </div>


                    <div className="layout horizontal">
                        <NormalTextField id="supplyNarrative" label="Supplies narrative" multiline custommargin="true" placeholder="Enter narrative" className="flex" error={this.isNarrativeError('supply')} mandatory={this.isNarrativeError('supply', true).toString()} value={this.props.currentApplicationState.supplyNarrative || ''} onChange={this.handleChange('supplyNarrative')} margin="normal" InputProps={{  readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />
                    </div>

                    {/* Others section */}

                    <div className="height-20"></div>

                    <div className="layout horizontal center section-title form-margin">
                        VI. Other
                    </div>

                    <div className="layout horizontal wrap">
                        {
                            this.props.currentApplicationState.other && this.props.currentApplicationState.other.items &&
                            this.filterItems(this.props.currentApplicationState, "other").map((item) => {
                                return (

                                    <Link to={"/application/application_id/other/" + (item.id ? item.id : "temp_" + item.tempId)}  key={item.id || item.tempId} title={checkIfAmountHasBeenReimbursed(this.props.selectedCounty, item.name, 'other') ? 'Item cannot be deleted because the amount for it has been reimbursed' : ''}>
                                        <CardComponent2R1C className="set-width card-shell"
                                            title={<span>{item.name}</span>}
                                            title2={
                                                <NumberFormat fixedDecimalScale={true} decimalScale={2} value={getValue([item.federal, item.county, item.countyInKind])} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                            }

                                            suffix={
                                                checkIfAmountHasBeenReimbursed(this.props.selectedCounty, item.name, 'other') ? '' : 
                                                <IconButton
                                                    aria-label="close"
                                                    hidden={this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS"} 
                                                    onClick={(event) => this.deleteItem(event, item, "saveOther")}         
                                                >
                                                    <MdClear className="icon-2" />
                                                </IconButton>
                                            }
                                        />
                                    </Link>
                                )
                            })
                        }
                    </div>

                    <div hidden={this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS"} className="layout horizontal form-margin">
                        <div className="flex"></div>
                        <SecondaryButton onClick={() => this.saveApplicationState()}>
                            <Link to="/application/application_id/other">
                                Add Other
                                </Link>
                        </SecondaryButton>
                    </div>

                    <div className="height-16"></div>

                    <div className="layout summary-cards form-margin">
                        <CardComponentSummaryCards
                            cards = { [
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAmount(this.props.currentApplicationState.other, 'federal')} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>Federal</span>
                                },
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAmount(this.props.currentApplicationState.other, 'county')} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>County Match</span>
                                },
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAmount(this.props.currentApplicationState.other)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>Total</span>
                                }
                            ] }
                        />
                    </div>


                    <div className="layout horizontal">
                        <NormalTextField id="otherNarrative" label="Other narrative" multiline custommargin="true" placeholder="Enter narrative" className="flex" error={this.isNarrativeError('other')} mandatory={this.isNarrativeError('other', true).toString()} value={this.props.currentApplicationState.otherNarrative || ''} onChange={this.handleChange('otherNarrative')} margin="normal" InputProps={{  readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />
                    </div>

                    <div className="height-20"></div>

                    <div className="layout horizontal center section-title form-margin">
                        VII. CERT
                    </div>
                    {
                        this.props.grantApplicationShowCertCheckbox ? 
                        <FormGroup className="application-cert-checkbox" row>
                            <FormControlLabel className="application-cert-checkbox"
                                control={<NormalCheckbox onChange={this.handleCheckboxChange('deductCertFromFederal')} checked={this.props.currentApplicationState.deductCertFromFederal}  disabled={this.props.lempgUserType && this.props.lempgUserType !== "LEMPG_ACCESS"} />}
                                label="Deduct from federal award."
                            />
                        </FormGroup>
                        : ""
                    }
                    
                   
                    <div className="layout horizontal wrap">
                        {
                            this.props.currentApplicationState.cert && this.props.currentApplicationState.cert.items &&
                            this.filterItems(this.props.currentApplicationState, "cert").map((item) => {
                                return (
                                  
                                    <Link to={"/application/application_id/cert/" + (item.id ? item.id : "temp_" + item.tempId)}  key={item.id || item.tempId} title={checkIfAmountHasBeenReimbursed(this.props.selectedCounty, item.name, 'cert') ? 'Item cannot be deleted because the amount for it has been reimbursed' : ''}>
                                        <CardComponent2R1C className="set-width card-shell"
                                            title={<span>{item.name}</span>}
                                            title2={
                                                <NumberFormat fixedDecimalScale={true} decimalScale={2} value={getValue([item.federal, item.county, item.countyInKind])} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                            }

                                            suffix={
                                                checkIfAmountHasBeenReimbursed(this.props.selectedCounty, item.name, 'cert') ? '' : 
                                                <IconButton
                                                    aria-label="close"
                                                    hidden={this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS"} 
                                                    onClick={(event) => this.deleteItem(event, item, "saveCert")}         
                                                >
                                                    <MdClear className="icon-2" />
                                                </IconButton>
                                            }
                                        />
                                    </Link>
                                )
                            })
                        }
                    </div>

                    <div hidden={this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS"} className="layout horizontal form-margin">
                        <div className="flex"></div>
                        <SecondaryButton onClick={() => this.saveApplicationState()}>
                            <Link to="/application/application_id/cert">
                                Add Cert
                                </Link>
                        </SecondaryButton>
                    </div>

                    <div className="height-16"></div>

                    <div className="layout summary-cards form-margin">
                        <CardComponentSummaryCards
                            cards = { [
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAmount(this.props.currentApplicationState.cert, 'federal')} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>Federal</span>
                                },
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAmount(this.props.currentApplicationState.cert, 'county')} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>County Match</span>
                                },
                                { text: 
                                    <NumberFormat fixedDecimalScale={true} decimalScale={2} value={this.calculateAmount(this.props.currentApplicationState.cert)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                  ,
                                  title: <span>Total</span>
                                }
                            ] }
                        />
                    </div>

                    <div className="layout horizontal">
                        <NormalTextField id="certNarrative" label="CERT narrative" multiline custommargin="true" placeholder="Enter narrative" className="flex" error={this.isNarrativeError('cert')} mandatory={this.isNarrativeError('cert', true).toString()} value={this.props.currentApplicationState.certNarrative || ''} onChange={this.handleChange('certNarrative')} margin="normal" InputProps={{  readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />
                    </div>

                    {/* Other information section */}

                    <div className="height-16"></div>

                    <div className="layout horizontal center section-title form-margin">
                        VIII. Information
                    </div>

                    <div className="layout horizontal">
                        <NormalTextField id="implementingAgency" label="Name of Implementing agency" custommargin="true" placeholder="Enter name" className="flex" value={this.state.implementingAgency} onChange={this.handleChange('implementingAgency')} margin="normal" InputProps={{ readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />
                    </div>

                    {/* Addresss module would go here */}

                    <div className="layout horizontal wrap">
                        <NormalTextField id="address" label="Address" custommargin="true" placeholder="Enter address" className="flex" value={this.state.address} onChange={this.handleChange('address')} margin="normal" InputProps={{ readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />

                        <NormalTextField id="city" label="City" custommargin="true" placeholder="Enter city" className="flex" value={this.state.city} onChange={this.handleChange('city')} margin="normal" InputProps={{ readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />
                    </div>

                    <div className="layout horizontal wrap">
                        <NormalTextField id="county" label="County" custommargin="true" placeholder="Enter county" className="flex" value={this.state.county} onChange={this.handleChange('county')} margin="normal" InputProps={{ readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />

                        <NormalTextField id="state" label="State" custommargin="true" placeholder="Enter state" className="flex" value={this.state.state} onChange={this.handleChange('state')} margin="normal" InputProps={{ readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />

                        <NormalTextField id="zipcode" label="Zip Code" custommargin="true" placeholder="Enter zip code" className="flex" value={this.state.zipcode} onChange={this.handleChange('zipcode')} margin="normal" InputProps={{ readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />
                    </div>


                    <div className="layout horizontal wrap">
                        {/* <NormalTextField id="implementingAgency" label="Implementing agency" custommargin="true" placeholder="Enter agency" className="flex" value={this.state.implementingAgency} onChange={this.handleChange('implementingAgency')} margin="normal" InputProps={{  readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} /> */}

                        <NormalTextField id="phoneNumber" label="Phone number" custommargin="true" placeholder="Enter phone number" className="flex" value={this.state.phoneNumber} onChange={this.handleChange('phoneNumber')} margin="normal" InputProps={{  readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />

                        <NormalTextField id="faxNumber" label="Fax" custommargin="true" placeholder="Enter fax number" className="flex" value={this.state.faxNumber} onChange={this.handleChange('faxNumber')} margin="normal" InputProps={{  readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />
                    </div>


                </div>
            

                {
                    this.props.revision ?
                        this.props.lempgUserType
                            && this.props.lempgUserType == "LEMPG_ACCESS"
                            ?
                            <div className="layout horizontal center-justified form-container" style={{ borderTop: "1px solid #E0E0E0" }}>
                                <PrimaryButton setwidth="true" onClick={() => this.submitGrantApplication()}> Submit </PrimaryButton>
                            </div>

                            : (this.props.lempgUserType
                                && (this.props.lempgUserType != "LEMPG_ACCESS") && (this.props.currentApplicationState && this.props.currentApplicationState.status == "SUBMITTED"))
                                ?
                                <div className="layout horizontal center center-justified wrap  form-container" style={{ borderTop: "1px solid #E0E0E0" }}>
                                    <DeleteButton setwidth="true" onClick={() => this.requestChangesInGrantApplication()}>
                                        Request changes
                                </DeleteButton>
                                    <div style={{ width: "20px" }}></div>
                                    <PrimaryButton setwidth="true" onClick={() => this.awardApplicationAndLinkRevision()}>
                                        Approve
                                </PrimaryButton>
                                </div>

                                : ""
                        : ""
                }
                
            </div>

        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationForm);

