export const REMContactInfo = {
    "M-113": {
        "R1": {
            "narrativePOCFirstName": "Scot",
            "narrativePOCLastName": "Yarbrough",
            "narrativePOCPhoneNumber": "803-361-6445",
            "narrativePOCEmailAddress": "syarbrough@emd.sc.gov",
            "narrativePOCPositionTitle": "REM",
        },
        "R2": {
            "narrativePOCFirstName": "Kimberly",
            "narrativePOCLastName": "Shiverdecker",
            "narrativePOCPhoneNumber": "803-728-5559",
            "narrativePOCEmailAddress": "kshiverdecker@emd.sc.gov",
            "narrativePOCPositionTitle": "REM",
        },
        "R3": {
            "narrativePOCFirstName": "Bethany",
            "narrativePOCLastName": "Morton",
            "narrativePOCPhoneNumber": "803-800-2583",
            "narrativePOCEmailAddress": "bmorton@emd.sc.gov",
            "narrativePOCPositionTitle": "REM",
        },
        "R4": {
            "narrativePOCFirstName": "James",
            "narrativePOCLastName": "Grant",
            "narrativePOCPhoneNumber": "803-800-2962",
            "narrativePOCEmailAddress": "jgrant@emd.sc.gov",
            "narrativePOCPositionTitle": "REM",
        },
        "R5": {
            "narrativePOCFirstName": "Ben",
            "narrativePOCLastName": "Webster",
            "narrativePOCPhoneNumber": "803-638-3405",
            "narrativePOCEmailAddress": "bwebster@emd.sc.gov",
            "narrativePOCPositionTitle": "REM",
        },
        "R6": {
            "narrativePOCFirstName": "Rocky",
            "narrativePOCLastName": "Tucker",
            "narrativePOCPhoneNumber": "803-367-1845",
            "narrativePOCEmailAddress": "ltucker@emd.sc.gov",
            "narrativePOCPositionTitle": "REM",
        }
    },
    "M-107": {
        "R1": {
            "narrativePOCFirstName": "Scot",
            "narrativePOCLastName": "Yarbrough",
            "narrativePOCPhoneNumber": "803-361-6445",
            "narrativePOCEmailAddress": "syarbrough@emd.sc.gov",
            "narrativePOCPositionTitle": "REM",
        },
        "R2": {
            "narrativePOCFirstName": "Kimberly",
            "narrativePOCLastName": "Shiverdecker",
            "narrativePOCPhoneNumber": "803-728-5559",
            "narrativePOCEmailAddress": "kshiverdecker@emd.sc.gov",
            "narrativePOCPositionTitle": "REM",
        },
        "R3": {
            "narrativePOCFirstName": "Bethany",
            "narrativePOCLastName": "Morton",
            "narrativePOCPhoneNumber": "803-800-2583",
            "narrativePOCEmailAddress": "bmorton@emd.sc.gov",
            "narrativePOCPositionTitle": "REM",
        },
        "R4": {
            "narrativePOCFirstName": "James",
            "narrativePOCLastName": "Grant",
            "narrativePOCPhoneNumber": "803-800-2962",
            "narrativePOCEmailAddress": "jgrant@emd.sc.gov",
            "narrativePOCPositionTitle": "REM",
        },
        "R5": {
            "narrativePOCFirstName": "Ben",
            "narrativePOCLastName": "Webster",
            "narrativePOCPhoneNumber": "803-638-3405",
            "narrativePOCEmailAddress": "bwebster@emd.sc.gov",
            "narrativePOCPositionTitle": "REM",
        },
        "R6": {
            "narrativePOCFirstName": "Rocky",
            "narrativePOCLastName": "Tucker",
            "narrativePOCPhoneNumber": "803-367-1845",
            "narrativePOCEmailAddress": "ltucker@emd.sc.gov",
            "narrativePOCPositionTitle": "REM",
        }
    },
    "M-108": {
        "R1": {
            "narrativePOCFirstName": "Scot",
            "narrativePOCLastName": "Yarbrough",
            "narrativePOCPhoneNumber": "803-361-6445",
            "narrativePOCEmailAddress": "syarbrough@emd.sc.gov",
            "narrativePOCPositionTitle": "REM",
        },
        "R2": {
            "narrativePOCFirstName": "Kimberly",
            "narrativePOCLastName": "Shiverdecker",
            "narrativePOCPhoneNumber": "803-728-5559",
            "narrativePOCEmailAddress": "kshiverdecker@emd.sc.gov",
            "narrativePOCPositionTitle": "REM",
        },
        "R3": {
            "narrativePOCFirstName": "Bethany",
            "narrativePOCLastName": "Morton",
            "narrativePOCPhoneNumber": "803-800-2583",
            "narrativePOCEmailAddress": "bmorton@emd.sc.gov",
            "narrativePOCPositionTitle": "REM",
        },
        "R4": {
            "narrativePOCFirstName": "James",
            "narrativePOCLastName": "Grant",
            "narrativePOCPhoneNumber": "803-800-2962",
            "narrativePOCEmailAddress": "jgrant@emd.sc.gov",
            "narrativePOCPositionTitle": "REM",
        },
        "R5": {
            "narrativePOCFirstName": "Ben",
            "narrativePOCLastName": "Webster",
            "narrativePOCPhoneNumber": "803-638-3405",
            "narrativePOCEmailAddress": "bwebster@emd.sc.gov",
            "narrativePOCPositionTitle": "REM",
        },
        "R6": {
            "narrativePOCFirstName": "Rocky",
            "narrativePOCLastName": "Tucker",
            "narrativePOCPhoneNumber": "803-367-1845",
            "narrativePOCEmailAddress": "ltucker@emd.sc.gov",
            "narrativePOCPositionTitle": "REM",
        }
    }    
}