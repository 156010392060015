import React from 'react';
import { Route, Link, Switch } from "react-router-dom";
import { connect } from 'react-redux'
import IconButton from "@material-ui/core/IconButton";
import { MdChevronRight } from 'react-icons/md';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

// Local imports

// Component imports
import StatusComponent from "../../styled-components/Statuses/StausComponent";

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const styles = {
    listItem: {
        // minHeight: "48px",
        // maxHeight: "60px",
        paddingLeft: "16px",
        paddingRight: "0px",
    },
    listText: {
        fontSize: "16px",
        lineHeight: "1.5",
        color: "rgba(0, 0, 0, 0.87)"
    },
    extraHeight: {
        height: "64px"
    }

};


const mapStateToProps = (state) => {
    return {
        loading: state.rootReducer.loading,
        lempgUserType: state.rootReducer.lempgUserType,
        selectedYear: state.rootReducer.selectedYear,
        currentFiscalYear: state.rootReducer.currentFiscalYear,
    }
};

class TermsAndConditionDocuments extends React.Component {

    state = {
    }

    constructor(props, context) {
        super(props, context);
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render() {

        const { classes } = this.props;

        return (
            <div className="layout vertical full-height">
                <div className="flex container-overflow-y">

                    {/* Acceptance of audit requirement */}

                    <Link className={`layout horizontal ${classes.listItem} ${this.props.currentFiscalYear && this.props.currentFiscalYear.acceptanceOfAuditReqFile ? classes.extraHeight : ""}`} to="/prefiscalyear/termsandcondition/auditreq">

                        <div className="layout vertical only-vertical-center flex">
                            <div className={`truncate ${classes.listText}`}>
                                Acceptance of Audit Requirements
                                </div>

                            {
                                this.props.currentFiscalYear && this.props.currentFiscalYear.acceptanceOfAuditReqFile ? <div><StatusComponent text="Completed" /></div> : null
                            }

                        </div>

                        <IconButton>
                            <MdChevronRight className="icon-2 opacity-54" />
                        </IconButton>

                    </Link>
                    <Divider />

                    {/* Certification document */}

                    <Link className={`layout horizontal ${classes.listItem} ${this.props.currentFiscalYear && this.props.currentFiscalYear.certificationDocumentFile ? classes.extraHeight : ""}`} to="/prefiscalyear/termsandcondition/certificationdocuments">

                        <div className="layout vertical only-vertical-center flex">
                            <div className={`truncate ${classes.listText}`}>
                                Certification Document
                                </div>

                            {
                                this.props.currentFiscalYear && this.props.currentFiscalYear.certificationDocumentFile ? <div><StatusComponent text="Completed" /></div> : null
                            }

                        </div>

                        <IconButton>
                            <MdChevronRight className="icon-2 opacity-54" />
                        </IconButton>

                    </Link>
                    <Divider />

                    {/* Assurances Non-Construction */}

                    <Link className={`layout horizontal ${classes.listItem} ${this.props.currentFiscalYear && this.props.currentFiscalYear.assurancesNonContructionFile ? classes.extraHeight : ""}`} to="/prefiscalyear/termsandcondition/assurances">

                        <div className="layout vertical only-vertical-center flex">
                            <div className={`truncate ${classes.listText}`}>
                                Assurances Non-Construction
                                </div>

                            {
                                this.props.currentFiscalYear && this.props.currentFiscalYear.assurancesNonContructionFile ? <div><StatusComponent text="Completed" /></div> : null
                            }

                        </div>

                        <IconButton>
                            <MdChevronRight className="icon-2 opacity-54" />
                        </IconButton>

                    </Link>
                    <Divider />

                    {/* Lobbying certification */}

                    <Link className={`layout horizontal ${classes.listItem} ${this.props.currentFiscalYear && this.props.currentFiscalYear.lobbyingCertificationFile ? classes.extraHeight : ""}`} to="/prefiscalyear/termsandcondition/lobbying">

                        <div className="layout vertical only-vertical-center flex">
                            <div className={`truncate ${classes.listText}`}>
                                Lobbying Certification
                                </div>

                            {
                                this.props.currentFiscalYear && this.props.currentFiscalYear.lobbyingCertificationFile ? <div><StatusComponent text="Completed" /></div> : null
                            }

                        </div>

                        <IconButton>
                            <MdChevronRight className="icon-2 opacity-54" />
                        </IconButton>

                    </Link>
                    <Divider />

                    {/* EDM Equipment Policy */}


                    <Link className={`layout horizontal ${classes.listItem} ${this.props.currentFiscalYear && this.props.currentFiscalYear.edmEquipmentPolicyFile ? classes.extraHeight : ""}`} to="/prefiscalyear/termsandcondition/edm">

                        <div className="layout vertical only-vertical-center flex">
                            <div className={`truncate ${classes.listText}`}>
                                Equipment Policy
                                </div>

                            {
                                this.props.currentFiscalYear && this.props.currentFiscalYear.edmEquipmentPolicyFile ? <div><StatusComponent text="Completed" /></div> : null
                            }

                        </div>

                        <IconButton>
                            <MdChevronRight className="icon-2 opacity-54" />
                        </IconButton>

                    </Link>
                    <Divider />

                    {/* Scope of Work */}

                    {/* <Link className={`layout horizontal ${classes.listItem} ${this.props.currentFiscalYear && this.props.currentFiscalYear.scopeOfWorkFile ? classes.extraHeight : ""}`} to="/prefiscalyear/termsandcondition/scopeofworkdocument">

                        <div className="layout vertical only-vertical-center flex">
                            <div className={`truncate ${classes.listText}`}>
                                Scope of Work
                            </div>

                            {
                                this.props.currentFiscalYear && this.props.currentFiscalYear.scopeOfWorkFile ? <div><StatusComponent text="Completed" /></div> : null
                            }

                        </div>

                        <IconButton>
                            <MdChevronRight className="icon-2 opacity-54" />
                        </IconButton>

                    </Link>
                    <Divider /> */}

                    {/* Narratives */}

                    <Link className={`layout horizontal ${classes.listItem} ${this.props.currentFiscalYear && this.props.currentFiscalYear.narrativesFile ? classes.extraHeight : ""}`} to="/prefiscalyear/termsandcondition/narrativesdocument">

                        <div className="layout vertical only-vertical-center flex">
                            <div className={`truncate ${classes.listText}`}>
                                Narratives
                                </div>

                            {
                                this.props.currentFiscalYear && this.props.currentFiscalYear.narrativesFile ? <div><StatusComponent text="Completed" /></div> : null
                            }

                        </div>

                        <IconButton>
                            <MdChevronRight className="icon-2 opacity-54" />
                        </IconButton>

                    </Link>
                    <Divider />

                    {/* Supplemental Guidelines */}

                    <Link className={`layout horizontal ${classes.listItem} ${this.props.currentFiscalYear && this.props.currentFiscalYear.guidelinesFile ? classes.extraHeight : ""}`} to="/prefiscalyear/termsandcondition/guidelinesdocument">

                        <div className="layout vertical only-vertical-center flex">
                            <div className={`truncate ${classes.listText}`}>
                                Supplemental Guidelines
                                </div>

                            {
                                this.props.currentFiscalYear && this.props.currentFiscalYear.guidelinesFile ? <div><StatusComponent text="Completed" /></div> : null
                            }

                        </div>

                        <IconButton>
                            <MdChevronRight className="icon-2 opacity-54" />
                        </IconButton>

                    </Link>
                    <Divider />


                </div>
            </div>

        )
    }
}

export default connect(mapStateToProps, null)(withStyles(styles)(TermsAndConditionDocuments));
