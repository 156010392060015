export const GetGroups = `query ListGroups(
  $filter: ModelGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      groupName
      createdAt
      updatedAt
      region
      palmettoId
      year
      awardAmount
      quarterlyprogressreporting {
        items {
          id
          quarter
          taskingslipstatus
          updatedAt
          updatedBy
          updatedByRemName
        }
        nextToken
      }            

      monitoringgrantinformation {
        id
        updatedAt
        createdAt
        updatedBy
        monitoringType
        monitoringDate
        subRecipientAgency
        grantProjectTitle
        grantNumber
        approvedGrantStartDate
        approvedGrantEndDate
        extensionEndDate
        name
        title
        projectDirectorName
        projectDirectorTitle
        personnelName
        personnelTitle
      }
      monitoringsitevisitreview {
        id
        updatedAt
        createdAt
        updatedBy
        gaOnFile
        gaSupportingDocumentationOnFile
        gaComments
        personnelFunded
        personnelDocumentationOnFile
        personnelRecordsOnFile
        personnelComments
        contractualServiceOnFile
        contractualServiceExecutedContractsOnFile
        contractualServiceComments
        travelOnFile
        travelDocumentationOnFile
        travelComments
        equipmentProperlySheltered
        equipmentTransferred
        equipmentComments
        suppliesOnFile
        suppliesTransferred
        suppliesComments
        otherOnFile
        otherComments
        summaryComments
        monitoringReportSubmittedBy
        monitoringReportSubmittedAt
        scemdPOCReviewedBy
        scemdPOCReviewedAt
        monitoringFiles {
          items {
            id
            bucket
            title
            size
            downloadTitle
            createdAt
            updatedAt
            deleted
            updatedBy
          }
          nextToken
        }
        status
      } 

      narratives(limit: 50) {
        nextToken
        items {
          id
          completed
          createdAt
          updatedAt
          deleted
          updatedBy
          status
          notes
          extraFile
          quarter
          currentExtension {
            id
            updatedAt
            createdAt
            updatedBy
            year
            type
            group
            status
            for
            quarterFrom
            quarterTo
            dueDate
            reasonForExtension
            dateAdjustedTo
            reasonForAdjustment
            reasonForDeniedExtension
          }
        

          narrative {
            id
            narrativeTitle
            narrativeCategory
            narrativeShortDescription
            narrativeLongDescription
            narrativeFrequency
            narrativePOCFirstName
            narrativePOCLastName
            narrativePOCPhoneNumber
            narrativePOCEmailAddress
            narrativePOCPositionTitle
            usePalmettoForREMInformation
            useFinancialReimbursementStatus            
            quarter
            deleted
            createdAt
            updatedAt
            updatedBy
            groups {
              nextToken
            }
          }
          
        }
        
      }
      deleted
      grantapplication {
        nextToken
        items {

          id
          group {
            id
            groupName
            createdAt
            updatedAt
            region
            palmettoId
            narratives {
              nextToken
            }
            deleted
            grantapplication {
              nextToken
            }
          }
          updatedBy
          version
          versionName
          createdAt
          updatedAt
          year
          deleted
          status
          awarded
          deductCertFromFederal

          currentApplicationRevisionId
          grantApplicationRevision(limit: 10) {
            items {
              id
              createdAt
              updatedAt
              updatedBy
              revision {
                id
                bucket
                title
                size
                downloadTitle
                createdAt
                updatedAt
                deleted
                updatedBy
              }          
            }
            nextToken
          }              
          salary(limit: 50) {
            items {
              id
              deleted
              updatedBy
              name
              positionTitle
              federal
              county
              countyInKind
              fbFederalFunding
              fbCountyCashFunding
              fbCountyInkindFunding
            }
            nextToken
          }
          salaryNarrative

          fringeBenefitNarrative
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          contractualService(limit: 50) {
            items {
              id
              name
              deleted
              updatedBy
              federal
              county
              countyInKind
            }
            nextToken
          }
          contractualServiceNarrative
          travel(limit: 50) {
            items {
              id
              name
              deleted
              updatedBy
              federal
              county
              countyInKind
            }
            nextToken
          }
          travelNarrative
          equipment(limit: 50) {
            items {
              id
              name
              femaItemNumber
              quantity
              deleted
              updatedBy
              federal
              county
              countyInKind
            }
            nextToken
          }
          equipmentNarrative
          supply(limit: 50) {
            items {
              id
              name
              deleted
              updatedBy
              federal
              county
              countyInKind
            }
            nextToken
          }
          supplyNarrative
          other(limit: 50) {
            items {
              id
              name
              deleted
              updatedBy
              federal
              county
              countyInKind
            }
            nextToken
          }
          otherNarrative
          cert(limit: 50) {
            items {
              id
              name
              deleted
              updatedBy
              federal
              county
              countyInKind
            }
            nextToken
          }
          certNarrative
          acceptanceOfAuditReqFile {
            id
            bucket
            title
            size
            downloadTitle
            createdAt
            updatedAt
            deleted
            updatedBy
          }
          certificationDocumentFile {
            id
            bucket
            title
            size
            downloadTitle
            createdAt
            updatedAt
            deleted
            updatedBy
          }
          assurancesNonContructionFile {
            id
            bucket
            title
            size
            downloadTitle
            createdAt
            updatedAt
            deleted
            updatedBy
          }
          lobbyingCertificationFile {
            id
            bucket
            title
            size
            downloadTitle
            createdAt
            updatedAt
            deleted
            updatedBy
          }
          edmEquipmentPolicyFile {
            id
            bucket
            title
            size
            downloadTitle
            createdAt
            updatedAt
            deleted
            updatedBy
          }
          positionDescFile {
            id
            bucket
            title
            size
            downloadTitle
            createdAt
            updatedAt
            deleted
            updatedBy
          }
          acknowledgementFile {
            id
            bucket
            title
            size
            downloadTitle
            createdAt
            updatedAt
            deleted
            updatedBy
          }
          otherFile {
            id
            bucket
            title
            size
            downloadTitle
            createdAt
            updatedAt
            deleted
            updatedBy
          }
          scopeOfWorkFile {
            id
            bucket
            title
            size
            downloadTitle
            createdAt
            updatedAt
            deleted
            updatedBy
          }
          narrativesFile {
            id
            bucket
            title
            size
            downloadTitle
            createdAt
            updatedAt
            deleted
            updatedBy
          }
          extraFile
          awardDocumentsUploadedByState {
            id
            bucket
            title
            size
            downloadTitle
            createdAt
            updatedAt
            deleted
            updatedBy
          }
          awardCoverLetterUploadedByState {
            id
            bucket
            title
            size
            downloadTitle
            createdAt
            updatedAt
            deleted
            updatedBy
          }
          awardDocumentUploadedByState {
            id
            bucket
            title
            size
            downloadTitle
            createdAt
            updatedAt
            deleted
            updatedBy
          }
          awardSpecialInstructionsUploadedByState {
            id
            bucket
            title
            size
            downloadTitle
            createdAt
            updatedAt
            deleted
            updatedBy
          }
          awardDocumentsUploadedByCounty {
            id
            bucket
            title
            size
            downloadTitle
            createdAt
            updatedAt
            deleted
            updatedBy
          }
          extensions(limit: 50) {
            items {
              id
              updatedAt
              createdAt
              updatedBy
              year
              type
              group
              status
              for
              quarterFrom
              quarterTo
              dueDate
              reasonForExtension
              dateAdjustedTo
              reasonForAdjustment
              reasonForDeniedExtension
            }
            nextToken
          }
          currentExtension {
            id
            updatedAt
            createdAt
            updatedBy
            year
            type
            group
            status
            for
            quarterFrom
            quarterTo
            dueDate
            reasonForExtension
            dateAdjustedTo
            reasonForAdjustment
            reasonForDeniedExtension
          }
          changesRequestedOnApplication
          name
          address
          address2
          city
          state
          county
          zipcode
          latitude
          longitude
          enableReverseGeocoding
          implementingAgency
          phoneNumber
          faxNumber          

        }
      }
    
      financialreimbursement {
        nextToken
        items {
          
          id
          quarter
          quarterStartDate
          quarterEndDate
          updatedBy
          year
          createdAt
          updatedAt
          deleted
          status
          awarded
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
      
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
      
          additionalChecks {
            items {
              id
              deleted
              updatedBy
              createdAt
              updatedAt
              checkNumber
              checkAmount
              checkMailDate
              stateSceisDocumentNumber
              stateSceisSubmissionDate
              reason
              checkNumber2
              checkAmount2
              checkMailDate2
              stateSceisDocumentNumber2
              stateSceisSubmissionDate2
              reason2        
      
            }
            nextToken
          }
          additionalCheckInProgress {
            id
            deleted
            updatedBy
            createdAt
            updatedAt
            checkNumber
            checkAmount
            checkMailDate
            stateSceisDocumentNumber
            stateSceisSubmissionDate
            reason
            checkNumber2
            checkAmount2
            checkMailDate2
            stateSceisDocumentNumber2
            stateSceisSubmissionDate2
            reason2        
    
          }

          checksReissued(limit: 10) {
            items {
              id
              deleted
              updatedBy
              createdAt
              updatedAt
              checkNumber
              checkAmount
              checkMailDate
              reason
              checkNumber2
              checkAmount2
              checkMailDate2
              stateSceisDocumentNumber2
              stateSceisSubmissionDate2
              reason2        
      
            }
            nextToken
          }
          currentReissuedCheck {
            id
            deleted
            updatedBy
            createdAt
            updatedAt
            checkNumber
            checkAmount
            checkMailDate
            reason
            checkNumber2
            checkAmount2
            checkMailDate2
            stateSceisDocumentNumber2
            stateSceisSubmissionDate2
            reason2        
    
          }
          
          personnel(limit: 50) {
            items {
              id
              deleted
              updatedBy
              positionTitle
              quarterAmount
              matchAmount
              taxAmount
              file
            }
            nextToken
          }
          contractualService(limit: 50) {
            items {
              id
              deleted
              updatedBy
              positionTitle
              quarterAmount
              matchAmount
              taxAmount
              file
            }
            nextToken
          }
          travel(limit: 50) {
            items {
              id
              deleted
              updatedBy
              positionTitle
              quarterAmount
              matchAmount
              taxAmount
              file
            }
            nextToken
          }
          equipment(limit: 50) {
            items {
              id
              deleted
              updatedBy
              positionTitle
              quarterAmount
              matchAmount
              taxAmount
              file
            }
            nextToken
          }
          supply(limit: 50) {
            items {
              id
              deleted
              updatedBy
              positionTitle
              quarterAmount
              matchAmount
              taxAmount
              file
            }
            nextToken
          }
          cert(limit: 50) {
            items {
              id
              deleted
              updatedBy
              positionTitle
              quarterAmount
              matchAmount
              taxAmount
              file
            }
            nextToken
          }
          other(limit: 50) {
            items {
              id
              deleted
              updatedBy
              positionTitle
              quarterAmount
              matchAmount
              taxAmount
              file
            }
            nextToken
          }
          extensions(limit: 20) {
            items {
              id
              updatedAt
              createdAt
              updatedBy
              year
              type
              group
              status
              for
              quarterFrom
              quarterTo
              dueDate
              reasonForExtension
              dateAdjustedTo
              reasonForAdjustment
              reasonForDeniedExtension
            }
            nextToken
          }
          currentExtension {
            id
            updatedAt
            createdAt
            updatedBy
            year
            type
            group
            status
            for
            quarterFrom
            quarterTo
            dueDate
            reasonForExtension
            dateAdjustedTo
            reasonForAdjustment
            reasonForDeniedExtension
          }
          deobligatedFunds
          deobligatedFundsReason
        }
      }      
    }
    nextToken
  }
}
`;