import React from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import IconButton from "@material-ui/core/IconButton";
import { MdMenu } from 'react-icons/md';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { SecondaryButton } from '../../styled-components/Buttons/Buttons.js';

// js
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import { CardComponent2R1C } from "../../layout/CardComponent";

import { getCountyAction, getFilesAssociatedWithCounty, genPersonnelReport } from "../../../redux/actions/index";

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        token: state.rootReducer.token,
        counties: state.rootReducer.counties,
        selectedYear: state.rootReducer.selectedYear
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCountyAction: (payload, callback) => dispatch(getCountyAction(payload, callback)),
        getFilesAssociatedWithCounty: () => dispatch(getFilesAssociatedWithCounty()),
        genPersonnelReport: (payload, callback) => dispatch(genPersonnelReport(payload, callback))
    }
}


class FileLibraryState extends React.Component {

    state = {
        selectedTab: 1,
        smallScreen: false,
    }

    constructor(props, context) {
        super(props, context);
        this.selectedCountyCallback = this.selectedCountyCallback.bind(this);
    }

    checkWidth = () => {
        const match = window.matchMedia(`(max-width: 768px)`).matches;
        if (match == this.state.smallScreen) return;
        if (match) {
            this.setState({ smallScreen: true });
        } else {
            this.setState({ smallScreen: false });
        }
    }

    componentDidMount() {
        this.checkWidth();
        window.addEventListener("resize", this.checkWidth);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkWidth);
    }

    getSelectedCountyFiles(county) {
        this.props.getCountyAction({ selectedGroupID: parseInt(county.palmettoId) }, this.selectedCountyCallback);
    }

    selectedCountyCallback() {
        this.props.getFilesAssociatedWithCounty()
    }

    handleMenuClick = (event) => {
        window.dispatchEvent(new CustomEvent("toggle-sidebar"))
    }

    selectTab = (index) => {
        this.setState({ selectedTab: index })
    }

    downloadPersonnelReport = () => this.props.genPersonnelReport();

    render() {
        return (


            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.handleMenuClick}
                        >
                            <MdMenu className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Documents</span>}
                    titleSmall={<span>{window.appHeaderTitle}</span>}
                />



                <Tabs className="layout horizontal center"
                    value={this.state.selectedTab}
                    indicatorColor="primary"
                    textColor="primary"
                    classes={{
                        root: "tabs-root",
                        flexContainer: "flex",
                        indicator: "tab-indicator-color",
                    }}
                    // onChange={handleChange}
                    aria-label="disabled tabs example"
                >
                    <Tab
                        className="flex"
                        classes={{
                            root: "tab-root",
                            selected: "tab-selected",
                        }}
                        onClick={() => this.selectTab(0)}
                        label="State"
                    />

                    <Tab
                        className="flex"
                        classes={{
                            root: "tab-root",
                            selected: "tab-selected",
                        }}
                        onClick={() => this.selectTab(1)}
                        label="County"
                    />
                </Tabs>

                <div className="flex container-overflow-y">
                    {
                        this.state.selectedTab == 1 ?
                            <>
                                {
                                    this.props.counties.map((county) => {
                                        return (
                                            <Link key={county.groupName} to="/filelibrary/county" onClick={() => this.getSelectedCountyFiles(county)} className="layout horizontal">
                                                <CardComponent2R1C
                                                    className="flex no-opacity-important-childs"
                                                    title={<span style={{ textTransform: "capitalize" }}>{county.groupName}</span>}
                                                />
                                            </Link>

                                        )
                                    })
                                }
                            </>
                            :
                            <>
                                {
                                    this.state.smallScreen ?
                                        <div onClick={this.downloadPersonnelReport} className="layout vertical award-letters-download-box cursor-pointer" style={{ width: "100%" }}>

                                            <div className="layout horizontal center award-file">
                                                <span className="award-file-title">{`Personnel Report`}</span>
                                            </div>

                                        </div>
                                        :
                                        <div className="layout horizontal award-letters-download-box" style={{ width: "100%" }}>

                                            <div className="layout horizontal flex center award-file form-margin-16">
                                                <span className="award-file-title">{`Personnel Report`}</span>
                                                <div className="flex"></div>
                                                <SecondaryButton onClick={this.downloadPersonnelReport} nowidth="true">
                                                    Download
                                    </SecondaryButton>
                                            </div>

                                        </div>

                                }
                            </>
                    }

                </div>
            </div>
        )
    }
}

// export default FileLibraryState;
export default connect(mapStateToProps, mapDispatchToProps)(FileLibraryState);
