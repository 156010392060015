import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux'
import { setDefaultInfo } from "../../redux/actions/index";
import Login from "./Login";
import LoginErrorMsg from "./LoginErrorMsg";

// return <Comp {...this.props} />

const mapDispatchToProps = (dispatch) => {
    return {
        setDefaultInfo: (payload, magicUrl) => dispatch(setDefaultInfo(payload, magicUrl)),
    }
}

const mapStateToProps = state => {
    return {
        appLoading: state.rootReducer.appLoading,
    };
};


const withPalmettoAuth = PassedComponent => {
    const Wrapper = props => (
        class WithPalmettoAuth extends React.Component {

            state = {
                loginSuccessful: false,
                errorOccured: false,
                errorMessage: "",
            }

            /**
             * Referenced from https://stackoverflow.com/questions/8486099/how-do-i-parse-a-url-query-parameters-in-javascript/8486188
             */
            getJsonFromUrl = (hashBased) => {
                window.location.hash = ""
                var query;
                if (hashBased) {
                    var pos = window.location.href.indexOf("?");
                    if (pos === -1) return [];
                    query = window.location.href.substr(pos + 1);
                } else {
                    query = window.location.search.substr(1);
                }
                var result = {};
                query.split("&").forEach(function (part) {
                    if (!part) return;
                    part = part.split("+").join(" "); // replace every + with space, regexp-free version
                    var eq = part.indexOf("=");
                    var key = eq > -1 ? part.substr(0, eq) : part;
                    var val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)).replace("#", "") : ""; // Removes a hash symbol
                    var from = key.indexOf("[");
                    if (from === -1) result[decodeURIComponent(key)] = val;
                    else {
                        var to = key.indexOf("]", from);
                        var index = decodeURIComponent(key.substring(from + 1, to));
                        key = decodeURIComponent(key.substring(0, from));
                        if (!result[key]) result[key] = [];
                        if (!index) result[key].push(val);
                        else result[key][index] = val;
                    }
                });
                return result;
            }

            getPermissionsForSite = (requestedSite, requestedGroupId, data) => {
                let sitePermissions = [];
                if (!data) {
                    return sitePermissions;
                }
                for (let i = 0; i < data.length; i++) {
                    let attributes = data[i];
                    let site = attributes && Object.keys(attributes) && Object.keys(attributes)[0];
                    if (requestedSite === site) {
                        for (let j = 0; j < Object.values(attributes)[0].length; j++) {
                            let attribute = Object.values(attributes)[0][j];
                            let key = Object.keys(attribute)[0];
                            if (!isNaN(key)) {
                                // Group ID
                                let groupId = parseInt(key);
                                let status = 'DNY';
                                if (requestedGroupId === groupId) {
                                    for (let k = 0; k < Object.values(attribute)[0].length; k++) {
                                        let values = Object.values(attribute)[0][k];
                                        if (values && Object.keys(values) && Object.keys(values)[0]) {
                                            if (Object.keys(values)[0] === "S") {
                                                status = Object.values(values)[0];            
                                            } else if (Object.keys(values)[0] === "P") {
                                                sitePermissions = Object.values(values)[0];
                                                break;
                                            }
                                        }
                                    }
                                    break;
                                }
                                //console.log("SITE: " + site + " GROUP ID: " + groupId + " STATUS: " + status + " PERMISSIONS: " + JSON.stringify(sitePermissions));
                            } else {
                                let status = Object.values(attribute)[0];
                                //console.log("SITE: " + site + " STATUS: " + status);
                            }
                        }
                        break;
                   }
                }
                return sitePermissions;            
            }    

            async processLogin() {

                var parameters = this.getJsonFromUrl(window.location);
                var magicUrl = false;
                var lempgUserType = "LEMPG_ACCESS";
                var isUserInLempgChiefOfStaffPosition;

                /**
                 * To allow a user to login into palmetto we need their access_token, user_id, group_id  and narrative_id. 
                 * This would give us a ids to get right information based on counties and narrative data
                 * 
                 */

                 if (parameters["id_token"]) {
                    const headers = {
                        'Authorization': parameters["id_token"],
                    };
                    try {
                        let tokenVerification = await axios.get('https://portal.palmettoeoc.com/prod/public/api/cognito/account', { headers });
                        if (tokenVerification && tokenVerification.data) {
                            //parameters["user_id"] = tokenVerification.data.id;
                            //parameters["access_token"] =  tokenVerification.data.accessToken;
                            //parameters["group_id"] =  tokenVerification.data.pvActiveGroupID;
                            let permissions = this.getPermissionsForSite('LEMPG', tokenVerification.data.pvActiveGroupID, tokenVerification.data.attributes);
                            //console.log(JSON.stringify(tokenVerification.data.attributes));

                            // eslint-disable-next-line default-case
                            switch (permissions && permissions[0]) {
                                case 'AC':
                                    lempgUserType = "LEMPG_ACCESS";
                                    break;
                                case 'CE':
                                    lempgUserType = "LEMPG_CERT";
                                    break;
                                case 'CA':
                                    lempgUserType = "LEMPG_STATE_ACCESS";
                                    isUserInLempgChiefOfStaffPosition = true;
                                    break;
                                case 'FEMA':
                                    lempgUserType = "LEMPG_FEMA";
                                    break;
                                case 'R1':
                                    lempgUserType = "LEMPG_REGION_1";
                                    break;                                             
                                case 'R2':
                                    lempgUserType = "LEMPG_REGION_2";
                                    break;    
                                case 'R3':
                                    lempgUserType = "LEMPG_REGION_3";
                                    break;                                             
                                case 'R4':
                                    lempgUserType = "LEMPG_REGION_4";
                                    break;    
                                case 'R5':
                                    lempgUserType = "LEMPG_REGION_5";
                                    break;                                             
                                case 'R6':
                                    lempgUserType = "LEMPG_REGION_6";
                                    break;    
                                case 'SL':
                                    lempgUserType = "LEMPG_SLED";
                                    break;    
                                case 'SM':
                                    lempgUserType = "LEMPG_SME";
                                    break;                                             
                                case 'ST':
                                    lempgUserType = "LEMPG_ACCESS_STATE";
                                    break;                                                                                                                                                      
                            }
                            //console.log(JSON.stringify(Object.keys(tokenVerification.data.attributes)));
                            try {
                                //let verification = await axios.get(process.env.REACT_APP_PALMETTO_ENDPOINT + "/api/accounts/" + 
                                //tokenVerification.data.id + "/accessTokens/" + tokenVerification.data.accessToken + "?access_token=" + tokenVerification.data.accessToken);
                                let userAuthCreds = {
                                    id: tokenVerification.data.accessToken,
                                    userId: tokenVerification.data.id
                                };
                                sessionStorage.setItem("userAuthCreds", JSON.stringify(userAuthCreds));
                                magicUrl = parameters;
                            }  catch (e) {
                                    console.log('Error occured', e)
                                    return;
                            }
                        }
                    }
                    catch (e) {
                        console.log('Error occured', e)
                        return;
                    }
                } else if (parameters["access_token"] && parameters["user_id"] && parameters["group_id"]) {
                    try {
                        let tokenVerification = await axios.get(process.env.REACT_APP_PALMETTO_ENDPOINT + "/api/accounts/" + parameters["user_id"] + "/accessTokens/" + parameters["access_token"] + "?access_token=" + parameters["access_token"])
                        sessionStorage.setItem("userAuthCreds", JSON.stringify(tokenVerification.data));
                        magicUrl = parameters;
                    }
                    catch (e) {
                        console.log('Error occured', e)
                        return;
                    }
                }

                if (sessionStorage.getItem("userAuthCreds")) {
                    // check the validity of the token, if valid, show the app, if not, get the show the login screen
                    try {
                        let authObj = JSON.parse(sessionStorage.getItem("userAuthCreds"));
                        var filter = {
                            "where": {
                                "id": authObj.userId
                            },
                            "include":
                                [
                                    {
                                        "relation": "account2positions",
                                        "scope": {
                                            "include": {
                                                "relation": "positions"
                                            }
                                        }
                                    },
                                    {
                                        "relation": "account2groups"
                                    }
                                ]
                        };

                        /**
                         * Query palmetto for user account data + settings. The account information (userid + username) will be stored with every record.
                         * The data we will get is account data (group + positions), settings mainly (selectedgroup).
                         * The user accounts under ONE of the following positions will get access
                         * 1.  LEMPG State Access
                         * 2.  LEMPG Access
                         * 4.  LEMPG Region 1
                         * 5.  LEMPG Region 2
                         * 6.  LEMPG Region 3
                         * 7.  LEMPG Region 4
                         * 8.  LEMPG Region 5
                         * 9.  LEMPG Region 6
                         * 10. LEMPG SME
                         * 10. LEMPG CERT
                         *
                         * Note that users under > 1 above listed positions will be NOT ALLOWED to log in. There is no priority level amongst these positions. An error message will be thrown for the same.
                         * 
                         */
                        // LEMPG CERT position will alter the progress reporting state UI to show counties that has CERT checkbox selected in grant application
                        let userAccountObjRes = await axios.get(process.env.REACT_APP_PALMETTO_ENDPOINT + "/api/accounts/fetchData?access_token=" + authObj.id + "&filter=" + JSON.stringify(filter));
                        let userAccountObj = userAccountObjRes.data
                        var selectedGroup = null;
                        var lempgPosition = {};

                        for (var i = 0; i < userAccountObj.settings.length; i++) {
                            if (userAccountObj.settings[i].pvSettingType === "selectedGroup") {
                                selectedGroup = parseInt(userAccountObj.settings[i].pvSettingValue);
                            }
                        }
                        // Check if the user is the part of selected group or not
                        var selectedGroupFound = false;
                        userAccountObj.accountInfo.account2groups.forEach((group) => {
                            if (group.pvGroupID === selectedGroup) {
                                selectedGroupFound = true;
                            }
                        })
                        // This is to prevent a case where if the user has no selected group (meaning) he is in a single group account, grant him access
                        if (!selectedGroupFound && userAccountObj.accountInfo.account2groups.length === 1) {
                            selectedGroupFound = true;
                            selectedGroup = userAccountObj.accountInfo.account2groups[0].pvGroupID;
                        }
                        if (selectedGroupFound) {
                            if (!parameters["id_token"]) {
                                // Now search for positions
                                const allowedLEMPGPositions = [
                                    "LEMPG State Access",
                                    "LEMPG Access",
                                    "LEMPG SME",
                                    "LEMPG Region 1",
                                    "LEMPG Region 2",
                                    "LEMPG Region 3",
                                    "LEMPG Region 4",
                                    "LEMPG Region 5",
                                    "LEMPG Region 6",
                                    "LEMPG CERT",
                                    "LEMPG SLED"
                                ];
                                var lempgPositionCount = 0;

                            
                                userAccountObj.accountInfo.account2positions.forEach((position) => {
                                    if (position.positions && (position.positions.pvGroupID === selectedGroup)) {
                                        selectedGroupFound = true;
                                        if (allowedLEMPGPositions.indexOf(position.pvPositionName) >= 0) {
                                            lempgPositionCount++;
                                            lempgPosition = JSON.parse(JSON.stringify(position));
                                        }
                                        if (position.pvPositionName === 'LEMPG Chief of Staff') {
                                            isUserInLempgChiefOfStaffPosition = true;
                                        }

                                    }
                                });
                                // Check if lempgPositionCount === 1, allow user to login, else throw an error state
                                if (lempgPositionCount) {
                                    if (lempgPositionCount === 1) {
                                        switch (lempgPosition.pvPositionName) {
                                            case "LEMPG State Access":
                                                lempgUserType = "LEMPG_STATE_ACCESS"
                                                break
                                            case "LEMPG Access":
                                                lempgUserType = "LEMPG_ACCESS"
                                                break
                                            case "LEMPG SME":
                                                lempgUserType = "LEMPG_SME"
                                                break
                                            case "LEMPG Region 1":
                                                lempgUserType = "LEMPG_REGION_1"
                                                break
                                            case "LEMPG Region 2":
                                                lempgUserType = "LEMPG_REGION_2"
                                                break
                                            case "LEMPG Region 3":
                                                lempgUserType = "LEMPG_REGION_3"
                                                break
                                            case "LEMPG Region 4":
                                                lempgUserType = "LEMPG_REGION_4"
                                                break
                                            case "LEMPG Region 5":
                                                lempgUserType = "LEMPG_REGION_5"
                                                break
                                            case "LEMPG Region 6":
                                                lempgUserType = "LEMPG_REGION_6"
                                                break
                                            case "LEMPG CERT":
                                                lempgUserType = "LEMPG_CERT"
                                                break
                                            case "LEMPG SLED":
                                                lempgUserType = "LEMPG_SLED"
                                                break
                                            default:
                                        }
                                    //console.log("accountInfo:" + JSON.stringify(userAccountObj.accountInfo));
                                    } else {
                                        // More than one position. Cannot login. Throw an error msg
                                        sessionStorage.removeItem("userAuthCreds");
                                        this.setState({
                                            "errorOccured": true
                                        });
                                        this.setState({
                                            "errorMessage": "Looks like you are in multiple LEMPG positions under your selected group. Contact any Palmetto Administrator to resolve this issue."
                                        });
                                    }
                                } else {
                                    // No LEMPG Position found for selected group
                                    sessionStorage.removeItem("userAuthCreds");
                                    this.setState({
                                        "errorOccured": true
                                    });
                                    this.setState({
                                        "errorMessage": "We did not find any LEMPG positions associated with your selected group. Contact any Palmetto Administrator for access."
                                    });
                              
                                }
                            }
                            if (!this.state.errorOccured) {
                                let defaultInfoPayload = {
                                    "settings": userAccountObj.settings,
                                    "selectedGroupID": selectedGroup,
                                    "selectedLEMPGPositionObj": lempgPosition,
                                    "accountInfo": userAccountObj.accountInfo,
                                    "lempgUserType": lempgUserType,
                                    "isUserInLempgChiefOfStaffPosition": isUserInLempgChiefOfStaffPosition,
                                };
                                this.props.setDefaultInfo(defaultInfoPayload, magicUrl);
                                this.setState({ "loginSuccessful": true });
                            }
                        } else {
                            this.setState({
                                "errorOccured": true
                            });
                            this.setState({
                                "errorMessage": "No selected group found. Either this is a new account or you were removed from your previously selected group. Login into palmetto to solve this problem."
                            });
                        }                       
                    } catch (err) {
                        console.warn("An error occured", err);
                    }
                }
            }

            async componentDidMount() {
                // probaby check here if token exists in local storage or not

                // This can probably go into Redux. Keep it here for now. If we use amplify's `withAuthenticator` HOC we wont need that anyways.
                this.processLogin();
            }

            constructor(props, context) {
                super(props, context);

                this.processLogin = this.processLogin.bind(this);
            }


            render() {
                return (
                    <>
                        {
                            this.state.loginSuccessful ? <PassedComponent {...this.props} /> : this.state.errorOccured ? <LoginErrorMsg error={this.state.errorOccured} message={this.state.errorMessage}  {...this.props} /> : <Login processLogin={this.processLogin} />
                        }
                    </>

                )
            }
        }

    )

    return connect(mapStateToProps, mapDispatchToProps)(Wrapper());
}

export { withPalmettoAuth }
