import axios from 'axios';
import uuid from "uuid";
import { GetDrawerItems } from "../other/DrawerItems";

import { API, graphqlOperation } from "aws-amplify";
import aws_config from '../../aws-exports';
import moment from 'moment';

import * as mutations from '../../graphql/mutations';
import { updateGrantApplication } from "../mutations/updateGrantApplication";
import { updateFinancialReimbursement } from "../mutations/updateFinancialReimbursement";
import * as queries from '../../graphql/queries';
import { sendEmail } from './EmailRecords';
import { GetGroupIds } from '../queries/GetGroupIds';


// Local Actions
import * as Salary from "./SalaryAction";
import * as ContractualService from "./ContractualServiceAction";
import * as Travel from "./TravelAction";
import * as Other from "./OtherAction";
import * as Cert from "./CertAction";
import * as Supply from "./SupplyAction";
import * as Equipment from "./EquipmentAction";
import { isInteger, getFormattedNumberForPrint } from "../../components/utilities/NumberFormat";
import { put as S3FileUploadPut, getFileUploadedLinkFromBucket } from "../../components/common/FileUploadApi";

//subscriptions
import { subscriptionInit } from "../other/Subscriptions";

// Other

import { generateHistoryComments, generateDetailedHistory } from "../other/ActivityFeedProcessor";

// The reducer will take this `AUTHENTICATED_USER` string and determine the next state
import { 
    AUTHENTICATED_USER, 
    SET_USER_SETTINGS, 
    SET_SELECTED_GROUP, 
    SET_LEMPG_POSITION, 
    SET_USER_ACCOUNT_INFO, 
    LEMPG_USER_TYPE,
    SIDE_DRAWER_ITEMS,
    SET_COUNTIES_FOR_STATE_USER,
    SET_SELECTED_COUNTY,
    SET_GRANT_APPLICATION,
    CURRENT_APPLICATION_STATE,
    APPLICATION_LOADING_STATE,
    APP_LOADING_STATE,
    CURRENT_QUARTER,
    CURRENT_QUARTER_ITEM,
    CURRENT_NARRATIVE,
    CURRENT_GROUP_NARRATIVE_M2M,
    FILES_UPLOADED_BY_COUNTY,    
    IS_TASKING_SLIP_GENERATED_FOR_QUARTER,
    PROGRESS_REPORTING_QUARTER,
    GET_AWARD_AMOUNT_FOR_COUNTIES,
    ACTIVITY_FEED_HISTORY,
    ACTIVITY_FEED_COMMENTS,
    ACTIVITY_FEED_HISTORY_COMMENTS,
    ACTIVITY_FEED_FIELD_TITLE_REFERENCE,
    ACTIVITY_FEED_FIELD_VALUE_REFERENCE,
    SELECTED_QUARTER_FINANCIAL, 
    SELECTED_QUARTER_PROGRESS,
    CURRENT_EXTENSION,
    ALL_EXTENSIONS,
    PROGRESS_REPORTING_STATE_COUNTIES_TABLE_SORT,
    PROGRESS_REPORTING_STATE_COUNTY_TABLE_SORT,
    PROGRESS_REPORTING_COUNTY_TABLE_SORT,
    FINANCIAL_REIMBURSEMENT_STATE_COUNTIES_TABLE_SORT,
    SET_SELECTED_YEAR,
    SELECTED_YEAR_OBJECT,
    SET_FISCAL_YEARS,
    CURRENT_FISCAL_YEAR,
    PRE_GRANT_SELECTED_COUNTY,
    SELECTED_QUARTER_PRE_FISCAL_YEAR,
    ALL_NARRATIVES,
    CURRENT_MONITORING_GRANT_INFORMATION_PAGE,
    CURRENT_MONITORING_SITE_VISIT_REVIEW,    
    CURRENT_REM_CONTACT,
    ALL_REM_CONTACTS,    

} from "../constants/action-types";

// Graphql Queries
import { GetGroups } from "../queries/GetGroups";
import { GetGroupsNarrativesM2M } from "../queries/GetGroupNarrativesM2M";
import { GetGrantApplication } from "../queries/GetGrantApplication";
import { GetFinancialReimbursement } from "../queries/GetFinancialReimbursement";

// Import narratives data
import NarrativeData from "../other/NarrativeData";
import { REMContactInfo } from "../other/ProgressReportingREMContactInfo";

export function getAuthenticatedUserInfo(payload) {
    return { type: AUTHENTICATED_USER, payload };
}

export function getAppConfiguration(payload) {
    return async function (dispatch) {
    }
}

async function getFiscalYearSettings(dispatch, getState) {
    return new Promise(async (resolve, reject) => {
        
        const { user } = getState().rootReducer;
    
        let fiscalYearRes = await API.graphql(graphqlOperation(queries.listFiscalYears))
        dispatch({ type: SET_FISCAL_YEARS, payload: fiscalYearRes.data.listFiscalYears.items });
    
        // Get selected-year setting from the settings table
    
        // let settingsRes = await API.graphql(graphqlOperation(queries.listSettings, {
        //     filter: {
        //         and: [
        //             {
        //                 type: {
        //                     eq: "selected-year"
        //                 }
        //             },
        //             {
        //                 userId: {
        //                     eq: user.id + ""
        //                 }
        //             }
        //         ]
        //     }
        // }))

        let authObj = JSON.parse(sessionStorage.getItem("userAuthCreds"));
        let palmettoSettingsFilter = {
            where: {
                and: [
                    {

                        pvSettingType: "supplemental-system-selected-year",
                    },
                    {
                        pvAccountID: user.id,
                    }
                ]
            }
        }

        let settingsRes = await axios.get(process.env.REACT_APP_PALMETTO_ENDPOINT + "/api/settings?access_token=" + authObj.id + "&filter=" + JSON.stringify(palmettoSettingsFilter))        

        // Default year if the setting is not found
        let current_year = "2020";

        console.log("setting response from palmetto", settingsRes)
    
        if (settingsRes.data && settingsRes.data.length) {

            // Sort by last updated at
            // Added these in case there are duplicate settings
            settingsRes.data.sort( (a, b) => {
                return new Date(b.pvEntryDate) - new Date(a.pvEntryDate);
            });

            current_year = settingsRes.data[0].pvSettingValue || "2020";
            dispatch({ type: SELECTED_YEAR_OBJECT, payload: settingsRes.data[0] });
        }

        let yearObjInFiscalYear = {};

        if (fiscalYearRes.data 
        && fiscalYearRes.data.listFiscalYears 
        && fiscalYearRes.data.listFiscalYears.items
        && fiscalYearRes.data.listFiscalYears.items.length) {
            fiscalYearRes.data.listFiscalYears.items.forEach( (item) => {
                if(item.year == current_year) {
                    yearObjInFiscalYear = item;
                }
            })
        }


        dispatch({ type: SET_SELECTED_YEAR, payload: current_year });
        dispatch({ type: CURRENT_FISCAL_YEAR, payload: yearObjInFiscalYear });

        resolve(yearObjInFiscalYear)
    })

}

// This is the first function that gets called after user first logs in

export function setDefaultInfo(payload, urlParams) {
    return async function (dispatch, getState) {

        // Calculate side drawer items based on user type
        dispatch({ type: APP_LOADING_STATE, payload: true });
        dispatch({ type: SET_USER_ACCOUNT_INFO, payload: payload.accountInfo });
        dispatch({ type: LEMPG_USER_TYPE, payload: payload.lempgUserType});
        
        let yearInfo = await getFiscalYearSettings(dispatch, getState);
        dispatch({ type: SIDE_DRAWER_ITEMS, payload: GetDrawerItems(payload.lempgUserType, yearInfo)})
        
        
        dispatch({ type: SET_USER_SETTINGS, payload: payload.settings });
        dispatch({ type: SET_SELECTED_GROUP, payload: payload.selectedGroupID });
        dispatch({ type: SET_LEMPG_POSITION, payload: payload.selectedLEMPGPositionObj });
        
        let implAgencyRes = await axios.get(process.env.REACT_APP_PALMETTO_ENDPOINT + "/api/implementingagencies");
        
        dispatch({ type: GET_AWARD_AMOUNT_FOR_COUNTIES, payload: implAgencyRes.data });
        
        subscriptionInit(dispatch, getState);
        
        // if the user is a state user, get the right data
        if (payload.lempgUserType != "LEMPG_ACCESS") {           
            getCounties(dispatch, getState, payload.lempgUserType, payload.accountInfo, implAgencyRes, async function() {
                if(urlParams) {
                    // Select county, select narrative

                    /**
                     * We need to replicate what the below functions does. Calling them directly will result in diffcult user experience since spinner will go on and off
                     * 1. getCountyGroupNarrativeDataM2M
                     * 2. getNarrativeAndGroupNarrativeM2MData
                     * 
                     * 
                     * We need to set following
                     * 
                     * 1. SET_SELECTED_COUNTY -  This should be fairly easy since we are setting counties in the callback of this county
                     * 2. PROGRESS_REPORTING_QUARTER
                     */


                    const { counties, allREMContacts } = getState().rootReducer;
                    let selectedCounty = null
                     // Get county by looping through counties object and matching it by ID
                    counties.forEach((county, index) => {
                        if (county.id === urlParams["group_id"]) {
                            selectedCounty = county;
                            dispatch({ type: SET_SELECTED_COUNTY, payload: county });
                        }
                    })

                    if (urlParams.type === "rem" && urlParams.quarter) {
                        // Take the user to the Generate Tasking Slip Screen
                        dispatch({ type: PROGRESS_REPORTING_QUARTER, payload: parseInt(urlParams.quarter) + 1 });
                        window.location.hash = "#/progressreport/county/taskingslip";

                    } else if(urlParams.type === "fr_extension") {

                        if (selectedCounty.financialreimbursement && selectedCounty.financialreimbursement.items) {
                            let found = false;
                            selectedCounty.financialreimbursement.items.forEach((fr) => {
                                if (fr.id === urlParams.quarter) {
                                    found = true;
                                    dispatch({type: CURRENT_QUARTER, payload: fr});
                                    dispatch({type: CURRENT_EXTENSION, payload: fr.currentExtension});
                                }
                            });
                            // Prevent a crash if the path is malformed
                            if (found) {
                                window.location.hash ="#/extensions/financialreimbursements/view";
                            }
                        }
                    } else if (urlParams["narrative_id"]) {
                        let appSyncFilter = {
                            id: urlParams["narrative_id"]
                        };
    
                        const res = await API.graphql(graphqlOperation(queries.getGroupNarrativeM2M, appSyncFilter));
    
                        // Update below narratives contact info 
                        if(res.data.getGroupNarrativeM2M.narrative.usePalmettoForREMInformation) {
                            allREMContacts.forEach((contact) => {
                                if(contact.region == selectedCounty.region) {
                                    res.data.getGroupNarrativeM2M.narrative.narrativePOCFirstName = contact.firstName;
                                    res.data.getGroupNarrativeM2M.narrative.narrativePOCLastName = contact.lastName;
                                    res.data.getGroupNarrativeM2M.narrative.narrativePOCPhoneNumber = contact.phoneNumber;
                                    res.data.getGroupNarrativeM2M.narrative.narrativePOCEmailAddress = contact.emailAddress;
                                    res.data.getGroupNarrativeM2M.narrative.narrativePOCPositionTitle = contact.positionTitle;
                                }
                            })
                        } else {
                            let specialNarratives = {
                                "M-113": 1,
                                "M-107": 1,
                                "M-108": 1,
                            }
                            if ((specialNarratives[res.data.getGroupNarrativeM2M.narrative.narrativeTitle]) && selectedCounty) {
        
                                let narrativeTitle = res.data.getGroupNarrativeM2M.narrative.narrativeTitle;
        
                                let contactInfo = REMContactInfo[narrativeTitle][selectedCounty.region]
                                Object.keys(contactInfo).forEach((key) => {
                                    res.data.getGroupNarrativeM2M.narrative[key] = contactInfo[key];
                                })
                            }
                                
                        }
    
    
                        dispatch({ type: CURRENT_NARRATIVE, payload: JSON.parse(JSON.stringify(res.data.getGroupNarrativeM2M.narrative)) });
                        dispatch({ type: CURRENT_GROUP_NARRATIVE_M2M, payload: JSON.parse(JSON.stringify(res.data.getGroupNarrativeM2M)) });
    
                        window.history.replaceState(null, null, window.location.pathname);
    
                        window.location.hash ="#/progressreport/county/narrative";
                    }
                }

                dispatch({ type: APP_LOADING_STATE, payload: false });
            })
        } else {
            
            // Third parameter is `counties`

            getCountyData(dispatch, getState, payload.selectedGroupID, undefined,  function () {
                dispatch({ type: APP_LOADING_STATE, payload: false });
            })
        }

    }
}

function getPalmettoGroupID(groups, county) {
    let groupId = null
    groups.forEach((group) => {
        if(group.pvGroupName.toLowerCase() == county) {
            groupId = group.pvGroupID;
        }
    })

    return groupId;
}

function getQuarterDates(quarter) {
    if (!quarter) return

    let obj = {}
    obj.quarter1 = { start: moment("01-01-2019", "MM-DD-YYYY").month(6).startOf('month').format("MM-DD-YYYY"), end: moment("01-01-2019", "MM-DD-YYYY").month(8).endOf('month').toDate()}
    obj.quarter2 = { start: moment("01-01-2019", "MM-DD-YYYY").month(9).startOf('month').format("MM-DD-YYYY"), end: moment("01-01-2019", "MM-DD-YYYY").month(11).endOf('month').toDate()}
    obj.quarter3 = { start: moment("01-01-2019", "MM-DD-YYYY").month(0).startOf('month').add('years', 1).format("MM-DD-YYYY"), end: moment("01-01-2019", "MM-DD-YYYY").month(2).endOf('month').add('years', 1).toDate()}
    obj.quarter4 = { start: moment("01-01-2019", "MM-DD-YYYY").month(3).startOf('month').add('years', 1).format("MM-DD-YYYY"), end: moment("01-01-2019", "MM-DD-YYYY").month(5).endOf('month').add('years', 1).toDate()}

    return obj[quarter];
}

export function getCountyAction (payload, callback) {
    return async function(dispatch, getState) {
        const { counties } = getState().rootReducer;
        getCountyData(dispatch, getState, payload.selectedGroupID, counties,  callback)

    }
} 

// TODO: Check on this function, dont know why but it fails sometimes getting correct requests

async function getCountyData(dispatch, getState, palmettoGroupID, counties,  callback) {

    let countyRes = null;
    const { selectedYear } = getState().rootReducer;
    const current_year = selectedYear;

    if(current_year == '2021') {
        window.appHeaderTitle = 'LEMPG ARPA FY2021'
    } else {
        window.appHeaderTitle = `Supplemental System FY${current_year}`
    }    

    if (counties && counties.length > 0) {

        counties.forEach( (county) => {
            if(county.palmettoId == palmettoGroupID) {
                countyRes = county;
            }
        })

    } else {

        const groupsResponseFromAppSync = await API.graphql(graphqlOperation(GetGroups, {
            filter: {
                and: [
                    {
                        year: {
                            eq: current_year
                        }
                    },
                    {
                        palmettoId: {
                            eq: "" + palmettoGroupID
                        }
                    }
                ]

            },
            limit: 200
        }));

        groupsResponseFromAppSync.data.listGroups.items.forEach((county) => {
            if (county.palmettoId == palmettoGroupID) {
                countyRes = county;
            }
        })
    }

    if(countyRes) {
        dispatch({ type: SET_SELECTED_COUNTY, payload: countyRes});
        dispatch({ type: CURRENT_MONITORING_SITE_VISIT_REVIEW, payload: countyRes.monitoringsitevisitreview});
        dispatch({ type: CURRENT_MONITORING_GRANT_INFORMATION_PAGE, payload: countyRes.monitoringgrantinformation});
        await dispatch(getAllRemContacts())

        if(callback) {
            callback()
        }

    } else {
        console.warn("Throw an error state");
    }
    
}

async function getCounties(dispatch, getState, lempgUserType, accountInfo, implAgencyRes, callback) {

    // Get fiscal years

    const { selectedYear } = getState().rootReducer;
    const current_year = selectedYear;

    if(current_year == '2021') {
        window.appHeaderTitle = 'LEMPG ARPA FY2021'
    } else {
        window.appHeaderTitle = `Supplemental System FY${current_year}`
    }    

    // Decide which splash screen we have to display to counties
    // Before Quarter 1 of the new fiscal year display Pre-award dashboard
    // From Quarter 1 display post-award dashboard

    // When state starts 2020 the dashboard will switch to Pre-award dashboards until Quater 1 starts at which point we will display Post award dashboards

    // Before a new fiscal year is started state will see Pre fiscal year dashboard 

    var region_num = parseInt(lempgUserType.split("").reverse().join(""));

    var appSyncFilter = {};

    console.log(implAgencyRes)

    let allCounties = []

    if (lempgUserType.indexOf("REGION") >= 0) {
        appSyncFilter = {
            and: [
                {
                    region: {
                        eq: "R" + region_num
                    }
                },
                {
                    year: {
                        eq: current_year
                    }
                }
            ]
            
        };


    } else {

        appSyncFilter = {
            year: {
                eq: current_year
            }
        }
        
    }

    let groupIds = await API.graphql(graphqlOperation(GetGroupIds, {
         filter: appSyncFilter,
         limit: 1000
    }))

    console.log(groupIds)

    let calls = []

    if(groupIds && groupIds.data && groupIds.data.listGroups && groupIds.data.listGroups.items) {
        

        // this algorithm is for getting data in chunks so it doesnot get lost

        // for a batch of 5 it gets data like this

        // starting batchcount with startIndex 0, endIndex 10 and filter limit 100
        // starting batchcount with startIndex 10, endIndex 20 and filter limit 200
        // starting batchcount with startIndex 20, endIndex 30 and filter limit 300
        // starting batchcount with startIndex 30, endIndex 40 and filter limit 400
        // starting batchcount with startIndex 40, endIndex 46 and filter limit 500

        let totalBatch = 5;
        let batchCount = 1;
        let MAX_LIMIT = groupIds.data.listGroups.items.length

        let requestCount = MAX_LIMIT % totalBatch === 0 ? parseInt(MAX_LIMIT / totalBatch) : (parseInt(MAX_LIMIT / totalBatch) + (MAX_LIMIT % totalBatch) )

        while(batchCount <= totalBatch) {

            
            let startIndex = (batchCount - 1) * requestCount;
            let endIndex = startIndex + requestCount >= MAX_LIMIT ? MAX_LIMIT :  startIndex + requestCount;

            if(startIndex > endIndex) break

            console.log(`starting batchcount with startIndex ${startIndex}, endIndex ${endIndex} and filter limit ${150*batchCount}`)

            for (var i = startIndex; i < endIndex; i++) {

                var item = groupIds.data.listGroups.items[i];
                appSyncFilter = {
                    id: {
                        eq: item.id
                    }
                };
        
                calls.push(API.graphql(graphqlOperation(GetGroups, {
                    filter: appSyncFilter,
                    limit: 150 * batchCount
                })))
            }
    
    
            let d = await Promise.all(calls);
    
            d.forEach( (item) => {
                allCounties.push(item.data.listGroups.items[0])
            })

            calls = []
            batchCount++;
        }

    }

    console.log(allCounties)

    dispatch(getFiscalYearNarratives())
    await dispatch(getAllRemContacts())

    allCounties.sort((a, b) => {
        return a.groupName.localeCompare(b.groupName);
    });

    dispatch({ type: SET_COUNTIES_FOR_STATE_USER, payload: allCounties });

    // Fix narrative recurring issue
    // let statusObj = {};
    // let funcsFix = []

    // allCounties.forEach((county) => {
    //     if(county.narratives && county.narratives.items && county.narratives.items.length) {
    //         county.narratives.items.forEach((item) => {
    //             if(item.narrative.narrativeTitle === 'S-104') {
    //                 statusObj[item.status] = statusObj[item.status] || [];
    //                 statusObj[item.status].push(item)
    //             }
    //         })
    //         console.log(county.groupName, statusObj)
    //         if(statusObj['DRAFT']) {
    //             // figure out later
    //             let approvedNarratives = (statusObj['APPROVED'])  || [];
    //             let incompleteNarratives = (statusObj['INCOMPLETE']) || [];
    //             let draftNarratives = (statusObj['DRAFT']) || [];
    //             let submittedNarratives = (statusObj['SUBMITTED']) || [];

    //             let start = 1;


    //             for(let i = 0; i < approvedNarratives.length; i++) {

    //                 console.log(`Moving ${county.groupName} approved narrative to quarter${start}`)

    //                 let asyncOperation = API.graphql(graphqlOperation(mutations.updateGroupNarrativeM2M, {
    //                     input: {
    //                         id: approvedNarratives[i].id,
    //                         quarter: `quarter${start}`,
    //                     }
    //                 }))
    //                 funcsFix.push(asyncOperation)

    //                 start++
    //             }

    //             for(let i = 0; i < submittedNarratives.length; i++) {

    //                 console.log(`Moving ${county.groupName} draft narrative to quarter${start}`)

    //                 let asyncOperation = API.graphql(graphqlOperation(mutations.updateGroupNarrativeM2M, {
    //                     input: {
    //                         id: submittedNarratives[i].id,
    //                         quarter: `quarter${start}`,
    //                     }
    //                 }))
    //                 funcsFix.push(asyncOperation)

    //                 start++
    //             }

    //             for(let i = 0; i < draftNarratives.length; i++) {

    //                 console.log(`Moving ${county.groupName} draft narrative to quarter${start}`)

    //                 let asyncOperation = API.graphql(graphqlOperation(mutations.updateGroupNarrativeM2M, {
    //                     input: {
    //                         id: draftNarratives[i].id,
    //                         quarter: `quarter${start}`,
    //                     }
    //                 }))
    //                 funcsFix.push(asyncOperation)

    //                 start++
    //             }



    //             for(let i = 0; i < incompleteNarratives.length; i++) {

    //                 console.log(`Moving ${county.groupName} incomplete narrative to quarter${start}`)
    //                 let asyncOperation = API.graphql(graphqlOperation(mutations.updateGroupNarrativeM2M, {
    //                     input: {
    //                         id: incompleteNarratives[i].id,
    //                         quarter: `quarter${start}`,
    //                     }
    //                 }))
    //                 funcsFix.push(asyncOperation)


    //                 start++
    //             }


    //             console.log('  ')

    //         } else {
    //             let approvedNarratives = (statusObj['APPROVED'])  || [];
    //             let incompleteNarratives = (statusObj['INCOMPLETE']) || [];
    //             let submittedNarratives = (statusObj['SUBMITTED']) || [];

    //             let start = 1;
    //             for(let i = 0; i < approvedNarratives.length; i++) {

    //                 console.log(`Moving ${county.groupName} approved narrative to quarter${start}`)

    //                 let asyncOperation = API.graphql(graphqlOperation(mutations.updateGroupNarrativeM2M, {
    //                     input: {
    //                         id: approvedNarratives[i].id,
    //                         quarter: `quarter${start}`,
    //                     }
    //                 }))
    //                 funcsFix.push(asyncOperation)

    //                 start++
    //             }

    //             for(let i = 0; i < submittedNarratives.length; i++) {

    //                 console.log(`Moving ${county.groupName} draft narrative to quarter${start}`)

    //                 let asyncOperation = API.graphql(graphqlOperation(mutations.updateGroupNarrativeM2M, {
    //                     input: {
    //                         id: submittedNarratives[i].id,
    //                         quarter: `quarter${start}`,
    //                     }
    //                 }))
    //                 funcsFix.push(asyncOperation)

    //                 start++
    //             }                

    //             for(let i = 0; i < incompleteNarratives.length; i++) {

    //                 console.log(`Moving ${county.groupName} incomplete narrative to quarter${start}`)
    //                 let asyncOperation = API.graphql(graphqlOperation(mutations.updateGroupNarrativeM2M, {
    //                     input: {
    //                         id: incompleteNarratives[i].id,
    //                         quarter: `quarter${start}`,
    //                     }
    //                 }))
    //                 funcsFix.push(asyncOperation)


    //                 start++
    //             }

    //             console.log('  ')


    //         }
    //     }
    //     statusObj = {}
    // })


    // Promise.all(funcsFix)
    // .then(async (data) => {

    //     console.log('data fixed')

    // })
    // .catch(function (err) {
    //     console.log(err)
    // })
    // return


    // // This would be used to update groups model in prod and training and dev


    // window.addEventListener("create-fiscal-year", async (e) => {
    //     console.log("create-fiscal-year", e.detail)
    //     if (!e.detail.year || e.detail.availableToCounties === undefined) {
    //         console.error("year and availableToCounties fields are required")
    //         return
    //     }

    //     await API.graphql(graphqlOperation(mutations.createFiscalYear, {
    //         input: {
    //             year: e.detail.year,
    //             availableToCounties: e.detail.availableToCounties,
    //             createdAt: new Date(),
    //             updatedAt: new Date(),
    //             updatedBy: accountInfo.username   
    //         }
    //     }))
    //     console.log("created fiscal year entry for ", e.detail.year)
    // })

    // window.addEventListener("update-random-award-amounts", async (e) => {
    //     if (process.env.REACT_APP_NODE_ENV != "PRODUCTION") {
    //         // not prodcution
    //         console.log("NOT PRODUCTION")
    //         dispatch(updateCountiesWithRandomAwardAmount())
    //     }
    // })

    // window.addEventListener("update-random-narratives", async (e) => {
    //     if (process.env.REACT_APP_NODE_ENV != "PRODUCTION") {
    //         // not prodcution
    //         console.log("NOT PRODUCTION")
    //         dispatch(createRandomNarratives())
    //     }
    // })

    if (callback) {
        callback()
    }
}


/**
 * This function gets detailed county grant application information
 * 
 */

export function getCountyApplicationData(payload) {
    return async function (dispatch, getState) {

        const { selectedCounty, selectedYear } = getState().rootReducer;

        if (selectedCounty.grantapplication) {
            
            let appSyncFilter = {
                id: selectedCounty.grantapplication.items[0].id
            };

            dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

            const applicationRes = await API.graphql(graphqlOperation(GetGrantApplication, appSyncFilter));
    
            let implAgencyRes = await axios.get(process.env.REACT_APP_PALMETTO_ENDPOINT + "/api/implementingagencies");

            if (implAgencyRes && implAgencyRes.data) {
                implAgencyRes.data.forEach( (county) => {
                    if (county.pvCountyName.toLowerCase() === selectedCounty.groupName.toLowerCase()) {
                        applicationRes.data.getGrantApplication.address = applicationRes.data.getGrantApplication.address || county.pvAddress; applicationRes.data.getGrantApplication.city = applicationRes.data.getGrantApplication.city || county.pvCity; applicationRes.data.getGrantApplication.county = applicationRes.data.getGrantApplication.county || county.pvCountyName; applicationRes.data.getGrantApplication.state = applicationRes.data.getGrantApplication.state || county.pvState; applicationRes.data.getGrantApplication.zipcode = applicationRes.data.getGrantApplication.zipcode || county.pvZip; applicationRes.data.getGrantApplication.implementingAgency = applicationRes.data.getGrantApplication.implementingAgency || county.pvName;
                        applicationRes.data.getGrantApplication.phoneNumber = applicationRes.data.getGrantApplication.phoneNumber || county.pvPhone;
                        applicationRes.data.getGrantApplication.faxNumber = applicationRes.data.getGrantApplication.faxNumber || county.pvFax;
                        applicationRes.data.getGrantApplication.award = {};

                        // For 2019, we have to use award amount saved in implementing agencies

                        if(selectedYear == "2019") {
                            applicationRes.data.getGrantApplication.award.amount = parseInt(county.pvAwardAmount);
                            applicationRes.data.getGrantApplication.award.remaining = parseInt(county.pvAwardAmount);
                        } else {
                            applicationRes.data.getGrantApplication.award.amount = (selectedCounty.awardAmount && parseInt(selectedCounty.awardAmount)) || 0;
                            applicationRes.data.getGrantApplication.award.remaining = (selectedCounty.awardAmount && parseInt(selectedCounty.awardAmount)) || 0;
                        }

                        applicationRes.data.getGrantApplication.award.federal = 0;
                        applicationRes.data.getGrantApplication.award.county = 0;
                        applicationRes.data.getGrantApplication.countyNumber = county.pvCountyNumber;
                        applicationRes.data.getGrantApplication.congressionalDistrict = county.pvCongressionalDistrict;
                    }
                })
            }
          
            let applicationTypes = [ 'contractualService', 'equipment', 'other', 'salary', 'supply', 'travel']; 
            if (applicationRes.data.getGrantApplication.deductCertFromFederal) {
                applicationTypes.push('cert');
            }
            applicationTypes.forEach(function(type) {
               applicationRes.data.getGrantApplication[type].items.forEach(function(item) {
                    if (!item.deleted) {
                        applicationRes.data.getGrantApplication.award.county += 
                           (isInteger(item.countyInKind) ? parseInt(item.countyInKind) : 0) + 
                              (isInteger(item.county) ? parseInt(item.county) : 0);
                        applicationRes.data.getGrantApplication.award.federal += 
                          isInteger(item.federal) ? parseInt(item.federal) : 0;
                    }
                });
            });
            applicationRes.data.getGrantApplication.award.federal += 
                isInteger(applicationRes.data.getGrantApplication.fbFederalFunding) ?
                    parseInt(applicationRes.data.getGrantApplication.fbFederalFunding) : 0;
            applicationRes.data.getGrantApplication.award.county += 
                isInteger(applicationRes.data.getGrantApplication.fbCountyCashFunding) ?
                    parseInt(applicationRes.data.getGrantApplication.fbCountyCashFunding) : 0;
            applicationRes.data.getGrantApplication.award.county += 
                isInteger(applicationRes.data.getGrantApplication.fbCountyInkindFunding) ?
                    parseInt(applicationRes.data.getGrantApplication.fbCountyInkindFunding) : 0;

            applicationRes.data.getGrantApplication.award.remaining -= applicationRes.data.getGrantApplication.award.federal;
            dispatch({ type: CURRENT_APPLICATION_STATE, payload: JSON.parse(JSON.stringify(applicationRes.data.getGrantApplication))})
            dispatch({ type: SET_GRANT_APPLICATION, payload: applicationRes.data.getGrantApplication });

            dispatch({ type: APPLICATION_LOADING_STATE, payload: false });

        } else {
            console.warn("dispatch error state")
        }

    }
}

/**
 * This function sets temporary application form state
 * 
 */

export function setCurrentApplicationState(state) {
    return async function (dispatch, getState) {
        dispatch({ type: CURRENT_APPLICATION_STATE, payload: JSON.parse(JSON.stringify(state)) });
    }
}

/**
 * This function saves the current grant application
 * 
 */

export function saveApplication(callback) {
    return async function (dispatch, getState) {
        const { grantApplication, currentApplicationState, user } = getState().rootReducer;

        let appSyncFilter = {
            input: {
                id: grantApplication.id,
                salaryNarrative: currentApplicationState.salaryNarrative || null,
                fbFederalFunding: currentApplicationState.fbFederalFunding || null,
                fbCountyCashFunding: currentApplicationState.fbCountyCashFunding || null,
                fbCountyInkindFunding: currentApplicationState.fbCountyInkindFunding || null,
                deductCertFromFederal: currentApplicationState.deductCertFromFederal || '',
                contractualServiceNarrative: currentApplicationState.contractualServiceNarrative || null,
                travelNarrative: currentApplicationState.travelNarrative || null,
                equipmentNarrative: currentApplicationState.equipmentNarrative || null,
                supplyNarrative: currentApplicationState.supplyNarrative || null,
                otherNarrative: currentApplicationState.otherNarrative || null,
                certNarrative: currentApplicationState.certNarrative || null,
                name: currentApplicationState.name || null,
                address: currentApplicationState.address || null,
                address2: currentApplicationState.address2 || null,
                city: currentApplicationState.city || null,
                state: currentApplicationState.state || null,
                county: currentApplicationState.county || null,
                zipcode: currentApplicationState.zipcode || null,
                latitude: currentApplicationState.latitude || null,
                longitude: currentApplicationState.longitude || null,
                enableReverseGeocoding: currentApplicationState.enableReverseGeocoding || null,
                implementingAgency: currentApplicationState.implementingAgency || null,
                phoneNumber: currentApplicationState.phoneNumber || null,
                faxNumber: currentApplicationState.faxNumber || null,
                updatedBy: user.username, 
                updatedAt: new Date(),
            }
        };

        
        let funcs = [];
        // let func = API.graphql(graphqlOperation(updateGrantApplication, appSyncFilter));
        // funcs.push(func);

        window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Starting new request ' } }))

        // Now update all the individual models
        Salary.saveSalaryAppSync(currentApplicationState.salary.items || [], grantApplication, user, funcs);
        ContractualService.saveContractualServiceAppSync(currentApplicationState.contractualService.items || [], grantApplication, user, funcs);
        Travel.saveTravelAppSync(currentApplicationState.travel.items || [], grantApplication, user, funcs);

        Supply.saveSupplyAppSync(currentApplicationState.supply.items || [], grantApplication, user, funcs);
        Other.saveOtherAppSync(currentApplicationState.other.items || [], grantApplication, user, funcs);
        Cert.saveCertAppSync(currentApplicationState.cert.items || [], grantApplication, user, funcs);
        Equipment.saveEquipmentAppSync(currentApplicationState.equipment.items || [], grantApplication, user, funcs);


        // After all the models are updated, update the grantApplication and reset the currentApplicationState

        Promise.all(funcs)
        .then(async (data) => {
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Application saved succesfully'}}));
            if(callback) {
                callback();
            }

            await API.graphql(graphqlOperation(updateGrantApplication, appSyncFilter))
            // Make a request to get new data
            dispatch(getCountyApplicationData());

        })
        .catch(function (err) {
            console.log(err)
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Error while saving the application' } }))
        })

    }
}

export function submitApplication(payload) {
    return async function(dispatch, getState) {
        const { grantApplication, currentApplicationState, user } = getState().rootReducer;
        let appSyncFilter = {
            input: {
                id: grantApplication.id,
                status: "SUBMITTED",
                updatedBy: user.username, 
                updatedAt: new Date(),
            }
        };

        let submittedRes = await API.graphql(graphqlOperation(updateGrantApplication, appSyncFilter));

        window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Application submitted succesfully' } }))

        // Make a request to get new data
        dispatch(getCountyApplicationData());
    }
}

export function awardApplication(payload) {
    return async function (dispatch, getState) {
        const { grantApplication, currentApplicationState, user } = getState().rootReducer;
        let appSyncFilter = {
            input: {
                id: grantApplication.id,
                status: "AWARDED",
                updatedBy: user.username, 
                updatedAt: new Date(),
            }
        };

        let submittedRes = await API.graphql(graphqlOperation(updateGrantApplication, appSyncFilter));

        window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Application awarded succesfully' } }))

        // Make a request to get new data
        dispatch(getCountyApplicationData());
    }
}

export function awardApplicationAndLinkRevision(payload) {
    return async function (dispatch, getState) {
        const { grantApplication, currentApplicationState, user } = getState().rootReducer;
        let appSyncFilter = {
            input: {
                id: grantApplication.id,
                status: "AWARDED",
                currentApplicationRevisionId: null, 
                updatedBy: user.username,
                updatedAt: new Date(),
            }
        };

        let submittedRes = await API.graphql(graphqlOperation(updateGrantApplication, appSyncFilter));

        appSyncFilter = {
            input: {
                id: grantApplication.currentApplicationRevisionId,
                grantApplicationGrantApplicationRevisionId: grantApplication.id,
                updatedBy: user.username,
                updatedAt: new Date(),
            }
        };

        let revisionRes = await API.graphql(graphqlOperation(mutations.updateGrantApplicationRevision, appSyncFilter));

        window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Application awarded succesfully' } }))

        // Make a request to get new data
        dispatch(getCountyApplicationData());
    }
}


export function approveApplication(payload) {
    return async function (dispatch, getState) {
        const { grantApplication, lempgUserType, user } = getState().rootReducer;
        let appSyncFilter = {
            input: {
                id: grantApplication.id,
                status: "APPROVED",
                updatedBy: user.username, 
                updatedAt: new Date(),
            }
        };

        let submittedRes = await API.graphql(graphqlOperation(updateGrantApplication, appSyncFilter));

        window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Application approved succesfully' } }))

        // Make a request to get new data
        dispatch(getCountyApplicationData());
        //TODO: Create a clone function to get latest county information
        // getCounties(dispatch, getState, lempgUserType, user)

    }
}

export function requestChangesInApplication(payload, callback) {
    return async function (dispatch, getState) {

        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });
        
        const { grantApplication, lempgUserType, user } = getState().rootReducer;
        let appSyncFilter = {
            input: {
                id: grantApplication.id,
                status: "DRAFT",
                updatedBy: user.username,
                updatedAt: new Date(),
            }
        };

        let draftRes = await API.graphql(graphqlOperation(updateGrantApplication, appSyncFilter));

        window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Application updated succesfully' } }))

        // Make a request to get new data
        await dispatch(getCountyApplicationData());
        dispatch({ type: APP_LOADING_STATE, payload: false });
        if(callback) callback()
        //TODO: Create a clone function to get latest county information
        // getCounties(dispatch, getState, lempgUserType, user, function () {
        //     dispatch({ type: APP_LOADING_STATE, payload: false });
        //     if(callback) callback()

        // })

    }
}

/**
 * 
 * 
*/

export function saveFile(payload, callback) {
    return async function (dispatch, getState) {

        const { user } = getState().rootReducer;

        let appSyncFilter = {
            input: {
                bucket: aws_config.aws_user_files_s3_bucket,
                title: payload.key,
                downloadTitle: payload.downloadTitle || null,
                size: payload.size || null,
                createdAt: new Date(),
                updatedAt: new Date(),
                deleted: false, 
                updatedBy: user.username
            }
        };

        if(callback) {
            let fileRes = await API.graphql(graphqlOperation(mutations.createFile, appSyncFilter));
            callback(fileRes)
        } else {
            return await API.graphql(graphqlOperation(mutations.createFile, appSyncFilter));
        }

    }
}


/**
 * 
 * 
*/

export function saveFileAppSync(payload, fieldName, callback) {
    return async function (dispatch, getState) {

        const { grantApplication, user } = getState().rootReducer;
        let appSyncFilter = {};
        if (payload.other_files || payload.file) {
            // This means that there are multiple uploads

            appSyncFilter = {
                input: {
                    id: grantApplication.id,
                    [fieldName]: payload.file && payload.file.id || null,
                    extraFile: JSON.stringify(payload.other_files),
                    updatedBy: user.username,
                    updatedAt: new Date(),
                }
            };


        } else {

            appSyncFilter = {
                input: {
                    id: grantApplication.id,
                    [fieldName]: payload.file_id || null,
                    updatedBy: user.username, 
                    updatedAt: new Date(),
                }
            };
        }

        let fileRes = await API.graphql(graphqlOperation(updateGrantApplication, appSyncFilter));

        window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'File associated with application succesfully'}}))
        callback(fileRes)
        
        // Make a request to get new data
        dispatch(getCountyApplicationData());

    }
}

export function saveAwardLetterFileState(payload, callback) {
    return async function (dispatch, getState) {

        const { grantApplication, user } = getState().rootReducer;

        let appSyncFilter = {
            input: {
                id: grantApplication.id,
                grantApplicationAwardCoverLetterUploadedByStateId: payload.cover_letter_file_id || null,
                grantApplicationAwardDocumentUploadedByStateId: payload.award_letter_file_id || null,
                grantApplicationAwardSpecialInstructionsUploadedByStateId: payload.special_instructions_file_id || null,
                updatedBy: user.username,
                updatedAt: new Date(),
            }
        };

        let fileRes = await API.graphql(graphqlOperation(updateGrantApplication, appSyncFilter));

        window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'File associated with application succesfully' } }))
        callback(fileRes)

        // Make a request to get new data
        dispatch(getCountyApplicationData());

    }
}

export function downloadDataAndPrintGrantApplicationForm(payload, isThisGAInitial = false) {
    return async function (dispatch, getState) {
        let a  = await axios(payload.link);
        a.data.isThisGAInitial = isThisGAInitial ? 'yes' : 'no';
        dispatch(printGrantApplicationForm(a.data));
    }
}


export function printFinancialReimbursementForm(payload) {
    return async function (dispatch, getState) {

        let postPayload = {
            encoding: null,
            data: payload,
        }

        let url = process.env.REACT_APP_PALMETTO_SERVERLESS_DOCUMENTS_ENDPOINT;

        axios.post(url + "/documents/lempg/financialreimbursement", postPayload,
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'FinancialReimbursement.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => console.log(error));

    }

}


export function printGrantApplicationForm(payload) {
    return async function (dispatch, getState) {

        if (!('isThisGAInitial' in payload)) {
            if (payload.grantApplicationRevision && payload.grantApplicationRevision.items && payload.grantApplicationRevision.items.length) {
                payload.isThisGAInitial = 'no';
            } else {
                payload.isThisGAInitial = 'yes';
            }
        }


        let postPayload = {
            encoding: null,
            data: payload,
            app_type: 'supplemental'
        }

        let url = process.env.REACT_APP_PALMETTO_SERVERLESS_DOCUMENTS_ENDPOINT;

        axios.post(url + "/documents/lempg/attachb", postPayload, 
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                }
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'GrantApplication.docx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => console.log(error));

    }    

}

export function reviseGrantApplication(payload) {
    return async function (dispatch, getState) {

        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

        const { grantApplication } = getState().rootReducer;

        /**
         * Grant application revision process
         * 1. Upload a json file on s3
         * 2. Create a corresponding entry in Files table
         * 3. Create a corresponding entry in GrantApplicationRevision table
         * 4. Update Grant application with the updated temp grant application revision info
         * 
         */

        // 1. Upload the JSON stringified grantApplication object on amazon s3
        let filename = new Date().getTime() + "__" + uuid.v4() + ".json";
        let obj = await S3FileUploadPut(filename, JSON.stringify(grantApplication), "application/json");

        // 2. Create a corresponding entry
        let saveFileRes = await dispatch(saveFile(obj));

        // 3. Create a corresponding Grant Application Revision table entry
        let appSyncFilter = {
            input: {
                grantApplicationRevisionRevisionId: saveFileRes.data.createFile.id,
                grantApplicationLastUpdatedAt: grantApplication.updatedAt
            }
        };

        const grantApplicationRevisionRes = await API.graphql(graphqlOperation(mutations.createGrantApplicationRevision, appSyncFilter));

        // 4. Update Grant application with the updated temp grant application revision info

        appSyncFilter = {
            input: {
                id: grantApplication.id,
                status: 'DRAFT',
                currentApplicationRevisionId: grantApplicationRevisionRes.data.createGrantApplicationRevision.id
            }
        }

        const grantAppliactionRes = await API.graphql(graphqlOperation(updateGrantApplication, appSyncFilter))
        dispatch(getCountyApplicationData());

        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });

    }
}

// Financial Reimbursements function

export function selectQuarterFinancialReimbursement(quarter) {
    return function(dispatch, getState) {
        dispatch( {type: SELECTED_QUARTER_FINANCIAL, payload: quarter});
    }
}

export function getFinancialQuarterlyData(payload, callback) {
    return async function(dispatch, getState) {
        let appSyncFilter = {
            id: payload.id
        };

        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

        const res = await API.graphql(graphqlOperation(GetFinancialReimbursement, appSyncFilter));

        dispatch(getCountyApplicationData());
        dispatch({ type: CURRENT_QUARTER, payload: res.data.getFinancialReimbursement });
        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });

        if(callback) {
            callback()
        }
    }
}

export function setCurrentQuarterlyItem(payload) {
    return async function (dispatch, getState) {
        dispatch({ type: CURRENT_QUARTER_ITEM, payload: payload });
    }
}

export function getFinancialQuarterlyDataForCounty(payload) {
    return async function (dispatch, getState) {
    }
}

export function draftFinancialReimbursementApplication(payload) {
    return async function (dispatch, getState) {

        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

        const { currentQuarter, user } = getState().rootReducer;
        let appSyncFilter = {
            input: {
                id: currentQuarter.id,
                status: "DRAFT",
                updatedBy: user.username,
                updatedAt: new Date(),
            }
        };

        let submittedRes = await API.graphql(graphqlOperation(updateFinancialReimbursement, appSyncFilter));

        window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Application updated succesfully' } }))

        let filter = {
            id: currentQuarter.id
        };


        const quarterRes = await API.graphql(graphqlOperation(GetFinancialReimbursement, filter));


        dispatch({ type: CURRENT_QUARTER, payload: quarterRes.data.getFinancialReimbursement });
        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
    }
}

export function submitFinancialReimbursementApplication(payload) {
    return async function (dispatch, getState) {

        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

        const { currentQuarter, user } = getState().rootReducer;
        let appSyncFilter = {
            input: {
                id: currentQuarter.id,
                status: "SUBMITTED",
                updatedBy: user.username,
                updatedAt: new Date(),
            }
        };

        let submittedRes = await API.graphql(graphqlOperation(updateFinancialReimbursement, appSyncFilter));

        window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Application submitted succesfully' } }))

        let filter = {
            id: currentQuarter.id
        };


        const quarterRes = await API.graphql(graphqlOperation(GetFinancialReimbursement, filter));


        dispatch({ type: CURRENT_QUARTER, payload: quarterRes.data.getFinancialReimbursement });
        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
    }
}

export function submitFinancialReimbursementDeobligation(payload, callback) {
    return async function (dispatch, getState) {

        const { user, selectedCounty, currentQuarter } = getState().rootReducer;

        // let q4 = null;
        // selectedCounty.financialreimbursement.items.forEach((item) => {
        //     if (item.quarter === "quarter4") {
        //         q4 = item;
        //     }
        // });

        let quarterToUpdate = currentQuarter;
        
        var appSyncFilter = {
            input: {
                id: quarterToUpdate.id,
                updatedBy: user.username,
                status: "SUBMITTED",
                updatedAt: new Date(),
                deobligatedFunds: payload.deobligatedFunds,
                deobligatedFundsReason: payload.deobligatedFundsReason
            }
        };

        await API.graphql(graphqlOperation(updateFinancialReimbursement, appSyncFilter));

        var filter = {
            id: quarterToUpdate.id
        };

        const quarterRes = await API.graphql(graphqlOperation(GetFinancialReimbursement, filter));


        dispatch({ type: CURRENT_QUARTER, payload: quarterRes.data.getFinancialReimbursement });
        let emailPayload = {
            ...selectedCounty,
            deobligatedFunds: payload.deobligatedFunds,
            year: quarterToUpdate.year
        }

        // Send the Email
        sendDeobligationEmail(emailPayload, (err, res) => {
            dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
            if (err) {
                console.error("Error Sending Email:", err);
            }
            if (callback) {
                if (err) {
                    callback(err);
                } else {
                    callback(null, res);
                }
            }
        });
        

    }
}

async function sendDeobligationEmail(payload, callback) {
    if (process.env.REACT_APP_NODE_ENV === "PRODUCTION") {

        let filter = {
            where: {
                pvPositionName: `LEMPG State Access`
            },
            include: {
                relation: "account2positions",
                scope: {
                    include: "accounts"
                }
            }
        };
        let users = [];
        let authObj = JSON.parse(sessionStorage.getItem("userAuthCreds"));
        let posData = await axios.get(`${process.env.REACT_APP_PALMETTO_ENDPOINT}/api/positions?filter=${JSON.stringify(filter)}&access_token=${authObj.id}`);
        if (posData && posData.data && posData.data[0] && posData.data[0].account2positions) {
            // Extract the Accounts from the connector table
            posData.data[0].account2positions.forEach((acct) => {
                if (acct && acct.accounts && !acct.accounts.pvVoid) {
                    users.push(acct.accounts);
                }
            });
        } else {
            console.error("Bad Response:", posData);
            callback(new Error(posData));
        }

        // Generate and send the emails.
        let messagePayload = {
            smsEmailObjects: []
        };

        for (let i = 0; i < users.length; i++) {
            let account = users[i];
            let emailAddr = account.email;
            let HTMLbody = `<p>${account.ncPersonGivenName} ${account.ncPersonSurName},</p> <p>${capitalize(payload.groupName)} County has chosen to deobligate $${getFormattedNumberForPrint(payload.deobligatedFunds)} from their federal award for FY${payload.year}</p>`;

            let textBody = ``;

            let emailObj = {
                pvMessageType: "EMAIL",
                pvEmailMessageText: textBody,
                pvEmailMessageHtml: HTMLbody,
                pvEmailSubject: `Supplemental - Financial Reimbursement - Deobligation Notice for ${capitalize(payload.groupName)} County`,
                pvRecipientFirstName: account.ncPersonGivenName,
                pvRecipientLastName: account.ncPersonSurName,
                pvRecipientEmailAddress: emailAddr
            };
            messagePayload.smsEmailObjects.push(emailObj);
        };

        sendEmail(messagePayload, authObj, 'Send Supplemental` Financial Reimbursement Deobligation notice', (err, response) => {
            if (err) {
                console.log(err)
                callback(err)
            } else {
                console.log("messages sent")
                callback(null, response)
            }
        })


    } else {
        console.log("This is not Prod. Refusing to send Messages!");
        callback(null, {});
    }
}

export function approveFinancialReimbursementApplication(payload) {
    return async function (dispatch, getState) {

        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

        const { currentQuarter, user } = getState().rootReducer;
        let appSyncFilter = {
            input: {
                id: currentQuarter.id,
                status: "APPROVED",
                updatedBy: user.username,
                updatedAt: new Date(),
            }
        };

        let submittedRes = await API.graphql(graphqlOperation(updateFinancialReimbursement, appSyncFilter));

        window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Application approved succesfully' } }))

        let filter = {
            id: currentQuarter.id
        };


        const quarterRes = await API.graphql(graphqlOperation(GetFinancialReimbursement, filter));


        dispatch({ type: CURRENT_QUARTER, payload: quarterRes.data.getFinancialReimbursement });
        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
    }
}

export function requestChangesFinancialReimbursementApplication(payload) {
    return async function (dispatch, getState) {

        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

        const { currentQuarter, user } = getState().rootReducer;
        let appSyncFilter = {
            input: {
                id: currentQuarter.id,
                status: "CHANGES_REQUESTED",
                updatedBy: user.username,
                updatedAt: new Date(),
            }
        };

        let submittedRes = await API.graphql(graphqlOperation(updateFinancialReimbursement, appSyncFilter));

        window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Application updated succesfully' } }))

        let filter = {
            id: currentQuarter.id
        };


        const quarterRes = await API.graphql(graphqlOperation(GetFinancialReimbursement, filter));


        dispatch({ type: CURRENT_QUARTER, payload: quarterRes.data.getFinancialReimbursement });
        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
    }
}

export function resetStatusToApprovedFinancialReimbursement(callback) {
    return async function (dispatch, getState) {

        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

        const { currentQuarter, user } = getState().rootReducer;


        var appSyncFilter = {
            input: {
                id: currentQuarter.id,
                status: "APPROVED",
                updatedBy: user.username,
                updatedAt: new Date(),
            }
        };

        let submittedRes = await API.graphql(graphqlOperation(updateFinancialReimbursement, appSyncFilter));

        window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Application status reverted to approved succesfully' } }))

        var filter = {
            id: currentQuarter.id
        };


        const quarterRes = await API.graphql(graphqlOperation(GetFinancialReimbursement, filter));


        dispatch({ type: CURRENT_QUARTER, payload: quarterRes.data.getFinancialReimbursement });
        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
        callback && callback()
    }
}


export function reissueCheckFinancialReimbursement() {
    return async function(dispatch, getState) {

        /**
         * 
         * Reissuing for a check can happen multiple times
         * Whenever a state user starts reissue process we will create an entry in ChecksReissued table
         * After an entry is created we will save an existing reissued check id in `currentReissuedCheck` field in FinancialReimbursement table
         * 
         * If there exists an `currentReissuedCheck` then we have to display that rather than initial stored check information
         * 
         */

        let {
            selectedCounty,
            lempgUserType,
            user,
            currentQuarter
        } = getState().rootReducer;

        if(lempgUserType != "LEMPG_ACCESS") {
            // State user

            dispatch({ type: APPLICATION_LOADING_STATE, payload: true });
            
            try {
                let createChecksReissuedRes  = await API.graphql(graphqlOperation(mutations.createChecksReissued, {
                    input: {
                        createdAt: new Date(),
                        updatedBy: user.username,
                        updatedAt: new Date(),
                        checkAmount: currentQuarter.checkAmount,
                        // We dont wanna copy older check number and check mail date information
                        checkNumber: null,
                        checkMailDate: null,
                        checkAmount2: currentQuarter.checkAmount2,
                        // We dont wanna copy older check number and check mail date information
                        checkNumber2: null,
                        checkMailDate2: null,                        
                        financialReimbursementChecksReissuedId: currentQuarter.id
                    }
                }))

                let updateFinRimRes = await API.graphql(graphqlOperation(updateFinancialReimbursement, {
                    input: {
                        id: currentQuarter.id,
                        updatedBy: user.username,
                        updatedAt: new Date(),
                        status: "APPROVED",
                        financialReimbursementCurrentReissuedCheckId: createChecksReissuedRes.data.createChecksReissued.id
                    }
                }))

                const quarterRes = await API.graphql(graphqlOperation(GetFinancialReimbursement, { id: currentQuarter.id }));


                dispatch({ type: CURRENT_QUARTER, payload: quarterRes.data.getFinancialReimbursement });
                dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
                
            } catch(e) {
                console.log("Error occured", e)
            }
        }
    }
}

export function sendAdditionalCheckFinancialReimbursement(payload, callback) {
    return async function(dispatch, getState) {

        /**
         * Additional Check workflow can happen many times
         * When the additional check workflow is in progress, we will move the status to `APPROVED`
         * Once the status is changed we will let users enter all the details that they normally enter
         * 
         * We will also create an entry is AdditionalChecks table.
         * The latest entry will be tied upto FinancialReimbursement's `additionalCheckInProgress` connection
         * If the status is `APPROVED` and `additionalCheckInProgress` field is not null, we will assume user wants to sent additional checks.
         * 
         */
        
        
        let {
            selectedCounty,
            lempgUserType,
            user,
            currentQuarter
        } = getState().rootReducer;

        if (lempgUserType != "LEMPG_ACCESS") {
            // State user

            dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

            try {
                let createAdditionalChecksRes = await API.graphql(graphqlOperation(mutations.createAdditionalChecks, {
                    input: {
                        createdAt: new Date(),
                        updatedBy: user.username,
                        updatedAt: new Date(),
                        financialReimbursementAdditionalChecksId: currentQuarter.id
                    }
                }))

                let updateFinRimRes = await API.graphql(graphqlOperation(updateFinancialReimbursement, {
                    input: {
                        id: currentQuarter.id,
                        updatedBy: user.username,
                        updatedAt: new Date(),
                        status: "APPROVED",
                        financialReimbursementAdditionalCheckInProgressId: createAdditionalChecksRes.data.createAdditionalChecks.id
                    }
                }))

                const quarterRes = await API.graphql(graphqlOperation(GetFinancialReimbursement, { id: currentQuarter.id }));


                dispatch({ type: CURRENT_QUARTER, payload: quarterRes.data.getFinancialReimbursement });
                dispatch({ type: APPLICATION_LOADING_STATE, payload: false });

            } catch (e) {
                console.log("Error occured", e)
            }
        }
    }
}

async function sendCheckGenerationEmailAfterInformationIsSubmitted(payload, selectedCounty, currentQuarter) {

    // Get the contacts data from palmetto, the contacts are stored in groups > group_contact relation using Control Panel application
    // Send an email to all contacts


    let authObj = JSON.parse(sessionStorage.getItem("userAuthCreds"));

    let groupsData = await axios.get(process.env.REACT_APP_PALMETTO_ENDPOINT + "/api/groups/" + selectedCounty.palmettoId + "?access_token=" + authObj.id + "&filter=" + JSON.stringify({ include: ["group_contact"] }));


    let groupName = selectedCounty.groupName.toLowerCase().replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    let quarter = "Q" + parseInt(currentQuarter.quarter.split("").reverse().join(""));


    // Send email to group contact stored in palmetto

    if (groupsData.data && groupsData.data.group_contact && groupsData.data.group_contact.length) {

        let authObj = JSON.parse(sessionStorage.getItem("userAuthCreds"));
        let messagePayload = {
            smsEmailObjects: []
        };
        groupsData.data.group_contact.forEach((contact) => {
            let emailBody = "<p>" + contact.pvFirstName + " " + contact.pvLastName + ", </p>" + "<br /><p>" + groupName + "  County's Supplemental System  reimbursement check for " + quarter + " has been sent on " + moment(payload.checkMailDate, "YYYY-MM-DD").format("MMM DD, YYYY") + " to your county finance department.</p> <br /> v/r, <br /> EMD Finance Dept."

            let smsEmailObject = {
                "pvMessageType": "EMAIL",
                "pvEmailMessageText": "TEST TEST TEST",
                "pvEmailMessageHtml": emailBody,
                "pvEmailSubject": "Supplemental System - Financial Reimbursement - " + quarter + " Check sent for " + groupName,
                "pvRecipientFirstName": contact.pvFirstName,
                "pvRecipientLastName": contact.pvLastName,
                "pvRecipientEmailAddress": contact.pvEmail
            }
            messagePayload.smsEmailObjects.push(smsEmailObject);
        })

        axios.post(process.env.REACT_APP_PALMETTO_ENDPOINT + "/messages/send?user_id=" + authObj.userId + "&access_token=" + authObj.id, messagePayload,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                console.log("messages sent")
            })
            .catch((error) => console.log(error));
    }


}


export function processFinancialReimbursementApplication(payload) {
    return async function (dispatch, getState) {

        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

        const { currentQuarter, user, selectedCounty } = getState().rootReducer;

        if(payload.additionalCheck) {

            await API.graphql(graphqlOperation(mutations.updateAdditionalChecks, {
                input: {
                    id: currentQuarter.additionalCheckInProgress.id,
                    checkNumber: payload.checkNumber || null,
                    checkAmount: payload.checkAmount || null,
                    checkMailDate: payload.checkMailDate || null,
                    stateSceisSubmissionDate: payload.stateSceisSubmissionDate || null,
                    stateSceisDocumentNumber: payload.stateSceisDocumentNumber || null,


                    checkNumber2: payload.checkNumber2 || null,
                    checkAmount2: payload.checkAmount2 || null,
                    checkMailDate2: payload.checkMailDate2 || null,
                    stateSceisSubmissionDate2: payload.stateSceisSubmissionDate2 || null,
                    stateSceisDocumentNumber2: payload.stateSceisDocumentNumber2 || null,
                    
                    
                    updatedBy: user.username,
                    updatedAt: new Date(),
                }
            }))

            await API.graphql(graphqlOperation(updateFinancialReimbursement, {
                input: {
                    id: currentQuarter.id,
                    status: payload.status,
                    updatedBy: user.username,
                    financialReimbursementAdditionalCheckInProgressId: null,
                    updatedAt: new Date(),
                }
            }));

        }
        else if(payload.reissueCheck) {

            await API.graphql(graphqlOperation(mutations.updateChecksReissued, {
                input: {
                    id: currentQuarter.currentReissuedCheck.id,
                    checkNumber: payload.checkNumber || null,
                    checkAmount: payload.checkAmount || null,
                    checkMailDate: payload.checkMailDate || null,
                    reason: payload.reason || null,

                    checkNumber2: payload.checkNumber2 || null,
                    checkAmount2: payload.checkAmount2 || null,
                    checkMailDate2: payload.checkMailDate2 || null,
                    reason2: payload.reason2 || null,
                    
                    
                    updatedBy: user.username,
                    updatedAt: new Date(),
                }
            }))

            await API.graphql(graphqlOperation(updateFinancialReimbursement, {
                input: {
                    id: currentQuarter.id,
                    status: payload.status,
                    stateSceisSubmissionDate: payload.stateSceisSubmissionDate || null,
                    stateSceisDocumentNumber: payload.stateSceisDocumentNumber || null,
                    stateSceisSubmissionDate2: payload.stateSceisSubmissionDate2 || null,
                    stateSceisDocumentNumber2: payload.stateSceisDocumentNumber2 || null,

                    financialReimbursementCurrentReissuedCheckId: null,
                    updatedBy: user.username,
                    updatedAt: new Date(),
                }
            }));

        } 
        else {
            let appSyncFilter = {
                input: {
                    id: currentQuarter.id,
                    status: payload.status,
                    checkNumber: payload.checkNumber || null,
                    checkAmount: payload.checkAmount || null,
                    checkMailDate: payload.checkMailDate || null,
                    stateSceisSubmissionDate: payload.stateSceisSubmissionDate || null,
                    stateSceisDocumentNumber: payload.stateSceisDocumentNumber || null,

                    checkNumber2: payload.checkNumber2 || null,
                    checkAmount2: payload.checkAmount2 || null,
                    checkMailDate2: payload.checkMailDate2 || null,
                    stateSceisSubmissionDate2: payload.stateSceisSubmissionDate2 || null,
                    stateSceisDocumentNumber2: payload.stateSceisDocumentNumber2 || null,

                    updatedBy: user.username,
                    updatedAt: new Date(),
                }
            };
    
            let submittedRes = await API.graphql(graphqlOperation(updateFinancialReimbursement, appSyncFilter));
        }

        // Send email if status is PROCESSED or `Submit` button is hit in FinancialReimbursementApprovedState component

        if (payload.status == "PROCESSED" && process.env.REACT_APP_NODE_ENV == "PRODUCTION") {
            sendCheckGenerationEmailAfterInformationIsSubmitted(payload, selectedCounty, currentQuarter)
        }


        if(payload.status == 'APPROVED') {
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Application saved succesfully' } }))
        } else {
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Application processed succesfully' } }))
        }

        let filter = {
            id: currentQuarter.id
        };


        const quarterRes = await API.graphql(graphqlOperation(GetFinancialReimbursement, filter));


        dispatch({ type: CURRENT_QUARTER, payload: quarterRes.data.getFinancialReimbursement });
        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
    }
}


// Progress Reporting functions


function capitalize(str) {
    if (!str) return "";
    return str.substring(0, 1).toUpperCase() + str.substring(1);
}

export function progressReportingNotifyREM(payload, callback) {
    return async function (dispatch, getState) {
        // Get Users of the correct position

        if (process.env.REACT_APP_NODE_ENV === "PRODUCTION") {
            dispatch({ type: APPLICATION_LOADING_STATE, payload: true });


            let region = payload.region.substring(1);
            let filter = {
                where: {
                    pvPositionName: `LEMPG Region ${region}`
                },
                include: {
                    relation: "account2positions",
                    scope: {
                        include: "accounts"
                    }
                }
            };
            let users = [];
            let authObj = JSON.parse(sessionStorage.getItem("userAuthCreds"));
            let posData = await axios.get(`${process.env.REACT_APP_PALMETTO_ENDPOINT}/api/positions?filter=${JSON.stringify(filter)}&access_token=${authObj.id}`);
            if (posData && posData.data && posData.data[0] && posData.data[0].account2positions) {
                // Extract the Accounts from the connector table
                posData.data[0].account2positions.forEach((acct) => {
                    if (acct && acct.accounts && !acct.accounts.pvVoid) {
                        users.push(acct.accounts);
                    }
                });
            } else {
                console.error("Bad Response:", posData);
                callback(new Error(posData));
                return;
            }

            // Generate and send the emails.
            let messagePayload = {
                smsEmailObjects: []
            };

            for (let i = 0; i < users.length; i++) {
                let account = users[i];
                // Create the Access Token
                let accessTokenRes = await axios.post(process.env.REACT_APP_PALMETTO_ENDPOINT + "/api/accounts/" + account.id + "/accessTokens/?access_token=" + authObj.id, {
                    "id": uuid.v4(),
                    "ttl": 604800,
                    "userId": account.id
                });
                let token = accessTokenRes.data.id;
                let magicURL = `http://supplemental.palmettoeoc.com?access_token=${token}&type=rem&user_id=${account.id}&group_id=${payload.id}&quarter=${payload.quarter}`;

                let emailAddr = account.email;

                let HTMLbody = `<p>${account.ncPersonGivenName} ${account.ncPersonSurName},</p> <p>All of ${capitalize(payload.groupName)} County's work elements have been submitted and approved. Select the link below to generate the Tasking Slip.</p><a href='${magicURL}'>${magicURL}</a>`;

                let textBody = ``;

                let emailObj = {
                    pvMessageType: "EMAIL",
                    pvEmailMessageText: textBody,
                    pvEmailMessageHtml: HTMLbody,
                    pvEmailSubject: `Supplemental System - Progress Reporting - REM Notice about ${capitalize(payload.groupName)} County`,
                    pvRecipientFirstName: account.ncPersonGivenName,
                    pvRecipientLastName: account.ncPersonSurName,
                    pvRecipientEmailAddress: emailAddr
                };
                messagePayload.smsEmailObjects.push(emailObj);
            };

            await axios.post(`${process.env.REACT_APP_PALMETTO_ENDPOINT}/messages/send?user_id=${authObj.userId}&access_token=${authObj.id}`, messagePayload, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((response) => {
                callback(null, response);
            }).catch((error) => {
                console.log(error);
                callback(error);
            });
            dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
        }
        else {
            console.log("This is not Prod. Refusing to send Messages!");
            callback(null, {});
        }
    }
}


export function selectQuarterProgressReporting(quarter) {
    return function (dispatch, getState) {
        dispatch({ type: SELECTED_QUARTER_PROGRESS, payload: quarter });
    }
}


export function getCountyGroupNarrativeDataM2M(payload, callback) {
    return async function(dispatch, getState) {

        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });
        dispatch({ type: SET_SELECTED_COUNTY, payload: payload });
        dispatch({ type: PROGRESS_REPORTING_QUARTER, payload: payload.quarter });
        
        dispatch({ type: IS_TASKING_SLIP_GENERATED_FOR_QUARTER, payload: false });

        if (payload.quarterlyprogressreporting
            && payload.quarterlyprogressreporting.items
            && payload.quarterlyprogressreporting.items.length) {

            payload.quarterlyprogressreporting.items.forEach((item) => {
                if (item.quarter == payload.quarter) {
                    dispatch({ type: IS_TASKING_SLIP_GENERATED_FOR_QUARTER, payload: item.taskingslipstatus == "COMPLETED" ? true : false });

                }
            })
        }

        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
    }
}


export function getNarrativeAndGroupNarrativeM2MData(payload, callback) {
    return async function(dispatch, getState) {
        
        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });
        let appSyncFilter = {
            id: payload.id
        };

        const { selectedCounty, selectedYear, allREMContacts } = getState().rootReducer;
        
        const res = await API.graphql(graphqlOperation(queries.getGroupNarrativeM2M, appSyncFilter));
        
        dispatch({ type: IS_TASKING_SLIP_GENERATED_FOR_QUARTER, payload: false });

        if (selectedCounty.quarterlyprogressreporting 
            && selectedCounty.quarterlyprogressreporting.items
            && selectedCounty.quarterlyprogressreporting.items.length) {

            selectedCounty.quarterlyprogressreporting.items.forEach((item) => {
                if(item.quarter == payload.quarter) {
                    dispatch({ type: IS_TASKING_SLIP_GENERATED_FOR_QUARTER, payload: item.taskingslipstatus == "COMPLETED" ? true : false });

                }
            })
        }

        if (selectedYear == "2019") {
            if (res.data.getGroupNarrativeM2M.narrative.narrativeTitle == "M-117") {
                let a = await getFileUploadedLinkFromBucket({ key: "M-117-Training-Data-Table.docx" }, "palmetto-lempg-global-documents");
    
                res.data.getGroupNarrativeM2M.m117DownloadLink = a;
            }
            else if (res.data.getGroupNarrativeM2M.narrative.narrativeTitle == "M-111") {
                let a = await getFileUploadedLinkFromBucket({ key: "CERT.Report.Template.docx" }, "palmetto-lempg-global-documents");

                res.data.getGroupNarrativeM2M.m117DownloadLink = a;
            }

        }

        // Get history for this group narrative

        // let auditRes = await axios.get(process.env.REACT_APP_LEMPG_SERVERLESS_URL + "/api/groupNarrativeM2M/" + res.data.getGroupNarrativeM2M.id)

        let fieldTitleReference = {
            "notes": "Notes updated",
            "extraFile": "Files updated",
            "groupNarrativeM2MUploadedFileId": "Files updated",
            "updatedBy": "Updated by"
        };

        // let historyComments = generateHistoryComments(auditRes.data, res.data.getGroupNarrativeM2M.comments.items, fieldTitleReference);

        let historyComments = generateHistoryComments([], res.data.getGroupNarrativeM2M.comments.items, fieldTitleReference);


        if(res.data.getGroupNarrativeM2M.narrative.usePalmettoForREMInformation) {
            allREMContacts.forEach((contact) => {
                if(contact.region == selectedCounty.region) {
                    res.data.getGroupNarrativeM2M.narrative.narrativePOCFirstName = contact.firstName;
                    res.data.getGroupNarrativeM2M.narrative.narrativePOCLastName = contact.lastName;
                    res.data.getGroupNarrativeM2M.narrative.narrativePOCPhoneNumber = contact.phoneNumber;
                    res.data.getGroupNarrativeM2M.narrative.narrativePOCEmailAddress = contact.emailAddress;
                    res.data.getGroupNarrativeM2M.narrative.narrativePOCPositionTitle = contact.positionTitle;
                }
            })
        } else {
            // Update below narratives contact info 
            let specialNarratives = {
                "M-113": 1,
                "M-107": 1,
                "M-108": 1,
            }
            if (specialNarratives[res.data.getGroupNarrativeM2M.narrative.narrativeTitle]) {
    
                let narrativeTitle = res.data.getGroupNarrativeM2M.narrative.narrativeTitle;
    
                let contactInfo = REMContactInfo[narrativeTitle][selectedCounty.region]
                Object.keys(contactInfo).forEach((key) => {
                    res.data.getGroupNarrativeM2M.narrative[key] = contactInfo[key];
                })
            }
        }


        dispatch({ type: CURRENT_NARRATIVE, payload: JSON.parse(JSON.stringify(res.data.getGroupNarrativeM2M.narrative)) });
        dispatch({ type: CURRENT_GROUP_NARRATIVE_M2M, payload: JSON.parse(JSON.stringify(res.data.getGroupNarrativeM2M)) });

        // dispatch({ type: ACTIVITY_FEED_HISTORY, payload: JSON.parse(JSON.stringify(auditRes.data)) });
        dispatch({ type: ACTIVITY_FEED_HISTORY, payload: [] });
        dispatch({ type: ACTIVITY_FEED_COMMENTS, payload: res.data.getGroupNarrativeM2M.comments.items });
        dispatch({ type: ACTIVITY_FEED_FIELD_TITLE_REFERENCE, payload: fieldTitleReference });
        dispatch({ type: ACTIVITY_FEED_FIELD_VALUE_REFERENCE, payload: {} });

        dispatch({ type: ACTIVITY_FEED_HISTORY_COMMENTS, payload: JSON.parse(JSON.stringify(historyComments)) });


        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });

        if(callback) {
            callback();
        }
    }
}

async function getLatestCountyNarrativeInformation(payload, selectedCounty, counties,  dispatch) {
    
    let appSyncFilter = {
        id: payload.id
    }


    const groupsResponseFromAppSync = await API.graphql(graphqlOperation(queries.getGroupNarrativeM2M, appSyncFilter));

    selectedCounty.narratives.items.forEach((item, index) => {
        if (item.id == groupsResponseFromAppSync.data.getGroupNarrativeM2M.id) {
            selectedCounty.narratives.items[index] = groupsResponseFromAppSync.data.getGroupNarrativeM2M;
        }
    })

    dispatch({ type: SET_SELECTED_COUNTY, payload: selectedCounty });

    if(counties) {
        counties.forEach((county) => {
            if(county.id == selectedCounty.id) {
                county.narratives.items.forEach((item, index) => {
                    if (item.id == groupsResponseFromAppSync.data.getGroupNarrativeM2M.id) {
                        county.narratives.items[index] = groupsResponseFromAppSync.data.getGroupNarrativeM2M;
                    }
                })

            }
        })

        dispatch({ type: SET_COUNTIES_FOR_STATE_USER, payload: counties });

    }
    
}

export function dismissExtensionProgressReporting() {
   return async function() {
   }
}

export function saveGroupNarrativeM2M(payload, callback) {
    return async function(dispatch, getState) {

        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

        if(!payload.id) {
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Group narrative id is required' } }))
            return;
        }

        const { user, selectedCounty, counties } = getState().rootReducer;

        let appSyncFilter = {
            input: {
                id: payload.id, 
                status:  'DRAFT',
                notes: payload.notes || null, 
                extraFile: JSON.stringify(payload.other_files),
                groupNarrativeM2MUploadedFileId: payload.file && payload.file.id || null,
                groupNarrativeM2MM117CompletedDocumentId: ("m117CompletedDocumentId" in payload) ? (payload.m117CompletedDocumentId || null) : undefined,
                updatedByFirstName: user.ncPersonGivenName,
                updatedByLastName: user.ncPersonSurName || null,

                updatedBy: user.username,
                updatedAt: new Date(),
            }
        };

        try {
            let submittedRes = await API.graphql(graphqlOperation(mutations.updateGroupNarrativeM2M, appSyncFilter));

            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Narrative saved successfully' } }))
            
            if(callback) {
                callback()
            }

        }
        catch(e) {
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Error occured while saving narrative information.' } }))
        }

        // Get latest Narrative data 
        getLatestCountyNarrativeInformation(payload, selectedCounty, counties, dispatch);

        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });

    }
}

export function updateStatusGroupNarrativeM2M(payload, callback) {
    return async function (dispatch, getState) {

        if (!payload.id) {
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Group narrative id is required' } }))
            return;
        }

        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

        const { user, counties, selectedCounty } = getState().rootReducer;

        // ("pvOfficePhone" in payload) ? payload.pvOfficePhone : undefined,
        let appSyncFilter = {
            input: {
                id: payload.id,
                status: payload.status,

                notes: ("notes" in payload) ? (payload.notes || null) : undefined,
                extraFile: ("other_files" in payload) ? (JSON.stringify(payload.other_files)) : undefined,
                groupNarrativeM2MUploadedFileId: ("file" in payload) ? (payload.file && payload.file.id || null) : undefined,
                groupNarrativeM2MM117CompletedDocumentId: ("m117CompletedDocumentId" in payload) ? (payload.m117CompletedDocumentId || null) : undefined,
                updatedByFirstName: user.ncPersonGivenName,
                updatedByLastName: user.ncPersonSurName || null,

                updatedBy: user.username,
                updatedAt: new Date(),
            }
        };

        try {
            let submittedRes = await API.graphql(graphqlOperation(mutations.updateGroupNarrativeM2M, appSyncFilter));

            let msg;
            switch (payload.status) {
                case "SUBMITTED":
                    msg = "Narrative submitted successfully";
                    break;
                case "APPROVED":
                    msg = "Narrative approved successfully";
                    break;
                case "CHANGES_REQUESTED":
                    msg = "Narrative updated successfully";
                    break;
                default:
                    msg = "Narrative saved successfully";
            }

            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: msg } }))

            if ((payload.status == "SUBMITTED") && (process.env.REACT_APP_NODE_ENV == "PRODUCTION")) {

                // Get user

                let authObj = JSON.parse(sessionStorage.getItem("userAuthCreds"));

                let palmettoUserObj = await axios.post(process.env.REACT_APP_PALMETTO_ENDPOINT + "/api/accounts/getUsernames?access_token=" + authObj.id, {
                    email: payload.narrativePOCEmailAddress
                })

                if (palmettoUserObj.data && palmettoUserObj.data.length == 1) {
                    
                    // Create a valid entry for that accesstoken
    
                    let accessTokenRes = await axios.post(process.env.REACT_APP_PALMETTO_ENDPOINT + "/api/accounts/" + palmettoUserObj.data[0].id + "/accessTokens/?access_token=" + authObj.id, {
                        "id": uuid.v4(),
                        "ttl": 604800,
                        "userId": palmettoUserObj.data[0].id
                    })
    
                    let groupName = selectedCounty.groupName.toLowerCase().replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    
                    let emailBody = "<p> " + groupName + " County has submitted " + payload.narrativeTitle + " narrative for you to review. Please click on the link below to review their submission.  </p> <a href='https://supplemental.palmettoeoc.com/?access_token=" + accessTokenRes.data.id + "&user_id=" + accessTokenRes.data.userId + "&narrative_id=" + payload.id + "&group_id=" + selectedCounty.id + "&fsv=1' target='_blank'> View narrative </a>";

                    // Add it back 
                    if (payload.narrativePOCEmailAddress) {
                        let authObj = JSON.parse(sessionStorage.getItem("userAuthCreds"));
                        let messagePayload = {
                            // "smsEmailObjects": []
                            "smsEmailObjects": [
                                {
                                    "pvMessageType": "EMAIL",
                                    "pvEmailMessageText": "TEST TEST TEST",
                                    "pvEmailMessageHtml": emailBody,
                                    "pvEmailSubject": "Supplemental System " + groupName + " " + payload.narrativeTitle + " Narrative Submission",
                                    "pvRecipientFirstName": payload.narrativePOCFirstName,
                                    "pvRecipientLastName": payload.narrativePOCLastName,
                                    "pvRecipientEmailAddress": payload.narrativePOCEmailAddress
                                }
                            ]
            
                        };
        
                        axios.post(process.env.REACT_APP_PALMETTO_ENDPOINT + "/messages/send?user_id=" + user.id + "&access_token=" + authObj.id, messagePayload,
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                }
                            })
                            .then((response) => {
                                console.log("message send")
                            })
                            .catch((error) => console.log(error));
                    }

                } else {

                    // Problem occured, send phil a message

                    let groupName = selectedCounty.groupName.toLowerCase().replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });

                    let emailBody = "<p> Hey phil, We encountered a problem while sending a submission email to " + groupName + ". Pass this object to a developer so he/she can take a deeper look at the issue " + JSON.stringify(payload).replace(/"/g, '\'') + " </p>"

                    // Add it back (process.env.REACT_APP_NODE_ENV == "PRODUCTION") && 
                    console.log("sending message")
                    let authObj = JSON.parse(sessionStorage.getItem("userAuthCreds"));
                    let messagePayload = {
                        // "smsEmailObjects": []
                        "smsEmailObjects": [
                            {
                                "pvMessageType": "EMAIL",
                                "pvEmailMessageText": "TEST TEST TEST",
                                "pvEmailMessageHtml": emailBody,
                                "pvEmailSubject": "Supplemental System " + groupName + " " + payload.narrativeTitle + " Narrative Submission - Failed to send email to POC",
                                "pvRecipientFirstName": "Philip",
                                "pvRecipientLastName": "Armijo",
                                "pvRecipientEmailAddress": "parmijo@earthtechint.com"
                            }
                        ]

                    };

                    axios.post(process.env.REACT_APP_PALMETTO_ENDPOINT + "/messages/send?user_id=" + user.id + "&access_token=" + authObj.id, messagePayload,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                            }
                        })
                        .then((response) => {
                            console.log("failed message send")
                        })
                        .catch((error) => console.log(error));

                }

            }

            // Get latest Narrative data 

            getLatestCountyNarrativeInformation(payload, selectedCounty, counties,  dispatch);

            if (callback) {
                callback()
            }

        }
        catch (e) {
            console.log(e)
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Error occured while saving narrative information.' } }))
        }

        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
    }
}

async function getLatestCountyTaskingSlipStatusInformation( selectedCounty, counties, dispatch, callback) {

    let appSyncFilter = {
        id: selectedCounty.id
    }


    const groupsResponseFromAppSync = await API.graphql(graphqlOperation(queries.getGroup, appSyncFilter));

    if (counties) {
        counties.forEach((county, index) => {
            if (county.id == selectedCounty.id) {
                counties[index].quarterlyprogressreporting = groupsResponseFromAppSync.data.getGroup.quarterlyprogressreporting
            }
        })

        dispatch({ type: SET_COUNTIES_FOR_STATE_USER, payload: JSON.parse(JSON.stringify(counties)) });

        if (callback) {
            callback()
        }
    }

}


export function updateGroupTaskingSlipStatus(payload, callback) {
    return async function (dispatch, getState) {
        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

        
        const { user, counties, selectedCounty } = getState().rootReducer;
        let appSyncFilter = {
            input: {
                quarter: selectedCounty.quarter,
                taskingslipstatus: "COMPLETED",
                updatedAt: new Date(),
                updatedBy: user.username,
                updatedByRemName: ((user.ncPersonGivenName || "") + " " + (user.ncPersonSurName || "")),
                groupQuarterlyprogressreportingId: selectedCounty.id
            }
        };

        try {
            let submittedRes = await API.graphql(graphqlOperation(mutations.createQuarterlyProgressReporting, appSyncFilter));

            // Send an email to finance people telling them that county's tasking slip has been generated

            if (process.env.REACT_APP_NODE_ENV == "PRODUCTION") {
                let groupName = selectedCounty.groupName.toLowerCase().replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    
                let emailBody = "<p> I have reviewed " + groupName + " County's Q" + parseInt(selectedCounty.quarter.split("").reverse().join("")) + " progress report and find that it is complete and in keeping with the grant guidelines. I recommend this county's fund be released. </p> From, <br /> " + ((user.ncPersonGivenName || "") + " " + (user.ncPersonSurName || ""));
    
                let authObj = JSON.parse(sessionStorage.getItem("userAuthCreds"));
    
                let messagePayload = {
                    "smsEmailObjects": [
                        {
                            "pvMessageType": "EMAIL",
                            "pvEmailMessageText": "TEST TEST TEST",
                            "pvEmailMessageHtml": emailBody,
                            "pvEmailSubject": "Supplemental System " + groupName + " County tasking slip generated",
                            "pvRecipientFirstName": "Adam",
                            "pvRecipientLastName": "Lawrence",
                            "pvRecipientEmailAddress": "alawrence@emd.sc.gov"
                            // "pvRecipientEmailAddress": "rudra@krum.io"
                        },
                        {
                            "pvMessageType": "EMAIL",
                            "pvEmailMessageText": "TEST TEST TEST",
                            "pvEmailMessageHtml": emailBody,
                            "pvEmailSubject": "Supplemental System " + groupName + " County tasking slip generated",
                            "pvRecipientFirstName": "Steven",
                            "pvRecipientLastName": "Batson",
                            "pvRecipientEmailAddress": "sbatson@emd.sc.gov"
                        },
                        {
                            "pvMessageType": "EMAIL",
                            "pvEmailMessageText": "TEST TEST TEST",
                            "pvEmailMessageHtml": emailBody,
                            "pvEmailSubject": "Supplemental System " + groupName + " County tasking slip generated",
                            "pvRecipientFirstName": "Deborah",
                            "pvRecipientLastName": "Dawson",
                            "pvRecipientEmailAddress": "ddawson@emd.sc.gov"
                        },
    
                    ]
                }
    
                axios.post(process.env.REACT_APP_PALMETTO_ENDPOINT + "/messages/send?user_id=" + user.id + "&access_token=" + authObj.id, messagePayload,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    })
                    .then((response) => {
                        console.log("Message send")
                    })
                    .catch((error) => console.log(error));            

            } else {
                console.log("Not prod")
            }


            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: "County tasking slip approved" } }))
            getLatestCountyTaskingSlipStatusInformation(selectedCounty, counties, dispatch, callback);
        }
        catch (e) {
            console.log(e)
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Error occured while saving narrative information.' } }))
        }
        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });

    }
}


export function printQuarterlyTaskingSlip(quarter) {
    return async function (dispatch, getState) {


        const {selectedCounty, selectedYear} = getState().rootReducer;

        let postPayload = {};
        let fileName = "TaskingSlip";

        if (selectedCounty.quarterlyprogressreporting
            && selectedCounty.quarterlyprogressreporting.items
            && selectedCounty.quarterlyprogressreporting.items.length) {

            selectedCounty.quarterlyprogressreporting.items.forEach((item) => {
                if (item.quarter == quarter) {
                    item.quarter = item.quarter.replace("quarter", "Q");

                    postPayload = {
                        county: (selectedCounty.groupName.charAt(0).toUpperCase() + selectedCounty.groupName.slice(1)),
                        year: selectedYear,
                        date: moment(item.updatedAt).format("YYYY-MM-DD HH:mm"), 
                        remName: item.updatedByRemName,
                        quarter: item.quarter.replace("quarter", "Q")
                    }

                    fileName = "TaskingSlip-" + postPayload.county + "-" + postPayload.quarter;

                }
            })
        }

        let url = process.env.REACT_APP_PALMETTO_SERVERLESS_DOCUMENTS_ENDPOINT;

        axios.post(url + "/documents/lempg/taskingslip", postPayload,
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                }
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName +'.docx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => console.log(error));

    }

}

export function printSOWTracker(quarter) {
    return async function (dispatch, getState) {

        const {
            counties
        } = getState().rootReducer;

        let postPayload = {};
        let fileName = "SOW-Tracker";

        // This is the payload we will send palmetto documents service to print

        // postPayload = {
        //     "county": {
        //         "quarter": {
        //             "narrative (M-101)": {
        //                 "value": "Y/N"
        //             }
        //         },
        //     }
        // }

        counties.forEach( (county) => {
            if(!postPayload[county.groupName]) {
                postPayload[county.groupName] = {}
            }
            if (county.narratives &&
            county.narratives.items &&
            county.narratives.items.length) {
                let prItems = county.narratives.items;

                prItems.forEach( (item) => {

                    if((item.narrative.useFinancialReimbursementStatus)) {
                        let finItems = county && county.financialreimbursement && county.financialreimbursement.items;
                        // loop over finItems
                        finItems.forEach((finItem) => {
                            if(finItem.quarter === item.quarter) {
                                item.status = finItem.status;
                                if (item.status == "AWARDED") item.status = "APPROVED";
                                else if (item.status == "PROCESSED") item.status = "APPROVED";
                                else if (item.status == "NOT_AVAILABLE") item.status = "DRAFT";
                                else if (item.status == "NOT_STARTED") item.status = "DRAFT";
            
                            }
                        })
                    }
                                        

                    if (!postPayload[county.groupName][item.quarter]) {
                        postPayload[county.groupName][item.quarter] = {}
                    }
                    if (!postPayload[county.groupName][item.quarter][item.narrative.narrativeTitle]) {
                        postPayload[county.groupName][item.quarter][item.narrative.narrativeTitle] = {}
                    }
                    if(item.status && item.status == "APPROVED") {
                        postPayload[county.groupName][item.quarter][item.narrative.narrativeTitle] = {
                            value: "Y"
                        }
                    } 
                    else {
                        postPayload[county.groupName][item.quarter][item.narrative.narrativeTitle] = {
                            value: "N"
                        }
                    }
                })

            }
        })

        let url = process.env.REACT_APP_PALMETTO_SERVERLESS_DOCUMENTS_ENDPOINT;
        axios.post( url + "/documents/lempg/sowtracker", postPayload, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "SOW-Tracker" + '.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => console.log(error));

    }

}


// Extensions Functions


export function requestFinancialReimbursementExtension(payload, callback) {
    return async function(dispatch, getState) {

        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });
        // Steps to request an extension

        // 1. County puts in an extension request, create an extension entry with the financial reimbursement id for the county.

        const { user, currentQuarter, selectedCounty, selectedYear} = getState().rootReducer;

        let appSyncFilter = {
            input: {
                financialReimbursementExtensionsId: currentQuarter.id,
                type: "BY_DATE",
                for: "FINANCIAL_REIMBURSEMENT",
                year: selectedYear,
                group: selectedCounty.groupName,
                status: "PENDING",
                dueDate: payload.dueDate,
                reasonForExtension: payload.reasonForExtension,
                createdAt: new Date(),
                updatedAt: new Date(),
                updatedBy: user.username,
            }
        }

        console.log(appSyncFilter)
        
        try {
            let submittedRes = await API.graphql(graphqlOperation(mutations.createExtension, appSyncFilter));
            console.log(submittedRes)

            // Update financial reimbursement

            appSyncFilter = {
                input: {
                    id: currentQuarter.id,
                    financialReimbursementCurrentExtensionId: submittedRes.data.createExtension.id,
                    updatedBy: user.username,
                    updatedAt: new Date(),
                }
            };

            submittedRes = await API.graphql(graphqlOperation(updateFinancialReimbursement, appSyncFilter));            
            let filter = {
                id: currentQuarter.id
            };


            const quarterRes = await API.graphql(graphqlOperation(GetFinancialReimbursement, filter));


            dispatch({ type: CURRENT_QUARTER, payload: quarterRes.data.getFinancialReimbursement });

            await sendExtensionRequestEmail(payload, currentQuarter, selectedCounty, submittedRes.data.createExtension);

            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: "Request for an extension submitted successfully" } }))

            await getAllExtensions()(dispatch, getState);

            callback && callback()
        } catch(e) {
            console.log("Error occured while submitting the extension", e)
        }

        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });

    }
}

// Sends an extension request notification email to lempg state positions
async function sendExtensionRequestEmail(payload, currentQuarter, selectedCounty) {
    if (process.env.REACT_APP_NODE_ENV === "PRODUCTION") {
        let filter = {
            where: {
                pvPositionName: `LEMPG State Access`
            },
            include: {
                relation: "account2positions",
                scope: {
                    include: "accounts"
                }
            }
        };
        let users = [];
        let authObj = JSON.parse(sessionStorage.getItem("userAuthCreds"));
        let posData = await axios.get(`${process.env.REACT_APP_PALMETTO_ENDPOINT}/api/positions?filter=${JSON.stringify(filter)}&access_token=${authObj.id}`);
        if (posData && posData.data && posData.data[0] && posData.data[0].account2positions) {
            // Extract the Accounts from the connector table
            posData.data[0].account2positions.forEach((acct) => {
                if (acct && acct.accounts && !acct.accounts.pvVoid) {
                    users.push(acct.accounts);
                }
            });
        } else {
            // eslint-disable-next-line no-console
            console.error("Bad Response:", posData);
            return;
        }

        let numQuarter = parseInt(currentQuarter.quarter.replace("quarter", ""));
        let dueDate = moment(payload.dueDate).format("MM/DD/YYYY");

        // Generate and send the emails.
        let messagePayload = {
            smsEmailObjects: []
        };

        for (let i = 0; i < users.length; i++) {
            let account = users[i];
            // Create the Access Token
            let accessTokenRes = await axios.post(process.env.REACT_APP_PALMETTO_ENDPOINT + "/api/accounts/" + account.id + "/accessTokens/?access_token=" + authObj.id, {
                "id": uuid.v4(),
                "ttl": 604800,
                "userId": account.id
            });
            let token = accessTokenRes.data.id;
            let magicURL = `https://supplemental.palmettoeoc.com?access_token=${token}&type=fr_extension&user_id=${account.id}&group_id=${selectedCounty.id}&quarter=${currentQuarter.id}`;
            let emailAddr = account.email;
            let HTMLbody = `<p>${account.ncPersonGivenName} ${account.ncPersonSurName},</p>
            <p>${capitalize(selectedCounty.groupName)} County has requested to move the due date for Q${numQuarter} financial reimbursement to ${dueDate}. The reason for the request is: ${payload.reasonForExtension}</p>
            <a href='${magicURL}'>${magicURL}</a>`;

            let textBody = ``;

            let emailObj = {
                pvMessageType: "EMAIL",
                pvEmailMessageText: textBody,
                pvEmailMessageHtml: HTMLbody,
                pvEmailSubject: `Supplemental System - Financial Reimbursements - Extension Notice about ${capitalize(payload.groupName)} County`,
                pvRecipientFirstName: account.ncPersonGivenName,
                pvRecipientLastName: account.ncPersonSurName,
                pvRecipientEmailAddress: emailAddr
            };
            messagePayload.smsEmailObjects.push(emailObj);
        }

        let resp = await axios.post(`${process.env.REACT_APP_PALMETTO_ENDPOINT}/messages/send?user_id=${authObj.userId}&access_token=${authObj.id}`, messagePayload, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return resp;

    } else {
        // eslint-disable-next-line no-console
        console.warn("This is not Prod. Refusing to send Messages!");
    }
}

export function requestProgressReportingExtension(payload, callback) {
    return async function(dispatch, getState) {
        // groupNarrativeM2MExtensionsId

        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });
        // Steps to request an extension

        // 1. County puts in an extension request, create an extension entry with the financial reimbursement id for the county.

        const { user, currentGroupNarrativeM2M, selectedCounty, selectedYear } = getState().rootReducer;

        let appSyncFilter = {
            input: {
                groupNarrativeM2MExtensionsId: currentGroupNarrativeM2M.id,
                type: payload.type,
                for: "PROGRESS_REPORTING",
                year: selectedYear,
                group: selectedCounty.groupName,
                status: "PENDING",
                dueDate: payload.dueDate,
                reasonForExtension: payload.reasonForExtension,
                quarterTo: payload.quarterTo,
                quarterFrom: payload.quarterFrom,
                createdAt: new Date(),
                updatedAt: new Date(),
                updatedBy: user.username,
            }
        }

        try {
            let submittedRes = await API.graphql(graphqlOperation(mutations.createExtension, appSyncFilter));

            // 2. Update financial reimbursement with the current extension information since there is no many to many connection defined between Extension model and financial reimbursements model

            appSyncFilter = {
                input: {
                    id: currentGroupNarrativeM2M.id,
                    groupNarrativeM2MCurrentExtensionId: submittedRes.data.createExtension.id,
                    updatedBy: user.username,
                    updatedAt: new Date(),
                }
            };

            submittedRes = await API.graphql(graphqlOperation(mutations.updateGroupNarrativeM2M, appSyncFilter));

            // Send an email to chief-of-staff, telling hime that county's has requested extension.

            if (process.env.REACT_APP_NODE_ENV == "PRODUCTION") {
                let groupName = selectedCounty.groupName.toLowerCase().replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
                let emailBody = "<p>" + groupName + " county has requested the progress report due date for work element " + payload.narrativeTitle +  " be extended until " + payload.dueDate + ".</p>.";
                let authObj = JSON.parse(sessionStorage.getItem("userAuthCreds"));
                let messagePayload = {
                    "smsEmailObjects": [
                        {
                            "pvMessageType": "EMAIL",
                            "pvEmailMessageText": "TEST TEST TEST",
                            "pvEmailMessageHtml": emailBody,
                            "pvEmailSubject": "Supplemental System -" + groupName + " County Progress Report Extension Request",
                            "pvRecipientFirstName": "Steven",
                            "pvRecipientLastName": "Batson",
                            "pvRecipientEmailAddress": "sbatson@emd.sc.gov",
                            // "pvRecipientEmailAddress": "rudra@krum.io"
                        }
                    ]
                };
                axios.post(process.env.REACT_APP_PALMETTO_ENDPOINT + "/messages/send?user_id=" + user.id + "&access_token=" + authObj.id, messagePayload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then((response) => {
                   console.log("Message(s) sent")
                }).catch((error) => console.log(error));            
            } else {
                console.log("Not prod")
            }
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: "Request for an extension submitted successfully" } }))

            await getAllExtensions()(dispatch, getState);

            callback && callback()
        } catch (e) {
            console.log("Error occured while submitting the extension", e)
        }

        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });

    }
}

export function requestGrantApplicationExtension(payload, callback) {
    return async function(dispatch, getState) {

        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });
        // Steps to request an extension

        // 1. County puts in an extension request, create an extension entry with the grant application id for the county.

        const {user, grantApplication, selectedCounty, selectedYear} = getState().rootReducer;

        let appSyncFilter = {
            input: {
                grantApplicationExtensionsId: grantApplication.id,
                type: "BY_DATE",
                for: "GRANT_APPLICATION",
                year: selectedYear,
                group: selectedCounty.groupName,
                status: "APPROVED",
                dueDate: payload.dueDate,
                reasonForExtension: payload.reasonForExtension,
                createdAt: new Date(),
                updatedAt: new Date(),
                updatedBy: user.username,
            }
        }

        try {
            let submittedRes = await API.graphql(graphqlOperation(mutations.createExtension, appSyncFilter));

            // Update grant application
            appSyncFilter = {
                input: {
                    id: grantApplication.id,
                    grantApplicationCurrentExtensionId: submittedRes.data.createExtension.id,
                    updatedBy: user.username,
                    updatedAt: new Date(),
                }
            };

            submittedRes = await API.graphql(graphqlOperation(updateGrantApplication, appSyncFilter));

            await sendGrantAppExtensionRequestEmail(payload, selectedCounty);

            dispatch(getCountyApplicationData());
            
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: "Request for an extension submitted successfully" } }))
            callback && callback()
        } catch(e) {
            console.log("Error occured while submitting the extension", e)
        }

        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });

    }
}

async function sendGrantAppExtensionRequestEmail(payload, selectedCounty) {
    if (process.env.REACT_APP_NODE_ENV === "PRODUCTION") {
        let filter = {
            where: {
                pvPositionName: `LEMPG State Access`
            },
            include: {
                relation: "account2positions",
                scope: {
                    include: "accounts"
                }
            }
        };
        let users = [];
        let authObj = JSON.parse(sessionStorage.getItem("userAuthCreds"));
        let posData = await axios.get(`${process.env.REACT_APP_PALMETTO_ENDPOINT}/api/positions?filter=${JSON.stringify(filter)}&access_token=${authObj.id}`);
        if (posData && posData.data && posData.data[0] && posData.data[0].account2positions) {
            // Extract the Accounts from the connector table
            posData.data[0].account2positions.forEach((acct) => {
                if (acct && acct.accounts && !acct.accounts.pvVoid) {
                    users.push(acct.accounts);
                }
            });
        } else {
            console.error("Bad Response:", posData);
            return;
        }

        let dueDate = moment(payload.dueDate).format("MM/DD/YYYY");

        // Generate and send the emails.
        let messagePayload = {
            smsEmailObjects: []
        };

        for (let i = 0; i < users.length; i++) {
            let account = users[i];
            let emailAddr = account.email;
            let HTMLbody = `<p>${account.ncPersonGivenName} ${account.ncPersonSurName},</p>
            <p>${capitalize(selectedCounty.groupName)} County has extended the grant application due date to ${dueDate}.</p>`;

            let textBody = ``;

            let emailObj = {
                pvMessageType: "EMAIL",
                pvEmailMessageText: textBody,
                pvEmailMessageHtml: HTMLbody,
                pvEmailSubject: `Supplemental System - Grant Application - Extension Notice about ${capitalize(selectedCounty.groupName)} County`,
                pvRecipientFirstName: account.ncPersonGivenName,
                pvRecipientLastName: account.ncPersonSurName,
                pvRecipientEmailAddress: emailAddr
            };
            messagePayload.smsEmailObjects.push(emailObj);
        }

        let resp = await axios.post(`${process.env.REACT_APP_PALMETTO_ENDPOINT}/messages/send?user_id=${authObj.userId}&access_token=${authObj.id}`, messagePayload, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return resp;

    } else {
        console.log("This is not Prod. Refusing to send Messages!");
    }
}

export function setActionsForFinancialReimbursementExtensions(payload) {
    return async function(dispatch, getState) {
        dispatch({ type: CURRENT_QUARTER, payload: payload });
        dispatch({ type: CURRENT_EXTENSION, payload: payload.currentExtension });
    }
}

export function setActionsForProgressReportingExtensions(payload) {
    return async function(dispatch, getState) {
        dispatch({ type: CURRENT_GROUP_NARRATIVE_M2M, payload: payload });
        dispatch({ type: CURRENT_NARRATIVE, payload: payload.narrative });
        dispatch({ type: CURRENT_EXTENSION, payload: payload.currentExtension });
    }
}

export function approveExtensionFinancialReimbursement(payload, callback) {
    return async function(dispatch, getState) {

        const { user, currentQuarter, currentExtension, counties } = getState().rootReducer;
        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

        try {
            // Update the extension
            let appSyncInput = {};
            //if (currentExtension.type == "BY_DATE") {
                appSyncInput = {
                    input: {
                        id: currentExtension.id,
                        status: "APPROVED",
                        updatedAt: new Date(),
                        updatedBy: user.username,
                    }
                }
                if (payload.adjustment) {
                    appSyncInput.input.dateAdjustedTo = payload.dateAdjustedTo;
                    appSyncInput.input.reasonForAdjustment = payload.reasonForAdjustment || null
                }

            //}

            let updateExtensionRes = await API.graphql(graphqlOperation(mutations.updateExtension, appSyncInput));

            // Update financial reimbursement for live updating

            let filter = {
                id: currentQuarter.id,
                // set the current extension to null
                // currentExtension: null,
            };


            const quarterRes = await API.graphql(graphqlOperation(GetFinancialReimbursement, filter));

            dispatch({ type: CURRENT_QUARTER, payload: quarterRes.data.getFinancialReimbursement });

            // Update counties array

            if (counties) {
                counties.forEach((county, index) => {
                    county.financialreimbursement.items.forEach(( item, finIndex ) => {
                        if(item.id == currentQuarter.id) {
                            county.financialreimbursement.items[finIndex] = quarterRes.data.getFinancialReimbursement
                        }
                    })
                })
                dispatch({ type: SET_COUNTIES_FOR_STATE_USER, payload: JSON.parse(JSON.stringify(counties)) });
            }


            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: "Extension approved successfully" } }))
            
            // Update extensions page
            await getAllExtensions()(dispatch, getState);
            callback && callback()            
        }
        catch(e) {
            console.log("Error", e)
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: "Error occured while approving the extension" } }))
        }

        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
    }
}

export function deniedExtensionProgressReporting(payload, callback) {
    return async function (dispatch, getState) {
     
        const { user, currentGroupNarrativeM2M, currentExtension, counties } = getState().rootReducer;
        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

        try {
            // Update the extension
            let appSyncInput = {
                input: {
                    id: currentExtension.id,
                    status: "DENIED",
                    updatedAt: new Date(),
                    updatedBy: user.username,
                }
            };

            if (payload.reasonForDeniedExtension) {
                appSyncInput.input.reasonForDeniedExtension = payload.reasonForDeniedExtension || null;
            }
            
            let updateExtensionRes = await API.graphql(graphqlOperation(mutations.updateExtension, appSyncInput));
                    
            // 2. Update financial reimbursement with the current extension information since there is no many to many connection defined between Extension model and financial reimbursements model
            let appSyncFilter = {
                input: {
                    id: currentGroupNarrativeM2M.id,
                    groupNarrativeM2MCurrentExtensionId: updateExtensionRes.data.updateExtension.id,
                    updatedBy: user.username,
                    updatedAt: new Date()
                }
            };
           
            let submittedRes = await API.graphql(graphqlOperation(mutations.updateGroupNarrativeM2M, appSyncFilter));
                 
            // Update groupnarratives for live updating

            let filter = {
                id: currentGroupNarrativeM2M.id,
            };

            const quarterRes = await API.graphql(graphqlOperation(queries.getGroupNarrativeM2M, filter));
            dispatch({ type: CURRENT_GROUP_NARRATIVE_M2M, payload: quarterRes.data.getGroupNarrativeM2M });

            // Update counties array

            if (counties) {
                counties.forEach((county, index) => {
                    county.narratives.items.forEach((item, finIndex) => {
                        if (item.id == currentGroupNarrativeM2M.id) {
                            county.narratives.items[finIndex] = quarterRes.data.getGroupNarrativeM2M
                        }
                    })
                })
                dispatch({ type: SET_COUNTIES_FOR_STATE_USER, payload: JSON.parse(JSON.stringify(counties)) });
            }

            // Send an email to county P.O.C. that progress report extension approved.

            if (process.env.REACT_APP_NODE_ENV == "PRODUCTION") {
                let emailBody = "<p> The request to move progress report work element " + currentGroupNarrativeM2M.narrative.narrativeTitle  + " has been denied.";
                if (payload.reasonForDeniedExtension) {
                    emailBody += " It was denied because " + payload.reasonForDeniedExtension  + ".</p>";
                }
                let authObj = JSON.parse(sessionStorage.getItem("userAuthCreds"));
                let groupName = currentExtension.group.charAt(0).toUpperCase() + currentExtension.group.substring(1);
                let messagePayload = {
                    "smsEmailObjects": [
                        {
                            "pvMessageType": "EMAIL",
                            "pvEmailMessageText": "TEST TEST TEST",
                            "pvEmailMessageHtml": emailBody,
                            "pvEmailSubject": "Supplemental System - Progress Report Extension Request Denied for " + groupName,
                            "pvRecipientFirstName": currentGroupNarrativeM2M.narrative.narrativePOCFirstName,
                            "pvRecipientLastName": currentGroupNarrativeM2M.narrative.narrativePOCLastName,
                            "pvRecipientEmailAddress": currentGroupNarrativeM2M.narrative.narrativePOCEmailAddress,
                            // "pvRecipientEmailAddress": "rudra@krum.io"
                        }
                    ]
                };
           
                axios.post(process.env.REACT_APP_PALMETTO_ENDPOINT + "/messages/send?user_id=" + user.id + "&access_token=" + authObj.id, messagePayload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then((response) => {
                   console.log("Message(s) sent")
                }).catch((error) => console.log(error));            
            } else {
                console.log("Not prod")
            }

            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: "Extension denied successfully" } }))
            await getAllExtensions()(dispatch, getState);

            callback && callback()
        }
        catch (e) {
            console.log("Error", e)
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: "Error occured while denying the extension" } }))
        }

        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
    }
}

const sendEmailToCounty = async (payload) => {

    if (process.env.REACT_APP_NODE_ENV === "PRODUCTION") {

        let users = [];
        let authObj = JSON.parse(sessionStorage.getItem("userAuthCreds"));
        let posData = await axios.get(process.env.REACT_APP_PALMETTO_ENDPOINT + "/api/groups/" + payload.palmettoId + "?access_token=" + authObj.id + "&filter=" + JSON.stringify({ include: ["group_contact"] }));

        if (posData.data && posData.data.group_contact && posData.data.group_contact.length) {
            // Extract the Accounts from the connector table
            users = posData.data.group_contact || []

            // Generate and send the emails.
            let messagePayload = {
                smsEmailObjects: []
            };

            for (let i = 0; i < users.length; i++) {
                let account = users[i];
                // Create the Access Token

                let emailObj = {
                    pvMessageType: "EMAIL",
                    pvEmailMessageText: '',
                    pvEmailMessageHtml: payload.body,
                    pvEmailSubject: payload.subject,
                    pvRecipientFirstName: account.pvFirstName,
                    pvRecipientLastName: account.pvLastName,
                    pvRecipientEmailAddress: account.pvEmail
                };
                messagePayload.smsEmailObjects.push(emailObj);
            };

            sendEmail(messagePayload, authObj, payload.emailRecordText, (err, response) => {
                if (err) {
                    console.log(err)
                } else {
                    console.log("messages sent")
                }
            })
        } else {
            console.error("Bad Response:", posData);
        }
    } else {
        console.log("This is not Prod. Refusing to send Messages!");
    }

}

export function approveExtensionProgressReporting(payload, callback) {
    return async function (dispatch, getState) {

        const { user, currentGroupNarrativeM2M, currentExtension, counties, selectedYear } = getState().rootReducer;
        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

        try {
            // Update the extension
            let appSyncInput = {
                input: {
                    id: currentExtension.id,
                    status: "APPROVED",
                    updatedAt: new Date(),
                    updatedBy: user.username,

                }
            };

            if (payload.adjust) {
                appSyncInput.input.dateAdjustedTo = payload.dateAdjustedTo;
                // appSyncInput.input.dueDate = payload.dateAdjustedTo;
                appSyncInput.input.reasonForAdjustment = payload.reasonForAdjustment || null;
            } else if (payload.dueDate) {
                appSyncInput.input.dueDate = payload.dueDate;
            }
            
            if (payload.quarterTo) {
               appSyncInput.input.dueDate = null; //payload.dueDate || null;
               appSyncInput.input.reasonForExtension = null;
            }

            let updateExtensionRes = await API.graphql(graphqlOperation(mutations.updateExtension, appSyncInput));
                
            // 2. Update financial reimbursement with the current extension information since there is no many to many connection defined between Extension model and financial reimbursements model
            let appSyncFilter = {
                input: {
                    id: currentGroupNarrativeM2M.id,
                    groupNarrativeM2MCurrentExtensionId: updateExtensionRes.data.updateExtension.id,
                    updatedBy: user.username,
                    updatedAt: new Date()
                }
            };
          
            if (payload.quarterTo) {
                appSyncFilter.input.quarter = payload.quarterTo;
                //appSyncFilter.input.dueDate = new Date();
            }
            
            let submittedRes = await API.graphql(graphqlOperation(mutations.updateGroupNarrativeM2M, appSyncFilter));
         
            // Update groupnarratives for live updating

            let filter = {
                id: currentGroupNarrativeM2M.id,
            };

            const quarterRes = await API.graphql(graphqlOperation(queries.getGroupNarrativeM2M, filter));
            dispatch({ type: CURRENT_GROUP_NARRATIVE_M2M, payload: quarterRes.data.getGroupNarrativeM2M });

            // Update counties array
            let countyGettingApproved

            if (counties) {
                counties.forEach((county, index) => {
                    county.narratives.items.forEach((item, finIndex) => {
                        if (item.id == currentGroupNarrativeM2M.id) {
                            countyGettingApproved = county;
                            county.narratives.items[finIndex] = quarterRes.data.getGroupNarrativeM2M
                        }
                    })
                })
                dispatch({ type: SET_COUNTIES_FOR_STATE_USER, payload: JSON.parse(JSON.stringify(counties)) });
            }

            // Send an email to county P.O.C. that progress report extension approved.

            if (process.env.REACT_APP_NODE_ENV == "PRODUCTION") {
                let emailBody = "<p> The request for an extention to progress report work element " + currentGroupNarrativeM2M.narrative.narrativeTitle  + " has been approved.";

                if(payload.type == 'BY_DATE') {
                    if (payload.adjustment) {
                        emailBody += " It was adjusted from the requested date of  " + currentGroupNarrativeM2M.currentExtension.dueDate + " to " + payload.dueDate;
                        emailBody += payload.reasonForAdjustment ? " because " + payload.reasonForAdjustment  + ".</p>" : ".</p>";
                    } else {
                        emailBody += " It been extended until " + currentGroupNarrativeM2M.currentExtension.dueDate + ".";
                    }
                } else {
                    // BY_QUARTER move
                    emailBody += " It been extended until " + payload.quarterTo + ".";
                }
                let authObj = JSON.parse(sessionStorage.getItem("userAuthCreds"));
                let groupName = currentExtension.group.charAt(0).toUpperCase() + currentExtension.group.substring(1);
                let messagePayload = {
                    "smsEmailObjects": [
                        {
                            "pvMessageType": "EMAIL",
                            "pvEmailMessageText": "TEST TEST TEST",
                            "pvEmailMessageHtml": emailBody,
                            "pvEmailSubject": "Supplemental System - Progress Report Extension Request Approved for " + groupName,
                            "pvRecipientFirstName": currentGroupNarrativeM2M.narrative.narrativePOCFirstName,
                            "pvRecipientLastName": currentGroupNarrativeM2M.narrative.narrativePOCLastName,
                            "pvRecipientEmailAddress": currentGroupNarrativeM2M.narrative.narrativePOCEmailAddress,
                            // "pvRecipientEmailAddress": "rudra@krum.io"
                        }
                    ]
                };

                // send counties email
                let countyEmailPayload = {
                    palmettoId: countyGettingApproved.palmettoId,
                    emailRecordText: 'Supplemental System - Progress Reporting - send county an email when an extension is approved',
                    subject: 'Supplemental System - Progress reporting - extension approval notice',
                    body: ''
                }

                if (payload.type == 'BY_DATE') {
                    countyEmailPayload.body = `${capitalize(currentExtension.group)} County, the EMD has approved your request to extend the deadline for submitting for FY${selectedYear} work element ${currentGroupNarrativeM2M.narrative.narrativeTitle} until ${currentGroupNarrativeM2M.currentExtension.dueDate}`
                } else {
                    countyEmailPayload.body = `${capitalize(currentExtension.group)}, County the EMD has approved your request to extend the deadline for submitting for FY${selectedYear} work element ${currentGroupNarrativeM2M.narrative.narrativeTitle} until ${payload.quarterTo}`
                }

                sendEmailToCounty(countyEmailPayload)                
           
                axios.post(process.env.REACT_APP_PALMETTO_ENDPOINT + "/messages/send?user_id=" + user.id + "&access_token=" + authObj.id, messagePayload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then((response) => {
                   console.log("Message(s) sent")
                }).catch((error) => console.log(error));            
            } else {
                console.log("Not prod")
            }

            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: "Extension approved successfully" } }))
            await getAllExtensions()(dispatch, getState);

            callback && callback()
        }
        catch (e) {
            console.log("Error", e)
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: "Error occured while approving the extension" } }))
        }

        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
    }
}


export function adjustExtension(payload, callback) {
    return async function(dispatch, getState) {
        callback && callback()
    }
}

function _convertToTitleCase(str) {
    str = str.toLowerCase();
    return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
}

export function getAllExtensions() {
    return async function(dispatch, getState) {
        const {counties} = getState().rootReducer;

        let frCards = [];
        let prCards = [];
        let totalCards = [];

        // 1. Get financial reimbursements extensions

        counties.forEach((county) => {
            if (county.financialreimbursement && county.financialreimbursement.items && county.financialreimbursement.items.length) {
                county.financialreimbursement.items.forEach((item) => {

                    if (item.currentExtension && item.currentExtension.status == "PENDING") {
                        item.title = _convertToTitleCase(item.currentExtension.group) + " • Q" + (item && item.quarter && parseInt(item.quarter.split("").reverse().join("")))
                        item.subtitle = "Financial Reimbursement"
                        frCards.push(item)
                    }
                })
            }
            if (county.narratives && county.narratives.items && county.narratives.items.length) {
                county.narratives.items.forEach((item) => {
                    if (item.currentExtension && item.currentExtension.status == "PENDING") {
                        if (item.currentExtension.type == "BY_DATE") {
                            item.title = _convertToTitleCase(item.currentExtension.group) + " • Q" + (item && item.quarter && parseInt(item.quarter.split("").reverse().join("")))
                            item.subtitle = "Progress Reporting • " + item.narrative.narrativeTitle
                            frCards.push(item)
                        } else if (item.currentExtension.type == "BY_QUARTER") {
                            item.title = _convertToTitleCase(item.currentExtension.group) + " • Q" + (item && item.currentExtension.quarterFrom && parseInt(item.currentExtension.quarterFrom.split("").reverse().join(""))) + " • Q" + (item && item.currentExtension.quarterTo && parseInt(item.currentExtension.quarterTo.split("").reverse().join("")))
                            item.subtitle = "Progress Reporting • " + item.narrative.narrativeTitle
                            frCards.push(item)
                        }
                    }
                })
            }
        })

        // 2. Get progress reporting extensions (TODO)
        // 3. Concat financial reimbursements and progress report extension array
        totalCards = frCards.concat(prCards)
        dispatch({ type: ALL_EXTENSIONS, payload: totalCards });

    }
}

// File library functions

function _toArray(obj) {
    return Object.keys(obj).map(function (key) {
        return {
            key: key,
            title: obj[key].title,
            files: obj[key].files,
            file: obj[key].file,
        };
    });
}

export function getFilesAssociatedWithCounty() {
    return async function (dispatch, getState) {

        await getCountyApplicationData()(dispatch, getState);

        let keyFilesObj = {};
        const { selectedCounty, grantApplication, selectedYear } = getState().rootReducer;

        // 2019.Guidelines.and.Application.Procedures.pdf

        if (!keyFilesObj[selectedYear + '.Guidelines.and.Application.Procedures.pdf']) {
            keyFilesObj[selectedYear + '.Guidelines.and.Application.Procedures.pdf'] = {
                title: selectedYear + " Guidelines and Application Procedures",
            }
        }
        
        // County pre-award letter

        if(grantApplication.awardDocumentsUploadedByCounty) {
            if (!keyFilesObj['awardDocumentsUploadedByCounty']) {
                keyFilesObj['awardDocumentsUploadedByCounty'] = {
                    title: "County pre-award letter",
                    files: [grantApplication.awardDocumentsUploadedByCounty]
                }
            }
        }

        // Acceptance of Audit requirements

        if (grantApplication.acceptanceOfAuditReqFile) {
            if (!keyFilesObj['acceptanceOfAuditReqFile']) {
                keyFilesObj['acceptanceOfAuditReqFile'] = {
                    title: "Acceptance of Audit Requirements",
                    files: [grantApplication.acceptanceOfAuditReqFile]
                }
            }
        }

        // Certification documents

        if (grantApplication.certificationDocumentFile) {
            if (!keyFilesObj['certificationDocumentFile']) {
                keyFilesObj['certificationDocumentFile'] = {
                    title: "Certification Document",
                    files: [grantApplication.certificationDocumentFile]
                }
            }
        }

        // Assurances non construction

        if (grantApplication.assurancesNonContructionFile) {
            if (!keyFilesObj['assurancesNonContructionFile']) {
                keyFilesObj['assurancesNonContructionFile'] = {
                    title: "Assurances Non-Construction",
                    files: [grantApplication.assurancesNonContructionFile]
                }
            }
        }

        // Lobbying Certification

        if (grantApplication.lobbyingCertificationFile) {
            if (!keyFilesObj['lobbyingCertificationFile']) {
                keyFilesObj['lobbyingCertificationFile'] = {
                    title: "Lobbying Certification",
                    files: [grantApplication.lobbyingCertificationFile]
                }
            }
        }

        // Equipment Policy

        if (grantApplication.edmEquipmentPolicyFile) {
            if (!keyFilesObj['edmEquipmentPolicyFile']) {
                keyFilesObj['edmEquipmentPolicyFile'] = {
                    title: "Equipment Policy",
                    files: [grantApplication.edmEquipmentPolicyFile]
                }
            }
        }

        // Scope of work

        if (grantApplication.scopeOfWorkFile) {
            if (!keyFilesObj['scopeOfWorkFile']) {
                keyFilesObj['scopeOfWorkFile'] = {
                    title: "Scope of Work",
                    files: [grantApplication.scopeOfWorkFile]
                }
            }
        }   
        
        // Scope of work

        if (grantApplication.narrativesFile) {
            if (!keyFilesObj['narrativesFile']) {
                keyFilesObj['narrativesFile'] = {
                    title: "Narratives",
                    files: [grantApplication.narrativesFile]
                }
            }
        }           

        // Position Description

        if (grantApplication.positionDescFile) {
            if (!keyFilesObj['positionDescFile']) {
                keyFilesObj['positionDescFile'] = {
                    title: "Position Description",
                    files: [grantApplication.positionDescFile]
                }
            }
        }
        // Position description has multiple files
        if(grantApplication.extraFile) {
            try {
                let extraFilesObj = JSON.parse(grantApplication.extraFile);
                extraFilesObj.forEach( (file) => {
                    if (file.type === "grantApplicationPositionDescFileId") {

                        if (!keyFilesObj['positionDescFile']) {
                            keyFilesObj['positionDescFile'] = {
                                title: "Position Description",
                                files: []
                            }
                        }

                        keyFilesObj['positionDescFile'].files.push(file);
                    }
                })
            } catch (e) {
                console.log(e)
            }
        }

        // Grant Application versions

        if (grantApplication.grantApplicationRevision
            && grantApplication.grantApplicationRevision.items
            && grantApplication.grantApplicationRevision.items.length) {

            /**
             * grantApplicationLastUpdatedAt object is used here because we have to know when was the grant application was lasted updated.
             * 
             */

            grantApplication.grantApplicationRevision.items.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(a.grantApplicationLastUpdatedAt || a.updatedAt) - new Date(b.grantApplicationLastUpdatedAt || b.updatedAt);
            });
            grantApplication.grantApplicationRevision.items.forEach((item, index) => {
                keyFilesObj['grantApplicationRevision-' + item.id] = {
                    title: "Grant Application v" + (index + 1) + " (" + moment(item.grantApplicationLastUpdatedAt || item.updatedAt).format("MM/DD/YYYY") + ")",
                    file: item.revision,
                    initial: index == 0 ? true : false,
                    // files: [item.revision]
                }

            })
        }

        // Grant application - approved

        if ((grantApplication.status == "APPROVED") || (grantApplication.status == "AWARDED")) {
            if (!keyFilesObj['grantApplication']) {
                var versions = (grantApplication.grantApplicationRevision
                    && grantApplication.grantApplicationRevision.items
                    && grantApplication.grantApplicationRevision.items.length) || 0
                keyFilesObj['grantApplication'] = {
                    title: "Grant Application v" + (versions + 1) + " (" + moment(grantApplication.updatedAt).format("MM/DD/YYYY") + ")",
                    initial: true,
                }

                if (grantApplication.grantApplicationRevision && grantApplication.grantApplicationRevision.items && grantApplication.grantApplicationRevision.items.length) {
                    keyFilesObj['grantApplication'].initial = false
                }
            }
        }


        // Application cover letter uploaded by state

        if (grantApplication.awardCoverLetterUploadedByState) {
            if (!keyFilesObj['awardCoverLetterUploadedByState']) {
                keyFilesObj['awardCoverLetterUploadedByState'] = {
                    title: "Application cover letter",
                    files: [grantApplication.awardCoverLetterUploadedByState]
                }
            }
        }

        // Award letter state

        if (grantApplication.awardDocumentUploadedByState) {
            if (!keyFilesObj['awardDocumentUploadedByState']) {
                keyFilesObj['awardDocumentUploadedByState'] = {
                    title: "Award letter - state",
                    files: [grantApplication.awardDocumentUploadedByState]
                }
            }
        }

        // Award letter county

        if (grantApplication.awardDocumentsUploadedByCounty) {
            if (!keyFilesObj['awardDocumentsUploadedByCounty']) {
                keyFilesObj['awardDocumentsUploadedByCounty'] = {
                    title: "Award letter - county",
                    files: [grantApplication.awardDocumentsUploadedByCounty]
                }
            }
        }

        // Special Instructions

        if (grantApplication.awardSpecialInstructionsUploadedByState) {
            if (!keyFilesObj['awardSpecialInstructionsUploadedByState']) {
                keyFilesObj['awardSpecialInstructionsUploadedByState'] = {
                    title: "Special instructions",
                    files: [grantApplication.awardSpecialInstructionsUploadedByState]
                }
            }
        }

        // Financial Reimburesements documents

        if (selectedCounty
            && selectedCounty.financialreimbursement
            && selectedCounty.financialreimbursement.items
            && selectedCounty.financialreimbursement.items.length) {

            selectedCounty.financialreimbursement.items.forEach((item) => {
                if ((item.status == "SUBMITTED") || (item.status == "APPROVED") || (item.status == "AWARDED") || (item.status == "PROCESSED")) {
                    if (!keyFilesObj['financialreimbursement' + item.quarter]) {
                        keyFilesObj['financialreimbursement' + item.quarter] = {
                            title: "Financial Reimbursement Q" + parseInt(item.quarter.split("").reverse().join()),
                        }
                    }

                    // Gather financial reimbursement documents
                    /**
                     * personnel
                     * cert
                     * travel
                     * equipment
                     * supply
                     * other
                     * contractualService
                     */
                    if (!keyFilesObj['financialreimbursement' + item.quarter + "-documents"]) {
                        keyFilesObj['financialreimbursement' + item.quarter + "-documents"] = {
                            title: "Financial Reimbursement Q" + parseInt(item.quarter.split("").reverse().join()) + " Documents",
                            files: []
                        }
                    }

                    [   "personnel", 
                        "contractualService", 
                        "travel", 
                        "equipment", 
                        "supply",
                        "cert",
                        "other"
                    ].forEach((type) => {
                        if (item[type] && item[type].items && item[type].items.length) {
                            item[type].items.forEach( (subitem) => {
                                if(!subitem.deleted) {
                                    if(subitem.file) {
                                        let files = JSON.parse(subitem.file) 
                                        keyFilesObj['financialreimbursement' + item.quarter + "-documents"].files = keyFilesObj['financialreimbursement' + item.quarter + "-documents"].files.concat(files);
                                    }
                                }
                            })
                        }
                    })
                }
            })

        }


        // Tasking slips

        let quarterObj = {
            "quarter1": false,
            "quarter2": false,
            "quarter3": false,
            "quarter4": false,
        }

        if(selectedCounty 
            && selectedCounty.quarterlyprogressreporting
            && selectedCounty.quarterlyprogressreporting.items
            && selectedCounty.quarterlyprogressreporting.items.length) {
                
                selectedCounty.quarterlyprogressreporting.items.forEach( (item) => {
                    quarterObj[item.quarter] = true
                })
                
            }
            
        // Tasking slip - Q1
        if(quarterObj["quarter1"]) {
            if (!keyFilesObj['taskingslipquarter1']) {
                keyFilesObj['taskingslipquarter1'] = {
                    title: "Tasking Slip Q1",
                }
            }
        }
        // Tasking slip - Q2
        if(quarterObj["quarter2"]) {
            if (!keyFilesObj['taskingslipquarter2']) {
                keyFilesObj['taskingslipquarter2'] = {
                    title: "Tasking Slip Q2",
                }
            }
        }
        // Tasking slip - Q3
        if (quarterObj["quarter3"]) {
            if (!keyFilesObj['taskingslipquarter3']) {
                keyFilesObj['taskingslipquarter3'] = {
                    title: "Tasking Slip Q3",
                }
            }
        }
        // Tasking slip - Q4
        if (quarterObj["quarter4"]) {
            if (!keyFilesObj['taskingslipquarter4']) {
                keyFilesObj['taskingslipquarter4'] = {
                    title: "Tasking Slip Q4",
                }
            }
        }

        dispatch({ type: FILES_UPLOADED_BY_COUNTY, payload: _toArray(keyFilesObj) });


    }
}


// Activity Feed

export function saveComment(payload, field, callback) {
    return async function(dispatch, getState) {
        
        const { user, history, comments, fieldTitleReference, currentGroupNarrativeM2M } = getState().rootReducer;

        let _comments = comments;

        let appSyncFilter = {
            input: {
                [field]: currentGroupNarrativeM2M.id,
                createdAt: new Date(),
                updatedAt: new Date(),
                updatedBy: user.username,
                updatedByFirstName: user.ncPersonGivenName,
                updatedByLastName: user.ncPersonSurName || null,
                comment: payload.comment || "",
            }
        }

        let res = await API.graphql(graphqlOperation(mutations.createComment, appSyncFilter));
        _comments.push(res.data.createComment);

        let historyComments = generateHistoryComments(history, _comments, fieldTitleReference);

        // Commented because of update nature of react

        // dispatch({ type: ACTIVITY_FEED_HISTORY_COMMENTS, payload: JSON.parse(JSON.stringify(historyComments)) });


        if(callback) {
            callback(historyComments)
        }
    }
}

export function getActivityFeedHistory(item, history, fieldValueReference, fieldTitleReference, callback) {
    return async function(dispatch, getState) {
        if(callback) {
            callback(generateDetailedHistory(item, history, fieldValueReference, fieldTitleReference));
        }
    }
}

// Print functions
const REQUEST_CONFIG = {
    responseType: 'arraybuffer',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
};


export function printTable(type, payload, callback) {
    return async function(dispatch, getState) {

        const url = process.env.REACT_APP_PALMETTO_SERVERLESS_DOCUMENTS_ENDPOINT;

        axios.post(url + "/documents/" + type + "/print", payload,
            {
                ...REQUEST_CONFIG, 
            })
            .then((response) => {
                let ResponseType = response.headers["content-type"] || 'application/vnd.ms-excel';
                const TemporaryBlob = new Blob([response.data], {type: ResponseType});
                const UrlForExcelDocument = URL.createObjectURL(TemporaryBlob); 
                const link = document.createElement('a');
                link.href = UrlForExcelDocument
                link.setAttribute('download', type + '.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => console.log(error));

        if(callback) {
            callback()
        }
    }   
}

// Table sorting

export function setProgressReportingStateCountiesTableSort(payload) {
    return async function(dispatch, getState) {
        dispatch({ type: PROGRESS_REPORTING_STATE_COUNTIES_TABLE_SORT, payload: JSON.parse(JSON.stringify(payload)) });
    }
}

export function setProgressReportingStateCountyTableSort(payload) {
    return async function (dispatch, getState) {
        dispatch({ type: PROGRESS_REPORTING_STATE_COUNTY_TABLE_SORT, payload: JSON.parse(JSON.stringify(payload)) });
    }
}

export function setProgressReportingCountyTableSort(payload) {
    return async function (dispatch, getState) {
        dispatch({ type: PROGRESS_REPORTING_COUNTY_TABLE_SORT, payload: JSON.parse(JSON.stringify(payload)) });
    }
}

export function setFinancialReimbursementStateCountiesTableSort(payload) {
    return async function (dispatch, getState) {
        dispatch({ type: FINANCIAL_REIMBURSEMENT_STATE_COUNTIES_TABLE_SORT, payload: JSON.parse(JSON.stringify(payload)) });
    }
}

export function genPersonnelReport(payload, callback) {
    return async function (dispatch, getState) {
        const { counties, selectedYear } = getState().rootReducer;

        let startDate = moment("07/01/2019").year(selectedYear);
        let endDate = moment("06/30/2020").year(parseInt(selectedYear) + 1);

        let postPayload = {
            title: `${selectedYear} LEMPG Personnel Report (${startDate.format("MM/DD/YYYY")} - ${endDate.format("MM/DD/YYYY")})`,
            columns: [
                {
                    checked: true,
                    title: "County",
                    path: "county"
                },
                {
                    checked: true,
                    title: "Names",
                    path: "name"
                },
                {
                    checked: true,
                    title: "# Personnel",
                    path: "numPersonnel"
                },
                {
                    checked: true,
                    title: "# EMPG",
                    path: "numEMPG"
                },
                {
                    checked: true,
                    title: "# Match",
                    path: "numMatch"
                },
                {
                    checked: true,
                    title: "Notes",
                    path: "notes"
                }
            ],
            items: []
        };
        counties.forEach((county) => {
            let headerItem = {};
            headerItem.county = capitalize(county.groupName);

            let map = {}
            if (county.grantapplication.items) {
                let ga = county.grantapplication.items[0];
                headerItem.numPersonnel = 0

                ga.salary.items.forEach((item) => {
                    if (!item.deleted) {
                        headerItem.numPersonnel++
                    }
                })

                if (!(ga.salary.items && ga.salary.items[0])) {
                    headerItem.name = "";
                    headerItem.numPersonnel = 0;
                    headerItem.numEMPG = 0;
                    headerItem.numMatch = 0;
                } else {
                    // map[ga.salary.items[0].name] = 1
                    // headerItem.name = ga.salary.items[0].name;
                    headerItem.name = ''
                    headerItem.numEMPG = ga.salary.items.reduce((acc, curr) => curr.federal && !curr.deleted ? acc + 1 : 1, 0);
                    headerItem.numMatch = ga.salary.items.reduce((acc, curr) => curr.county && !curr.deleted ? acc + 1 : 1, 0);
                }
            } else {
                headerItem.name = "";
                headerItem.numPersonnel = 0;
                headerItem.numEMPG = 0;
                headerItem.numMatch = 0;
            }
            headerItem.notes = "";
            postPayload.items.push(headerItem);

            // Add Rows listing the names of the Personnel
            if (county.grantapplication.items) {
                let ga = county.grantapplication.items[0];
                // Skip the first name, since we already added it
                ga.salary.items.forEach(item => {
                    if (!map[item.name] && !item.deleted) {
                        let toAdd = {};
                        toAdd.county = "";
                        toAdd.numPersonnel = "";
                        toAdd.numEMPG = "";
                        toAdd.numMatch = "";
                        toAdd.notes = "";
                        toAdd.name = item.name;
                        postPayload.items.push(toAdd);
                        map[item.name] = 1
                    }
                });
            }
        });

        let url = process.env.REACT_APP_PALMETTO_SERVERLESS_DOCUMENTS_ENDPOINT;

        axios.post(url + "/documents/lempg/print", postPayload,
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'personnelReport.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => console.log(error));

        if (callback) {
            callback()
        }

    }
}

// Multi year workflow functions

// This function updates user selected-year setting and refreshes the page to get new data
export function selectYear(year) {
    return async function(dispatch, getState) {

        try {

            const { selectedYearObject, user} = getState().rootReducer;
            let authObj = JSON.parse(sessionStorage.getItem("userAuthCreds"));

            let savedSettingsRes = await axios.put(process.env.REACT_APP_PALMETTO_ENDPOINT + "/api/settings?access_token=" + authObj.id, {
                pvSettingID: selectedYearObject.pvSettingID || undefined,
                pvSettingType: "supplemental-system-selected-year",
                pvAccountID: user.id,
                pvSettingValue: year + "",
                pvEntryDate: new Date()
            });

            // if(selectedYearObject.id) {
            //     // Update the object
            //     await API.graphql(graphqlOperation(mutations.updateSetting, {
            //         input: {
            //             id: selectedYearObject.id,
            //             type: "selected-year",
            //             value: year + "",
            //             userId: user.id,
            //             updatedAt: new Date(),
            //             updatedBy: user.username,
            //         }
            //     }));
            // } else {
            //     // create the selected-year setting
            //     await API.graphql(graphqlOperation(mutations.createSetting, {
            //         input: {
            //             type: "selected-year",
            //             value: year + "",
            //             userId: user.id,
            //             createdAt: new Date(),
            //             updatedAt: new Date(),
            //             updatedBy: user.username,
            //         }
            //     }));
            // }

            window.location.reload()
 

        } catch(e) {
            console.error("Error occured while changing selected year", e)
        }
    }
}

// Pre grant award amount

export function selectQuarterPreFiscalYear(quarter) {
    return function (dispatch, getState) {
        dispatch({ type: SELECTED_QUARTER_PRE_FISCAL_YEAR, payload: quarter });
    }
}


export function setPreGrantSelectedCounty(payload) {
    return async function(dispatch, getState) {
        dispatch({ type: PRE_GRANT_SELECTED_COUNTY, payload: JSON.parse(JSON.stringify(payload)) });
    }
}

export function savePreGrantAwardAmount(payload, callback) {
    return async function(dispatch, getState) {
        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });
        const { preGrantSelectedCounty } = getState().rootReducer;

        await API.graphql(graphqlOperation(mutations.updateGroup, {
            input: {
                id: preGrantSelectedCounty.id,
                awardAmount: payload.awardAmount || null, 
                updatedAt: new Date(),
            }
        }));
        window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: "Award amount saved successfully" } }))

        callback && callback()
        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
    }
}

export function saveFileForNewFiscalYear(payload, fieldName, callback) {
    return async function(dispatch, getState) {

        const  { currentFiscalYear, user } = getState().rootReducer;

        let appSyncFilter = {
            input: {
                id: currentFiscalYear.id,
                [fieldName]: payload.file_id || null,
                updatedBy: user.username,
                updatedAt: new Date(),
            }
        };

        let fileRes = await API.graphql(graphqlOperation(mutations.updateFiscalYear, appSyncFilter));

        window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'File uploaded succesfully' } }))
        callback(fileRes)

    }
}


// Narratives functions

export function getFiscalYearNarratives(payload) {
    return async function(dispatch, getState) {

        const { currentFiscalYear } = getState().rootReducer;

        try {
            let res = await API.graphql(graphqlOperation(queries.listNarratives, {
                filter: {
                    year: {
                        eq: currentFiscalYear.year
                    }
                },
                limit: 100
            }))

            console.log(res.data.listNarratives.items)

            dispatch({ type: ALL_NARRATIVES, payload: res.data.listNarratives.items || [] });

        } catch(e) {

            // If the error is caught here then its likely that the fiscal year is not created

            // Look for `create-fiscal-year` event

            // eslint-disable-next-line
            console.error("Error while loading narratives", e)
            dispatch({ type: ALL_NARRATIVES, payload: [] });
        }

    }
}

export function resetNarrative() {
    return async function(dispatch, getState) {
        dispatch({ type: CURRENT_NARRATIVE, payload: {} })
        dispatch({ type: CURRENT_GROUP_NARRATIVE_M2M, payload: {} })
    }
}


export function setCurrentNarrative(payload) {
    return async function (dispatch, getState) {
        dispatch({ type: CURRENT_NARRATIVE, payload: payload })
    }
}

async function getLatestGroupNarrativesForAllGroups(dispatch, getState) {
    return new Promise( async (resolve, reject) => {
        let { lempgUserType, selectedYear, counties } = getState().rootReducer;
    
        let region_num = parseInt(lempgUserType.split("").reverse().join(""));
    
        let appSyncFilter = {};
    
        if (lempgUserType === "LEMPG_STATE_ACCESS") {
            appSyncFilter = {
                year: {
                    eq: selectedYear
                }
            };
        }
        else if (lempgUserType === "LEMPG_SME") {
            appSyncFilter = {
                year: {
                    eq: selectedYear
                }
            };
        }
        else if (lempgUserType.indexOf("REGION") >= 0) {
            appSyncFilter = {
                and: [
                    {
                        region: {
                            eq: "R" + region_num
                        }
                    },
                    {
                        year: {
                            eq: selectedYear
                        }
                    }
                ]
    
            };
        }
    
    
        const groupsResponseFromAppSync = await API.graphql(graphqlOperation(GetGroupsNarrativesM2M, {
            filter: appSyncFilter,
            limit: 100
        }));
        
        let updatedCounties = groupsResponseFromAppSync.data.listGroups.items;

        for(let i = 0; i < updatedCounties.length; i++) {
            for (let j = 0; j < counties.length; j++) {
                if(counties[j].id == updatedCounties[i].id) {
                    counties[j].narratives = updatedCounties[i].narratives;
                }
            }

        }

        dispatch({ type: SET_COUNTIES_FOR_STATE_USER, payload: counties });
        resolve();
    })
}

export function createNarrative(payload, callback) {
    return async function(dispatch, getState) {

        // This createNarrative function will perform two sets of operation
        // 1. Create a entry in Narrative model
        // 2. Create 46 entries in GroupsNarrativesM2M model using the group id and narrative id created in the step 1

        // If you dont do step 2 the narrative would be considered as a ghost narrative and no counties will be able to access it.

        const { currentFiscalYear, counties, user, selectedYear } = getState().rootReducer;
        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

        // Step 1

        let input = {
            input: {
                narrativeTitle: payload.narrativeTitle || null,
                narrativeCategory: payload.narrativeCategory || "NULL",
                narrativeShortDescription: payload.narrativeShortDescription || null,
                narrativeLongDescription: payload.narrativeLongDescription || null,
                // Recurring | Once
                narrativeFrequency: payload.narrativeFrequency || null,
                narrativePOCFirstName: payload.narrativePOCFirstName || null,
                narrativePOCLastName: payload.narrativePOCLastName || null,
                narrativePOCPhoneNumber: payload.narrativePOCPhoneNumber || null,
                narrativePOCEmailAddress: payload.narrativePOCEmailAddress || null,
                narrativePOCPositionTitle: payload.narrativePOCPositionTitle || null,
                usePalmettoForREMInformation: payload.usePalmettoForREMInformation || null,
                // quarter1 | quarter2 | quarter3 | quarter4 | all
                quarter: payload.quarter || null,
                deleted: false,
                createdAt: new Date(),
                updatedAt: new Date(),
                updatedBy: user.username,
                year: currentFiscalYear.year,
                narrativeUploadedFileId: payload.file_id || null
            }
        }

        let narrativeRes = await API.graphql(graphqlOperation(mutations.createNarrative, input))

        let createNarrative = narrativeRes.data.createNarrative;
        let funcs = []


        // For a recurring narrative, we have to create 46*4 group narratives

        if(payload.quarter == "all") {
            let allQuarters = [ "quarter1", "quarter2", "quarter3", "quarter4"];
            counties.forEach((county) => {
                allQuarters.forEach( (quarter) => {
                    funcs.push(API.graphql(graphqlOperation(mutations.createGroupNarrativeM2M, {
                        input: {
                            createdAt: new Date(),
                            updatedAt: new Date(),
                            updatedBy: user.username,
                            completed: false,
                            status: "INCOMPLETE",
                            year: currentFiscalYear.year,
                            quarter: quarter,
                            groupNarrativeM2MNarrativeId: createNarrative.id,
                            groupNarrativeM2MGroupId: county.id,
                        }
                    })))
                })
            })

        } 
        else {

            // For a quarterly narrative, we have to create 46 narratives

            counties.forEach( (county) => {
                funcs.push(API.graphql(graphqlOperation(mutations.createGroupNarrativeM2M, {
                    input: {
                        createdAt: new Date(),
                        updatedAt: new Date(),
                        updatedBy: user.username,
                        completed: false, 
                        status: "INCOMPLETE",
                        year: currentFiscalYear.year,
                        quarter: createNarrative.quarter,
                        groupNarrativeM2MNarrativeId: createNarrative.id,
                        groupNarrativeM2MGroupId: county.id,
                    }
                })))
            })
        }


        Promise.all(funcs)
        .then( async (data) => {

            // Get latest Group Narrative information and apply it to all counties
            // We have to update counties narratives information so it doesnt get out of sync
            await getLatestGroupNarrativesForAllGroups(dispatch, getState)
            console.log("Got all data")
            dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Narrative created succesfully' } }))
            callback && callback()

        })
        .catch((err) => {
            dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Error while creating the narrative' } }))
            callback && callback()
        })


    }
}

// Update narratives

// Steps
// 1. Update narrative
// 2. Perform QUARTER_TO_RECURRING or RECURRING_TO_QUARTER operation

export function updateNarrative(payload, currentNarrative, callback) {
    return async function(dispatch, getState) {
        const { counties, currentFiscalYear, user } = getState().rootReducer;
        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

        // Step 1

        // Check if the narrative frequency is changed

        var updatedNarrativeInput = {
            input: {
                id: currentNarrative.id, 
                narrativeTitle: payload.narrativeTitle || null,
                narrativeCategory: payload.narrativeCategory || "NULL",
                narrativeShortDescription: payload.narrativeShortDescription || null,
                narrativeLongDescription: payload.narrativeLongDescription || null,
                // Recurring | Once
                narrativeFrequency: payload.narrativeFrequency || null,
                narrativePOCFirstName: payload.narrativePOCFirstName || null,
                narrativePOCLastName: payload.narrativePOCLastName || null,
                narrativePOCPhoneNumber: payload.narrativePOCPhoneNumber || null,
                narrativePOCEmailAddress: payload.narrativePOCEmailAddress || null,
                narrativePOCPositionTitle: payload.narrativePOCPositionTitle || null,
                usePalmettoForREMInformation: payload.usePalmettoForREMInformation || null,
                useFinancialReimbursementStatus: payload.useFinancialReimbursementStatus || null,
                // quarter1 | quarter2 | quarter3 | quarter4 | all
                quarter: payload.quarter || null,
                updatedAt: new Date(),
                updatedBy: user.username,
                year: currentFiscalYear.year,
                narrativeUploadedFileId: payload.file_id || null
            }
        }

        await API.graphql(graphqlOperation(mutations.updateNarrative, updatedNarrativeInput))

        // Recurring -> Quarter = Delete 46*3 entries, 3 quarters from this entry is to removed from and 1 quarter for which we have to keep this entry

        // Quarter -> Recurring = Create 46*3 entries, 3 quarters for this entries need to be created

        var type;

        if(payload.quarter == "all" && currentNarrative.quarter != "all") {
            type = "QUARTER_TO_RECURRING"
        } else if (payload.quarter != "all" && currentNarrative.quarter == "all") {
            type = "RECURRING_TO_QUARTER"
        } else {
            // This might be a quarter to quarter for which we dont to worry about creating/deleting entries in GroupNarrativesM2M table
            type = null;
        }

        if(type) {
            if(type == "RECURRING_TO_QUARTER") {
                // Delete 46 * 3 entries

                // 1. Find which quarter to keep
                // 2. Loop through the counties to get all other entries for the narrative but the quarter. Entries should be equal to 46 * 3
                // 3. Delete all the entries

                var quarterToKeep = payload.quarter;
                var groupNarratviesFuncs = []

                // Find Narratives to delete
                // Lets say user wants to keep `quarter2`, we would loop through the county narratives model and find all entries but the user chosen quarter

                counties.forEach( (county) => {
                    if (county.narratives && county.narratives.items && county.narratives.items.length) {
                        county.narratives.items.forEach((item) => {

                            // Delete group narratives except for the quarter user wants to keep

                            if(item.narrative && item.narrative.id == currentNarrative.id && item.quarter != quarterToKeep) {
                                var asyncOperation = API.graphql(graphqlOperation(mutations.deleteGroupNarrativeM2M, {
                                    input: {
                                        id: item.id
                                    }
                                }))
                                groupNarratviesFuncs.push(asyncOperation)
                            }
                        })
                    }
                })

                Promise.all(groupNarratviesFuncs)
                .then(function() {
                    dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
                    window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Narrative updated succesfully' } }))
                    callback && callback()

                })
                .catch(function(err) {
                    console.log("Error", err)
                })


            } else if (type == "QUARTER_TO_RECURRING") {

                var quarterToKeep = currentNarrative.quarter;
                var groupNarratviesFuncs = []
                
                var allQuarters = ["quarter1", "quarter2", "quarter3", "quarter4"];
                
                // Remove quarter that already exists
                allQuarters.splice(allQuarters.indexOf(quarterToKeep), 1)


                // Create 46 * 3 entries for the counties
                counties.forEach((county) => {
                    allQuarters.forEach((quarter) => {
                        // 1. Create the group narrative payload with the narrative id in the payload
                        groupNarratviesFuncs.push(API.graphql(graphqlOperation(mutations.createGroupNarrativeM2M, {
                            input: {
                                createdAt: new Date(),
                                updatedAt: new Date(),
                                updatedBy: user.username,
                                completed: false,
                                status: "INCOMPLETE",
                                year: currentFiscalYear.year,
                                quarter: quarter,
                                groupNarrativeM2MNarrativeId: currentNarrative.id,
                                groupNarrativeM2MGroupId: county.id,
                            }
                        })))
                    })
                })
                
                Promise.all(groupNarratviesFuncs)
                .then(function () {
                    dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
                    window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Narrative updated succesfully' } }))
                    callback && callback()

                })
                .catch(function (err) {
                    console.log("Error", err)
                })                
            }
        } else {

            if(payload.quarter == 'all') {

                console.log('dont change narrative')

                dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
                window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Narrative updated succesfully' } }))
                callback && callback()

            } else {

                // Update GroupNarratives table for Quarter-Quarter to change because thats how we process items to display in the progress reporting table
    
                var groupNarratviesFuncs = []
    
                // Find Group narratives to update
    
                counties.forEach((county) => {
                    if (county.narratives && county.narratives.items && county.narratives.items.length) {
                        county.narratives.items.forEach((item) => {
    
                            // Delete group narratives except for the quarter user wants to keep
    
                            if (item.narrative && item.narrative.id == currentNarrative.id && item.quarter != quarterToKeep) {
                                var asyncOperation = API.graphql(graphqlOperation(mutations.updateGroupNarrativeM2M, {
                                    input: {
                                        id: item.id,
                                        quarter: payload.quarter,
                                    }
                                }))
                                groupNarratviesFuncs.push(asyncOperation)
                            }
                        })
                    }
                })
    
                Promise.all(groupNarratviesFuncs)
                    .then(function () {
                        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
                        window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Narrative updated succesfully' } }))
                        callback && callback()
    
                    })
                    .catch(function (err) {
                        console.log("Error", err)
                    })
            }

        }


    }
}

export function deleteNarrative(callback) {
    return async function (dispatch, getState) {

        // Steps to delete a narrative
        // 1. If the narrative is a quarterly narrative then first delete 46 group narrative m2ms associated with it
        // 2. If the narrative is a recurring narrative then we have to delete 46*4 group narrative m2ms associated with it first

        // 3. Then delete the associated narrative

        const { counties, currentFiscalYear, currentNarrative, user } = getState().rootReducer;
        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

        let groupNarratviesFuncs = []

        counties.forEach((county) => {
            if (county.narratives && county.narratives.items && county.narratives.items.length) {
                county.narratives.items.forEach((item) => {
                    if (item.narrative && item.narrative.id == currentNarrative.id) {
                        groupNarratviesFuncs.push(API.graphql(graphqlOperation(mutations.deleteGroupNarrativeM2M, {
                            input: {
                                id: item.id
                            }
                        })))
                    }
                })
            }
        })

        
        Promise.all(groupNarratviesFuncs)
        .then(async function () {
            
            // Need to delete the narrative after the group narrative is deleted to respect the connection netween narrative and group narrative models
            
            await API.graphql(graphqlOperation(mutations.deleteNarrative, {
                input: {
                    id: currentNarrative.id
                }
            }))
            
            await getLatestGroupNarrativesForAllGroups(dispatch, getState)
            
            dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Narrative deleted  succesfully' } }))
            callback && callback()

        })
        .catch(function (err) {
            console.log("Error occurd while deleting the entry", err)
        })                
        // console.log(groupNarratviesFuncs)
    }
}

export function startNewFiscalYear(callback) {
    return async function(dispatch, getState) {

        const { currentFiscalYear } = getState().rootReducer;

        // Update Fiscal year model
        
        // Update availableToCounties field in the FiscalYear model

        await API.graphql(graphqlOperation(mutations.updateFiscalYear, {
            input: {
                id: currentFiscalYear.id,
                availableToCounties: true,
            }
        }));

        // lets reload the page to switch to Pre-award dashboard
        window.location.reload();

    }
}

// Monitoring function


export function setMonitoringCounty (payload, callback) {
    return async function(dispatch, getState) {
        const { counties } = getState().rootReducer;
        getCountyData(dispatch, getState, payload.selectedGroupID, counties,  callback)

    }
} 

export function submitMonitoringGrantApplication(payload, callback) {
    return async function(dispatch, getState) {

        const { user, currentMonitoringGrantInformationPage, selectedCounty, counties } = getState().rootReducer;

        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

        try {

            let commonData = {
                monitoringType: (payload && payload.monitoringType) || null,
                monitoringDate: (payload && payload.monitoringDate && (new Date(payload.monitoringDate))) || null,
                subRecipientAgency: (payload && payload.subRecipientAgency) || null,
                grantProjectTitle: (payload && payload.grantProjectTitle) || null,
                grantNumber: (payload && payload.grantNumber) || null,
            
                approvedGrantStartDate: (payload && payload.approvedGrantStartDate && (new Date(payload.approvedGrantStartDate))) || null,
                approvedGrantEndDate: (payload && payload.approvedGrantEndDate && (new Date(payload.approvedGrantEndDate))) || null,
                extensionEndDate: (payload && payload.extensionEndDate && (new Date(payload.extensionEndDate))) || null,
            
                name: (payload && payload.name) || null,
                title: (payload && payload.title) || null,
            
                projectDirectorName: (payload && payload.projectDirectorName) || null,
                projectDirectorTitle: (payload && payload.projectDirectorTitle) || null,
                
                personnelName: (payload && payload.personnelName) || null,
                personnelTitle: (payload && payload.personnelTitle) || null,  
            }

            if(currentMonitoringGrantInformationPage && currentMonitoringGrantInformationPage.id) {
                // update 
                let appSyncFilter = {
                    input: {
                        id: currentMonitoringGrantInformationPage.id,
                        updatedAt: new Date(),
                        updatedBy: user.username,
                        ...commonData
                    }
                }

                let res = await API.graphql(graphqlOperation(mutations.updateMonitoringGrantInformation, appSyncFilter));
    
                window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Monitoring grant information saved successfully' } }))

                // get latest monitoring data for the county
                await getLatestCountyData(dispatch, counties, selectedCounty.id)

            } else {
                // create
                let appSyncFilter = {
                    input: {
                        createdAt: new Date(),
                        updatedAt: new Date(),
                        updatedBy: user.username,
                        ...commonData
                    }
                }
    
                let res = await API.graphql(graphqlOperation(mutations.createMonitoringGrantInformation, appSyncFilter));
    
                // now save group
    
                appSyncFilter = {
                    input: {
                        id: selectedCounty.id,
                        groupMonitoringgrantinformationId: res.data.createMonitoringGrantInformation.id
                    }
                }
    
                await API.graphql(graphqlOperation(mutations.updateGroup, appSyncFilter));
                console.log('group updated')

                window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Monitoring grant information saved successfully' } }))

                // get latest monitoring data for the county
                await getLatestCountyData(dispatch, counties, selectedCounty.id)

            }
        } catch(e) {
            console.error('Error occured', e);
        }    
        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
        callback && callback();

    }
}


export function submitMonitoringSiteVisitReview(payload, callback) {
    return async function(dispatch, getState) {

        const { user, currentMonitoringSiteVisitReview, selectedCounty, counties } = getState().rootReducer;

        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

        try {

            let commonData = {
                gaOnFile: (payload && payload.gaOnFile) || null,
                gaSupportingDocumentationOnFile: (payload && payload.gaSupportingDocumentationOnFile) || null,
                gaComments: (payload && payload.gaComments) || null,
            
                personnelFunded: (payload && payload.personnelFunded) || null,
                personnelDocumentationOnFile: (payload && payload.personnelDocumentationOnFile) || null,
                personnelRecordsOnFile: (payload && payload.personnelRecordsOnFile) || null,
                personnelComments: (payload && payload.personnelComments) || null,
            
                contractualServiceOnFile: (payload && payload.contractualServiceOnFile) || null,
                contractualServiceExecutedContractsOnFile: (payload && payload.contractualServiceExecutedContractsOnFile) || null,
                contractualServiceComments: (payload && payload.contractualServiceComments) || null,
            
                travelOnFile: (payload && payload.travelOnFile) || null,
                travelDocumentationOnFile: (payload && payload.travelDocumentationOnFile) || null,
                travelComments: (payload && payload.travelComments) || null,
            
                equipmentProperlySheltered: (payload && payload.equipmentProperlySheltered) || null,
                equipmentTransferred: (payload && payload.equipmentTransferred) || null,
                equipmentComments: (payload && payload.equipmentComments) || null,
            
                suppliesOnFile: (payload && payload.suppliesOnFile) || null,
                suppliesTransferred: (payload && payload.suppliesTransferred) || null,
                suppliesComments: (payload && payload.suppliesComments) || null,
            
                otherOnFile: (payload && payload.otherOnFile) || null,
                otherComments: (payload && payload.otherComments) || null,
            
                summaryComments: (payload && payload.summaryComments) || null,
                monitoringReportSubmittedBy: (payload && payload.monitoringReportSubmittedBy) || null,
                monitoringReportSubmittedAt: (payload && payload.monitoringReportSubmittedAt  && (new Date(payload.monitoringReportSubmittedAt))) || null,
            
                scemdPOCReviewedBy: (payload && payload.scemdPOCReviewedBy) || null,
                scemdPOCReviewedAt: (payload && payload.scemdPOCReviewedAt && (new Date(payload.scemdPOCReviewedAt))) || null,
                updatedBy: `${user.ncPersonGivenName} ${user.ncPersonSurName}`,
            }

            if(payload.save) {
                // do nothing
                // added this if check for logic purposes
            } else if(payload.submit) {
                commonData.status = 'SUBMITTED';
            } else if(payload.submitWithIssues) {
                commonData.status = 'SUBMITTED_WITH_ISSUES';
            }

            if(currentMonitoringSiteVisitReview && currentMonitoringSiteVisitReview.id) {
                // update 
                let appSyncFilter = {
                    input: {
                        id: currentMonitoringSiteVisitReview.id,
                        updatedAt: new Date(),
                        ...commonData
                    }
                }

                await API.graphql(graphqlOperation(mutations.updateMonitoringSiteVisitReview, appSyncFilter));
    
                // save files

                let filesReqs = [];

                if(payload.uploadedFiles && payload.uploadedFiles.length) {
                    payload.uploadedFiles.forEach((file) => {
                        let filesAppSyncFiler = {
                            input: {
                                id: file.id,
                                monitoringSiteVisitReviewMonitoringFilesId: currentMonitoringSiteVisitReview.id,
                                deleted: file.deleted || false,
                                updatedBy: user.username,
                                updatedAt: new Date(),
                            }
                        };

                        filesReqs.push(API.graphql(graphqlOperation(mutations.updateFile, filesAppSyncFiler)))

                    })

                    await Promise.all(filesReqs);

                }

                // get latest monitoring data for the county
                await getLatestCountyData(dispatch, counties, selectedCounty.id)

            } else {
                // create
                let appSyncFilter = {
                    input: {
                        createdAt: new Date(),
                        updatedAt: new Date(),
                        ...commonData 
                    }
                }
    
                let res = await API.graphql(graphqlOperation(mutations.createMonitoringSiteVisitReview, appSyncFilter));
    

                let filesReqs = [];

                if(payload.uploadedFiles && payload.uploadedFiles.length) {
                    payload.uploadedFiles.forEach((file) => {
                        let filesAppSyncFiler = {
                            input: {
                                id: file.id,
                                monitoringSiteVisitReviewMonitoringFilesId: res.data.createMonitoringSiteVisitReview.id,
                                deleted: file.deleted || false,
                                updatedBy: user.username,
                                updatedAt: new Date(),
                            }
                        };

                        filesReqs.push(API.graphql(graphqlOperation(mutations.updateFile, filesAppSyncFiler)))

                    })

                    await Promise.all(filesReqs);
                }

                // now save group
    
                appSyncFilter = {
                    input: {
                        id: selectedCounty.id,
                        groupMonitoringsitevisitreviewId: res.data.createMonitoringSiteVisitReview.id
                    }
                }
    
                await API.graphql(graphqlOperation(mutations.updateGroup, appSyncFilter));
                console.log('group updated')

                
                // get latest monitoring data for the county
                await getLatestCountyData(dispatch, counties, selectedCounty.id)

            }
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Monitoring site visit review saved successfully' } }))

        } catch(e) {
            console.error('Error occured', e);
        }    
        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
        callback && callback();

    }
}

export function approveMonitoringSiteVisitReview(callback) {
    return async function(dispatch, getState) {

        const { user, currentMonitoringSiteVisitReview, selectedCounty, counties } = getState().rootReducer;

        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

        try {

            let commonData = {
                status: 'APPROVED',
                updatedBy: `${user.ncPersonGivenName} ${user.ncPersonSurName}`,
                scemdPOCReviewedBy: `${user.ncPersonGivenName} ${user.ncPersonSurName}`,
                scemdPOCReviewedAt: new Date(),
            }

            if(currentMonitoringSiteVisitReview && currentMonitoringSiteVisitReview.id) {
                // update 
                let appSyncFilter = {
                    input: {
                        id: currentMonitoringSiteVisitReview.id,
                        updatedAt: new Date(),
                        ...commonData
                    }
                }

                await API.graphql(graphqlOperation(mutations.updateMonitoringSiteVisitReview, appSyncFilter));

                // get latest monitoring data for the county
                await getLatestCountyData(dispatch, counties, selectedCounty.id)

            } 

            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Monitoring site visit review approved successfully' } }))

        } catch(e) {
            console.error('Error occured', e);
        }    
        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
        callback && callback();

    }
}


export async function getLatestCountyData(dispatch, counties, id) {
    const groupsResponseFromAppSync = await API.graphql(graphqlOperation(GetGroups, {
        filter: {
            id: {
                eq: id
            }
        },
        limit: 1000
    }));

    if(groupsResponseFromAppSync && groupsResponseFromAppSync.data  && groupsResponseFromAppSync.data.listGroups  && groupsResponseFromAppSync.data.listGroups.items   && groupsResponseFromAppSync.data.listGroups.items.length) {
        let item = groupsResponseFromAppSync.data.listGroups.items[0];

        counties.forEach( (county, index) => {
            if(county.id === item.id) {
                counties[index] = item;
            }
        })

        dispatch({
            type: SET_COUNTIES_FOR_STATE_USER,
            payload: JSON.parse(JSON.stringify(counties))
        });

        dispatch({type: SET_SELECTED_COUNTY, payload: JSON.parse(JSON.stringify(item))});
        dispatch({ type: CURRENT_MONITORING_SITE_VISIT_REVIEW, payload: JSON.parse(JSON.stringify(item.monitoringsitevisitreview))});
        dispatch({ type: CURRENT_MONITORING_GRANT_INFORMATION_PAGE, payload: JSON.parse(JSON.stringify(item.monitoringgrantinformation)) });
    }
}

export function printMonitoringForm() {
    return async function(dispatch, getState) {

        // get latest monitoring form
        let {currentMonitoringSiteVisitReview, currentMonitoringGrantInformationPage, selectedYear} = getState().rootReducer;

        currentMonitoringSiteVisitReview = currentMonitoringSiteVisitReview || {};
        currentMonitoringGrantInformationPage = currentMonitoringGrantInformationPage || {};


        // field information

        // {s1md} -> Monitoring Date
        // {s1sba} -> Sub recipient agency
        // {s1gpt} -> Grant project title
        // {s1gn} -> Grant number
        // {s1ed} -> Approved Grant Performance Period:  07/01/20XX - 06/30/20XX or, if on extension:
        // {s1empn} -> Emergency program staff name
        // {s1empt} -> Emergency program staff title
        // {y} -> current selectedYear
        // {y1} -> current selectedYear + 1

        // Site visit review

        // {sva1} -> A 1. Grant application on file (yes/no/na)
        // {sva2} -> A 2. Quarterly reimbursements and supporting documentation on file?
        // {sva1comments} -> Comments
        
        // {svb1a} -> B 1 a.  Are personnel funded under this grant?
        // {svb1b} -> B 1 b.  Are grant funded personnel time sheets, attendance records activity reports and payroll records in the grant file and available for review?
        // {svb1c} -> B 1 c.  Are personnel training and exercise records in the grant file?
        // {svb1comments} -> Comments


        // {svb2a} -> B 2 a.  Are there contractual services?
        // {svb2b} -> B 2 b.  If so, are there executed contracts on file? 
        // {svb2comments} -> Comments

        // {svb3a} -> B 3 a.  Was travel conducted?
        // {svb3b} -> B 3 b.  Are travel expenditures documented with expense reports and/or detailed receipts?
        // {svb3comments} -> Comments

        // {svb4a} -> B 4 a.   Is the equipment properly sheltered from the elements,safeguarded against theft and vandalism, and protected to ensure operational capability and effectiveness?
        // {svb4b} -> B 4 b.  Has grant funded equipment been transferred to other agencies?
        // {svb4comments} -> Comments        


        // {svb5a} -> B 5 a.  Have supplies been purchased with this grant?
        // {svb5b} -> B 5 b.  Has grant funded supplies been transferred to other agencies?
        // {svb5comments} -> Comments

        // {svb6a} -> B 6 a.  ave other purchases been made with this grant?	
        // {svb6comments} -> Comments

        // {svcsummary} -> Summary Comments (Include summary statement regarding proper documentation and any recommendations, etc.):

        // {svcmrname} -> Monitoring report submitted by
        // {svcmrdate} -> Monitoring report submitted at

        // {svcemdname} -> SCEMD Admin/Finance POC reviewed by
        // {svcemddate} -> SCEMD Admin/Finance POC reviewed at

        const getMonitoringTypeValue = (value) => {
            if(value === 'Programmatic Only') return 'yes';
            if(value === 'Financial Only') return 'no';
            if(value === 'Programmatic and Financial') return 'na';
            return 'na'
        } 

        const convert = (value) => {
            if(value === 'Yes') return 'yes';
            if(value === 'No') return 'no';
            return 'na';
        }

        const toUTC = (date) => {
            var newDate = new Date();
            newDate.setTime(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));
            return newDate;
        }
        

        const formatDate = (value) => {
            if(value) return moment(toUTC(new Date(value))).format('MM/DD/YYYY')
            return ''
        }


        let obj = {

            'omt': getMonitoringTypeValue(currentMonitoringGrantInformationPage.monitoringType),

            's1md': formatDate(currentMonitoringGrantInformationPage.monitoringDate) || '',
            's1sba': currentMonitoringGrantInformationPage.subRecipientAgency || '',
            's1gpt': currentMonitoringGrantInformationPage.grantProjectTitle || '',
            's1gn': currentMonitoringGrantInformationPage.grantNumber || '',
            's1ed': formatDate(currentMonitoringGrantInformationPage.extensionEndDate) || '',
            's1empn': (currentMonitoringSiteVisitReview && currentMonitoringSiteVisitReview.monitoringReportSubmittedBy) || '',
            'y': parseInt(selectedYear),
            'y1': parseInt(selectedYear) + 1,
            's1empt': currentMonitoringGrantInformationPage.title || '',
            'pdname': currentMonitoringGrantInformationPage.projectDirectorName || '',
            'pdtitle': currentMonitoringGrantInformationPage.projectDirectorTitle || '',
            'pername': currentMonitoringGrantInformationPage.personnelName || '',
            'pertitle': currentMonitoringGrantInformationPage.personnelTitle || '',

            'sva1': convert(currentMonitoringSiteVisitReview.gaOnFile),
            'sva2': convert(currentMonitoringSiteVisitReview.gaSupportingDocumentationOnFile),
            'sva1comments': currentMonitoringSiteVisitReview.gaComments || '',

            'svb1a': convert(currentMonitoringSiteVisitReview.personnelFunded),
            'svb1b': convert(currentMonitoringSiteVisitReview.personnelDocumentationOnFile),
            'svb1c': convert(currentMonitoringSiteVisitReview.personnelRecordsOnFile),
            'svb1comments': currentMonitoringSiteVisitReview.personnelComments || '',

            'svb2a': convert(currentMonitoringSiteVisitReview.contractualServiceOnFile),
            'svb2b': convert(currentMonitoringSiteVisitReview.contractualServiceExecutedContractsOnFile),
            'svb2comments': currentMonitoringSiteVisitReview.contractualServiceComments || '',

            'svb3a': convert(currentMonitoringSiteVisitReview.travelOnFile),
            'svb3b': convert(currentMonitoringSiteVisitReview.travelDocumentationOnFile),
            'svb3comments': currentMonitoringSiteVisitReview.travelComments || '',

            'svb4a': convert(currentMonitoringSiteVisitReview.equipmentProperlySheltered),
            'svb4b': convert(currentMonitoringSiteVisitReview.equipmentTransferred),
            'svb4comments': currentMonitoringSiteVisitReview.equipmentComments || '',

            'svb5a': convert(currentMonitoringSiteVisitReview.suppliesOnFile),
            'svb5b': convert(currentMonitoringSiteVisitReview.suppliesTransferred),
            'svb5comments': currentMonitoringSiteVisitReview.suppliesComments || '',

            'svb6a': convert(currentMonitoringSiteVisitReview.otherOnFile),
            'svb6comments': currentMonitoringSiteVisitReview.otherComments || '',

            'svcsummary': currentMonitoringSiteVisitReview.summaryComments || '',
            'svcmrname': currentMonitoringSiteVisitReview.monitoringReportSubmittedBy || '',
            'svcmrdate': formatDate(currentMonitoringSiteVisitReview.monitoringReportSubmittedAt) || '',
            'svcemdname': currentMonitoringSiteVisitReview.scemdPOCReviewedBy || '',
            'svcemddate': formatDate(currentMonitoringSiteVisitReview.scemdPOCReviewedAt) || '',
        }


        let url = process.env.REACT_APP_PALMETTO_SERVERLESS_DOCUMENTS_ENDPOINT;

        axios.post(url + "/documents/lempg/monitoringForm", {data: obj},
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'MonitoringForm.docx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => console.log(error));


    }
}

// Update REM Contact Information

export function resetREMContact() {
    return async function(dispatch, getState) {
        dispatch({ type: CURRENT_REM_CONTACT, payload: {} })
    }
}


export function setCurrentREMContact(payload) {
    return async function (dispatch, getState) {
        dispatch({ type: CURRENT_REM_CONTACT, payload: payload })
    }
}

export function getAllRemContacts(callback) {
    return async function (dispatch, getState) {

        const { selectedYear } = getState().rootReducer;

        try {
            let getAllRemContactsRes = await API.graphql(graphqlOperation(queries.listRemContacts, {
                filter: {
                    and: [
                        {
                            year: {
                                eq: selectedYear
                            }
                        },
                        {
                            deleted: {
                                eq: false
                            }
                        }
                    ]

                },
                limit: 1000
           }))


           dispatch({ type: ALL_REM_CONTACTS, payload: (getAllRemContactsRes && getAllRemContactsRes.data && getAllRemContactsRes.data.listREMContacts && getAllRemContactsRes.data.listREMContacts.items) || [] })
           callback && callback(null, (getAllRemContactsRes && getAllRemContactsRes.data && getAllRemContactsRes.data.listREMContacts && getAllRemContactsRes.data.listREMContacts.items) || []);

        } catch(e) {
            console.log('Error creating REM contact', e)
            callback && callback(e);
        }
    }
}


export function createREMContact(payload, callback) {
    return async function (dispatch, getState) {

        const { user, selectedYear } = getState().rootReducer;
        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });

        try {
            var input = {
                input: {
                    firstName: payload.firstName || null,
                    lastName: payload.lastName || null,
                    phoneNumber: payload.phoneNumber || null,
                    emailAddress: payload.emailAddress || null,
                    positionTitle: payload.positionTitle || null,
                    region: payload.region || "",
                    deleted: false,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    updatedBy: user.username,
                    year: selectedYear,
                }
            }

            await API.graphql(graphqlOperation(mutations.createRemContact, input))

            let getAllRemContactsRes = await API.graphql(graphqlOperation(queries.listRemContacts, {
                filter: {
                    and: [
                        {
                            year: {
                                eq: selectedYear
                            }
                        },
                        {
                            deleted: {
                                eq: false
                            }
                        }
                    ]

                },
                limit: 1000
           }))
           dispatch({ type: ALL_REM_CONTACTS, payload: (getAllRemContactsRes && getAllRemContactsRes.data && getAllRemContactsRes.data.listREMContacts && getAllRemContactsRes.data.listREMContacts.items) || [] })

           window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'REM Contact created succesfully' } }))

           callback && callback();

        } catch(e) {
            console.log('Error creating REM contact', e)
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Error creating entry' } }))
            callback && callback(e);
        }
        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });

    }
}

export function updateREMContact(payload, callback) {
    return async function (dispatch, getState) {
        const { currentREMContact, user, selectedYear } = getState().rootReducer;
        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });


        try {
            var input = {
                input: {
                    id: currentREMContact.id,
                    firstName: payload.firstName || null,
                    lastName: payload.lastName || null,
                    phoneNumber: payload.phoneNumber || null,
                    emailAddress: payload.emailAddress || null,
                    positionTitle: payload.positionTitle || null,
                    region: payload.region || "",
                    deleted: false,
                    updatedAt: new Date(),
                    updatedBy: user.username,
                    year: selectedYear,
                }
            }

            await API.graphql(graphqlOperation(mutations.updateRemContact, input))

            let getAllRemContactsRes = await API.graphql(graphqlOperation(queries.listRemContacts, {
                filter: {
                    and: [
                        {
                            year: {
                                eq: selectedYear
                            }
                        },
                        {
                            deleted: {
                                eq: false
                            }
                        }
                    ]

                },
                limit: 1000
           }))
           dispatch({ type: ALL_REM_CONTACTS, payload: (getAllRemContactsRes && getAllRemContactsRes.data && getAllRemContactsRes.data.listREMContacts && getAllRemContactsRes.data.listREMContacts.items) || [] })

           window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'REM Contact updated succesfully' } }))

           callback && callback();

        } catch(e) {
            console.log('Error updating REM contact', e)
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Error updating entry' } }))
            callback && callback(e);
        }
        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });

    }
}


export function deleteREMContact(payload, callback) {
    return async function (dispatch, getState) {
        const { currentREMContact, user, selectedYear } = getState().rootReducer;
        dispatch({ type: APPLICATION_LOADING_STATE, payload: true });


        try {
            var input = {
                input: {
                    id: currentREMContact.id,
                    deleted: true,
                    updatedAt: new Date(),
                    updatedBy: user.username,
                }
            }

            await API.graphql(graphqlOperation(mutations.updateRemContact, input))

            let getAllRemContactsRes = await API.graphql(graphqlOperation(queries.listRemContacts, {
                filter: {
                    and: [
                        {
                            year: {
                                eq: selectedYear
                            }
                        },
                        {
                            deleted: {
                                eq: false
                            }
                        }
                    ]

                },
                limit: 1000
           }))
           dispatch({ type: ALL_REM_CONTACTS, payload: (getAllRemContactsRes && getAllRemContactsRes.data && getAllRemContactsRes.data.listREMContacts && getAllRemContactsRes.data.listREMContacts.items) || [] })

           window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'REM Contact deleted succesfully' } }))

           callback && callback();

        } catch(e) {
            console.log('Error updating REM contact', e)
            window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Error deleting entry' } }))
            callback && callback(e);
        }
        dispatch({ type: APPLICATION_LOADING_STATE, payload: false });
    }
}


// Contact support functions

export function contactSupport(payload, callback) {
    return async function(dispatch, getState)  {

        const { user, selectedGroup } = getState().rootReducer;

        function getSubject(payload) {
            var footer = "\n";
            footer += "Name: " + user.ncPersonGivenName + " " + user.ncPersonSurName + "\n";
            footer += "Email: " + user.email + "\n";

            user.account2groups.forEach(function(group) {
                if(group.pvGroupID == selectedGroup) {
                    footer += "Group: " + group.pvGroupName + "\n";
                }
            });
            user.account2positions.forEach(function(position) {
                if(position.positions && position.positions.pvGroupID == selectedGroup)  {
                    footer += "Position: " + position.pvPositionName + "\n";
                }
            })
            return payload.message + "\n" + footer;
        }

        let authObj = JSON.parse(sessionStorage.getItem("userAuthCreds"));
        let emailPayload = [{
            "pvMessageType":"EMAIL",
            "pvEmailMessageText":  getSubject(payload),
            "pvEmailMessageHtml":  "",
            "pvEmailSubject": `Supplemental Support - ${payload.subject}`,
            "pvRecipientFirstName": "support",
            "pvRecipientLastName": " ",
            "pvRecipientEmailAddress": "support@earthtechint.com"
        }];

        let messagePayload = {
            smsEmailObjects: emailPayload
        };        

        sendEmail(messagePayload, authObj, 'Supplemental Contact Support email', (err, response) => {
            if (err) {
                console.log(err)
                window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Error sending message' } }))
                callback && callback(err)
            } else {
                console.log("messages sent")
                window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: 'Message sent  successfully' } }))

                callback && callback(null, response)
            }
        })        
    }
}

// SCRIPT Functions

function updateCountiesWithRandomAwardAmount() {
    return async function(dispatch, getState) {
        if (process.env.REACT_APP_NODE_ENV != "PRODUCTION") {
            const {counties} = getState().rootReducer;
            console.log("counties", counties)
            let funcs = [];
    
            counties.forEach( (county) => {
                funcs.push(API.graphql(graphqlOperation(mutations.updateGroup, {
                    input: {
                        id: county.id,
                        awardAmount: Math.floor(Math.random() * 60000) + 10000  
                    }
                })))
            })
    
            Promise.all(funcs)
            .then( () => {
                console.log("Updated")
            })
            .catch((e) => {
                console.log("errored", e)
            })
        }

    }
}

function createRandomNarratives() {
    return async function(dispatch, getState) {
        if (process.env.REACT_APP_NODE_ENV != "PRODUCTION") {
            console.log(NarrativeData)
            NarrativeData.forEach( (narrative) => {

                dispatch(createNarrative(narrative, function(n) {
                    console.log("Created", n)
                }.bind(narrative)))
            })
        }

    }
}
