import React from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import { NumberFormatCustom, getValue } from "../utilities/NumberFormat";

import { DualTitleHeaderComponent } from "../layout/HeaderComponent.js"
import { PrimaryButton, HeaderButton } from "../styled-components/Buttons/Buttons";
import { CardComponentSummaryCard } from "../layout/CardComponent";
import { NormalTextField, RequiredTextField } from "../styled-components/TextFields/TextFields";
import ErrorBox from "../common/ErrorBox";

import IconButton from "@material-ui/core/IconButton";
import Button from '@material-ui/core/Button';
import { MdClear, MdInfoOutline } from 'react-icons/md';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { checkIfAmountEnteredIsBelowAmountReimbursed, checkIfAmountHasBeenReimbursed } from './GrantApplicationApi';

// Redux imports

import { saveEquipment } from "../../redux/actions/EquipmentAction.js";

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        grantApplication: state.rootReducer.grantApplication,
        currentApplicationState: state.rootReducer.currentApplicationState,
        selectedYear: state.rootReducer.selectedYear,
        selectedCounty: state.rootReducer.selectedCounty, 
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveEquipment: (payload) => dispatch(saveEquipment(payload)),
    }
};
class Equipment extends React.Component {
    
    modelType = 'equipment'
    
    state = {
        id: "",
        tempId: "",
        name: "",
        deleted: false,
        femaItemNumber: "",
        updatedBy: "",
        federal: "",
        county: "",
        countyInKind: "",
        open: false,
        errors: [],
        validate: false,
    };

    constructor(props, context) {
        super(props, context);
        this.goBack = this.goBack.bind(this);

        if (this.props.match && this.props.match.params && this.props.match.params.id) {
            // we are looking into an existing entry
            var isTempEntry = false;
            if (this.props.match.params.id.indexOf("temp_") >= 0) {
                isTempEntry = true;
            }
            this.props.currentApplicationState.equipment && this.props.currentApplicationState.equipment.items &&
                this.props.currentApplicationState.equipment.items.forEach((item) => {
                    if (isTempEntry) {
                        if (("temp_" + item.tempId) == this.props.match.params.id) {
                            this.state = item;
                            this.state.open = false;
                        }
                    } else {
                        if (item.id == this.props.match.params.id) {
                            this.state = item;
                            this.state.open = false;
                        }
                    }
                this.state.errors = [];
                this.state.validate = false;

                })
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    };


    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    saveEquipment(event) {
        this.state.errors = [];
        this.state.validate = false;
        this.setState({
            errors: this.state.errors
        });

        this.setState({
            validate: this.state.validate
        });

        if (!this.state.name) {
            this.state.errors.push({
                text: "Please enter name"
            })
        }

        if (!this.state.femaItemNumber) {
            this.state.errors.push({
                text: "Please enter FEMA Item number"
            })
        }

        let amountCheck = checkIfAmountEnteredIsBelowAmountReimbursed(this.props.selectedCounty, this.state, this.modelType);

        if(amountCheck) {
            this.state.errors.push({
                text: amountCheck.text
            })
        }

        if (this.state.errors.length > 0) {

            this.state.validate = true;

            this.setState({
                errors: this.state.errors
            });

            this.setState({
                validate: this.state.validate
            });

            return;
        }

        this.props.saveEquipment(this.state);
        this.goBack();
    }


    goBack() {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="layout vertical full-height">

                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"FEMA Authorized Equipment"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Would you like to go to the FEMA authorized equipment list?
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleClose} color="primary" autoFocus>
                            <a target="_blank" href="https://www.fema.gov/authorized-equipment-list">
                                Visit
                            </a>
                        </Button>
                    </DialogActions>

                </Dialog>

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdClear className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>{this.state.id || this.state.tempId ? "Edit equipment" : "Add equipment"}</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>{window.appHeaderTitle} • {this.props.grantApplication.group && this.props.grantApplication.group.groupName}</span>}
                    suffix={
                        this.state.id || this.state.tempId ?
                            <div className="form-margin-16">
                                <HeaderButton hidden={this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS"} onClick={() => this.saveEquipment()}>
                                    Save
                                </HeaderButton>
                            </div>
                            : ""
                    }

                />
                {
                    this.state.errors.length ?
                        <div className="margin-10">
                            <ErrorBox errors={this.state.errors} />
                        </div>
                        : ""
                }
                <div className="flex form-shell container-overflow-y">
                    <div className="layout horizontal">
                        <RequiredTextField error={this.state.name.length == 0 && this.state.validate ? true : false} id="equipmentName" label="Name" custommargin="true" placeholder="Enter name" className="flex" value={this.state.name} onChange={this.handleChange('name')} margin="normal" InputProps={{ readOnly: (this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS") || checkIfAmountHasBeenReimbursed(this.props.selectedCounty, this.state.name, this.modelType) }} InputLabelProps={{ shrink: true, }} />
                    </div>

                    <div className="layout horizontal">
                        <RequiredTextField error={this.state.femaItemNumber.length == 0 && this.state.validate ? true : false} id="equipmentFemaItemNumber" label="FEMA AEL Item number" custommargin="true" placeholder="Enter number" className="flex" value={this.state.femaItemNumber} onChange={this.handleChange('femaItemNumber')} margin="normal" InputProps={{ readOnly: (this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS") || checkIfAmountHasBeenReimbursed(this.props.selectedCounty, this.state.name, this.modelType) }} InputLabelProps={{ shrink: true, }} />

                        <div className="layout horizontal end" style={{ marginBottom: "8px" }}>
                            <MdInfoOutline onClick={this.handleClickOpen} className="primary-icon" />
                        </div>
                    </div>

                    <div className="layout horizontal">
                        <NormalTextField id="equipmentFederalFunding" label="Federal funding" custommargin="true" placeholder="Enter federal funding" className="flex" value={this.state.federal} onChange={this.handleChange('federal')} margin="normal" InputProps={{ inputComponent: NumberFormatCustom, readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />
                    </div>

                    <div className="layout horizontal">
                        <NormalTextField id="equipmentCountyFunding" label="County cash funding" custommargin="true" placeholder="Enter county cash funding" className="flex" value={this.state.county} onChange={this.handleChange('county')} margin="normal" InputProps={{ inputComponent: NumberFormatCustom, readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />
                    </div>

                    <div className="layout horizontal">
                        <NormalTextField id="equipmentCountyInKindFunding" label="County in-kind funding" custommargin="true" placeholder="Enter county in-kind funding" className="flex" value={this.state.countyInKind} onChange={this.handleChange('countyInKind')} margin="normal" InputProps={{ inputComponent: NumberFormatCustom, readOnly: this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS" }} InputLabelProps={{ shrink: true, }} />
                    </div>

                    <div className="height-20"></div>


                    <div className="layout horizontal form-margin">
                        <div className="flex"></div>
                        <CardComponentSummaryCard
                            cardText={
                                <NumberFormat value={getValue([this.state.federal, this.state.county, this.state.countyInKind])} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                            }
                            cardTitle={<span>Total</span>}
                        />
                    </div>

                    <div className="height-20"></div>
                    {
                        this.state.id || this.state.tempId ?
                            "" :
                            <div className="layout horizontal form-margin">
                                <div className="flex"></div>
                                <PrimaryButton onClick={() => this.saveEquipment()}>
                                    Add
                                </PrimaryButton>
                            </div>
                    }

                </div>

            </div>
        );
    }
}

// export default Equipment;

export default connect(mapStateToProps, mapDispatchToProps)(Equipment);


