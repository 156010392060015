import React from 'react';

import "../../../styles/common.css";
import "../../../styles/common.charts.css";

// Parent here is the CountySplashScreen, which passes on the selectedYear
class NarrativesCreated extends React.Component {

    state = {
    }


    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (

            <div className="layout vertical pie-chart-container narratives-created-container inline-grid">
                <div>
                    <span className="section-title form-margin-16">Narratives Created</span>
                    <div className="height-10"></div>
                    <div className="layout horizontal form-margin-16 title-divider"></div>
                </div>
                <div className="layout horizontal center form-margin-16 flex">
                    <span class="number-of-narratives">{this.props.number || "None"}</span>
                </div>
            </div>
        )
    }

}


export default NarrativesCreated;
