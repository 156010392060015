import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import IconButton from "@material-ui/core/IconButton";
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import { MdClear, MdAdd, MdArrowBack } from 'react-icons/md';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Fab } from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { RequiredDropdown } from "../styled-components/Dropdowns/Dropdown.js";


import { HeaderComponent } from "../layout/HeaderComponent.js"
import { HeaderButton, PrimaryButton } from "../styled-components/Buttons/Buttons";
import { selectYear } from "../../redux/actions/index";
import { startNewFiscalYearProcess, getCurrentFiscalYears } from '../../redux/actions/CreateNewFiscalYear';

// CSS imports
import "../../styles/common.css";
import "../../styles/iron-flex-layout.css";
import "../../styles/iron-flex-layout-classes.css";

const styles = {
    listItem: {
        height: "48px",
        paddingLeft: "16px",
        paddingRight: "16px",
    },
    listItemHover: {
        '&:hover': {
            backgroundColor: "#E0E0E0"
        }
    },
    heightInherit: {
        height: "inherit",
        color: "black !important",
    }

};

const DropdownMenuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
    },
    PaperProps: {
        style: {
            minWidth: "200px",
        },
    },
};

const mapStateToProps = (state) => {
    const { browser, rootReducer } = state
    return {
        browser,
        rootReducer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        selectYear: (year) => dispatch(selectYear(year)),
        startNewFiscalYearProcess: (year) => dispatch(startNewFiscalYearProcess(year)),
        getCurrentFiscalYears: (callback) => dispatch(getCurrentFiscalYears(callback)),
    }
}

class SelectNewYearDialog extends React.Component {

    state = {
        open: false,
        selectedYear: this.props.rootReducer.selectedYear,
        page: 'show-fiscal-years',
        newYear: '',
        years: []

    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }   

    handleDialog = (variable, value) => {
        this.setState({ [variable]: value });
    };

    selectYear() {
        this.props.selectYear(this.state.selectedYear)
    }

    handleRadioButtonChange = event => {
        this.setState({ selectedYear: event.target.value })
    };

    constructor(props, context) {
        super(props, context);

        this.selectYear = this.selectYear.bind(this)
        this.handleDialog = this.handleDialog.bind(this);

        //TODO: Probably update this code to use redux 

    }

    toggleDialog = () => {
        this.handleDialog('open', !this.state.open);
    }

    componentDidMount() {
        window.addEventListener('show-new-year-dialog', this.toggleDialog)
    }

    componentWillUnmount() {
        window.removeEventListener('show-new-year-dialog', this.toggleDialog)
    }

    createNewYear = () => {

        let proposedYears = [
            // 2019,
            2020,
            2021,
            2022,
            2023,
            2024,
            2025,
            // 2026,
            // 2027,
            // 2028,
            // 2029,
            // 2030,
        ];

        let availableYears = []

        this.props.getCurrentFiscalYears( (data) => {
            for(let i = 0; i < proposedYears.length; i++) {
                let found = false
                for(let j = 0; j < data.length; j++) {
                    if(proposedYears[i] == data[j].year) {
                        found = true
                        break
                    }
                }
                if(!found) {
                    availableYears.push(proposedYears[i])
                }
            }
            this.setState({ years: availableYears });
            this.setState({ page: 'create-new-year'})
        })
    }

    startNewFiscalYearProcess = () => {
        console.log('starting', this.state.newYear)
        this.props.startNewFiscalYearProcess(this.state.newYear)
    }

    sortYears = (arr = []) => {
        arr.sort((a, b) => +b.year - +a.year)
        return arr
    }

    render() {

        const { classes } = this.props;
        
        return (
            <Dialog
                open={this.state.open}
                onClose={() => this.handleDialog("open", false)}
                classes={{ paper: 'dialog-container' }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                {
                    this.state.page === 'show-fiscal-years' ?
                    <>
                        <div className="layout vertical dialog-container">

                            <HeaderComponent
                                icon={
                                    <IconButton
                                        onClick={() => this.handleDialog("open", false)}
                                    >
                                        <MdClear className="icon" />
                                    </IconButton>
                                }
                                title={<span className="truncate"> Select year </span>}
                                suffix={
                                    <HeaderButton onClick={() => this.selectYear()}>
                                        Save
                                    </HeaderButton>
                                }
                            />

                            <div className="flex container-overflow-y">


                                <FormControl>
                                    <RadioGroup
                                        aria-label="yearSelection"
                                        name="yearSelection"
                                        value={this.state.selectedYear}
                                        onChange={this.handleRadioButtonChange}
                                    >
                                        {
                                            this.sortYears(this.props.rootReducer.fiscalYears).map((year) => {
                                                return (
                                                    this.props.rootReducer.lempgUserType == "LEMPG_ACCESS" ?
                                                        <FormControlLabel
                                                            key={year.id}
                                                            value={year.year}
                                                            control={<Radio classes={{ root: 'layout horizontal material-radio' }} color="primary" />}
                                                            disabled={!year.availableToCounties}
                                                            label={year.year}
                                                        />
                                                        :
                                                        <FormControlLabel
                                                            key={year.id}
                                                            value={year.year}
                                                            control={<Radio classes={{ root: 'layout horizontal material-radio' }} color="primary" />}
                                                            label={year.year}
                                                        />
                                                )
                                            })
                                        }
                                    </RadioGroup>
                                    {/* <Fab color="secondary" aria-label="edit">
                                        <MdAdd className='icon' />
                                    </Fab>                             */}
                                </FormControl>
                                <Fab onClick={this.createNewYear} color="secondary" aria-label="add" style={{ position: "absolute", bottom: 32, right: 32 }}>
                                    <MdAdd className="icon"/>
                                </Fab>

                            </div>

                        </div>
                    </>
                    : ''
                }


                {
                    this.state.page === 'create-new-year' ?
                    <>

                        <div className="layout vertical dialog-container">

                            <HeaderComponent
                                icon={
                                    <IconButton
                                        onClick={() => this.setState({page: 'show-fiscal-years'})}
                                    >
                                        <MdArrowBack className="icon" />
                                    </IconButton>
                                }
                                title={<span className="truncate"> Create new year </span>}
                            />

                            <div className="flex container-overflow-y">

                                <div className='height-20'></div>

                                <div className='layout horizontal wrap form-margin'>
                                    <FormControl className="flex">
                                        <InputLabel style={{ margin: "0 6px" }} shrink htmlFor="createNewYear"> Select year <span className="required"></span> </InputLabel>
                                        <RequiredDropdown
                                            error={this.state.validate ? true : false} 
                                            value={this.state.newYear}
                                            onChange={this.handleChange('newYear')}
                                            input={<Input name="createNewYear" id="createNewYear" />}
                                            MenuProps={DropdownMenuProps}
                                            custommargin="true"
                                            displayEmpty
                                            name="createNewYear"
                                            className="required-dropdown"
                                        >
                                            <MenuItem value="">
                                                <span className="placeholder">Select new year</span>
                                            </MenuItem>
                                            {
                                                this.state.years.map( (year) => {
                                                    return (
                                                        <MenuItem key={year} value={year}>{year}</MenuItem>
                                                    )
                                                })
                                            }                                            
                                            {/* <MenuItem value="2021">2021</MenuItem>
                                            <MenuItem value="2022">2022</MenuItem>
                                            <MenuItem value="2023">2023</MenuItem>
                                            <MenuItem value="2024">2024</MenuItem>
                                            <MenuItem value="2025">2025</MenuItem>
                                            <MenuItem value="2026">2026</MenuItem>
                                            <MenuItem value="2027">2027</MenuItem> */}
                                        </RequiredDropdown>
                                    </FormControl>                                    
                                </div>

                            </div>

                            {
                                this.state.newYear ? 
                                <div className="layout horizontal center-justified form-container" style={{ borderTop: "1px solid #E0E0E0" }}>
                                    <PrimaryButton setwidth="true" onClick={() => this.startNewFiscalYearProcess()}>
                                        Create
                                    </PrimaryButton>
                                </div> : ''
                            }


                        </div>


                    </>
                    : ''
                }


            
            </Dialog>
        
        )

    }
}

SelectNewYearDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SelectNewYearDialog));