import React from 'react';
import { connect } from 'react-redux'
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withPalmettoAuth } from "./components/auth/PalmettoAuth";
import Home from "./components/pages/Home";
import Toast from "./components/utilities/Toast";
import BeginPrefiscalYearProcess from './components/pages/pre_fiscal_year/BeginPrefiscalYearProcess'

import "./styles/common.css";
import "./styles/iron-flex-layout.css";
import "./styles/iron-flex-layout-classes.css";

// window.logger = new Logger('foo');



// Amplify.Logger.LOG_LEVEL = "DEBUG";

const mapStateToProps = state => {
  return {
    appLoading: state.rootReducer.appLoading,
    beginFiscalYearProcess: state.rootReducer.beginFiscalYearProcess,    
    lempgUserType: state.rootReducer.lempgUserType,
    currentFiscalYear: state.rootReducer.currentFiscalYear,
    selectedYear: state.rootReducer.selectedYear,
  };
};



class App extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  componentDidMount() {
  }

  render() {
    return (
      <>
        {
          this.props.beginFiscalYearProcess ? <BeginPrefiscalYearProcess /> : 
          this.props.appLoading ?
            <div className="layout vertical vertical-center full-height">
              <Fade
                in={this.props.appLoading}
                size={80}
                unmountOnExit
              >
                <CircularProgress />
              </Fade>
              <div className="height-20"></div>
              <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading the app</span>
            </div>
            :
            <>
              <Home {...this.props} />
              <Toast />
            </>
        }
      </>
    );
  }
}

// When we move to cognito uncomment this line
// export default withAuthenticator(App, true);

// When we move to cognito comment this line
export default connect(mapStateToProps, null)(withPalmettoAuth(App));
