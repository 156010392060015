import React from 'react';

import "../../../styles/common.css";
import "../../../styles/common.charts.css";

class NumberOfCountiesParticipating extends React.Component {

    state = {
    }


    constructor(props, context) {
        super(props, context);
        console.log(this.props)
    }

    getNumberOfCountiesWithAwardAmount = (counties) => {
        let count = 0;

        counties.forEach( (county) => {
            if((county.awardAmount !== null) || (county.awardAmount > 0)) {
                count++
            }
        })

        return count;
    }

    render() {
        return (

            <div className="layout vertical pie-chart-container narratives-created-container inline-grid">
                <div>
                    <span className="section-title form-margin-16">Number of participating counties</span>
                    <div className="height-10"></div>
                    <div className="layout horizontal form-margin-16 title-divider"></div>
                </div>
                <div className="layout horizontal center form-margin-16 flex">
                    <span class="number-of-narratives">{this.getNumberOfCountiesWithAwardAmount(this.props.counties) || "None"}</span>
                </div>
            </div>
        )
    }

}


export default NumberOfCountiesParticipating;
