import * as subscriptions from '../../graphql/subscriptions';
import API from '@aws-amplify/api';

import {
    SET_SELECTED_COUNTY,
    CURRENT_QUARTER,
    SET_COUNTIES_FOR_STATE_USER,
    SET_GRANT_APPLICATION,
    UPDATE_CURRENT_APPLICATION_STATE,
    CURRENT_GROUP_NARRATIVE_M2M,
    CURRENT_FISCAL_YEAR,
    CURRENT_NARRATIVE,
    ALL_NARRATIVES,
} from "../constants/action-types";


export async function subscriptionInit(dispatch, getState) {

    let {
        lempgUserType,
        currentFiscalYear,
    } = getState().rootReducer;

    // State only subscriptions
    if (lempgUserType != "LEMPG_ACCESS") {
        // Set liveupdating for Fiscal year model
        // We will only listen for file upload changes
        subscribeToFiscalYearChanges(dispatch, getState);
    
        // Set liveupdating for Groups model
        // We will only listen update award amounts from this subscription
        subscribeToGroupChanges(dispatch, getState);

        // Set liveupdating for narratives model
        // We only want to listen changes to narrative for the pre-fiscal year workflow. Narratives DO NOT get changed after that

        if (!currentFiscalYear.availableToCounties) {
            subscribeToNarrativeChanges(dispatch, getState);
        }
    }


    // Set liveupdating for Financial Reimbursement forms
    subscribeToFinancialReimbursementChanges(dispatch, getState);

    // Set liveupdating for progress reporting
    subscribeToGroupNarrativeChanges(dispatch, getState);

    // Set liveupdating for grant application
    subscribeToGrantApplicationChanges(dispatch, getState);
}

function subscribeToFiscalYearChanges(dispatch, getState) {
    const subscription = API.graphql({
        query: subscriptions.onUpdateFiscalYear
    })

    subscription.subscribe({
        next: (data) => processFiscalYearUpdate(dispatch, getState, data),
        error: (error) => {
            console.log(JSON.stringify(error));
        }
    })

}

function processFiscalYearUpdate(dispatch, getState, data) {
    let {
        lempgUserType,
        currentFiscalYear,
        counties
    } = getState().rootReducer;

    if (lempgUserType != "LEMPG_ACCESS") {
        let payload = data.value.data.onUpdateFiscalYear;
        if(payload.id == currentFiscalYear.id) {
            dispatch({
                type: CURRENT_FISCAL_YEAR,
                payload: JSON.parse(JSON.stringify(payload))
            });
        }
    }

}

function subscribeToGroupChanges(dispatch, getState) {
    const subscription = API.graphql({
        query: subscriptions.onUpdateGroup
    })

    subscription.subscribe({
        next: (data) => processGroupUpdate(dispatch, getState, data),
        error: (error) => {
            console.log(JSON.stringify(error));
        }
    })
}

function processGroupUpdate(dispatch, getState, data) {
    console.log("processGroupUpdate", data);
    let {
        lempgUserType,
        counties
    } = getState().rootReducer;

    if (lempgUserType != "LEMPG_ACCESS") {
        // Only update awardAmount field because thats what groups model is mainly updated with 

        let payload = data.value.data.onUpdateGroup;

        counties.forEach( (county, index) => {
            if(county.id === payload.id) {
                counties[index].awardAmount = payload.awardAmount;
            }
        })

        dispatch({
            type: SET_COUNTIES_FOR_STATE_USER,
            payload: JSON.parse(JSON.stringify(counties))
        });
    }
}

function subscribeToNarrativeChanges(dispatch, getState) {
    const updateSubscription =  API.graphql({
        query: subscriptions.onUpdateNarrative
    });

    const createSubscription = API.graphql({
        query: subscriptions.onCreateNarrative
    });

    const deleteSubscription = API.graphql({
        query: subscriptions.onDeleteNarrative
    });


    updateSubscription.subscribe({
        next: (data) => processNarrativeUpdate(dispatch, getState, data),
        error: (error) => {
            console.log(JSON.stringify(error))
        }
    })

    createSubscription.subscribe({
        next: (data) => processNarrativeCreate(dispatch, getState, data),
        error: (error) => {
            console.log(JSON.stringify(error))
        }
    })

    deleteSubscription.subscribe({
        next: (data) => processNarrativeDelete(dispatch, getState, data),
        error: (error) => {
            console.log(JSON.stringify(error))
        }
    })

}

function processNarrativeUpdate(dispatch, getState, data) {
    let {
        allNarratives,
        currentNarrative, 
        currentGroupNarrativeM2M,
    } = getState().rootReducer;

    // Update currentNarrative, allNarratives and check currentGroupNarrativeM2M function
    let updatedData = data.value.data.onUpdateNarrative;

    // Update CURRENT_NARRATIVE

    if(currentNarrative.id == updatedData.id) {
        dispatch({ type: CURRENT_NARRATIVE, payload: JSON.parse(JSON.stringify(updatedData))});
    }

    // Update CURRENT_GROUP_NARRATIVE_M2M

    if(currentGroupNarrativeM2M 
    && currentGroupNarrativeM2M.id 
    && currentGroupNarrativeM2M.narrative
    && currentGroupNarrativeM2M.narrative.id == updatedData.id) {
        let obj = JSON.parse(JSON.stringify(currentGroupNarrativeM2M));
        obj.narrative = updatedData;
        dispatch({ type: CURRENT_GROUP_NARRATIVE_M2M, payload: JSON.parse(JSON.stringify(obj)) });
    }

    // Update ALL_NARRATIVES

    if(allNarratives && allNarratives.length) {
        allNarratives.forEach( (narrative, index) => {
            if(narrative.id == updatedData.id) {
                allNarratives[index] = updatedData
            }
        })
        dispatch({ type: ALL_NARRATIVES, payload: JSON.parse(JSON.stringify(allNarratives)) });
    }

}

function processNarrativeCreate(dispatch, getState, data) {
    let {
        allNarratives,
        selectedYear
    } = getState().rootReducer;

    let updatedData = data.value.data.onCreateNarrative;

    if(selectedYear == updatedData.year) {
        allNarratives.push(updatedData)
        dispatch({ type: ALL_NARRATIVES, payload: JSON.parse(JSON.stringify(allNarratives)) });
    }
}

function processNarrativeDelete(dispatch, getState, data) {
    let {
        allNarratives,
        selectedYear
    } = getState().rootReducer;

    let updatedData = data.value.data.onDeleteNarrative;

    if (selectedYear == updatedData.year) {

        for(let i = allNarratives.length; i--; ) {
            if(allNarratives[i].id == updatedData.id) {
                allNarratives.splice(i, 1)
            }
        }

        dispatch({ type: ALL_NARRATIVES, payload: JSON.parse(JSON.stringify(allNarratives)) });
    }

}

/**
 * Grant Application subscription functions
 */

function subscribeToGrantApplicationChanges(dispatch, getState) {
    const subscription = API.graphql({
        query: subscriptions.onUpdateGrantApplication
    })

    subscription.subscribe({
        next: (data) => processGrantApplicationUpdate(dispatch, getState, data),
        error: (error) => {
            console.log(JSON.stringify(error));
        }
    });

    // Stop receiving data updates from the subscription
    // subscription.unsubscribe();

}

function processGrantApplicationUpdate(dispatch, getState, data) {
    console.log('processGrantApplicationUpdate', data);

    let {
        lempgUserType,
        selectedCounty,
        grantApplication,
        counties
    } = getState().rootReducer;

    let updatedData = data.value.data.onUpdateGrantApplication;

    if (lempgUserType == "LEMPG_ACCESS") {


        if (selectedCounty.grantapplication &&
            selectedCounty.grantapplication.items &&
            selectedCounty.grantapplication.items.length) {

            let items = selectedCounty.grantapplication.items;

            if (grantApplication.id == updatedData.id) {
                console.log("[SUBSCRIPTION][LEMPG_ACCESS][GRANT_APPLICATION] found item, updating grantApplication")
                dispatch({
                    type: SET_GRANT_APPLICATION,
                    payload: JSON.parse(JSON.stringify(updatedData))
                });
                dispatch({
                    type: UPDATE_CURRENT_APPLICATION_STATE,
                    payload: JSON.parse(JSON.stringify(updatedData))
                });
            }
            items.forEach((item, index) => {
                if (item.id == updatedData.id) {
                    console.log("[SUBSCRIPTION][LEMPG_ACCESS][GRANT_APPLICATION] found item, updating selectedCounty action")
                    selectedCounty.grantapplication.items[index] = updatedData;
                    dispatch({
                        type: SET_SELECTED_COUNTY,
                        payload: JSON.parse(JSON.stringify(selectedCounty))
                    });
                }
            })


        }
    }
    else {
        // State users

        // we have to update following actions

        // SET_COUNTIES_FOR_STATE_USER
        // SET_SELECTED_COUNTY
        // SET_GRANT_APPLICATION


        // Update SET_COUNTIES_FOR_STATE_USER action

        counties.forEach((county, countyIndex) => {
            if (county.grantapplication &&
                county.grantapplication.items &&
                county.grantapplication.items.length) {

                let items = county.grantapplication.items;

                items.forEach((item, index) => {
                    if (item.id == updatedData.id) {
                        counties[countyIndex].grantapplication.items[index] = updatedData;
                        dispatch({
                            type: SET_COUNTIES_FOR_STATE_USER,
                            payload: JSON.parse(JSON.stringify(counties))
                        });
                    }
                })
            }
        })

        // Update SET_SELECTED_COUNTY action
        if (selectedCounty.grantapplication &&
            selectedCounty.grantapplication.items &&
            selectedCounty.grantapplication.items.length) {

            let items = selectedCounty.grantapplication.items;

            items.forEach((item, index) => {
                if (item.id == updatedData.id) {
                    selectedCounty.grantapplication.items[index] = updatedData;
                    dispatch({
                        type: SET_SELECTED_COUNTY,
                        payload: JSON.parse(JSON.stringify(selectedCounty))
                    });
                }
            })
        }

        // Update CURRENT_QUARTER action

        if (grantApplication.id == updatedData.id) {
            dispatch({
                type: SET_GRANT_APPLICATION,
                payload: JSON.parse(JSON.stringify(updatedData))
            });
            dispatch({
                type: UPDATE_CURRENT_APPLICATION_STATE,
                payload: JSON.parse(JSON.stringify(updatedData))
            });

        }


    }

}

/**
 * PROGRESS REPORTING subscription functions
*/

function subscribeToGroupNarrativeChanges(dispatch, getState) {
    const subscription = API.graphql({
        query: subscriptions.onUpdateGroupNarrativeM2M
    })

    subscription.subscribe({
        next: (data) => processGroupNarrativeM2MUpdate(dispatch, getState, data),
        error: (error) => {
            console.log(JSON.stringify(error));
        }
    });

    // Stop receiving data updates from the subscription
    // subscription.unsubscribe();

}

function processGroupNarrativeM2MUpdate(dispatch, getState, data) {
    console.log("processGroupNarrativeM2MUpdate", data)

    // Algorithm to update a progress reporting form

    /**
     * For LEMPG_ACCESS (county) users
     * 1. Check selectedCounty action, if the update ID matches one of the ids in the progress reporting array, update that entry, if not skip it. 
     * DO NOT APPEND in the existing progress reporting array.
     */

    /**
     * For NON LEMPG_ACCESS (state) users
     * 1. Check counties, selectedCounty action, if the update ID matches one of the ids in the progress reporting array, update that entry, if not skip it.
     * DO NOT APPEND in the existing progress reporting array.
     */
     

    let {
        lempgUserType,
        selectedCounty,
        currentGroupNarrativeM2M,
        counties
    } = getState().rootReducer;

    let updatedData = data.value.data.onUpdateGroupNarrativeM2M;

    if (lempgUserType == "LEMPG_ACCESS") {
        if (selectedCounty.narratives &&
        selectedCounty.narratives.items &&
        selectedCounty.narratives.items.length) {

            // Find the narrative and update with the updated value

            let items = selectedCounty.narratives.items;

            // we are not updating currentGroupNarrativeM2M action because we dont wanna update edit page when user is in process of making changes 

            // if (currentGroupNarrativeM2M.id == updatedData.id) {
            //     console.log("[SUBSCRIPTION][LEMPG_ACCESS] found item, updating current quarter")
            //     dispatch({
            //         type: CURRENT_QUARTER,
            //         payload: JSON.parse(JSON.stringify(updatedData))
            //     });
            // }
            items.forEach((item, index) => {
                if (item.id == updatedData.id) {
                    console.log("[SUBSCRIPTION][LEMPG_ACCESS] found item, updating selectedCounty action")
                    selectedCounty.narratives.items[index] = updatedData;
                    dispatch({
                        type: SET_SELECTED_COUNTY,
                        payload: JSON.parse(JSON.stringify(selectedCounty))
                    });
                }
            })
            items.forEach((item, index) => {
                if (item.id == updatedData.id && item.id == currentGroupNarrativeM2M.id) {
                    dispatch({
                        type: CURRENT_GROUP_NARRATIVE_M2M,
                        payload: JSON.parse(JSON.stringify(item))
                    });
                }
            })

        }
    }
    else {
        // State users

        // we have to update following actions

        // SET_COUNTIES_FOR_STATE_USER
        // SET_SELECTED_COUNTY


        // Update SET_COUNTIES_FOR_STATE_USER action

        counties.forEach((county, countyIndex) => {
            if (county.narratives &&
                county.narratives.items &&
                county.narratives.items.length) {

                let items = county.narratives.items;

                items.forEach((item, index) => {
                    if (item.id == updatedData.id) {
                        counties[countyIndex].narratives.items[index] = updatedData;
                        dispatch({
                            type: SET_COUNTIES_FOR_STATE_USER,
                            payload: JSON.parse(JSON.stringify(counties))
                        });
                    }
                })
            }
        })

        // Update SET_SELECTED_COUNTY action
        if (selectedCounty.narratives &&
            selectedCounty.narratives.items &&
            selectedCounty.narratives.items.length) {

            let items = selectedCounty.narratives.items;

            items.forEach((item, index) => {
                if (item.id == updatedData.id) {
                    selectedCounty.narratives.items[index] = updatedData;
                    dispatch({
                        type: SET_SELECTED_COUNTY,
                        payload: JSON.parse(JSON.stringify(selectedCounty))
                    });
                }
            })
        }

    }
}


/**
* Financial Reimbursement subscription functions
*/

function subscribeToFinancialReimbursementChanges(dispatch, getState) {
    const subscription = API.graphql({query: subscriptions.onUpdateFinancialReimbursement})
    
    subscription.subscribe({
        next: (data) => processFinancialReimbursementUpdate(dispatch, getState, data),
        error: (error) => {
            console.log(JSON.stringify(error));
        }
    });
    
    // Stop receiving data updates from the subscription
    // subscription.unsubscribe();

}

function processFinancialReimbursementUpdate(dispatch, getState, data) {
    console.log("Inside processFinancialReimbursementUpdate", data)
    
    // Algorithm to update a financial reimbursement form
    
    /**
     * For LEMPG_ACCESS (county) users
     * 1. Check selectedCounty action, if the update ID matches one of the ids in the financial reimbursement array, update that entry, if not skip it. 
     * DO NOT APPEND in the existing financial reimbursement array.
    */

    /**
    * For NON LEMPG_ACCESS (state) users
    * 1. Check counties, selectedCounty action, if the update ID matches one of the ids in the financial reimbursement array, update that entry, if not skip it.
    * DO NOT APPEND in the existing financial reimbursement array.
    */

    let { lempgUserType, selectedCounty, currentQuarter, counties } = getState().rootReducer;
    let updatedData = data.value.data.onUpdateFinancialReimbursement;
    
    if (lempgUserType == "LEMPG_ACCESS") {


        if (selectedCounty.financialreimbursement 
        && selectedCounty.financialreimbursement.items 
        && selectedCounty.financialreimbursement.items.length) {

            let items = selectedCounty.financialreimbursement.items;
            
            if (currentQuarter.id == updatedData.id) {
                console.log("[SUBSCRIPTION][LEMPG_ACCESS] found item, updating current quarter")
                dispatch({ type: CURRENT_QUARTER, payload: JSON.parse(JSON.stringify(updatedData)) });
            }
            items.forEach( (item, index) => {
                if(item.id == updatedData.id) {
                    console.log("[SUBSCRIPTION][LEMPG_ACCESS] found item, updating selectedCounty action")
                    selectedCounty.financialreimbursement.items[index] = updatedData;
                    dispatch({ type: SET_SELECTED_COUNTY, payload: JSON.parse(JSON.stringify(selectedCounty)) });
                }
            })
         
            
        }
    } 
    else {
        // State users

        // we have to update following actions
        
        // SET_COUNTIES_FOR_STATE_USER
        // SET_SELECTED_COUNTY
        // CURRENT_QUARTER


        // Update SET_COUNTIES_FOR_STATE_USER action

        counties.forEach( (county, countyIndex) => {
            if (county.financialreimbursement
                && county.financialreimbursement.items
                && county.financialreimbursement.items.length) {

                let items = county.financialreimbursement.items;

                items.forEach((item, index) => {
                    if (item.id == updatedData.id) {
                        counties[countyIndex].financialreimbursement.items[index] = updatedData;
                        dispatch({ type: SET_COUNTIES_FOR_STATE_USER, payload: JSON.parse(JSON.stringify(counties)) });
                    }
                })
            }
        })

        // Update SET_SELECTED_COUNTY action
        if (selectedCounty.financialreimbursement
            && selectedCounty.financialreimbursement.items
            && selectedCounty.financialreimbursement.items.length) {

            let items = selectedCounty.financialreimbursement.items;

            items.forEach((item, index) => {
                if (item.id == updatedData.id) {
                    selectedCounty.financialreimbursement.items[index] = updatedData;
                    dispatch({ type: SET_SELECTED_COUNTY, payload: JSON.parse(JSON.stringify(selectedCounty)) });
                }
            })
        }

        // Update CURRENT_QUARTER action

        if (currentQuarter.id == updatedData.id) {
            dispatch({ type: CURRENT_QUARTER, payload: JSON.parse(JSON.stringify(updatedData)) });
        }


    }

}
