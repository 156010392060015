// export const AUTHENTICATED_USER = "AUTHENTICATED_USER";
export const SET_SELECTED_YEAR = "SET_SELECTED_YEAR";
export const SELECTED_YEAR_OBJECT = "SELECTED_YEAR_OBJECT";
export const SET_FISCAL_YEARS = "SET_FISCAL_YEARS";
export const CURRENT_FISCAL_YEAR = "CURRENT_FISCAL_YEAR";

export const LEMPG_USER_TYPE = "LEMPG_USER_TYPE";
export const SIDE_DRAWER_ITEMS = "SIDE_DRAWER_ITEMS";
export const SET_SELECTED_COUNTY = "SET_SELECTED_COUNTY";
export const GET_SELECTED_COUNTY = "GET_SELECTED_COUNTY";

export const SET_GRANT_APPLICATION = "SET_GRANT_APPLICATION";
export const GET_GRANT_APPLICATION = "GET_GRANT_APPLICATION";
export const GRANT_APPLICATION_SHOW_CERT_CHECKBOX = "GRANT_APPLICATION_SHOW_CERT_CHECKBOX";
export const GRANT_APPLICATION_AUTO_DEDUCT_CERT_AMOUNT = "GRANT_APPLICATION_AUTO_DEDUCT_CERT_AMOUNT";

export const SET_GAUGE_PERCENT = "SET_GAUGE_PERCENT";
export const GET_GAUGE_PERCENT = "GET_GAUGE_PERCENT";

export const APP_CONFIGURATION = "APP_CONFIGURATION";
export const SET_APP_CONFIGURATION = "SET_APP_CONFIGURATION";
export const AUTHENTICATED_USER = "AUTHENTICATED_USER";

export const SET_USER_SETTINGS = "SET_USER_SETTINGS";
export const SET_SELECTED_GROUP = "SET_SELECTED_GROUP";
export const SET_LEMPG_POSITION = "SET_LEMPG_POSITION";
export const SET_USER_ACCOUNT_INFO = "SET_USER_ACCOUNT_INFO";

export const GET_USER_SETTINGS = "GET_USER_SETTINGS";
export const GET_SELECTED_GROUP = "GET_SELECTED_GROUP";
export const GET_LEMPG_POSITION = "GET_LEMPG_POSITION";
export const GET_USER_ACCOUNT_INFO = "GET_USER_ACCOUNT_INFO";

export const SET_COUNTIES_FOR_STATE_USER = "SET_COUNTIES_FOR_STATE_USER";
export const GET_COUNTIES_FOR_STATE_USER = "GET_COUNTIES_FOR_STATE_USER";

export const GET_AWARD_AMOUNT_FOR_COUNTIES = "GET_AWARD_AMOUNT_FOR_COUNTIES";

// This is for when grant application is loaded
export const APPLICATION_LOADING_STATE = "APPLICATION_LOADING_STATE";

// This is for when the whole app is loaded
export const APP_LOADING_STATE = "APP_LOADING_STATE";

// These variables are used to hold the application state of the form

export const APPLICATION_STATE = "APPLICATION_STATE";
export const CURRENT_APPLICATION_STATE = "CURRENT_APPLICATION_STATE";
export const UPDATE_CURRENT_APPLICATION_STATE = "UPDATE_CURRENT_APPLICATION_STATE";

// Financial Reimbursement

export const SELECTED_QUARTER_FINANCIAL = "SELECTED_QUARTER_FINANCIAL";
export const CURRENT_QUARTER = "CURRENT_QUARTER";
export const CURRENT_QUARTER_ITEM = "CURRENT_QUARTER_ITEM";
export const CURRENT_QUARTER_STATE = "CURRENT_QUARTER_STATE";
export const CURRENT_FINANCIAL_COUNTY = "CURRENT_FINANCIAL_COUNTY";


// Narratives

export const SELECTED_QUARTER_PROGRESS = "SELECTED_QUARTER_PROGRESS";
export const CURRENT_NARRATIVE = "CURRENT_NARRATIVE";
export const CURRENT_GROUP_NARRATIVE_M2M = "CURRENT_GROUP_NARRATIVE_M2M";
export const IS_TASKING_SLIP_GENERATED_FOR_QUARTER = "IS_TASKING_SLIP_GENERATED_FOR_QUARTER";
export const PROGRESS_REPORTING_QUARTER = "PROGRESS_REPORTING_QUARTER";
export const ALL_NARRATIVES = "ALL_NARRATIVES";

// File library
export const FILES_UPLOADED_BY_COUNTY = "FILES_UPLOADED_BY_COUNTY";

// Extensions
export const CURRENT_EXTENSION = "CURRENT_EXTENSION";
export const ALL_EXTENSIONS = "ALL_EXTENSIONS";

// Activity Feed
export const ACTIVITY_FEED_HISTORY = "ACTIVITY_FEED_HISTORY";
export const ACTIVITY_FEED_COMMENTS = "ACTIVITY_FEED_COMMENTS";
export const ACTIVITY_FEED_HISTORY_COMMENTS = "ACTIVITY_FEED_HISTORY_COMMENTS";
export const ACTIVITY_FEED_FIELD_TITLE_REFERENCE = "ACTIVITY_FEED_FIELD_TITLE_REFERENCE";
export const ACTIVITY_FEED_FIELD_VALUE_REFERENCE = "ACTIVITY_FEED_FIELD_VALUE_REFERENCE";

// Pre fiscal year
// Pre grant award amount
export const PRE_GRANT_SELECTED_COUNTY = "PRE_GRANT_SELECTED_COUNTY";
export const SELECTED_QUARTER_PRE_FISCAL_YEAR = "SELECTED_QUARTER_PRE_FISCAL_YEAR";
export const FISCAL_YEAR_STATUS = 'FISCAL_YEAR_STATUS';
export const BEGIN_FISCAL_YEAR_PROCESS = 'BEGIN_FISCAL_YEAR_PROCESS';
export const PROPOSED_FISCAL_YEAR = 'PROPOSED_FISCAL_YEAR';

// REM Contact

export const CURRENT_REM_CONTACT = "CURRENT_REM_CONTACT";
export const ALL_REM_CONTACTS = "ALL_REM_CONTACTS";

// Table Sort

// For the page where we display all the counties for the state user
export const PROGRESS_REPORTING_STATE_COUNTIES_TABLE_SORT = "PROGRESS_REPORTING_STATE_COUNTIES_TABLE_SORT";
// For the page where we display individual narratives for a county for a given quarter in the state view
export const PROGRESS_REPORTING_STATE_COUNTY_TABLE_SORT = "PROGRESS_REPORTING_STATE_COUNTY_TABLE_SORT";
// County progress reporting view
export const PROGRESS_REPORTING_COUNTY_TABLE_SORT = "PROGRESS_REPORTING_COUNTY_TABLE_SORT";

// For the page where we display all the counties for the state user
export const FINANCIAL_REIMBURSEMENT_STATE_COUNTIES_TABLE_SORT = "FINANCIAL_REIMBURSEMENT_STATE_COUNTIES_TABLE_SORT";

// MONITORING
export const CURRENT_MONITORING_GRANT_INFORMATION_PAGE = 'CURRENT_MONITORING_GRANT_INFORMATION_PAGE';
export const CURRENT_MONITORING_SITE_VISIT_REVIEW = 'CURRENT_MONITORING_SITE_VISIT_REVIEW';