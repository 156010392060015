import React from 'react';
import "../../styles/common.css"

class LoginErrorMsg extends React.Component {
    constructor(props, context) {
        super(props, context);
    }
    componentDidMount() {
        console.log(this.props);
    }

    render() {
        return (
            <div className="layout vertical full-height center vertical-center">
                <div className="app-logo-container">
                    <img sizing="contain" className="app-logo" src={"/images/launcher.png"} />
                </div>
                {
                    this.props.error ? 
                        <>
                            <span className="title-54-18 login-error-msg">{this.props.message}
                            </span>
                            <div className="height-10"></div>
                            <a class="return-to-login" href="/">Return to login</a>
                        </>
                    :
                    <span>Nothing really</span>
                }
            </div>
        );
    }
}


export default LoginErrorMsg;