/* eslint-disable no-loop-func */
import React from 'react';
import moment from "moment";

import { connect } from 'react-redux';
import { Route, Link, Switch } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { MdArrowBack, MdMenu, MdChevronRight, MdMoreVert } from 'react-icons/md';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NumberFormat from 'react-number-format';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ErrorBox from "../../common/ErrorBox";
// Local imports

// Component imports
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import { PrimaryButton, SecondaryButton } from "../../styled-components/Buttons/Buttons";

import FinancialPersonnel from "./FinancialPersonnel";
import FinancialContractualService from "./FinancialContractualService.js";
import FinancialTravel from "./FinancialTravel";
import FinancialEquipment from "./FinancialEquipment";
import FinancialCert from "./FinancialCert";
import FinancialOther from "./FinancialOther";
import FinancialSupply from "./FinancialSupply";
import { FinancialInformationalCards, FinancialInformationalCardsMobile } from './FinancialInformationalCards';
import FinancialReimbursementPendingApproval from "./FinancialReimbursementPendingApproval";
import FinancialReimbursementApproved from "./FinancialReimbursementApproved";
import FinancialReimbursementChangesRequested from "./FinancialReimbursementChangesRequested";
import FinancialReimbursementProcessed from "./FinancialReimbursementProcessed";

import { getFormattedNumberForPrint } from '../../utilities/NumberFormat';

// Redux imports 
import { submitFinancialReimbursementApplication, printFinancialReimbursementForm, submitFinancialReimbursementDeobligation } from "../../../redux/actions/index";

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

import {
    getInformationalCardInformation,
    getDeobligationSums,
    calculateRemainingFederal,
    calculateTotalsForQuarter,
    getRemainingOvermatch,
    getMostRecentGrantApplicationForAQuarter,
} from './FinancialCommonApi';

const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        lempgUserType: state.rootReducer.lempgUserType,
        currentQuarter: state.rootReducer.currentQuarter,
        grantApplication: state.rootReducer.grantApplication,
        currentApplicationState: state.rootReducer.currentApplicationState,
        open: false,
        deobligationOpen: false,
        selectedYear: state.rootReducer.selectedYear,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        submitFinancialReimbursementApplication: (payload) => dispatch(submitFinancialReimbursementApplication(payload)),
        submitFinancialReimbursementDeobligation: (payload, callback) => dispatch(submitFinancialReimbursementDeobligation(payload, callback)),
        printFinancialReimbursementForm: (payload) => dispatch(printFinancialReimbursementForm(payload)),
    }
};

function capitalize(str) {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
}

class FinancialReimbursementPackage extends React.Component {

    state = {
        errors: [],
        anchorEl: null,
        extensionPending: this.props.currentQuarter &&
            this.props.currentQuarter.currentExtension &&
            this.props.currentQuarter.currentExtension.status == "PENDING" || false,
        extensionApproved: this.props.currentQuarter &&
            this.props.currentQuarter.currentExtension &&
            this.props.currentQuarter.currentExtension.status == "APPROVED" || false,
        deobligation: false, // True if we need to save a deobligation
        remainingFederal:  calculateRemainingFederal(this.props)
    }

    constructor(props, context) {
        super(props, context);
        this.goBack = this.goBack.bind(this);
        this.submitFimApplication = this.submitFimApplication.bind(this);
        this.printFinancialReimbursementForm = this.printFinancialReimbursementForm.bind(this);
        this.deobSums = getDeobligationSums(props.selectedCounty);
        this.dialogText = this.getDialogText();
    }

    componentDidMount = () => {
        this.setState({remainingFederal: calculateRemainingFederal(this.props)});
    }

    componentDidUpdate() {
        this.dialogText = this.getDialogText();
    }

    checkIfItemExistsInGrantApplication = (item) => {
        if (this.props.grantApplication && this.props.grantApplication[item] && this.props.grantApplication[item].items && this.props.grantApplication[item].items.length > 0) {
            var nonDeletedEntries = []
            this.props.grantApplication[item].items.forEach( (item) => {
                if(!item.deleted) nonDeletedEntries.push(item)
            })
            return nonDeletedEntries.length;
        }

        return false;
    }
   
    calculateAmount = (type) => {
        let amount = 0;
        if (!this.props.currentQuarter[type]) return amount;
        if (!this.props.currentQuarter[type].items) return amount;
        if (!this.props.currentQuarter[type].items.length) return amount;

        this.props.currentQuarter[type].items.forEach((item) => {
            if(!item.deleted) {
                if (item.quarterAmount && !isNaN(parseFloat(item.quarterAmount))) {
                    amount += parseFloat(item.quarterAmount);
                }
                if (item.matchAmount && !isNaN(parseFloat(item.matchAmount))) {
                    amount += parseFloat(item.matchAmount);
                }
                // if (item.taxAmount && !isNaN(parseFloat(item.taxAmount))) {
                //     amount += parseFloat(item.taxAmount);
                // }
            }
        })

        return Number.parseFloat(amount).toFixed(2);
    }

    getButtonTitle = (type) => {
        let title = "Start";
        if (!this.props.currentQuarter[type]) return title;
        if (!this.props.currentQuarter[type].items) return title;
        if (!this.props.currentQuarter[type].items.length) return title;

        return "Edit";
    }

    goBack() {
        this.props.history.goBack();
    }

    submitFimApplication = () => {

        if (this.state.deobligation) {
            const deob = this.deobSums;
            let payload = {
                deobligatedFunds: deob.grantFederal - deob.financialFederal,
                deobligatedFundsReason: ""
            }
            this.props.submitFinancialReimbursementDeobligation(payload);
        } else {
            this.props.submitFinancialReimbursementApplication();
        }
    }

    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleChange = (field, value) => {
        this.setState({ [field]: value });
    };

    showFinancialReimbursementExtensionPage = () => {
        this.props.history.push("/financialreimbursement/extension");
    }

    async printFinancialReimbursementForm() {
        this.handleClose();

        let mostUpdatedGrantApplication = await getMostRecentGrantApplicationForAQuarter(this.props.currentQuarter, this.props.currentApplicationState, this.props.selectedYear);

        console.log('now print', mostUpdatedGrantApplication)
        this.props.currentQuarter.currentApplicationState = mostUpdatedGrantApplication;
        this.props.currentQuarter.financialreimbursement = {};
        this.props.currentQuarter.financialreimbursement.items = this.props.selectedCounty.financialreimbursement.items;
        this.props.printFinancialReimbursementForm(this.props.currentQuarter);
    }    

    handleDeobligate = () => {
        this.setState({deobligation: true}); // We do the deobligation at the end of the 2nd dialog.
        this.setState({deobligationOpen: false});
        this.setState({open: true});
    }

    getDialogText() {
        const deob = this.deobSums;
        const quarterSubheaderTitle = "Q" + parseInt(this.props.currentQuarter.quarter.split("").reverse())

        // Possible Situations:
        // 2. The county has not spent the entire award and there are no county match funds available. County user sees a popup.
        if (deob.financialFederal < deob.grantFederal && deob.financialCountyOvermatch === 0) {
            return `$${getFormattedNumberForPrint(deob.grantFederal - deob.financialFederal)} of the federal award has not been accounted for, do you wish to deobligate those funds and continue with submitting ${quarterSubheaderTitle} financial reimbursement?`;
        }

        // 3. The county has not spent the entire award and there is extra county match funds available. County user sees a popup.

        // If the financial federal is greater than grant federal
        let remainingFederal = deob.grantFederal - deob.financialFederal;
        
        // Grant overmatch (county's own money) is the best guess of the amount they will put in
        // Do not compare it with grantOvermatch when calculating financial county overmatch
        // let remainingOvermatch = deob.grantOvermatch - deob.financialCountyOvermatch;
        let remainingOvermatch = deob.financialCountyOvermatch;

        remainingFederal = remainingFederal > 0 ? remainingFederal : 0;
        remainingOvermatch = remainingOvermatch > 0 ? remainingOvermatch : 0;

        // - A county has overmatch but the amount is less then or equal to the award amount left over.The county is limited to being reimbursed by the amount of the overmatch available.

        // - A county has more overmatch funds then the award amount left over.The county is limited to being reimbursed by the amount of the federal award amount left over since they can't get more than their total award amount.

        // If the county match is over take federal
        // if the county match is under take match

        // lets say

        // remainingFederal is $23k and remainingOvermatch is $140k => maxOvermatch will be $23k
        // remainingFederal is $23k and remainingOvermatch is $10k => maxOvermatch will be $10k

        let maxOvermatch = remainingFederal >= remainingOvermatch ? remainingOvermatch : remainingFederal;

        return `$${getFormattedNumberForPrint(deob.grantFederal - deob.financialFederal)} of the federal award has not been accounted for and there is $${getFormattedNumberForPrint(remainingOvermatch)} in county overmatch funds available. ${capitalize(this.props.selectedCounty.groupName)} County could receive an additional $${getFormattedNumberForPrint(maxOvermatch)} by revising the grant application. Do you wish to deobligate $${getFormattedNumberForPrint(deob.grantFederal - deob.financialFederal)} and continue with submitting ${quarterSubheaderTitle} financial reimbursement?`;
    }

    handleSubmit() {

        // Check all the documents
        this.setState({ errors: [] });

        // Check for blank narratives.
        var types = ['Cert', 'Contractual Service', 'Equipment', 'Other', 'Personnel', 'Supply', 'Travel'];
        types.forEach(function (type) {
            if (Math.ceil(this.state.remainingFederal.types[type.replace(' ', '').toLowerCase().replace('service', 'Service')]) < 0) {
                this.state.errors.push({
                    text: type + ' rembursement exceeds the amount in grant application.'
                });
            }
        }.bind(this));

        let remainingOvermatch = getRemainingOvermatch(this.props);
        if (remainingOvermatch.current < 0) {
            this.state.errors.push({
                text: 'Insuffcient matching county funds.'
            });
        }

        if (this.state.errors.length > 0) {
            this.setState({
                errors: this.state.errors
            });
            return;
        }

        this.setState({open: true});
    }

    handleDeobligateAndSubmit = () => {

        // Check all the documents
        this.setState({ errors: [] });

        // Check for blank narratives.
        var types = ['Cert', 'Contractual Service', 'Equipment', 'Other', 'Personnel', 'Supply', 'Travel'];
        types.forEach(function (type) {
            if (Math.ceil(this.state.remainingFederal.types[type.replace(' ', '').toLowerCase().replace('service', 'Service')]) < 0) {
                this.state.errors.push({
                    text: type + ' rembursement exceeds the amount in grant application.'
                });
            }
        }.bind(this));

        let remainingOvermatch = getRemainingOvermatch(this.props);
        if (remainingOvermatch.current < 0) {
            this.state.errors.push({
                text: 'Insuffcient matching county funds.'
            });
        }

        if (this.state.errors.length > 0) {
            this.setState({
                errors: this.state.errors
            });
            return;
        }

        // 1. The county has spent the entire federal award amount and have enough county match to cover the reimbursement. County user sees the successfully submitted message. (No Dialog)

        const deob = this.deobSums;
        if ((
                (deob.financialFederal < deob.grantFederal && deob.financialCountyOvermatch === 0) ||
                (deob.financialFederal < deob.grantFederal && deob.financialCountyOvermatch > 0)
            )
        ) {
            this.setState({ deobligationOpen: true });
        } else {
            this.setState({ open: true });
        }        
    }
   
    render() {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const quarterSubheaderTitle = "Q" + parseInt(this.props.currentQuarter.quarter.split("").reverse())

        const FinancialItems = (props) => {

            if (this.props.currentQuarter.status && this.props.lempgUserType == "LEMPG_ACCESS" && this.props.currentQuarter.status == "SUBMITTED") {
                return <FinancialReimbursementPendingApproval {...props} />
            } 
            // What county sees if financial application is approved
            else if (this.props.currentQuarter.status && this.props.lempgUserType == "LEMPG_ACCESS" && this.props.currentQuarter.status == "APPROVED") {
                return <FinancialReimbursementApproved {...props} />
            } 
            // What both state and county sees if changes are requested in the financial application
            else if (this.props.currentQuarter.status && this.props.currentQuarter.status == "CHANGES_REQUESTED") {
                return <FinancialReimbursementChangesRequested {...props} />
            } 
            else if (this.props.currentQuarter.status && this.props.currentQuarter.status == "PROCESSED") {
                return <FinancialReimbursementProcessed {...props} />
            } 
            else {
                return (
                    <div className="layout vertical full-height">


                        <Dialog
                            open={this.state.open || false}
                            onClose={() => this.handleChange("open", false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Submit Financial Reimbursement Forms"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    I CERTIFY THAT ALL INVOICES, VOUCHERS AND BILLS SUBMITTED HEREON HAVE BEEN PAID.
                                </DialogContentText>
                            </DialogContent>

                            <DialogActions>
                                <Button onClick={() => this.handleChange("open", false)} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={this.submitFimApplication} color="primary" autoFocus>
                                    Ok
                                </Button>
                            </DialogActions>

                        </Dialog>

                        <Dialog
                            open={this.state.deobligationOpen}
                            onClose={() => this.handleChange("deobligationOpen", false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Submit Financial Reimbursement Forms"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {this.dialogText}
                                </DialogContentText>
                            </DialogContent>

                            <DialogActions>
                                <Button onClick={() => this.handleChange("deobligationOpen", false)} color="primary">
                                    No
                                </Button>
                                <Button onClick={this.handleDeobligate} color="primary" autoFocus>
                                    Yes
                                </Button>
                            </DialogActions>

                        </Dialog>
    
                        <DualTitleHeaderComponent
                            icon={
                                <IconButton
                                    aria-label="close"
                                    onClick={this.goBack}
                                >
                                    <MdArrowBack className="icon" />
                                </IconButton>
    
                            }
                            titleBig={<span>Financial Reimbursement</span>}
                            titleSmall={<span style={{ textTransform: "capitalize" }}> {quarterSubheaderTitle}  • {this.props.selectedCounty && this.props.selectedCounty.groupName}</span>}
                        
                            suffix={
                                <div>
                                    <IconButton
                                        aria-label="More"
                                        aria-haspopup="true"
                                        // eslint-disable-next-line no-restricted-globals
                                        aria-owns={open ? 'application-menu' : undefined}
                                        onClick={this.handleIconDropdownClick}>
                                        <MdMoreVert className="icon" />
                                    </IconButton>

                                    <Menu
                                        id="application-menu"
                                        // anchorEl={anchorEl}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                                        // eslint-disable-next-line no-restricted-globals
                                        open={open} 
                                        onClose={this.handleClose}>
                                        <MenuItem key="printFinancialReimbursementForm" onClick={ () => this.printFinancialReimbursementForm()}>
                                            Print Financial Reimbursement Form
                                        </MenuItem>
                                        {/* Cannot ask for another extension for the same quarter if its in pending state */}
                                        {
                                            this.state.extensionPending ? "" :
                                            <MenuItem key="FinancialReimbursementRequestExtension" onClick={() => this.showFinancialReimbursementExtensionPage()}>
                                                Request an Extension
                                            </MenuItem>
                                        }
                                    </Menu>
                                </div>

                            }
                       />

                       {
                           this.state.extensionPending ?
                                <div className="layout horizontal center center-justified" style={{ height: "24px", backgroundColor: "var(--extension-pending-color", color: "white"}}>
                                    Extension request pending approval
                                </div>
                           : ""
                       }
                        {
                            this.state.extensionApproved ?
                                <div className="layout horizontal center center-justified" style={{ height: "24px", backgroundColor: "var(--extension-approved-color", color: "white" }}>
                                    Extension approved until {this.props.currentQuarter && this.props.currentQuarter.currentExtension && moment(this.props.currentQuarter.currentExtension.dateAdjustedTo ? this.props.currentQuarter.currentExtension.dateAdjustedTo : this.props.currentQuarter.currentExtension.dueDate, "YYYY-MM-DD").local().format("MM-DD-YYYY")}
                                </div>
                                : ""
                        }
                       
                       <FinancialInformationalCards items={getInformationalCardInformation(this.props)} />
                       
                       <div style={{ margin:0,opacity:0.1 }} className="information-card-horizontal-line"></div>
                                           
                        {
                            this.state.errors.length ?
                                <div className="margin-10">
                                    <ErrorBox errors={this.state.errors} />
                                </div>
                                : ""
                        }
                    
                       <div className="flex container-overflow-y" style={{ padding: "0 16px 16px"}}>
                            
                            {
                                this.checkIfItemExistsInGrantApplication("salary") ? 
                                    <div className="layout horizontal center financial-item-container">
                                        <span className={  Math.ceil(this.state.remainingFederal.types['personnel']) >= 0 ? "title-87-16 line-height-15" : "informational-card-text-color-danger" }>Personnel</span>
                                        <div className="flex"></div>
                                        <div className={  Math.ceil(this.state.remainingFederal.types['personnel']) >= 0 ? "title-87-16 line-height-15 form-margin-16" : "informational-card-text-color-danger form-margin-16" }><NumberFormat value={this.calculateAmount('personnel')} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>
                                        <Link to="/financialreimbursement/county/quarter/personnel">
                                            <SecondaryButton setwidth="true" widthpx="77px"> {this.getButtonTitle('personnel')} </SecondaryButton>
                                        </Link>
                                    </div>
                                : ""
                            }
    
    
                            {
                                this.checkIfItemExistsInGrantApplication("contractualService") ? 
                                    <div className="layout horizontal center financial-item-container">
                                        <span className={  Math.ceil(this.state.remainingFederal.types['contractualService']) >= 0 ? "title-87-16 line-height-15" : "informational-card-text-color-danger" }>Contractual Services</span>
                                        <div className="flex"></div>
                                        <div className={  Math.ceil(this.state.remainingFederal.types['contractualService']) >= 0 ? "title-87-16 line-height-15 form-margin-16" : "informational-card-text-color-danger form-margin-16" }><NumberFormat value={this.calculateAmount('contractualService')} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>
                                        <Link to="/financialreimbursement/county/quarter/contractualService">
                                            <SecondaryButton setwidth="true" widthpx="77px"> {this.getButtonTitle('contractualService')} </SecondaryButton>
                                        </Link>
                                    </div>
                                : ""
                            }
    
    
                            {
                                this.checkIfItemExistsInGrantApplication("travel") ? 
                                    <div className="layout horizontal center financial-item-container">
                                        <span className={  Math.ceil(this.state.remainingFederal.types['travel']) >= 0 ? "title-87-16 line-height-15" : "informational-card-text-color-danger" }>Travel</span>
                                        <div className="flex"></div>
                                        <div className={  Math.ceil(this.state.remainingFederal.types['travel']) >= 0 ? "title-87-16 line-height-15 form-margin-16" : "informational-card-text-color-danger form-margin-16" }><NumberFormat value={this.calculateAmount('travel')} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>
                                        <Link to="/financialreimbursement/county/quarter/travel">
                                            <SecondaryButton setwidth="true" widthpx="77px"> {this.getButtonTitle('travel')} </SecondaryButton>
                                        </Link>
                                    </div>
                                : ""    
                            }
    
    
                            {
                                this.checkIfItemExistsInGrantApplication("equipment") ? 
                                    <div className="layout horizontal center financial-item-container">
                                        <span className={  Math.ceil(this.state.remainingFederal.types['equipment']) >= 0 ? "title-87-16 line-height-15" : "informational-card-text-color-danger" }>Equipment</span>
                                        <div className="flex"></div>
                                        <div className={  Math.ceil(this.state.remainingFederal.types['equipment']) >= 0 ? "title-87-16 line-height-15 form-margin-16" : "informational-card-text-color-danger form-margin-16" }><NumberFormat value={this.calculateAmount('equipment')} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>
                                        <Link to="/financialreimbursement/county/quarter/equipment">
                                            <SecondaryButton setwidth="true" widthpx="77px"> {this.getButtonTitle('equipment')} </SecondaryButton>
                                        </Link>
                                    </div>
                                : ""
                            }
    
    
                            {
                                this.checkIfItemExistsInGrantApplication("supply") ? 
                                    <div className="layout horizontal center financial-item-container">
                                        <span className={  Math.ceil(this.state.remainingFederal.types['supply']) >= 0 ? "title-87-16 line-height-15" : "informational-card-text-color-danger" }>Supplies</span>
                                        <div className="flex"></div>
                                        <div className={  Math.ceil(this.state.remainingFederal.types['supply']) >= 0 ? "title-87-16 line-height-15 form-margin-16" : "informational-card-text-color-danger form-margin-16" }><NumberFormat value={this.calculateAmount('supply')} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>
                                        <Link to="/financialreimbursement/county/quarter/supply">
                                            <SecondaryButton setwidth="true" widthpx="77px"> {this.getButtonTitle('supply')} </SecondaryButton>
                                        </Link>
                                    </div>
                                : ""
                            }
    
    
                            {
                                this.checkIfItemExistsInGrantApplication("cert") ? 
                                    <div className="layout horizontal center financial-item-container">
                                        <span className={ Math.ceil(this.state.remainingFederal.types['cert']) >= 0 ? "title-87-16 line-height-15" : "informational-card-text-color-danger" }>Cert</span>
                                        <div className="flex"></div>
                                        <div className={  Math.ceil(this.state.remainingFederal.types['cert']) >= 0 ? "title-87-16 line-height-15 form-margin-16" : "informational-card-text-color-danger form-margin-16" }><NumberFormat value={this.calculateAmount('cert')} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>
                                        <Link to="/financialreimbursement/county/quarter/cert">
                                            <SecondaryButton setwidth="true" widthpx="77px"> {this.getButtonTitle('cert')} </SecondaryButton>
                                        </Link>
                                    </div>
                                : ""
                            }
    
                            {
                                this.checkIfItemExistsInGrantApplication("other") ? 
                                    <div className="layout horizontal center financial-item-container">
                                        <span className={  Math.ceil(this.state.remainingFederal.types['other']) >= 0 ? "title-87-16 line-height-15" : "informational-card-text-color-danger" }>Other</span>
                                        <div className="flex"></div>
                                        <div className={  Math.ceil(this.state.remainingFederal.types['other']) >= 0 ? "title-87-16 line-height-15 form-margin-16" : "informational-card-text-color-danger form-margin-16" }><NumberFormat value={this.calculateAmount('other')} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>
                                        <Link to="/financialreimbursement/county/quarter/other">
                                            <SecondaryButton setwidth="true" widthpx="77px"> {this.getButtonTitle('other')} </SecondaryButton>
                                        </Link>
                                    </div>
                                : ""
                            }
    
                        </div>
                        
                        {/* Cannot submit financial reimbursement if its in pending state */}
                        {
                            this.state.extensionPending ? "" :
                            <div className="layout horizontal wrap center-justified" style={{ borderTop: "1px solid #E0E0E0"}}>
                                    <PrimaryButton margin="8px 8px"  setwidth="true" onClick={() => this.handleSubmit()}> Submit </PrimaryButton>
                                    <PrimaryButton margin="8px 8px" setwidth="true" onClick={() => this.handleDeobligateAndSubmit()}> Deobligate & Submit </PrimaryButton>
                            </div>
                        }
    
                    </div>
                )
            }
        }

        return (

            <Switch>

                <Route
                    exact
                    path="/financialreimbursement/county/quarter"
                    component={(props) => <FinancialItems {...props} />}
                />

                <Route
                    exact
                    path="/financialreimbursement/county/quarter/personnel"
                    component={(props) => <FinancialPersonnel {...props} />}
                />

                <Route
                    exact
                    path="/financialreimbursement/county/quarter/contractualService"
                    component={(props) => <FinancialContractualService {...props} />}
                />

                <Route
                    exact
                    path="/financialreimbursement/county/quarter/travel"
                    component={(props) => <FinancialTravel {...props} />}
                />

				        <Route
                    exact
                    path="/financialreimbursement/county/quarter/equipment"
                    component={(props) => <FinancialEquipment {...props} />}
                />
				
				        <Route
                    exact
                    path="/financialreimbursement/county/quarter/supply"
                    component={(props) => <FinancialSupply {...props} />}
                />
				
				        <Route
                    exact
                    path="/financialreimbursement/county/quarter/other"
                    component={(props) => <FinancialOther {...props} />}
                />

				        <Route
                    exact
                    path="/financialreimbursement/county/quarter/cert"
                    component={(props) => <FinancialCert {...props} />}
                />
            </Switch>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialReimbursementPackage);
