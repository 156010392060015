import React from 'react';
import { connect } from 'react-redux'
import IconButton from "@material-ui/core/IconButton";
import { MdClear, MdMoreVert } from 'react-icons/md';

import { MuiPickersUtilsProvider, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';


import { DualTitleHeaderComponent } from "../layout/HeaderComponent.js"
import { PrimaryButton, HeaderButton } from "../styled-components/Buttons/Buttons.js";
import ErrorBox from "../common/ErrorBox.js";
import { RequiredTextField } from "../styled-components/TextFields/TextFields.js";

import { PalmettoDatePicker } from "../common/PalmettoDatePicker.js";

import { requestGrantApplicationExtension } from "../../redux/actions/index"


// CSS imports
import "../../styles/common.css";
import "../../styles/iron-flex-layout.css";
import "../../styles/iron-flex-layout-classes.css";


const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        lempgUserType: state.rootReducer.lempgUserType,
        selectedYear: state.rootReducer.selectedYear
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        requestGrantApplicationExtension: (payload, callback) => dispatch(requestGrantApplicationExtension(payload, callback)),
    }
}


class ApplicationExtension extends React.Component {
    
    state = {
        open: false,
        errors: [],
        validate: false,
        reasonForExtension: "",
        dueDate: (this.props.currentQuarter && this.props.currentQuarter.currentExtension && this.props.currentQuarter.currentExtension.dueDate) || "",
        dueDateDisplay: (this.props.currentQuarter && this.props.currentQuarter.currentExtension && this.props.currentQuarter.currentExtension.dueDate) || "",
    }

    constructor(props, context) {
        super(props, context);

        if(this.props.currentQuarter && this.props.currentQuarter.currentExtension) {
            this.state.dueDateDisplay = this.props.currentQuarter.currentExtension.dueDate && (new Date(this.toUTC(new Date(this.props.currentQuarter.currentExtension.dueDate))));

        }
    }

    toUTC = (date) => {
        var newDate = new Date();
        newDate.setTime(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));
        return newDate;
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }
    
    requestGrantApplicationExtension = () => {
        this.props.requestGrantApplicationExtension(this.state, () => {
            this.goBack();
        })
    }

    goBack = () => {
        this.props.history.goBack()
    }

    handleClose = (variable) => {
        this.setState({
            [variable]: false
        });
    };

    openDialog = (variable) => {
        this.setState({
            [variable]: true
        });
    }

    


    handleDueDateChange = date => {
        this.state.dueDate = moment(date).format("YYYY-MM-DD");
        this.setState({ "dueDate": this.state.dueDate });
        this.state.dueDateDisplay = new Date(date);
        this.setState({ "dueDateDisplay": this.state.dueDateDisplay });
    }


    checkForErrors = () => {
        this.state.validate = false;
        this.state.errors = [];
        this.setState({ validate: this.state.validate });
        this.setState({ errors: this.state.errors });

        if (!this.state.dueDate) {
            this.state.errors.push({
                text: "Please select due date"
            });
        }

        if (!this.state.reasonForExtension) {
            this.state.errors.push({
                text: "Please enter reason for extension request"
            });
        }

        if (this.state.errors.length > 0) {
            this.state.validate = true;
            this.setState({ validate: this.state.validate });
            this.setState({ errors: this.state.errors });
            return;
        }


        this.openDialog("open");
    }
    
    componentDidUpdate() { }

    render() {
        return (

            <div className="layout vertical full-height">

                <Dialog
                    open={this.state.open}
                    onClose={() => this.handleClose("open")}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div style={{ maxWidth: "320px" }}>
                        <DialogTitle id="alert-dialog-title">Request an extension</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                You are about to request an extension for Grant Application. Are you sure you want to do this?
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.handleClose("open")} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={this.requestGrantApplicationExtension} color="primary" autoFocus>
                                Submit
                            </Button>
                        </DialogActions>
                    </div>

                </Dialog>

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdClear className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Request an extension</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>Grant Application • {this.props.selectedYear} • {this.props.selectedCounty && this.props.selectedCounty.groupName} </span>}
                />

                <div className="flex form-container">
                    {
                        this.state.errors.length ?
                            <ErrorBox errors={this.state.errors} />
                            : ""
                    }
                    <div className="layout horizontal center section-title">Extension Details</div>
                    <div className="opacity-54">
                        You are requesting an extension for <b>{this.props.selectedYear}</b> Grant Application. The state will review and, if approved, may adjust your new due date based on situation. You will be notified of progress via email.
                    </div>

                    <div className="layout horizontal">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            {/* <span style={{ "font-size": "12px", position: "relative", left: "92px", top: "16px", color: "var(--required-textfield-color)" }}>●</span> */}
                            <PalmettoDatePicker
                                margin="normal"
                                id="mui-pickers-date"
                                label="New due date"
                                placeholder="Select new due date"
                                className="flex"
                                mandatory="true" 
                                // custommargin="true"
                                value={this.state.dueDateDisplay}
                                onChange={this.handleDueDateChange}
                                minDate={new Date(this.props.selectedYear + '-07-03')} 
                                autoOk={true}
                                format='MMM dd, yyyy'
                                invalidDateMessage=""
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{
                                    shrink: true,
                                }}

                            />
                        </MuiPickersUtilsProvider>

                    </div>

                    <div className="layout horizontal">

                        <RequiredTextField value={this.state.reasonForExtension} multiline onChange={this.handleChange('reasonForExtension')} id="extensionReason" label="Reason for extension request" error={!this.state.checkAmount && this.state.validate ? true : false}   placeholder="Enter reason for extension request" className="flex" margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }} 
                        />


                    </div>

                </div>
                <div className="layout horizontal center-justified form-container" style={{ borderTop: "1px solid #E0E0E0" }}>
                    <PrimaryButton setwidth="true" onClick={() => this.checkForErrors()}>
                        Submit
                    </PrimaryButton>
                </div>


            </div>

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationExtension);
