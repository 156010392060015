import {
    API,
    graphqlOperation
} from "aws-amplify";
import axios from 'axios';
import uuid from "uuid";
import moment from 'moment';

import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';

import {
    UPDATE_CURRENT_APPLICATION_STATE,
    FISCAL_YEAR_STATUS,
    BEGIN_FISCAL_YEAR_PROCESS,
    PROPOSED_FISCAL_YEAR,
} from "../constants/action-types";

function getPalmettoGroupID(groups, county) {
    var groupId = null
    groups.forEach((group) => {
        if(group.pvGroupName.toLowerCase() == county) {
            groupId = group.pvGroupID;
        }
    })

    return groupId;
}


function getStatusBasedOnQuarter(quarterStart, quarterEnd) {
    var start = new Date(quarterStart);
    var current = new Date();
    var end = new Date(quarterEnd);
    return (start < current && current < end);
}


export function getCurrentFiscalYears(callback) {
    return async function(dispatch, getState) {
        const { user, lempgUserType } = getState().rootReducer; 

        let fiscalYearRes = await API.graphql(graphqlOperation(queries.listFiscalYears));
        if(callback) {
            callback((fiscalYearRes && fiscalYearRes.data && fiscalYearRes.data.listFiscalYears && fiscalYearRes.data.listFiscalYears.items) || []);
        }
    }
}

export function startNewFiscalYearProcess(year) {

    return async function(dispatch, getState) {

        let messages = []
        console.log('inside new startNewFiscalYearProcess')
        dispatch({ type: BEGIN_FISCAL_YEAR_PROCESS, payload: true });

        dispatch({ type: PROPOSED_FISCAL_YEAR, payload: year });

        try {
            const { user, lempgUserType } = getState().rootReducer; 
            var region_num = parseInt(lempgUserType.split("").reverse().join(""));

            if (lempgUserType == "LEMPG_STATE_ACCESS") {
                let current_year = year;
    
                // This IF block is for state users to generate grant application and financial reimbursement entries for a new fiscal year
                
                // If this if block is executed then that means there are no groups for this env
                // Run mutation query to set up the groups for the environment
    
                let authObj = JSON.parse(sessionStorage.getItem("userAuthCreds"));
    
                var filter = { where: {} };
    
                if (!isNaN(region_num)) {
                    filter.where.pvEmdRegion = "R" + region_num;
                }
    
                let eocStatusRes = await axios.get(process.env.REACT_APP_PALMETTO_ENDPOINT + "/api/countyregions?access_token=" + authObj.id + "&filter=" + JSON.stringify(filter));
                let groupsRes = await axios.get(process.env.REACT_APP_PALMETTO_ENDPOINT + "/api/groups?access_token=" + authObj.id);
    
                
                var counties = []
                eocStatusRes.data.forEach(function (county) {
                    if (county.pvCounty != "SCEMD") {
                        county.pvCounty = county.pvCounty.toLowerCase()
                        county.pvGroupID = getPalmettoGroupID(groupsRes.data, county.pvCounty)
                        counties.push(county)
                    }
                }.bind(this))
                
                counties.sort((a, b) => {
                    return a.pvCounty.localeCompare(b.pvCounty);
                });
    
                var funcs = []
    

                console.log('counties', counties)

                // this is a 5 step process
                // 1. Create a new fiscal year
                // 2. Create Groups for that fiscal year
                // 3. Create Year County Application
                // 4. Create Grant Application
                // 5. Create Financial reimbursement
                
                messages.push({
                    'info': true,
                    'message': 'Creating entries in fiscal year model'
                })
                dispatch({ type: FISCAL_YEAR_STATUS, payload: messages });
    
                // 1. Create new fiscal year
                await API.graphql(graphqlOperation(mutations.createFiscalYear, {
                    input: {
                        year: year,
                        availableToCounties: false,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                        updatedBy: user.username   
                    }
                }))                        
    
                messages.push({
                    'info': true,
                    'message': 'Creating groups for the new fiscal year'
                })
                dispatch({ type: FISCAL_YEAR_STATUS, payload: messages });    
    
                counties.forEach((county) => {
                    var func = API.graphql(graphqlOperation(mutations.createGroup, {
                        input: {
                            groupName: county.pvCounty,
                            region: county.pvEmdRegion,
                            palmettoId: county.pvGroupID,
                            year: current_year
                        }
                    }));
    
                    funcs.push(func)
                })
    
                let quarterObj = {};
                quarterObj.quarter1 = { start: moment().month(6).startOf('month').format("MM/DD/YYYY"), end: moment().month(8).endOf('month').format("MM/DD/YYYY") }
                quarterObj.quarter2 = { start: moment().month(9).startOf('month').format("MM/DD/YYYY"), end: moment().month(11).endOf('month').format("MM/DD/YYYY") }
                quarterObj.quarter3 = { start: moment().month(0).startOf('month').add('years', 1).format("MM/DD/YYYY"), end: moment().month(2).endOf('month').add('years', 1).format("MM/DD/YYYY") }
                quarterObj.quarter4 = { start: moment().month(3).startOf('month').add('years', 1).format("MM/DD/YYYY"), end: moment().month(5).endOf('month').add('years', 1).format("MM/DD/YYYY") }
    
                Promise.all(funcs)
                    .then(async (data) => {
    
                        // now here create an year county application
    
                        var yearCountyApplicationFuncs = [];
                        var grantApplicationFuncs = [];
                        var financialreimbursementFuncs = [];
    
                        data.forEach((group) => {
                            // var yearCountyApplicationFunc = API.graphql(graphqlOperation(mutations.createYearCountyApplication, {
                            //     input: {
                            //         yearCountyApplicationGroupId: group.data.createGroup.id,
                            //         year: current_year
                            //     }
                            // }));
                            // yearCountyApplicationFuncs.push(yearCountyApplicationFunc)
    
                            var grantApplicationFunc = API.graphql(graphqlOperation(mutations.createGrantApplication, {
                                input: {
                                    grantApplicationGroupId: group.data.createGroup.id,
                                    updatedBy: user.username,
                                    version: uuid.v4(),
                                    year: current_year,
                                    status: "DRAFT",
    
                                }
                            }));
                            grantApplicationFuncs.push(grantApplicationFunc)
    
                            Object.keys(quarterObj).forEach(function (quarter, index) {
                                var quarterPayload = {
                                    quarter: quarter,
                                    quarterStartDate: moment(quarterObj[quarter].start, 'MM/DD/YYYY').toISOString(),
                                    quarterEndDate: moment(quarterObj[quarter].end, 'MM/DD/YYYY').toISOString(),
                                    updatedBy: user.username,
                                    year: current_year,
                                    status: getStatusBasedOnQuarter(quarterObj[quarter].start, quarterObj[quarter].end) ? "NOT_STARTED" : "NOT_AVAILABLE",
                                    financialReimbursementGroupId: group.data.createGroup.id,
                                }
                                var financialreimbursementFunc = API.graphql(graphqlOperation(mutations.createFinancialReimbursement, {
                                    input: quarterPayload
                                }));
                                financialreimbursementFuncs.push(financialreimbursementFunc)
    
                            })
    
                        })
    
                        messages.push({
                            'info': true,
                            'message': 'Creating year county application for the new fiscal year'
                        })
                        dispatch({ type: FISCAL_YEAR_STATUS, payload: messages });    
        

                        // await Promise.all(yearCountyApplicationFuncs)
                        console.log("year county data created")
    
                        messages.push({
                            'info': true,
                            'message': 'Creating grant application for the new fiscal year'
                        })
                        dispatch({ type: FISCAL_YEAR_STATUS, payload: messages });    
                        await Promise.all(grantApplicationFuncs)
                        console.log("grant applications created")
    
                        messages.push({
                            'info': true,
                            'message': 'Creating financial reimbursements for the new fiscal year'
                        })
                        dispatch({ type: FISCAL_YEAR_STATUS, payload: messages });    

                        await Promise.all(financialreimbursementFuncs)
                        console.log("financial reimbursements created")
    
                        messages.push({
                            'success': true,
                            'message': 'New fiscal year created, your window will reload in 2 seconds'
                        })
                        dispatch({ type: FISCAL_YEAR_STATUS, payload: messages });    

                        setTimeout(() => {
                            window.location.reload()
                        }, 2000)

                    })
                    .catch((err) => {
                        console.log("err", err)
                    })
    
            }
        } catch(e) {
            console.log('error', e)
            messages.push({
                'error': true,
                'message': 'Error occured, please contact your system administrator'
            })
            dispatch({ type: FISCAL_YEAR_STATUS, payload: messages });    

        }

    }
}
