import React from 'react';
import moment from "moment";
import { connect } from 'react-redux';
import { Route, Switch } from "react-router-dom";
import ReactTable from "react-table";
import IconButton from "@material-ui/core/IconButton";
import { MdArrowBack, MdMoreVert } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// Local imports
import { PrimaryButton, DeleteButton } from "../../styled-components/Buttons/Buttons";

// Component imports
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import FinancialPersonnelEditPage from './FinancialPersonnelEditPage.js';
import FinancialCertEditPage from './FinancialCertEditPage.js';
import FinancialContractualServiceEditPage from './FinancialContractualServiceEditPage.js';
import FinancialEquipmentEditPage from './FinancialEquipmentEditPage.js';
import FinancialSupplyEditPage from './FinancialSupplyEditPage.js';
import FinancialTravelEditPage from './FinancialTravelEditPage.js';
import FinancialOtherEditPage from './FinancialOtherEditPage.js';
import { FinancialInformationalCards, FinancialInformationalCardsMobile } from './FinancialInformationalCards';
import FinancialReimbursementApprovedState from "./FinancialReimbursementApprovedState";
import FinancialReimbursementChangesRequestedState from "./FinancialReimbursementChangesRequestedState";
import FinancialReimbursementProcessed from "./FinancialReimbursementProcessed";

// Redux

import { 
    setCurrentQuarterlyItem, 
    approveFinancialReimbursementApplication, 
    requestChangesFinancialReimbursementApplication,
    printFinancialReimbursementForm,
 } from "../../../redux/actions/index";

import {
    getInformationalCardInformation,
    getMostRecentGrantApplicationForAQuarter
} from './FinancialCommonApi';

// CSS imports
import "react-table/react-table.css";
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = (state) => {
    
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        counties: state.rootReducer.counties,
        currentQuarter: state.rootReducer.currentQuarter,
        currentQuarterItem: state.rootReducer.currentQuarterItem,
        currentApplicationState: state.rootReducer.currentApplicationState,
        grantApplication: state.rootReducer.grantApplication,
        selectedYear: state.rootReducer.selectedYear
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentQuarterlyItem: (payload) => dispatch(setCurrentQuarterlyItem(payload)),
        approveFinancialReimbursementApplication: (payload) => dispatch(approveFinancialReimbursementApplication(payload)),
        requestChangesFinancialReimbursementApplication: (payload) => dispatch(requestChangesFinancialReimbursementApplication(payload)),
        printFinancialReimbursementForm: (payload) => dispatch(printFinancialReimbursementForm(payload)),
        
    }
};

const type2Title = {
    "personnel": "Personnel",
    "equipment": "Equipment",
    "contractualService": "Contractual Service",
    "supply": "Supply",
    "travel": "Travel",
    "cert": "CERT",
    "other": "Other",
}

class FinancialReimbursementStatePackage extends React.Component {

    state = {
        counties: this.props.counties || [],
        personnel: (this.props.currentQuarter && this.props.currentQuarter.personnel && this.props.currentQuarter.personnel && this.props.currentQuarter.personnel.items) || [],
        equipment: (this.props.currentQuarter && this.props.currentQuarter.equipment && this.props.currentQuarter.equipment && this.props.currentQuarter.equipment.items) || [],
        travel: (this.props.currentQuarter && this.props.currentQuarter.travel && this.props.currentQuarter.travel && this.props.currentQuarter.travel.items) || [],
        contractualService: (this.props.currentQuarter && this.props.currentQuarter.contractualService && this.props.currentQuarter.contractualService && this.props.currentQuarter.contractualService.items) || [],
        supply: (this.props.currentQuarter && this.props.currentQuarter.supply && this.props.currentQuarter.supply && this.props.currentQuarter.supply.items) || [],
        cert: (this.props.currentQuarter && this.props.currentQuarter.cert && this.props.currentQuarter.cert && this.props.currentQuarter.cert.items) || [],
        other: (this.props.currentQuarter && this.props.currentQuarter.other && this.props.currentQuarter.other && this.props.currentQuarter.other.items) || [],
        positionTitle: this.props.currentQuarterItem && this.props.currentQuarterItem.positionTitle || "",
        quarterAmount: this.props.currentQuarterItem && this.props.currentQuarterItem.quarterAmount || "",
        matchAmount: this.props.currentQuarterItem && this.props.currentQuarterItem.matchAmount || "",
        taxAmount: this.props.currentQuarterItem && this.props.currentQuarterItem.taxAmount || "",
        id: this.props.currentQuarterItem && this.props.currentQuarterItem.id || "",
        files: this.props.currentQuarterItem && this.props.currentQuarterItem.file && JSON.parse(this.props.currentQuarterItem.file) || [],
        showEditPage: false, 
        anchorEl: null,

    }

    constructor(props, context) {
        super(props, context);
        this.requestChangesFimApplication = this.requestChangesFimApplication.bind(this);
        this.approveFimApplication = this.approveFimApplication.bind(this);
    }

    componentDidUpdate() { }

    goBack = () => {
        this.props.history.goBack();
    }

    getItems = (type) => {

        var type2 = type === "salary" ? "personnel" : type;

        var items = this.props.grantApplication && this.props.grantApplication[type] && this.props.grantApplication[type].items || [];

        var remainingItems = []
        items.forEach((item) => {
            if (!item.deleted) {
                var found = false
                this.state[type2].forEach((personnel) => {
                    if ((item.positionTitle === personnel.positionTitle) && !personnel.deleted && !item.deleted) {
                        found = true
                    }
                });
                if (!found) remainingItems.push(item)
            }
        })

        return remainingItems;

    }

    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    showItems = () => {
        this.props.history.goBack();
    }

    _convertToTitleCase = (str) => {
        str = str.toLowerCase();
        return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    }

    async printFinancialReimbursementForm() {
        this.handleClose();

        let mostUpdatedGrantApplication = await getMostRecentGrantApplicationForAQuarter(this.props.currentQuarter, this.props.currentApplicationState, this.props.selectedYear);

        console.log('now print', mostUpdatedGrantApplication)
        this.props.currentQuarter.currentApplicationState = mostUpdatedGrantApplication;
        this.props.currentQuarter.financialreimbursement = {};
        this.props.currentQuarter.financialreimbursement.items = this.props.selectedCounty.financialreimbursement.items;
        this.props.printFinancialReimbursementForm(this.props.currentQuarter);        
    }    

    getTableData = (type) => {
        var arr = [];
        this.props.currentQuarter[type] && 
        this.props.currentQuarter[type].items && 
        this.props.currentQuarter[type].items.forEach((item) => {
            if(!item.deleted) {
                let totalAmount = 0;
                item.type = type;
                item.title = type2Title[type];
                if (item.quarterAmount && !isNaN(parseFloat(item.quarterAmount))) {
                    totalAmount += parseFloat(item.quarterAmount);
                }
                if (item.matchAmount && !isNaN(parseFloat(item.matchAmount))) {
                    totalAmount += parseFloat(item.matchAmount);
                }
                // if (item.taxAmount && !isNaN(parseFloat(item.taxAmount))) {
                //     totalAmount += parseFloat(item.taxAmount);
                // }
    
                item.totalAmount = Number.parseFloat(totalAmount).toFixed(2);
    
                arr.push(item)
            }
        })
        return arr;
    }

    getQuarterData = (currentQuarter) => {
        var arr = [];
        let types = [
            "personnel",
            "equipment",
            "contractualService",
            "supply",
            "travel",
            "cert",
            "other"
        ]

        types.forEach( (type) => {
            arr = arr.concat(this.getTableData(type))
        })

        return arr;
    }

    onRowClick = (state, rowInfo, column, instance) => {
        return {
            onClick: (e, handleOriginal) => {

                var item = rowInfo.original;
                
                this.props.setCurrentQuarterlyItem(item)
                
                // this.state.id = item.id;
                // this.state.positionTitle = item.positionTitle;
                // this.state.files = JSON.parse(item.file);
                // this.state.quarterAmount = item.quarterAmount;
                // this.state.matchAmount = item.matchAmount;
                // this.state.taxAmount = item.taxAmount;
                // this.state.deleted = item.deleted;
                
                // this.setState({
                //     id: this.state.id
                // });

                setTimeout(() => {
                    this.props.history.push("/financialreimbursement/state/county/quarter/" + rowInfo.original.type)
                })
                
                if (handleOriginal) {
                    handleOriginal();
                }
            }
        };
    };

    requestChangesFimApplication () {
        this.props.requestChangesFinancialReimbursementApplication();
    }

    approveFimApplication () {
        this.props.approveFinancialReimbursementApplication();
    }

    render() {

        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const quarterSubheaderTitle = "Q" + parseInt(this.props.currentQuarter && this.props.currentQuarter.quarter && this.props.currentQuarter.quarter.split("").reverse())

        const TableComp = (props) => {

            // What county sees if financial application is approved and progress reporting is not done and only state person sees is

            let extensionPending = this.props.currentQuarter && this.props.currentQuarter.currentExtension && this.props.currentQuarter.currentExtension.status == "PENDING";
            let extensionApproved = this.props.currentQuarter && this.props.currentQuarter.currentExtension && this.props.currentQuarter.currentExtension.status == "APPROVED";

            if (this.props.currentQuarter.status && this.props.currentQuarter.status == "APPROVED") {
                return <FinancialReimbursementApprovedState {...props} />
            } 
            else if (this.props.currentQuarter.status && this.props.currentQuarter.status == "CHANGES_REQUESTED") {
                return <FinancialReimbursementChangesRequestedState {...props} />
            } 

            else if (this.props.currentQuarter.status && this.props.currentQuarter.status == "PROCESSED") {
                return <FinancialReimbursementProcessed {...props} />
            } 

            else {
                return (
    
                    <div className="layout vertical full-height">
    
                        <DualTitleHeaderComponent
                            icon={
                                <IconButton
                                    aria-label="close"
                                    onClick={this.goBack}
                                >
                                    <MdArrowBack className="icon" />
                                </IconButton>
    
                            }
                            titleBig={<span>Financial Reimbursement</span>}
                            titleSmall={<span style={{ textTransform: "capitalize" }}> {window.appHeaderTitle} • {quarterSubheaderTitle}  • {this.props.selectedCounty && this.props.selectedCounty.groupName}</span>} 
                            suffix={
                                <div>
                                    <IconButton
                                    aria-label="More"
                                    aria-haspopup="true"
                                    aria-owns={open ? 'application-menu' : undefined}
                                    onClick={this.handleIconDropdownClick}>
                                        <MdMoreVert className="icon" />
                                    </IconButton>


                                    <Menu
                                        id="application-menu"
                                        // anchorEl={anchorEl}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                                        open={open}
                                        onClose={this.handleClose}
                                    >
                                        <MenuItem key="printFinancialReimbursement" onClick={() => this.printFinancialReimbursementForm()}>
                                            Print Financial Reimbursement Form
                                    </MenuItem>
                                    </Menu>                                    
                                </div>
                            }
                        />
                        {
                            extensionPending ?
                                <div className="layout horizontal center center-justified" style={{ height: "24px", backgroundColor: "var(--extension-pending-color", color: "white"}}>
                                    Extension request pending approval
                                </div>
                           : ""
                        }
                        {
                            extensionApproved ?
                                <div className="layout horizontal center center-justified" style={{ height: "24px", backgroundColor: "var(--extension-approved-color", color: "white" }}>
                                    Extension approved until {this.props.currentQuarter && this.props.currentQuarter.currentExtension && moment(this.props.currentQuarter.currentExtension.dateAdjustedTo ? this.props.currentQuarter.currentExtension.dateAdjustedTo : this.props.currentQuarter.currentExtension.dueDate, "YYYY-MM-DD").local().format("MM-DD-YYYY")}
                                </div>
                                : ""
                        }
                        <FinancialInformationalCards items={getInformationalCardInformation(this.props)} />
                       
                        <div className="flex container-overflow-y">
                            <ReactTable
                                minRows={0}
                                data={this.getQuarterData(this.props.currentQuarter)}
                                defaultPageSize={100}
                                NoDataComponent={() => null}
                                showPagination={false}
                                get
                                getTdProps={this.onRowClick}
                                columns={[
    
                                    {
                                        Header: () => (
                                            <div className="layout horizontal center body-header">Name</div>
                                        ),
                                        className: "body-cell",
                                        accessor: "positionTitle"
                                    },
                                    {
                                        Header: () => (
                                            <div className="layout horizontal center body-header">Type</div>
                                        ),
                                        className: "body-cell",
                                        accessor: "title"
                                    },
                                    {
                                        Header: () => (
                                            <div className="layout horizontal center body-header">Total amount</div>
                                        ),
                                        className: "body-cell",
                                        accessor: "totalAmount",
                                        Cell: row => (
                                            <NumberFormat value={row.value} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                        )
    
                                    },
                                ]}
                            />
                        </div>
                        <div className="layout horizontal center-justified wrap" style={{ borderTop: "1px solid #E0E0E0" }}>
                            <DeleteButton margin="8px 8px" setwidth="true" onClick={() => this.requestChangesFimApplication()}> Request Changes </DeleteButton>
                            <PrimaryButton margin="8px 8px" setwidth="true" onClick={() => this.approveFimApplication()}> Approve </PrimaryButton>
                        </div>
                    </div>
                )
            }

        }

        return (

            <Switch>

                <Route
                    exact
                    path="/financialreimbursement/state/county/quarter"
                    component={(props) => <TableComp {...props} />}
                />

                <Route
                    path="/financialreimbursement/state/county/quarter/personnel"
                    component={(props) => <FinancialPersonnelEditPage getItems={this.getItems} showItems={this.showItems} {...this.props}  {...this.state} />}
                />

                <Route
                    path="/financialreimbursement/state/county/quarter/equipment"
                    component={(props) => <FinancialEquipmentEditPage getItems={this.getItems} showItems={this.showItems} {...this.props} {...this.state} />}
                />

                <Route
                    path="/financialreimbursement/state/county/quarter/travel"
                    component={(props) => <FinancialTravelEditPage getItems={this.getItems} showItems={this.showItems} {...this.props} {...this.state} />}
                />

                <Route
                    path="/financialreimbursement/state/county/quarter/cert"
                    component={(props) => <FinancialCertEditPage getItems={this.getItems} showItems={this.showItems} {...this.props} {...this.state} />}
                />

                <Route
                    path="/financialreimbursement/state/county/quarter/supply"
                    component={(props) => <FinancialSupplyEditPage getItems={this.getItems} showItems={this.showItems} {...this.props} {...this.state} />}
                />

                <Route
                    path="/financialreimbursement/state/county/quarter/other"
                    component={(props) => <FinancialOtherEditPage getItems={this.getItems} showItems={this.showItems} {...this.props} {...this.state} />}
                />

                <Route
                    path="/financialreimbursement/state/county/quarter/contractualService"
                    component={(props) => <FinancialContractualServiceEditPage getItems={this.getItems} showItems={this.showItems} {...this.props} {...this.state} />}
                />

            </Switch>

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialReimbursementStatePackage);
