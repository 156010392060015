import React from 'react';
import { connect } from 'react-redux'

import IconButton from "@material-ui/core/IconButton";
import { MdClear, MdMoreVert } from 'react-icons/md';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { PrimaryButton } from "../../styled-components/Buttons/Buttons";
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"

import { updateGroupTaskingSlipStatus } from "../../../redux/actions/index";


const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        selectedCounty: state.rootReducer.selectedCounty,
        currentGroupNarrativeM2M: state.rootReducer.currentGroupNarrativeM2M,
        selectedYear: state.rootReducer.selectedYear
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGroupTaskingSlipStatus: (payload, callback) => dispatch(updateGroupTaskingSlipStatus(payload, callback))
    };
};

class ProgressReportGenerateTaskingSlip extends React.Component {

    state = {
    }

    constructor(props, context) {
        super(props, context);
    }


    goBack = () => {
        this.props.history.goBack();
    }

    approveReleaseOfFunds = () => {
        this.props.updateGroupTaskingSlipStatus({}, () => {
            this.props.history.push("/progressreport/");
        });
    }

    _convertToTitleCase = (str) => {
        str = str.toLowerCase();
        return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    }

    render() {
        const quarterSubheaderTitle = "Q" + (this.props.selectedCounty && this.props.selectedCounty.quarter && parseInt(this.props.selectedCounty.quarter.split("").reverse().join("")))

        return (

            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdClear className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Tasking slip</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>{window.appHeaderTitle} • {this.props.selectedCounty && this.props.selectedCounty.groupName}</span>}

                    // suffix={<> </>}
                />

                <div className="flex form-container">
                    <div className="layout horizontal center section-title">FOR REM USAGE ONLY</div>
                    <div className="opacity-87">
                        I have reviewed the {this.props.selectedCounty && this._convertToTitleCase(this.props.selectedCounty.groupName)} county’s quarterly Supplemental progress report and find that it is complete and in keeping with the grant guidelines for the {this.props.selectedYear} {quarterSubheaderTitle} requirements. I recommend this county’s fund may be released.
                    </div>
                    <div className="height-10"></div>
                    <span className="title-54-14">Remember that {this.props.selectedCounty && this._convertToTitleCase(this.props.selectedCounty.groupName)} county’s quarterly reimbursement request will not be processed until you review and approve their quarterly progress on Supplemental System.</span>
                </div>

                <div className="layout horizontal center-justified form-container" style={{ borderTop: "1px solid #E0E0E0" }}>
                    <PrimaryButton setwidth="true" onClick={() => this.approveReleaseOfFunds()}>
                        Approve Release of Funds
                    </PrimaryButton>
                </div>

            </div>

        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressReportGenerateTaskingSlip);
