import React from 'react';
import { connect } from 'react-redux'
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import IconButton from "@material-ui/core/IconButton";
import { MdArrowBack } from 'react-icons/md';
import { DecimalFormatCustom } from "../../utilities/NumberFormat.js";
import { RequiredTextField, NormalTextField } from "../../styled-components/TextFields/TextFields.js";
import { PrimaryButton, HeaderButton } from "../../styled-components/Buttons/Buttons.js";
import { MuiPickersUtilsProvider,  } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import ErrorBox from "../../common/ErrorBox.js";
// Redux imports 
import { processFinancialReimbursementApplication } from "../../../redux/actions/index";
import { RequiredPalmettoDatePicker } from "../../common/RequiredPalmettoDatePicker.js";
import { PalmettoDatePicker } from "../../common/PalmettoDatePicker.js";


const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        currentQuarter: state.rootReducer.currentQuarter,
        grantApplication: state.rootReducer.grantApplication,
        selectedCounty: state.rootReducer.selectedCounty,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        processFinancialReimbursementApplication: (payload) => dispatch(processFinancialReimbursementApplication(payload)),
    }
}

class FinancialReimbursementApprovedState extends React.Component {


    state = {
        checkAmount: (this.props.currentQuarter && this.props.currentQuarter.checkAmount) || "",
        checkNumber: (this.props.currentQuarter && this.props.currentQuarter.checkNumber) || "",
        checkMailDate: (this.props.currentQuarter && this.props.currentQuarter.checkMailDate) || "",
        checkMailDateDisplay: (this.props.currentQuarter && this.props.currentQuarter.checkMailDate)  || "",
        stateSceisSubmissionDate: (this.props.currentQuarter && this.props.currentQuarter.stateSceisSubmissionDate) || "",
        stateSceisSubmissionDateDisplay: (this.props.currentQuarter && this.props.currentQuarter.stateSceisSubmissionDate) || "",
        stateSceisDocumentNumber: (this.props.currentQuarter && this.props.currentQuarter.stateSceisDocumentNumber) || "",
        reason: "",
        reissueCheck: false, 
        errors: [],
        validate: false,
        checkAmount2: (this.props.currentQuarter && this.props.currentQuarter.checkAmount2) || "",
        checkNumber2: (this.props.currentQuarter && this.props.currentQuarter.checkNumber2) || "",
        checkMailDate2: (this.props.currentQuarter && this.props.currentQuarter.checkMailDate2) || "",
        checkMailDateDisplay2: (this.props.currentQuarter && this.props.currentQuarter.checkMailDate2)  || "",
        stateSceisSubmissionDate2: (this.props.currentQuarter && this.props.currentQuarter.stateSceisSubmissionDate2) || "",
        stateSceisSubmissionDateDisplay2: (this.props.currentQuarter && this.props.currentQuarter.stateSceisSubmissionDate2) || "",
        stateSceisDocumentNumber2: (this.props.currentQuarter && this.props.currentQuarter.stateSceisDocumentNumber2) || "",
        reason2: "",
    };

    constructor(props, context) {
        super(props, context);

        /**
         * This component handles three different cases
         * 
         * 1. When the county's status is changed to `PROCESSED` the very first time
         * 2. When the check is `reissued` 
         * 3. When an `additional` check is issued
         * 
         */

        if(this.props.currentQuarter && this.props.currentQuarter.additionalCheckInProgress) {
            this.state.additionalCheck = true;

            this.state.checkAmount = null;
            this.state.checkNumber = null;
            this.state.checkMailDate = null;
            this.state.checkMailDateDisplay = null;
            this.state.stateSceisDocumentNumber = null;
            this.state.stateSceisSubmissionDate = null;
            this.state.stateSceisSubmissionDateDisplay = null;

            this.state.checkAmount2 = null;
            this.state.checkNumber2 = null;
            this.state.checkMailDate2 = null;
            this.state.checkMailDateDisplay2 = null;
            this.state.stateSceisDocumentNumber2 = null;
            this.state.stateSceisSubmissionDate2 = null;
            this.state.stateSceisSubmissionDateDisplay2 = null;


        } 
        else {
            if (this.props.currentQuarter && this.props.currentQuarter.currentReissuedCheck) {
                this.state.reissueCheck = true;
                this.state.checkAmount = this.props.currentQuarter.currentReissuedCheck.checkAmount;
                this.state.checkNumber = this.props.currentQuarter.currentReissuedCheck.checkNumber;
                this.state.checkMailDate = this.props.currentQuarter.currentReissuedCheck.checkMailDate;
                this.state.checkMailDateDisplay = this.props.currentQuarter.currentReissuedCheck.checkMailDate && (new Date(this.toUTC(new Date(this.props.currentQuarter.currentReissuedCheck.checkMailDate))));
                this.state.reason = this.props.currentQuarter.currentReissuedCheck.reason;

                this.state.checkAmount2 = this.props.currentQuarter.currentReissuedCheck.checkAmount2;
                this.state.checkNumber2 = this.props.currentQuarter.currentReissuedCheck.checkNumber2;
                this.state.checkMailDate2 = this.props.currentQuarter.currentReissuedCheck.checkMailDate2;
                this.state.checkMailDateDisplay2 = this.props.currentQuarter.currentReissuedCheck.checkMailDate2 && (new Date(this.toUTC(new Date(this.props.currentQuarter.currentReissuedCheck.checkMailDate2))));
                this.state.reason2 = this.props.currentQuarter.currentReissuedCheck.reason2;

            } 
            else {
                this.state.checkMailDateDisplay = this.props.currentQuarter.checkMailDate && (new Date(this.toUTC(new Date(this.props.currentQuarter.checkMailDate))));
                this.state.checkMailDateDisplay2 = this.props.currentQuarter.checkMailDate2 && (new Date(this.toUTC(new Date(this.props.currentQuarter.checkMailDate2))));
            }
    
            this.state.stateSceisSubmissionDateDisplay = this.props.currentQuarter.stateSceisSubmissionDate && (new Date(this.toUTC(new Date(this.props.currentQuarter.stateSceisSubmissionDate))));
            this.state.stateSceisSubmissionDateDisplay2 = this.props.currentQuarter.stateSceisSubmissionDate2 && (new Date(this.toUTC(new Date(this.props.currentQuarter.stateSceisSubmissionDate2))));
        }


    }

    toUTC = (date) => {
        var newDate = new Date();
        newDate.setTime(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));
        return newDate;
    }


    goBack = () => {
        this.props.history.goBack();
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    handleDateChange =  date => {
        this.state.checkMailDate = moment(date).format("YYYY-MM-DD")
        this.setState({ "checkMailDate": this.state.checkMailDate });
        this.state.checkMailDateDisplay = new Date(date);
        this.setState({ "checkMailDateDisplay": this.state.checkMailDateDisplay });
    }

    handleSubmissionDateChange =  date => {
        console.log(date)
        this.state.stateSceisSubmissionDate = moment(date); 
        this.setState({ "stateSceisSubmissionDate": this.state.stateSceisSubmissionDate });
        this.state.stateSceisSubmissionDateDisplay = new Date(date);
        this.setState({ "stateSceisSubmissionDateDisplay": this.state.stateSceisSubmissionDateDisplay });
    }

    handleDateChange2 =  date => {
        this.state.checkMailDate2 = moment(date).format("YYYY-MM-DD")
        this.setState({ "checkMailDate2": this.state.checkMailDate2 });
        this.state.checkMailDateDisplay2 = new Date(date);
        this.setState({ "checkMailDateDisplay2": this.state.checkMailDateDisplay2 });
    }

    handleSubmissionDateChange2 =  date => {
        this.state.stateSceisSubmissionDate2 = moment(date); 
        this.setState({ "stateSceisSubmissionDate2": this.state.stateSceisSubmissionDate2 });
        this.state.stateSceisSubmissionDateDisplay2 = new Date(date);
        this.setState({ "stateSceisSubmissionDateDisplay2": this.state.stateSceisSubmissionDateDisplay2 });
    }


    processFimApplication = (status) => {

        this.state.validate = false;
        this.state.errors = [];
        this.setState({ validate: this.state.validate });
        this.setState({ errors: this.state.errors });
        

        if (!this.state.stateSceisDocumentNumber) {
            this.state.errors.push({
                text: "Please enter SCEIS document number"
            });
        }

        if (!this.state.stateSceisSubmissionDate) {
            this.state.errors.push({
                text: "Please enter SCEIS document date"
            });
        }


        if (!this.state.checkNumber) {
            this.state.errors.push({
                text: "Please enter check/document number"
            });
        }

        if (!this.state.checkAmount) {
            this.state.errors.push({
                text: "Please enter check/document amount"
            });
        }

        if (!this.state.checkMailDate) {
            this.state.errors.push({
                text: "Please enter check mailed/transfer date"
            });
        }


        if(this.state.reissueCheck) {
            if (!this.state.reason) {
                this.state.errors.push({
                    text: "Please enter reason for check reissue"
                });
            }
        }

        if (this.state.errors.length > 0) {
            this.state.validate = true;
            this.setState({ validate: this.state.validate });
            this.setState({ errors: this.state.errors });
            return;
        }

        this.state.status = status;
        this.props.processFinancialReimbursementApplication(this.state)
    }

    approveFimApplication = (status) => {
        this.state.status = status;
        this.props.processFinancialReimbursementApplication(this.state)
    }

    render() {
        const quarterSubheaderTitle = "Q" + parseInt(this.props.currentQuarter.quarter.split("").reverse())

        return (
            <div className="layout vertical full-height">
                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdArrowBack className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Financial Reimbursement</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>{quarterSubheaderTitle}  • {this.props.selectedCounty && this.props.selectedCounty.groupName}</span>}
                
                    suffix={
                            <div className="form-margin-16">
                                <HeaderButton onClick={() => this.approveFimApplication("APPROVED")}>
                                    Save
                                </HeaderButton>
                            </div> 
                    }

                />

                <div className="flex container-overflow-y">

                    {
                        this.state.errors.length ?
                            <div className="margin-10">
                                <ErrorBox errors={this.state.errors} />
                            </div>
                            : ""
                    }

                    <div className="layout horizontal end section-title form-margin-16"> SCEIS Details - Check 1 </div>

                    <div className="layout horizontal wrap form-margin-10">
                        {/*  */}
                        <RequiredTextField value={this.state.stateSceisDocumentNumber} onChange={this.handleChange('stateSceisDocumentNumber')} id="stateSceisDocumentNumber" label="Document number" error={!this.state.stateSceisDocumentNumber && this.state.validate ? true : false} custommargin="true" placeholder="Enter document number" className="flex" margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }} />
                        
                        
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <RequiredPalmettoDatePicker
                                margin="normal"
                                id="mui-pickers-date2"
                                label="Document date"
                                placeholder="Select date"
                                className="flex" 
                                custommargin="true" 
                                mandatory='true' 
                                error={!this.state.stateSceisSubmissionDateDisplay && this.state.validate ? true : false} 
                                value={this.state.stateSceisSubmissionDateDisplay} 
                                onChange={this.handleSubmissionDateChange}
                                autoOk={true}
                                format='MMM dd, yyyy'
                                invalidDateMessage=""
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{
                                    shrink: true,
                                }}

                            />
                        </MuiPickersUtilsProvider>
                    </div>

                    <div className="layout horizontal end section-title form-margin-16"> {this.state.additionalCheck ? "Additional Check/Document Details" : (this.state.reissueCheck ? "Reissue Check/Document Details" : "Check/Document Details")} </div>

                    <div className="layout horizontal wrap form-margin-10">
                        {/*  */}
                        <RequiredTextField value={this.state.checkNumber} onChange={this.handleChange('checkNumber')} id="checkNumber" label="Check/Document Number" error={!this.state.checkNumber && this.state.validate ? true : false} custommargin="true" placeholder="Enter number" className="flex" margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }} />
                        <RequiredTextField value={this.state.checkAmount} onChange={this.handleChange('checkAmount')} id="checkAmount" label="Check/Document Amount" error={!this.state.checkAmount && this.state.validate ? true : false} custommargin="true" placeholder="Enter amount" className="flex" margin="normal"
                            InputProps={{ inputComponent: DecimalFormatCustom, readOnly: this.state.reissueCheck }}
                            InputLabelProps={{
                                shrink: true,
                            }} />

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <RequiredPalmettoDatePicker
                                margin="normal"
                                id="mui-pickers-date"
                                label="Mailed/Transfer Date"
                                placeholder="Select date"
                                className="flex" 
                                custommargin="true"
                                mandatory='true' 
                                error={!this.state.checkMailDateDisplay && this.state.validate ? true : false} 
                                value={this.state.checkMailDateDisplay}
                                onChange={this.handleDateChange}
                                autoOk={true}
                                format='MMM dd, yyyy'
                                invalidDateMessage=""
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{
                                    shrink: true,
                                }}

                            />
                        </MuiPickersUtilsProvider>
                    </div>

                    <div className="layout horizontal form-margin-10">
                        {
                            this.state.reissueCheck ?
                            <RequiredTextField value={this.state.reason} onChange={this.handleChange('reason')} id="reason" label="Reason for check reissue" error={!this.state.reason && this.state.validate ? true : false} custommargin="true" placeholder="Enter reason" className="flex" margin="normal"                     InputLabelProps={{ shrink: true, }} />
                            : ""
                        }

                    </div>

                    {/* Second check */}

                    <div className="layout horizontal end section-title form-margin-16"> SCEIS Details - Check 2 </div>

                    <div className="layout horizontal wrap form-margin-10">
                        <NormalTextField value={this.state.stateSceisDocumentNumber2} onChange={this.handleChange('stateSceisDocumentNumber2')} id="stateSceisDocumentNumber2" label="Document number" custommargin="true" placeholder="Enter document number" className="flex" margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }} />
                        
                        
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <PalmettoDatePicker
                                margin="normal"
                                id="mui-pickers-date5"
                                label="Document date"
                                placeholder="Select date"
                                className="flex" 
                                custommargin="true" 
                                value={this.state.stateSceisSubmissionDateDisplay2} 
                                onChange={this.handleSubmissionDateChange2}
                                autoOk={true}
                                format='MMM dd, yyyy'
                                invalidDateMessage=""
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{
                                    shrink: true,
                                }}

                            />
                        </MuiPickersUtilsProvider>
                    </div>

                    <div className="layout horizontal end section-title form-margin-16"> {this.state.additionalCheck ? "Additional Check/Document Details" : (this.state.reissueCheck ? "Reissue Check/Document Details" : "Check/Document Details")} </div>

                    <div className="layout horizontal wrap form-margin-10">
                        {/*  */}
                        <NormalTextField value={this.state.checkNumber2} onChange={this.handleChange('checkNumber2')} id="checkNumber2" label="Check/Document Number"  custommargin="true" placeholder="Enter number" className="flex" margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }} />
                        <NormalTextField value={this.state.checkAmount2} onChange={this.handleChange('checkAmount2')} id="checkAmount2" label="Check/Document Amount"  custommargin="true" placeholder="Enter amount" className="flex" margin="normal"
                            InputProps={{ inputComponent: DecimalFormatCustom, readOnly: this.state.reissueCheck }}
                            InputLabelProps={{
                                shrink: true,
                            }} />

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <PalmettoDatePicker
                                margin="normal"
                                id="mui-pickers-date8"
                                label="Mailed/Transfer Date"
                                placeholder="Select date"
                                className="flex" 
                                custommargin="true"
                                value={this.state.checkMailDateDisplay2}
                                onChange={this.handleDateChange2}
                                autoOk={true}
                                format='MMM dd, yyyy'
                                invalidDateMessage=""
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{
                                    shrink: true,
                                }}

                            />
                        </MuiPickersUtilsProvider>
                    </div>

                    <div className="layout horizontal form-margin-10">
                        {
                            this.state.reissueCheck ?
                            <NormalTextField value={this.state.reason2} onChange={this.handleChange('reason2')} id="reason2" label="Reason for check reissue" custommargin="true" placeholder="Enter reason" className="flex" margin="normal" InputLabelProps={{ shrink: true, }} />
                            : ""
                        }

                    </div>


                </div>

                <div className="layout horizontal center-justified wrap" style={{ borderTop: "1px solid #E0E0E0" }}>
                    <PrimaryButton margin="8px 8px" setwidth="true" onClick={() => this.processFimApplication("PROCESSED")}> Submit </PrimaryButton>
                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialReimbursementApprovedState);