// The purpose of this function is to make sure that amount reimbursed for an item is not less than what is already reimbursed

export const checkIfAmountEnteredIsBelowAmountReimbursed = (selectedCounty = {}, modelObj, model) => {
    let amountReimbursed = 0;
    let checkIfItemIsProcessed = false;

    if (selectedCounty && selectedCounty.financialreimbursement && selectedCounty.financialreimbursement.items && selectedCounty.financialreimbursement.items.length) {
        let items = selectedCounty.financialreimbursement.items;
        for (var i = 0; i < items.length; i++) {
            var item = items[i];
            if (item.status === 'PROCESSED') {

                // At least one financial quarter is processed

                checkIfItemIsProcessed = true;

                if (item[model] && item[model].items) {
                    for (var j = 0; j < item[model].items.length; j++) {
                        var subItem = item[model].items[j];
                        // Here we are checking that if the item has been reimbursed in any of the financial quarters then the county cannot delete the item
                        modelObj.name = (modelObj.name.trim() || '')
                        subItem.positionTitle = (subItem.positionTitle.trim() || '');

                        if ((subItem.positionTitle === modelObj.name) && !subItem.deleted) {
                            amountReimbursed += parseFloat(subItem.quarterAmount);
                        }
                    }
                }
            }
        }
    }

    // If the item is not processed there is no point in checking whether the amount reimbursed is greater or not

    if (!checkIfItemIsProcessed) return false;

    // We cannot allow counties to enter less amounts than whats already reimbursed

    // The Math.fround() function returns the nearest 32 - bit single precision float representation of a Number.

    // And therefore is one of the best choices to compare 2 floats.

    let numFB = (!isNaN(modelObj.fbFederalFunding) && Math.fround(modelObj.fbFederalFunding) || 0);
    if ((Math.fround(modelObj.federal) + Math.fround(numFB))  < Math.fround(amountReimbursed)) {        
        return {
            'error': true,
            'text': 'The federal amount you have entered cannot be less than $' + parseFloat(amountReimbursed).toFixed(2) + ' dollars'
        }
    }

    // If the amount entered is more than amount reimbursed than we are good
    return false;
}



// The purpose of this function is to check whether any item in the grant application has been reimburesed in the financial reimbursement quarter, if it is then it cannot be removed

export const checkIfAmountHasBeenReimbursed = (selectedCounty, name, model) => {

    if (selectedCounty && selectedCounty.financialreimbursement && selectedCounty.financialreimbursement.items && selectedCounty.financialreimbursement.items.length) {
        let items = selectedCounty.financialreimbursement.items;

        for (var i = 0; i < items.length; i++) {
            var item = items[i];

            // Added the DRAFT status check because the counties were deleting the personnel items in grant application AFTER asking for money and submitting the financial reimbursement quarter while being in DRAFT status

            if ((item.status === 'DRAFT') || (item.status === 'PROCESSED')) {
                if (item[model] && item[model].items) {
                    for (var j = 0; j < item[model].items.length; j++) {
                        var subItem = item[model].items[j];
                        // Here we are checking that if the item has been reimbursed in any of the financial quarters then the county cannot delete the item
                        
                        // Make sure leading and trailing spaces are removed
                        name = (name.trim() || '');
                        subItem.positionTitle = (subItem.positionTitle.trim() || '');

                        if ((subItem.positionTitle === name) && !subItem.deleted) {
                            return true;
                        }
                    }
                }
            }
        }
    }

    return false;
}