import React from 'react';
import { connect } from 'react-redux'
import IconButton from "@material-ui/core/IconButton";
import { MdArrowBack, } from 'react-icons/md';
import NumberFormat from 'react-number-format';

// Local imports
import FinancialPersonnelEditPage from "./FinancialPersonnelEditPage";
import { FinancialInformationalCards, FinancialInformationalCardsMobile } from './FinancialInformationalCards';

import { 
    getQuarterAmountByAmountType, 
    splitAmountIntoWholeNumberAndDecimal, 
    calculateAmount, 
    calculateTotalsForQuarter,
    getNonDeletedItems, 
    getAwardedTotal } from './FinancialCommonApi';

// Component imports
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js";
import { PrimaryButton } from "../../styled-components/Buttons/Buttons.js";
import { CardComponent2R1C } from "../../layout/CardComponent.js";

//Redux import

import { setCurrentQuarterlyItem } from "../../../redux/actions/index";

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = (state) => {
    console.log("got an update", state)
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        currentQuarter: state.rootReducer.currentQuarter,
        grantApplication: state.rootReducer.grantApplication,
        currentApplicationState: state.rootReducer.currentApplicationState
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentQuarterlyItem: (payload) => dispatch(setCurrentQuarterlyItem(payload)),
    }
};

class FinancialPersonnel extends React.Component {

    state = {
        personnel: (this.props.currentQuarter && this.props.currentQuarter.personnel && this.props.currentQuarter.personnel && this.props.currentQuarter.personnel.items) || [],
        showEditPage: false,
        informationalCards: [],
    }

    constructor(props, context) {
        super(props, context);
        this.goBack = this.goBack.bind(this);
    }

    showEditPage = (event) => {
        this.state.showEditPage = true;
        this.setState({ "showEditPage": this.state.showEditPage });
    }

    showItems = () => {
        this.state.showEditPage = false;
        this.setState({ "showEditPage": this.state.showEditPage });
    }

    addPersonnel = () => {
        this.state.id = "";
        this.state.positionTitle = "";
        this.state.files = [];
        this.state.quarterAmount = "";
        this.state.matchAmount = "";
        this.state.taxAmount = "";
        this.state.deleted = false; 
        this.showEditPage();
    }

    goBack() {
        this.props.history.goBack();
    }

    getPersonnelItems = (type) => {

        var items = this.props.grantApplication && this.props.grantApplication[type] && this.props.grantApplication[type].items || [];

        var remainingItems = []
        
        items.forEach((item) => {
            if (!item.deleted) {
                var found = false
                this.state.personnel.forEach((personnel) => {
                    if ((item.positionTitle === personnel.positionTitle) && !personnel.deleted && !item.deleted) {
                        found = true
                    }
                });
                if (!found) remainingItems.push(item)
            }
        })

        return remainingItems;

    }

    getPersonnelItem = (item) => {
        
        // this.props.setCurrentQuarterlyItem(item)
        this.state.id = item.id;
        this.state.positionTitle = item.positionTitle;
        this.state.files = JSON.parse(item.file);
        this.state.quarterAmount = item.quarterAmount;
        this.state.matchAmount = item.matchAmount;
        this.state.taxAmount = item.taxAmount;
        this.state.deleted = item.deleted;
        this.state.validate = false;
        this.state.errors = [];
        this.showEditPage();
    }



    /**
     * 
     * This is a feeder function for showing types of informational cards being displayed
     * The calculation for these cards might depend on some functions defined in FinancialCommonApi
     * 
     */


    getInformationalCardInformation = (currentQuarter) => {
        let informationalCards = [];
        let count = 0;

        // Fed share total
        let federalShareTotal = getQuarterAmountByAmountType(this.props.currentQuarter, ["personnel"], "quarterAmount");

        // salary amount awarded
        let totals = calculateTotalsForQuarter(this.props);

        let remainingTotal = totals.remaining['personnel'] - federalShareTotal;

        let { whole, decimal } = splitAmountIntoWholeNumberAndDecimal(remainingTotal)

        // let awardedTotal = getAwardedTotal(this.props.grantApplication, 'salary');
        // awardedTotal += (this.props.grantApplication.fbFederalFunding && !isNaN(this.props.grantApplication.fbFederalFunding) ?
        //   parseInt(this.props.grantApplication.fbFederalFunding) : 0);
  
        informationalCards.push({
            id: count++,
            title: "PERSONNEL AMT. REMAINING",
            amount1: whole,
            amount2: decimal ? ("." + decimal) : "",
            class: remainingTotal > 0 ? "informational-card-text-color-safe" : "informational-card-text-color-danger",
        })

        // Grant total / 4
        let awardedTotal = getAwardedTotal(this.props.grantApplication, 'salary');
        awardedTotal += (this.props.grantApplication.fbFederalFunding && !isNaN(this.props.grantApplication.fbFederalFunding) ?
            parseFloat(this.props.grantApplication.fbFederalFunding) : 0);

        let grantTotal = (awardedTotal / 4);
        let { whole: grantWhole, decimal: grantDecimal } = splitAmountIntoWholeNumberAndDecimal(grantTotal)

        informationalCards.push({
            id: count++,
            title: "PERSONNEL / 4",
            amount1: grantWhole,
            amount2: grantDecimal ? ("." + grantDecimal) : "",
            class: "informational-card-text-color-safe",
        })

        let { whole: federalShareTotalWhole, decimal: federalShareTotalDecimal } = splitAmountIntoWholeNumberAndDecimal(federalShareTotal)

        informationalCards.push({
            id: count++,
            title: "FED SHARE TOTAL",
            amount1: federalShareTotalWhole,
            amount2: federalShareTotalDecimal ? ("." + federalShareTotalDecimal) : "",
            class: federalShareTotal > grantTotal ? "informational-card-text-color-info" : "informational-card-text-color-safe",
        })


        // county share total
        // Federal = 0:  County = (County + Tax)
        let countyShareTotal = getQuarterAmountByAmountType(this.props.currentQuarter, ["personnel"], "matchAmount");

        // if (federalShareTotal == 0) {
        //     countyShareTotal += getQuarterAmountByAmountType(this.props.currentQuarter, ["personnel"],  "taxAmount");
        // }


        let { whole: countyShareTotalWhole, decimal: countyShareTotalDecimal } = splitAmountIntoWholeNumberAndDecimal(countyShareTotal)

        informationalCards.push({
            id: count++,
            title: "COUNTY SHARE TOTAL",
            amount1: countyShareTotalWhole,
            amount2: countyShareTotalDecimal ? ("." + countyShareTotalDecimal) : "",
            class: "informational-card-text-color-safe",
        })


        // overmatch

        let overmatch = (countyShareTotal - federalShareTotal);
        // let overmatch = -10.00;
        let { whole: overmatchWhole, decimal: overmatchDecimal } = splitAmountIntoWholeNumberAndDecimal(overmatch)

        informationalCards.push({
            id: count++,
            title: "OVERMATCH",
            amount1: overmatchWhole,
            amount2: (overmatchDecimal ? ("." + overmatchDecimal) : ""),
            hideLine: true,
            class: federalShareTotal > countyShareTotal ? "informational-card-text-color-info" : "informational-card-text-color-safe",
        })


        return informationalCards;
    }

    render() {

        const quarterSubheaderTitle = "Q" + parseInt(this.props.currentQuarter.quarter.split("").reverse())

        const PersonnelList = (props) => {
            return (
                <div className="layout vertical full-height">
                    <div className="flex container-overflow-y">
                        {
                            this.state.personnel.map((item, index) => {
                                if (!item.deleted) {
                                    return (
                                        <div key={item.id} className="layout horizontal cursor-pointer" onClick={() => this.getPersonnelItem(item)}>
                                            <CardComponent2R1C
                                                className="flex no-opacity-important-childs"
                                                title={<span style={{ textTransform: "capitalize" }}>{item.positionTitle}</span>}
                                                title2={<span className="title-54-14">
                                                    <NumberFormat value={calculateAmount(this.state.personnel || [], item)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                </span>}
                                            />
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>

                    <div className="layout horizontal margin-16">
                        <div className="flex"></div>
                        {
                            this.getPersonnelItems('salary').length > 0 ?
                                <PrimaryButton setwidth="true" onClick={() => this.addPersonnel()}> Add Personnel </PrimaryButton>
                                : ""
                        }
                    </div>
                </div>
            )
        }

        const EmptyState = (props) => {
            return (
                <div className="layout vertical full-height">
                    <div className="layout vertical flex  vertical-center">
                        <span className="text-center title-87-24">No personnel  added</span>
                        <span className="text-center title-54-16 line-height-15">Select add personnel button below to get started</span>
                    </div>

                    <div className="layout horizontal margin-16">
                        <div className="flex"></div>
                        {
                            this.getPersonnelItems('salary').length > 0 ?
                                <PrimaryButton setwidth="true" onClick={() => this.addPersonnel()}> Add Personnel </PrimaryButton>
                                : ""
                        }
                    </div>
                </div>
            )
        }



        return (

            this.state.showEditPage ? <FinancialPersonnelEditPage showItems={this.showItems} getItems={this.getPersonnelItems} {...this.state} />
                :
                <div className="layout vertical full-height">

                    <DualTitleHeaderComponent
                        icon={
                            <IconButton
                                aria-label="close"
                                onClick={this.goBack}
                            >
                                <MdArrowBack className="icon" />
                            </IconButton>

                        }
                        titleBig={<span> Personnel </span>}
                        titleSmall={<span style={{ textTransform: "capitalize" }}>{quarterSubheaderTitle}  • {this.props.selectedCounty && this.props.selectedCounty.groupName}</span>}

                        suffix={
                                <FinancialInformationalCardsMobile items={this.getInformationalCardInformation(this.props.currentQuarter, this.props.selectedCounty)} />
                            }                            
                        />
                    
                    <FinancialInformationalCards items={this.getInformationalCardInformation(this.props.currentQuarter, this.props.selectedCounty)} />

                    <div className="flex container-overflow-y">

                        {
                            this.state.personnel && this.state.personnel.length && getNonDeletedItems(this.state.personnel) > 0 ? <PersonnelList /> : <EmptyState {...this.props} />

                        }

                    </div>


                </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialPersonnel);
