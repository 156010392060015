import React from 'react';
import { Route, Switch } from "react-router-dom";
import { connect } from 'react-redux'
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';

// Local imports

// Component imports
import FinancialCountyPage from "./FinancialCountyPage";
import FinancialStatePage from "./FinancialStatePage";
import FinancialReimbursementPackage from "./FinancialReimbursementPackage";
import FinancialReimbursementStatePackage from "./FinancialReimbursementStatePackage";
import FinancialReimbursementExtensionByDate from "../extensions/FinancialReimbursementExtensionByDate";

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";


const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        loading: state.rootReducer.loading,
    }
};

class FinancialHome extends React.Component {

    state = {
    }

    constructor(props, context) {
        super(props, context);
    }

    componentDidUpdate() { }

    render() {
        return (

            <Switch>

                <Route
                    exact
                    path="/financialreimbursement/"
                    
                    component={(props) => <FinancialStatePage {...props} />}
                />
                <Route
                    path="/financialreimbursement/state/county/quarter"
                    component={
                        (props) => this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading your application. Please wait</span>
                            </div>
                            : <FinancialReimbursementStatePackage {...props} />
                    }
                />
                <Route
                    path="/financialreimbursement/county/quarter"
                    component={
                        (props) => this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading your application. Please wait</span>
                            </div>
                            : <FinancialReimbursementPackage {...props} />
                    }                    
                />
                <Route
                    path="/financialreimbursement/county"
                    component={(props) => <FinancialCountyPage {...props} />}
                />
                <Route
                    path="/financialreimbursement/extension"
                    component={
                        (props) => this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading your application. Please wait</span>
                            </div>
                            : <FinancialReimbursementExtensionByDate {...props} />
                    }                    
                />
            </Switch>

        )
    }
}

export default connect(mapStateToProps, null)(FinancialHome);
