import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { MdMenu, MdMoreVert } from 'react-icons/md';
import ReactTable from "react-table";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import moment from 'moment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Local imports
import {  getFileUploadedLinkFromBucket } from '../../common/FileUploadApi';
import { checkIfQuarterIsStarted } from '../../utilities/QuarterDates';

// Component imports
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import { CardComponent2R1C } from "../../layout/CardComponent";
import StatusComponent from "../../styled-components/Statuses/StausComponent";

// CSS imports

import "react-table/react-table.css";
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

// Redux imports
import { getNarrativeAndGroupNarrativeM2MData,
    selectQuarterProgressReporting,
 } from "../../../redux/actions/index";

const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        selectedQuarterProgress: state.rootReducer.selectedQuarterProgress,
        selectedYear: state.rootReducer.selectedYear
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getNarrativeAndGroupNarrativeM2MData: (payload, callback) => dispatch(getNarrativeAndGroupNarrativeM2MData(payload, callback)),
        selectQuarterProgressReporting: (payload) => (dispatch(selectQuarterProgressReporting(payload))),

    }
};



class ProgressReportCountyPage extends React.Component {

    state = {
        selectedQuarter: this.props.selectedQuarterProgress || 0,
        anchorEl: null,
    }

    constructor(props, context) {
        super(props, context);
        this.processAndSortItems = this.processAndSortItems.bind(this);
        this.checkIfQuarterIsStarted = checkIfQuarterIsStarted.bind(this);
    }

    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    selectTab = (index) => {
        this.state.selectedQuarter = index;
        this.setState({
            "selectedQuarter": this.state.selectedQuarter
        });
        this.props.selectQuarterProgressReporting(this.state.selectedQuarter)
    }

    processAndSortItems(county) {

        let items = county && county.narratives && county.narratives.items;
        let finItems = county && county.financialreimbursement && county.financialreimbursement.items;

        var arr = []

        items.forEach((item) => {
            if ((+item.quarter.split('').reverse()[0]) == (+this.state.selectedQuarter + 1)) {
                if((item.narrative.useFinancialReimbursementStatus)) {
                    // loop over finItems
                    finItems.forEach((finItem) => {
                        if((+finItem.quarter.split('').reverse()[0]) === (+this.state.selectedQuarter + 1)) {
                            item.status = finItem.status;

                        }
                    })

                    item.updatedStatus = item.status ? item.status : "Draft";
                    if (item.status == "AWARDED") item.updatedStatus = "Approved";
                    if (item.status == "PROCESSED") item.updatedStatus = "Approved";
                    if (item.status == "NOT_AVAILABLE") item.updatedStatus = "Draft";
                    if (item.status == "NOT_STARTED") item.updatedStatus = "Draft";
                } 
            }
        })

        items.forEach( (item) => {
            if ((parseInt(item.quarter.split("").reverse().join("")) == (this.state.selectedQuarter + 1))) {
                item.updatedStatus = item.status ? item.status : "Draft";
                if (item.status == "NOT_AVAILABLE") item.updatedStatus = "Draft";
                if (item.status == "NOT_STARTED") item.updatedStatus = "Not Started";
                if (item.status == "CHANGES_REQUESTED") item.updatedStatus = "Changes Requested";

                if(item.currentExtension && item.currentExtension.status === "APPROVED" &&
                  item.currentExtension.dueDate) {
                    item.extensionDueDateDisplay = 
                       moment(item.currentExtension.dateAdjustedTo || item.currentExtension.dueDate, "YYYY-MM-DD").local().format("MM-DD-YYYY");
                }

                arr.push(item);
            }
        })

        return arr && arr.sort((a, b) => {
            return a.narrative.narrativeTitle.localeCompare(b.narrative.narrativeTitle);
        });

    }

    _convertToTitleCase = (str) => {
        str = str.toLowerCase();
        return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    }

    onRowClick = (state, rowInfo, column, instance) => {
        return {
            onClick: (e, handleOriginal) => {

                this.props.history.push("/progressreport/county/narrative");

                this.props.getNarrativeAndGroupNarrativeM2MData(rowInfo.original, () => {
                });

                if (handleOriginal) {
                    handleOriginal();
                }
            }
        };
    };    

    handleMenuClick = (event) => {
        window.dispatchEvent(new CustomEvent("toggle-sidebar"))
    }

    downloadProgressReportingUserGuide = async () => {
        var a = await getFileUploadedLinkFromBucket({ key: "LEMPG-ProgressReporting.pdf"}, "palmetto-lempg-global-documents");
        window.open(a);
    }

    render() {

        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return (

            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.handleMenuClick}
                        >
                            <MdMenu className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Progress Reporting</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>{window.appHeaderTitle}  • {this.props.selectedCounty && this.props.selectedCounty.groupName}</span>}
                    suffix={
                        <div>
                            <IconButton
                                aria-label="More"
                                aria-haspopup="true"
                                aria-owns={open ? 'application-menu' : undefined}
                                onClick={this.handleIconDropdownClick}
                            >
                                <MdMoreVert className="icon" />
                            </IconButton>

                            <Menu
                                id="application-menu"
                                // anchorEl={anchorEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                                open={open}
                                onClose={this.handleClose}
                            >
                                <MenuItem key="progressReportingHelp" onClick={() => this.downloadProgressReportingUserGuide()}>
                                    Help
                                </MenuItem>
                            </Menu>
                        </div>

                    }

                />


                <Tabs className="layout horizontal center"
                    value={this.state.selectedQuarter}
                    indicatorColor="primary"
                    textColor="primary"
                    classes={{
                        root: "tabs-root",
                        flexContainer: "flex",
                        indicator: "tab-indicator-color",
                    }}
                    // onChange={handleChange}
                    aria-label="disabled tabs example"
                >
                    <Tab
                        className="flex"
                        classes={{
                            root: "tab-root",
                            selected: "tab-selected",
                        }}
                        onClick={() => this.selectTab(0)}
                        label="Q1"
                    />

                    <Tab
                        className="flex"
                        classes={{
                            root: "tab-root",
                            selected: "tab-selected",
                        }}
                        onClick={() => this.selectTab(1)}
                        label="Q2"
                    />
                    <Tab
                        className="flex"
                        classes={{
                            root: "tab-root",
                            selected: "tab-selected",
                        }}
                        onClick={() => this.selectTab(2)}
                        label="Q3"
                    />
                    <Tab
                        className="flex"
                        classes={{
                            root: "tab-root",
                            selected: "tab-selected",
                        }}
                        onClick={() => this.selectTab(3)}
                        label="Q4"
                    />

                </Tabs>

                <div className="flex container-overflow-y">
                    <ReactTable
                        minRows={0}
                        data={this.processAndSortItems(this.props.selectedCounty)}
                        defaultPageSize={100}
                        NoDataComponent={() => null}
                        showPagination={false}
                        getTdProps={this.onRowClick}
                        columns={[

                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">EMF</div>
                                ),
                                className: "body-cell",
                                accessor: "narrative.narrativeTitle"
                            },
                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">Status</div>
                                ),
                                className: "body-cell",
                                accessor: "updatedStatus",
                                Cell: row => (
                                    <StatusComponent text={this._convertToTitleCase(row.value)} />
                                )                                
                            },
                            
                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">Frequency</div>
                                ),
                                className: "body-cell",
                                accessor: "narrative.narrativeFrequency",
                            },
                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">Extension</div>
                                ),
                                className: "body-cell",
                                accessor: "extensionDueDateDisplay",
                            },
                        ]}
                    />

                </div>
            </div>


        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressReportCountyPage);
