import React from 'react';
import { connect } from 'react-redux';
import IconButton from "@material-ui/core/IconButton";
import { MdMenu, MdArrowBack } from 'react-icons/md';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';


// Local imports
import { getFileUploadedLink, zipDownload, getFileUploadedLinkFromBucket, getFileUploadedObject, } from '../../common/FileUploadApi';
import { printGrantApplicationForm, printQuarterlyTaskingSlip, printFinancialReimbursementForm, downloadDataAndPrintGrantApplicationForm } from "../../../redux/actions/index";

// Component imports
import { SecondaryButton } from '../../styled-components/Buttons/Buttons.js';
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js";

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";


const mapStateToProps = (state) => {
    return {
        loading: state.rootReducer.loading,
        selectedCounty: state.rootReducer.selectedCounty,
        lempgUserType: state.rootReducer.lempgUserType,
        filesUploadedByCounty: state.rootReducer.filesUploadedByCounty,
        currentApplicationState: state.rootReducer.currentApplicationState,
        selectedYear: state.rootReducer.selectedYear,
        currentFiscalYear: state.rootReducer.currentFiscalYear, 
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        printGrantApplicationForm: (payload) => dispatch(printGrantApplicationForm(payload)),
        printQuarterlyTaskingSlip: (payload) => dispatch(printQuarterlyTaskingSlip(payload)),
        printFinancialReimbursementForm: (payload) => dispatch(printFinancialReimbursementForm(payload)), 
        downloadDataAndPrintGrantApplicationForm: (payload, initial) => dispatch(downloadDataAndPrintGrantApplicationForm(payload, initial))

    }
}

class FileLibrary extends React.Component {

    state = {
        smallScreen: false,
    }

    constructor(props, context) {
        super(props, context);
    }

    goBack = () => {
        this.props.history.goBack()
    }

    printGrantApplicationForm = () => {
        this.props.printGrantApplicationForm(this.props.currentApplicationState)
    }

    printQuarterlyTaskingSlip = (payload) => {
        this.props.printQuarterlyTaskingSlip(payload)
    }

    printFinancialReimbursementForm = (quarter) => {
        let payload = {};
        let selectedCounty = this.props.selectedCounty;

        if (selectedCounty
            && selectedCounty.financialreimbursement
            && selectedCounty.financialreimbursement.items
            && selectedCounty.financialreimbursement.items.length) {

            selectedCounty.financialreimbursement.items.forEach((item) => {
                if(item.quarter == quarter) {
                    payload = JSON.parse(JSON.stringify(item));
                }
            })

            payload.currentApplicationState = this.props.currentApplicationState;
            payload.financialreimbursement = {};
            payload.financialreimbursement.items = JSON.parse(JSON.stringify(selectedCounty.financialreimbursement.items));

            this.props.printFinancialReimbursementForm(payload);
        }

    }

    checkWidth = () => {
        const match = window.matchMedia(`(max-width: 768px)`).matches;
        if(match == this.state.smallScreen) return;
        if (match) {
            this.setState({smallScreen: true});
        } else {
            this.setState({smallScreen: false});
        }
    }

    componentDidMount() {
        this.checkWidth();
        window.addEventListener("resize", this.checkWidth);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkWidth);
    }


    handleMenuClick = (event) => {
        window.dispatchEvent(new CustomEvent("toggle-sidebar"))
    }

    downloadBtnFunc = async (item) => {
        if(item.files) {
            let arr = item.files;
            if (arr.length > 1) {
                zipDownload(arr, item.title);
            } else {
                let downloadLink = await getFileUploadedLink(item.files[0]);
                window.open(downloadLink);
            }
        } else {
            
            // If no files are attached, we have to write a special thing to download
            
            if(item.key == "grantApplication") {
                this.printGrantApplicationForm(item.initial)
            }
            else if (item.key == "taskingslipquarter1") {
                this.printQuarterlyTaskingSlip("quarter1")
            }
            else if (item.key == "taskingslipquarter2") {
                this.printQuarterlyTaskingSlip("quarter2")
            }
            else if (item.key == "taskingslipquarter3") {
                this.printQuarterlyTaskingSlip("quarter3")
            }
            else if (item.key == "taskingslipquarter4") {
                this.printQuarterlyTaskingSlip("quarter4")
            }
            else if (item.key == "financialreimbursementquarter1") {
                this.printFinancialReimbursementForm("quarter1")
            }
            else if (item.key == "financialreimbursementquarter2") {
                this.printFinancialReimbursementForm("quarter2")
            }
            else if (item.key == "financialreimbursementquarter3") {
                this.printFinancialReimbursementForm("quarter3")
            }
            else if (item.key == "financialreimbursementquarter4") {
                this.printFinancialReimbursementForm("quarter4")
            }
            else if (item.key.indexOf("grantApplicationRevision") >= 0) {
                var a = await getFileUploadedObject(item.file, "Grant Application");
                this.props.downloadDataAndPrintGrantApplicationForm(a, item.initial)
            }
           
            else if (item.key == this.props.selectedYear + ".Guidelines.and.Application.Procedures.pdf") {
                if (this.props.selectedYear == "2019") {
                    var a = await getFileUploadedLinkFromBucket(item, "palmetto-lempg-global-documents");
                    window.open(a);
                } else {
                    if (this.props.currentFiscalYear && this.props.currentFiscalYear.guidelinesFile) {
                        var a = await getFileUploadedLink(this.props.currentFiscalYear.guidelinesFile)
                        window.open(a);
                    } else {
                        window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: "Guidelines File Not attached" } }))

                    }
                }
            }

            
        }
    }
    
    render() {
        return (

            this.props.loading ?
                <div className="layout vertical vertical-center full-height">
                    <Fade
                        in={this.props.loading}
                        size={80}
                        unmountOnExit
                    >
                        <CircularProgress />
                    </Fade>
                    <div className="height-20"></div>
                    <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading your files. Please wait</span>
                </div>
                :
            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        this.props.lempgUserType == "LEMPG_ACCESS" ?
                            <IconButton
                                aria-label="close"
                                onClick={this.handleMenuClick}
                            >
                                <MdMenu className="icon" />
                            </IconButton>
                        :
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdArrowBack className="icon" />
                        </IconButton>


                    }
                    titleBig={<span>Documents</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>{this.props.selectedYear} • {this.props.selectedCounty && this.props.selectedCounty.groupName}</span>}
                />

                {
                    this.props.filesUploadedByCounty.length ? 
                    <div className="flex container-overflow-y">

                        {
                            this.props.filesUploadedByCounty.map( (item) => {
                                if(!item.hide) {
                                    return (
                                        this.state.smallScreen ?
                                        <div key={item.key}  onClick={() => this.downloadBtnFunc(item)} className="layout vertical award-letters-download-box" style={{ width: "100%"}}>
        
                                            <div className="layout horizontal center award-file">
                                                <span className="award-file-title">{item.title}</span>
                                            </div>
        
                                        </div>
                                        :
                                        <div key={item.key} className="layout vertical award-letters-download-box" style={{ width: "100%"}}>
        
                                            <div className="layout horizontal center award-file form-margin-16">
                                                <span className="award-file-title">{item.title}</span>
                                                <div className="flex"></div>
                                                <SecondaryButton onClick={() => this.downloadBtnFunc(item)} nowidth="true">
                                                    Download
                                                </SecondaryButton>
                                            </div>
        
                                        </div>
                                    )
                                } else {
                                    return ""
                                }
                            })
                        }

                    </div>
                    :
                        <div className="flex layout vertical only-vertical-center container-overflow-y">

                            <span className="layout horizontal center center-justified application-status-title-small">No files added yet</span>
                            <div className="height-20"></div>

                            <div className="layout horizontal center center-justified">
                                <span style={{ padding: "0 16px", maxWidth: "500px" }} className="text-center title-big opacity-54">Files will be added here as documents are generated in Supplemental progress</span>
                            </div>
                        </div>


                }

            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileLibrary);
