/* eslint-disable indent */
/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Link, Switch } from "react-router-dom";
import { connect } from 'react-redux'

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import { MdMenu, MdChevronRight, MdMoreVert } from 'react-icons/md';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from "moment";    

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { isInteger } from "../utilities/NumberFormat";
import { DualTitleHeaderComponent } from "../layout/HeaderComponent.js"
import { PrimaryButton, DeleteButton } from "../styled-components/Buttons/Buttons";
import StatusComponent from "../styled-components/Statuses/StausComponent";
import ErrorBox from "../common/ErrorBox";
import { isNumber, parseFloatWithPrecision } from "../utilities/NumberFormat";

import StateSubmitApproval from "./StateSubmitApproval";
import StateRequestChanges from "./StateRequestChanges";
import CountyApplicationAward from "./CountyApplicationAward";
import CountyApplicationApproved from "./CountyApplicationApproved";
import ApplicationApproved from "./ApplicationApproved";
import ApplicationForm from "./ApplicationForm";
import CountyGrantApplicationCompleted from "./CountyGrantApplicationCompleted";
import ApplicationAwarded from "./ApplicationAwarded";
import ApplicationSubmitted from "./ApplicationSubmitted";
import CountyPageForState from "./CountyPageForState";
import Application from "./Application";
import AcceptanceOfAuditRequirements from "./AcceptanceOfAuditRequirements";
import CertificationDocument from "./CertificationDocument";
import AssurancesNonConstruction from "./AssurancesNonConstruction";
import LobbyingCertification from "./LobbyingCertification";
import EDMEquipmentPolicy from "./EDMEquipmentPolicy";
import PositionDescription from "./PositionDescription";
import ScopeOfWorkDocument from "./ScopeOfWorkDocument";
import NarrativesDocument from "./NarrativesDocument";
import ApplicationExtension from "./ApplicationExtension";
//  Redux import

import { submitApplication, requestChangesInApplication, printGrantApplicationForm, approveApplication } from "../../redux/actions/index";

// CSS imports
import "../../styles/common.css";
import "../../styles/iron-flex-layout.css";
import "../../styles/iron-flex-layout-classes.css";

const styles = {
    listItem: {
        // minHeight: "48px",
        // maxHeight: "60px",
        paddingLeft: "16px",
        paddingRight: "0px",
    },
    listText: {
        fontSize: "16px",
        lineHeight: "1.5",
        color: "rgba(0, 0, 0, 0.87)"
    },
    extraHeight: {
        height: "64px"
    }

};
const ITEM_HEIGHT = 48;

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        token: state.rootReducer.token,
        loading: state.rootReducer.loading,
        grantApplication: state.rootReducer.grantApplication,
        currentApplicationState: state.rootReducer.currentApplicationState,
        selectedYear: state.rootReducer.selectedYear
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        submitApplication: () => dispatch(submitApplication()),
        requestChangesInApplication: () => dispatch(requestChangesInApplication()),
        printGrantApplicationForm: (payload) => dispatch(printGrantApplicationForm(payload)),
        approveApplication: () => dispatch(approveApplication())    
    }
};


class GrantApplicationPackage extends React.Component {

    state = {
        errors: [],
        anchorEl: null,
        extensionApproved: this.props.grantApplication &&
            this.props.grantApplication.currentExtension &&
            this.props.grantApplication.currentExtension.status == "APPROVED" || false
    }

    constructor(props, context) {
        super(props, context);
        this.submitApplication = this.submitApplication.bind(this);
        this.requestChangesInApplication = this.requestChangesInApplication.bind(this);
        this.approveApplication = this.approveApplication.bind(this);
        this.printGrantApplicationForm = this.printGrantApplicationForm.bind(this);

        // if (this.props.grantApplication.currentApplicationRevisionId) {
        //     this.props.history.replace({
        //         "pathname": "/application/application_id/revision"
        //     })
        // }
    }

    requestExtension() {
        this.props.history.push("/application/extension");
    }
    
    calculateAwardAmount(type) {
        if (!this.props.currentApplicationState) return
        if (!this.props.currentApplicationState.award) return
        this.props.currentApplicationState.award.county = 0;
        this.props.currentApplicationState.award.federal = 0;
        this.props.currentApplicationState.award.remaining =
            isNumber(this.props.currentApplicationState.award.amount) ?
                Number(this.props.currentApplicationState.award.amount) : 0;

        var applicationTypes = ['contractualService', 'equipment', 'other', 'salary', 'supply', 'travel'];
        if (this.props.currentApplicationState.deductCertFromFederal) {
            applicationTypes.push('cert');
        }
        applicationTypes.forEach(function (appType) {
            this.props.currentApplicationState[appType].items.forEach(function (item) {
                if (!item.deleted) {
                    this.props.currentApplicationState.award.county +=
                        (isNumber(item.countyInKind) ?
                            Number(item.countyInKind) : 0) +
                        (isNumber(item.county) ?
                            Number(item.county) : 0);
                    this.props.currentApplicationState.award.federal +=
                        isNumber(item.federal) ?
                            Number(item.federal) : 0;

                    this.props.currentApplicationState.award.remaining -=
                        isNumber(item.federal) ?
                            Number(item.federal) : 0;

                    this.props.currentApplicationState.award.remaining = parseFloatWithPrecision(this.props.currentApplicationState.award.remaining);

                    this.props.currentApplicationState.award.federal = parseFloatWithPrecision(this.props.currentApplicationState.award.federal);

                    this.props.currentApplicationState.award.county = parseFloatWithPrecision(this.props.currentApplicationState.award.county);

                }
            }.bind(this));
        }.bind(this));

        this.props.currentApplicationState.award.remaining -=
            isNumber(this.props.currentApplicationState.fbFederalFunding) ?
                Number(this.props.currentApplicationState.fbFederalFunding) : 0;
        this.props.currentApplicationState.award.federal +=
            isNumber(this.props.currentApplicationState.fbFederalFunding) ?
                Number(this.props.currentApplicationState.fbFederalFunding) : 0;
        this.props.currentApplicationState.award.county +=
            isNumber(this.props.currentApplicationState.fbCountyCashFunding) ?
                Number(this.props.currentApplicationState.fbCountyCashFunding) : 0;
        this.props.currentApplicationState.award.county +=
            isNumber(this.props.currentApplicationState.fbCountyInkindFunding) ?
                Number(this.props.currentApplicationState.fbCountyInkindFunding) : 0;


        return !type || type === undefined ? 0 :
            type === 'county' ? parseFloatWithPrecision(this.props.currentApplicationState.award.county) :
                type === 'federal' ? parseFloatWithPrecision(this.props.currentApplicationState.award.federal) :
                    type === 'remaining' ? parseFloatWithPrecision(this.props.currentApplicationState.award.remaining) :
                        type === 'amount' ? parseFloatWithPrecision(this.props.currentApplicationState.award.amount) : 0;
    }


    submitApplication () {

        // Check all the documents
        this.state.errors = [];
        this.setState({
            errors: this.state.errors
        });

        // acceptanceOfAuditReqFile
        // certificationDocumentFile
        // assurancesNonContructionFile
        // lobbyingCertificationFile
        // edmEquipmentPolicyFile
        // positionDescFile
        
        // In future
        // acknowledgementFile

        // Check for blank narratives.
        var applicationTypes = [ 'cert', 'contractualService', 'equipment', 'other', 'salary', 'supply', 'travel']; 
        applicationTypes.forEach(function(appType) {
            var narrative = appType !== undefined && appType && appType !== '' ?
                this.props.currentApplicationState[appType + 'Narrative'] : null;
            var narrativeName = 
                appType === 'contractualService' ? 'contractual service' : 
                appType === 'salary' ? 'personal budget': appType;   
            narrativeName = "The " + narrativeName + " narrative cannot be blank."    
            this.props.currentApplicationState[appType].items.forEach(function(item) {
                if (!item.deleted) {
                    if (((isInteger(item.countyInKind) && parseInt(item.countyInKind) > 0) ||
                        (isInteger(item.county) && parseInt(item.county) > 0) ||
                            (isInteger(item.federal) && parseInt(item.federal) > 0)) &&
                                (!narrative || narrative.trim().length === 0)) {
                                    this.state.errors.push({
                                        text: narrativeName
                                    });
                    }
                }
            }.bind(this));
        }.bind(this));

        if (this.props.currentApplicationState && this.calculateAwardAmount('federal') > this.calculateAwardAmount('amount')) {
            this.state.errors.push({
                text: "Total federal application amount cannot be greater than award."
            });
        }

        if (this.props.currentApplicationState && this.calculateAwardAmount('county') < this.calculateAwardAmount('federal')) {
            this.state.errors.push({
               text: "Total county application amount cannot be less than federal."
            });
        }
        
        if (this.props.currentApplicationState && this.props.currentApplicationState.acceptanceOfAuditReqFile == null) {
            this.state.errors.push({
                text: "Please upload Acceptance of Audit Requirement document"
            })
        }

        if (this.props.currentApplicationState && this.props.currentApplicationState.certificationDocumentFile == null) {
            this.state.errors.push({
                text: "Please upload Certification document"
            })
        }

        if (this.props.currentApplicationState && this.props.currentApplicationState.assurancesNonContructionFile == null) {
            this.state.errors.push({
                text: "Please upload Assurances Non-Construction document"
            })
        }

        if (this.props.currentApplicationState && this.props.currentApplicationState.lobbyingCertificationFile == null) {
            this.state.errors.push({
                text: "Please upload Lobbying document"
            })
        }

        if (this.props.currentApplicationState && this.props.currentApplicationState.edmEquipmentPolicyFile == null) {
            this.state.errors.push({
                text: "Please upload Equipment Policy document"
            })
        }

        if (this.props.currentApplicationState && this.checkExtraFileFieldForPositionDescription(this.props.currentApplicationState)) {
            this.state.errors.push({
                text: "Please upload Position Description document"
            })
        }

        // SCOPE of work document is only required for FY2019
        if(this.props.selectedYear == "2019") {
            if (this.props.currentApplicationState && this.props.currentApplicationState.scopeOfWorkFile == null) {
                this.state.errors.push({
                    text: "Please upload Scope of Work document"
                })
            }
        }

        if (this.props.currentApplicationState && this.props.currentApplicationState.narrativesFile == null) {
            this.state.errors.push({
                text: "Please upload Narratives document"
            })
        }


        if(this.state.errors.length > 0) {
            this.setState({
                errors: this.state.errors
            });
            return;
        }

        this.props.submitApplication();
    }
    
    checkExtraFileFieldForPositionDescription = (application) => {

        if (application && application.positionDescFile) return false;

        if(application.extraFile) {
            var files = JSON.parse(application.extraFile);
            var found = false;

            files.forEach((file) => {
                if (file.type == "grantApplicationPositionDescFileId") {
                    found = true;
                }
            })

            // File exists
            return !found;
        }

        // File does not exists in positionDescFile nor in extraFile
        return true;

    }

    approveApplication () {
        // Put the county into approval state after state user hits the `Approve` button
        this.props.approveApplication()
    }

    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    requestChangesInApplication () {
        this.props.requestChangesInApplication();
    }

    handleMenuClick = (event) => {
        window.dispatchEvent(new CustomEvent("toggle-sidebar"))
    }

    printGrantApplicationForm () {
        // implement rob stuff here
        this.handleClose();
        this.props.printGrantApplicationForm(this.props.currentApplicationState)
    }

    checkIfPositionDescFileExists (application) {
        if(application && application.positionDescFile) {
            return true;
        }

        if (application && application.extraFile) {

            var files = application.extraFile;

            try {
                var files = JSON.parse(files);

                for(var i = 0; i < files.length; i++) {
                    if (files[i].type === "grantApplicationPositionDescFileId") {
                        return true;
                    }
                }

            } catch (e) {
                return false;
            }

        }

        return false;
    }

    render() {

        const { classes } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        const GrantPackage = (props) => {

            if (this.props.grantApplication.status && this.props.lempgUserType == "LEMPG_ACCESS" && this.props.grantApplication.status == "SUBMITTED") {
                return <ApplicationSubmitted {...this.props} />
            } 

            // If the state approved the application, show the county user upload page
            else if (this.props.grantApplication.status && this.props.lempgUserType == "LEMPG_ACCESS" && this.props.grantApplication.status == "APPROVED") {
                // return <CountyApplicationAward {...this.props} />
                return <CountyApplicationApproved {...this.props} />
            } 
            else if (this.props.grantApplication.status && this.props.lempgUserType == "LEMPG_ACCESS" && this.props.grantApplication.status == "AWARDED") {
                if (this.props.grantApplication && this.props.grantApplication.awardDocumentsUploadedByCounty) {
                    return <CountyGrantApplicationCompleted {...this.props} />
                } else {
                    return <CountyApplicationAward {...this.props} />
                }
            } 
            else if (this.props.grantApplication.status && this.props.grantApplication.status == "APPROVED") {
                // return <ApplicationApproved {...this.props} />
                return <StateSubmitApproval {...this.props} />
            }

            // Awarded 
            else if (this.props.grantApplication.status && this.props.grantApplication.status == "AWARDED") {
                return <ApplicationAwarded {...this.props} />
            } 
            else {

                return (

                <div className="layout vertical full-height">

                    <DualTitleHeaderComponent
                        icon={
                            <IconButton
                                aria-label="close"
                                onClick={this.handleMenuClick}
                            >
                                <MdMenu className="icon" />
                            </IconButton>

                        }
                        titleBig={<span>Application</span>}
                        titleSmall={<span style={{ textTransform: "capitalize"}}>{window.appHeaderTitle} • {this.props.grantApplication.group && this.props.grantApplication.group.groupName}</span>}

                        suffix={
                            <div>
                                <IconButton
                                    aria-label="More"
                                    aria-haspopup="true"
                                    aria-owns={open ? 'application-menu' : undefined}
                                    onClick={this.handleIconDropdownClick}
                                >
                                    <MdMoreVert className="icon" />
                                </IconButton>

                                <Menu
                                    id="application-menu"
                                    // anchorEl={anchorEl}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                                    open={open} 
                                    onClose={this.handleClose}
                                >
                                    <MenuItem key="printApplicationForm" onClick={ () => this.printGrantApplicationForm()}>
                                        Print Grant Application Form
                                    </MenuItem>
                                    {
                                        this.props.lempgUserType == "LEMPG_ACCESS" ?
                                            <MenuItem key="requestExtension" onClick={() => this.requestExtension()}>
                                                Request an Extension
                                        </MenuItem> : ""
                                    }                                    
                                </Menu>
                            </div>

                        }
                    />
                    
                        {
                            this.state.extensionApproved ?
                                <div className="layout horizontal center center-justified" style={{ height: "24px", backgroundColor: "var(--extension-approved-color", color: "white" }}>
                                    Extension approved until {this.props.grantApplication && this.props.grantApplication.currentExtension && moment(this.props.grantApplication.currentExtension.dateAdjustedTo ? this.props.grantApplication.currentExtension.dateAdjustedTo : this.props.grantApplication.currentExtension.dueDate, "YYYY-MM-DD").local().format("MM-DD-YYYY")}
                                </div>
                                : ""
                        }                    

                    {
                        this.state.errors.length ?
                        <div className="margin-10">
                            <ErrorBox errors={this.state.errors} />
                        </div>
                        : ""
                    }
                    
                    <div className="flex container-overflow-y">

                        <div className={`layout vertical flex ${classes.listItem}`}>
                            <Link className="layout horizontal center truncate" to="/application/application_id">
                                <div className={`flex truncate ${classes.listText}`}>
                                    Grant Application Form

                                </div>
                                <IconButton>
                                    <MdChevronRight className="icon-2 opacity-54" />
                                </IconButton>
                            </Link>
                        </div>
                        <Divider /> 

                        {/* Acceptance of audit requirement */}

                        <Link className={`layout horizontal ${classes.listItem} ${this.props.currentApplicationState && this.props.currentApplicationState.acceptanceOfAuditReqFile ? classes.extraHeight : ""}`} to="/application/application_id/auditreq">

                            <div className="layout vertical only-vertical-center flex">
                                <div className={`truncate ${classes.listText}`}>
                                    Acceptance of Audit Requirements
                                </div>

                                {
                                    this.props.currentApplicationState && this.props.currentApplicationState.acceptanceOfAuditReqFile ? <div><StatusComponent text="Completed" /></div> : null
                                }

                            </div>

                            <IconButton>
                                <MdChevronRight className="icon-2 opacity-54" />
                            </IconButton>

                        </Link>
                        <Divider />

                        {/* Certification document */}

                        <Link className={`layout horizontal ${classes.listItem} ${this.props.currentApplicationState && this.props.currentApplicationState.certificationDocumentFile ? classes.extraHeight : ""}`} to="/application/application_id/certificationdocuments">

                            <div className="layout vertical only-vertical-center flex">
                                <div className={`truncate ${classes.listText}`}>
                                    Certification Document
                                </div>

                                {
                                    this.props.currentApplicationState && this.props.currentApplicationState.certificationDocumentFile ? <div><StatusComponent text="Completed" /></div> : null
                                }

                            </div>

                            <IconButton>
                                <MdChevronRight className="icon-2 opacity-54" />
                            </IconButton>

                        </Link>
                        <Divider />

                        {/* Assurances Non-Construction */}

                        <Link className={`layout horizontal ${classes.listItem} ${this.props.currentApplicationState && this.props.currentApplicationState.assurancesNonContructionFile ? classes.extraHeight : ""}`} to="/application/application_id/assurances">

                            <div className="layout vertical only-vertical-center flex">
                                <div className={`truncate ${classes.listText}`}>
                                    Assurances Non-Construction
                                </div>

                                {
                                    this.props.currentApplicationState && this.props.currentApplicationState.assurancesNonContructionFile ? <div><StatusComponent text="Completed" /></div> : null
                                }

                            </div>

                            <IconButton>
                                <MdChevronRight className="icon-2 opacity-54" />
                            </IconButton>

                        </Link>
                        <Divider />

                        {/* Lobbying certification */}

                        <Link className={`layout horizontal ${classes.listItem} ${this.props.currentApplicationState && this.props.currentApplicationState.lobbyingCertificationFile ? classes.extraHeight : ""}`} to="/application/application_id/lobbying">

                            <div className="layout vertical only-vertical-center flex">
                                <div className={`truncate ${classes.listText}`}>
                                    Lobbying Certification
                                </div>

                                {
                                    this.props.currentApplicationState && this.props.currentApplicationState.lobbyingCertificationFile ? <div><StatusComponent text="Completed" /></div> : null
                                }

                            </div>

                            <IconButton>
                                <MdChevronRight className="icon-2 opacity-54" />
                            </IconButton>

                        </Link>
                        <Divider />

                        {/* EDM Equipment Policy */}


                        <Link className={`layout horizontal ${classes.listItem} ${this.props.currentApplicationState && this.props.currentApplicationState.edmEquipmentPolicyFile ? classes.extraHeight : ""}`} to="/application/application_id/edm">

                            <div className="layout vertical only-vertical-center flex">
                                <div className={`truncate ${classes.listText}`}>
                                    Equipment Policy
                                </div>

                                {
                                    this.props.currentApplicationState && this.props.currentApplicationState.edmEquipmentPolicyFile ? <div><StatusComponent text="Completed" /></div> : null
                                }

                            </div>

                            <IconButton>
                                <MdChevronRight className="icon-2 opacity-54" />
                            </IconButton>

                        </Link>
                        <Divider />

                        {/* Position Description */}



                        <Link className={`layout horizontal ${classes.listItem} ${this.checkIfPositionDescFileExists(this.props.currentApplicationState) ? classes.extraHeight : ""}`} to="/application/application_id/positiondesc">

                            <div className="layout vertical only-vertical-center flex">
                                <div className={`truncate ${classes.listText}`}>
                                    Position Description
                                </div>

                                {
 
                                    this.checkIfPositionDescFileExists(this.props.currentApplicationState) ? <div><StatusComponent text="Completed" /></div> : null
                                }

                            </div>

                            <IconButton>
                                <MdChevronRight className="icon-2 opacity-54" />
                            </IconButton>

                        </Link>
                        <Divider />

                        {/* Scope of Work */}
                        {/* Document only available for FY2019 */}

                        {
                            this.props.selectedYear == "2019" ?
                            <>
                                <Link className={`layout horizontal ${classes.listItem} ${this.props.currentApplicationState && this.props.currentApplicationState.scopeOfWorkFile ? classes.extraHeight : ""}`} to="/application/application_id/scopeofworkdocument">
        
                                    <div className="layout vertical only-vertical-center flex">
                                        <div className={`truncate ${classes.listText}`}>
                                            Scope of Work
                                    </div>
        
                                        {
                                            this.props.currentApplicationState && this.props.currentApplicationState.scopeOfWorkFile ? <div><StatusComponent text="Completed" /></div> : null
                                        }
        
                                    </div>
        
                                    <IconButton>
                                        <MdChevronRight className="icon-2 opacity-54" />
                                    </IconButton>
        
                                </Link>
                                <Divider /> 
                            </>
                            : ""
                        }


                        {/* Narratives */}

                        <Link className={`layout horizontal ${classes.listItem} ${this.props.currentApplicationState && this.props.currentApplicationState.narrativesFile ? classes.extraHeight : ""}`} to="/application/application_id/narrativesdocument">

                            <div className="layout vertical only-vertical-center flex">
                                <div className={`truncate ${classes.listText}`}>
                                    Narratives
                                </div>

                                {
                                    this.props.currentApplicationState && this.props.currentApplicationState.narrativesFile ? <div><StatusComponent text="Completed" /></div> : null
                                }

                            </div>

                            <IconButton>
                                <MdChevronRight className="icon-2 opacity-54" />
                            </IconButton>

                        </Link>
                        <Divider />

                    </div>

                    {
                        this.props.lempgUserType == "LEMPG_ACCESS" ?
                        <div className="layout horizontal center form-container">
                            <div className="flex"></div>
                            <PrimaryButton setwidth="true" onClick={() => this.submitApplication() }>
                                Submit
                            </PrimaryButton>
                        </div>
                        : this.props.grantApplication && this.props.grantApplication.status == "SUBMITTED" ? 
                        <div className="layout horizontal center form-container">
                            <div className="flex"></div>
                            <DeleteButton setwidth="true" onClick={() => this.requestChangesInApplication()}>
                                <Link to="/application/application_id/requestChanges">
                                    Request changes
                                </Link>

                            </DeleteButton>
                            <div style={{ width: "20px"}}></div>
                            <PrimaryButton setwidth="true" onClick={() => this.approveApplication()}>
                                    Approve
                                {/* <Link to="/application/application_id/approved">
                                </Link> */}
                            </PrimaryButton>
                        </div>
                        : ""

                    }

                </div>

            )
            }
        }

        return (

            <Switch>

                <Route
                    path="/application/county"
                    component={(props) => <CountyPageForState {...props} />}
                />

                <Route
                    exact
                    path="/application/extension"
                    component={
                        (props) => this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading your application. Please wait</span>
                            </div>
                            :
                            <ApplicationExtension {...props} />
                    }
                />

                <Route
                    exact
                    path="/application" 
                    component={
                        () => this.props.loading ? 
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading your application. Please wait</span>
                            </div>
                        :
                            this.props.lempgUserType ? this.props.grantApplication && this.props.grantApplication.currentApplicationRevisionId ? <Application {...this.props} /> : 
                            <GrantPackage {...this.props} /> : ""
                    }
                />

                <Route
                    path="/application/application_id/auditreq"
                    component={(props) => <AcceptanceOfAuditRequirements {...props} />}
                />
                <Route
                    path="/application/application_id/certificationdocuments"
                    component={(props) => <CertificationDocument {...props} />}
                />

                <Route
                    path="/application/application_id/assurances"
                    component={(props) => <AssurancesNonConstruction {...props} />}
                />
                

                <Route
                    path="/application/application_id/lobbying"
                    component={(props) => <LobbyingCertification {...props} />}
                />

                <Route
                    path="/application/application_id/edm"
                    component={(props) => <EDMEquipmentPolicy {...props} />}
                />
                
                <Route
                    path="/application/application_id/positiondesc"
                    component={(props) => <PositionDescription {...props} />}
                />

                <Route
                    path="/application/application_id/scopeofworkdocument"
                    component={(props) => <ScopeOfWorkDocument {...props} />}
                />

                <Route
                    path="/application/application_id/narrativesdocument"
                    component={(props) => <NarrativesDocument {...props} />}
                />

                <Route
                    path="/application/application_id/approved"
                    component={(props) => <StateSubmitApproval {...props} />}
                />

                <Route
                    path="/application/application_id/requestChanges"
                    component={(props) => <StateRequestChanges {...props} />}
                />
                
                {
                    /** This is route for the application itself. 
                     * Since the terms and conditions route include `/application/application_id/audit_req`
                     * and the route for application itself is `/application/application_id` and if this is not put in the last it will take priority over the other one.
                     */
                }

                <Route
                    path="/application/application_id"
                    component={(props) => <Application {...props} />}
                />


            </Switch>
            
        )
        
    }
}

GrantApplicationPackage.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GrantApplicationPackage));
// export default withStyles(styles)(GrantApplicationPackage);
