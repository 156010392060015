import React from 'react';
import { connect } from 'react-redux'
import IconButton from "@material-ui/core/IconButton";
import { MdArrowBack } from 'react-icons/md';

import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { NumberFormatPhone } from "../../utilities/NumberFormat";
import { ValidateEmailAddress, ValidatePhoneNumber } from "../../utilities/utils";

import ErrorBox from "../../common/ErrorBox";

import { RequiredDropdown } from "../../styled-components/Dropdowns/Dropdown.js";
import { HeaderComponent } from "../../layout/HeaderComponent.js"
import { HeaderButton, DeleteButton, PrimaryButton } from "../../styled-components/Buttons/Buttons.js";
import { NormalTextField, RequiredTextField} from "../../styled-components/TextFields/TextFields.js";

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

import { createREMContact, updateREMContact, deleteREMContact } from "../../../redux/actions/index";



const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        currentREMContact: state.rootReducer.currentREMContact,
        allREMContacts: state.rootReducer.allREMContacts,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createREMContact: (payload, callback) => dispatch(createREMContact(payload, callback)),
        updateREMContact: (payload, currentREMContact, callback) => dispatch(updateREMContact(payload, currentREMContact, callback)),
        deleteREMContact: (currentREMContact, callback) => dispatch(deleteREMContact(currentREMContact, callback)),
    }
}

const DropdownMenuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
    },
    PaperProps: {
        style: {
            minWidth: "200px",
        },
    },
};

class REMContactEditPage extends React.Component {

    state = {
        firstName: (this.props.currentREMContact && this.props.currentREMContact.firstName) || null,
        lastName: (this.props.currentREMContact && this.props.currentREMContact.lastName) || null,
        phoneNumber: (this.props.currentREMContact && this.props.currentREMContact.phoneNumber) || null,
        emailAddress: (this.props.currentREMContact && this.props.currentREMContact.emailAddress) || null,
        positionTitle: (this.props.currentREMContact && this.props.currentREMContact.positionTitle) || null,
        region: (this.props.currentREMContact && this.props.currentREMContact.region) || "",
        errors: [],
        validate: false,

    }

    constructor(props, context) {
        super(props, context);
    }

    goBack = () => {
        this.props.history.goBack()
    }


    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    performErrorCheck = (callback) => {
        this.state.validate = false;
        this.state.phoneValidate = false;
        this.state.errors = [];
        this.setState({ phoneValidate: this.state.phoneValidate });
        this.setState({ validate: this.state.validate });
        this.setState({ errors: this.state.errors });

        if (!this.state.region) {
            this.state.errors.push({
                text: "Please select region"
            });
        }

        if (!this.state.firstName) {
            this.state.errors.push({
                text: "Please enter first name"
            });
        }

        if (!this.state.lastName) {
            this.state.errors.push({
                text: "Please enter last name"
            });
        }

        if (this.state.phoneNumber && !ValidatePhoneNumber(this.state.phoneNumber)) {
            this.state.errors.push({
                text: "Please enter valid phone number"
            });
            this.state.phoneValidate = true;
            this.setState({ phoneValidate: this.state.phoneValidate });
        }
        
        if (!this.state.emailAddress) {
            this.state.errors.push({
                text: "Please enter email address"
            });
        }

        if (this.state.emailAddress && !ValidateEmailAddress(this.state.emailAddress)) {
            this.state.errors.push({
                text: "Please enter a valid email address"
            });
        }

        if (this.state.errors.length > 0) {
            this.state.validate = true;
            this.setState({ validate: this.state.validate });
            this.setState({ errors: this.state.errors });
            callback(new Error("Error"));
            return
        }

        callback();
    }

    createNewREMContact = () => {
        this.performErrorCheck( (errors) => {
            if(!errors) {

                this.props.createREMContact(this.state, this.goBack)
            }
        })        
    }

    updateREMContact = () => {
        this.performErrorCheck((errors) => {
            if (!errors) {
                this.props.updateREMContact(this.state, this.props.currentREMContact, this.goBack)
            }
        })        
    }

    deleteREMContact = () => {
        this.props.deleteREMContact( this.props.currentREMContact, this.goBack)
    }

    getAvailableRemRegions = (contacts) => {
        let regions = [
            {
                key: 'R1',
                value: 'R1',
            },
            {
                key: 'R2',
                value: 'R2',
            },
            {
                key: 'R3',
                value: 'R3',
            },
            {
                key: 'R4',
                value: 'R4',
            },
            {
                key: 'R5',
                value: 'R5',
            },
            {
                key: 'R6',
                value: 'R6',
            },
        ]

        for(let i = 0; i < contacts.length; i++) {
            let found = false, index = -1;
            for(let j = 0; j < regions.length; j++) {
                if(contacts[i].region === regions[j].key && this.props.currentREMContact.region !== contacts[i].region) {
                    found = true;
                    index = j
                    break;
                }
            }

            if(found) {
                regions.splice(index, 1);
            }
        }

        return regions;
    }

    render() {
        return (
            <div className="layout vertical full-height">

                <HeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdArrowBack className="icon" />
                        </IconButton>

                    }
                    title={<span>{this.props.currentREMContact && this.props.currentREMContact.id ? "Edit REM Contact" : "Add REM Contact"}</span>}
                    suffix={
                        this.props.currentREMContact && this.props.currentREMContact.id ?
                        <HeaderButton hidden={false} onClick={(e) => this.updateREMContact(e)}> Save </HeaderButton> : ""
                    }
                />

                <div className="flex form-shell container-overflow-y">
                    {
                        this.state.errors.length ? <ErrorBox errors={this.state.errors} /> : ""
                    }

                    <div className="layout horizontal center section-title form-margin">REM Contact Details</div>

                    <div className="layout horizontal wrap">

                        <FormControl className="flex">
                            <InputLabel style={{ margin: "0 6px" }} shrink htmlFor="selectRegion"> Region <span className="required"></span> </InputLabel>
                            <RequiredDropdown
                                error={this.state.validate ? true : false} 
                                value={this.state.region}
                                onChange={this.handleChange('region')}
                                input={<Input name="selectRegion" id="selectregion" />}
                                MenuProps={DropdownMenuProps}
                                custommargin="true"
                                displayEmpty
                                name="selectregion"
                                className="required-dropdown"
                                placeholder='Select a region'
                            >
                                <MenuItem value="">
                                    <span className="placeholder">Select a region</span>
                                </MenuItem>
                                {
                                    this.getAvailableRemRegions(this.props.allREMContacts).map((item) => {
                                        return (
                                            <MenuItem value={item.key}>{item.value}</MenuItem>
                                        )
                                    })
                                }
                            </RequiredDropdown>
                        </FormControl>

                    </div>

                    <div className="height-10"></div>

                    <div className="layout horizontal wrap">
                        <RequiredTextField value={this.state.firstName} onChange={this.handleChange('firstName')}  error={this.state.validate ? true : false} id="firstName" label="First name" placeholder="Enter first name" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true,}} />

                        <RequiredTextField value={this.state.lastName} onChange={this.handleChange('lastName')} error={this.state.validate ? true : false} id="lastName" label="Last name" placeholder="Enter last name" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true, }} />

                    </div>

                    <div className="height-10"></div>

                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.positionTitle} id="positionTitle" onChange={this.handleChange('positionTitle')} label="Position/Title" placeholder="Enter position/title" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true, }} />

                            <NormalTextField value={this.state.phoneNumber} error={this.state.phoneValidate ? true : false} id="phoneNumber" onChange={this.handleChange('phoneNumber')} label="Phone number" placeholder="Enter phone number" custommargin="true" className="flex" InputProps={{ inputComponent: NumberFormatPhone, readOnly: false, }} InputLabelProps={{ shrink: true,}} />

                        <RequiredTextField value={this.state.emailAddress} error={this.state.validate ? true : false} onChange={this.handleChange('emailAddress')} id="emailAddress" label="Email address" placeholder="Enter email address" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true,}} />
                    </div>
                </div>
                

                <div className="layout horizontal center-justified form-container" style={{ borderTop: "1px solid #E0E0E0" }}>
                    {
                        this.props.currentREMContact && this.props.currentREMContact.id
                        ?
                            <DeleteButton setwidth="true" onClick={() => this.deleteREMContact()}>
                                Delete
                            </DeleteButton>
                        :
                            <PrimaryButton setwidth="true" onClick={() => this.createNewREMContact()}>
                                Submit
                            </PrimaryButton>
                    }
                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(REMContactEditPage);