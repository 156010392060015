import React from 'react';
import moment from 'moment';

import { calcQuarters } from '../../utilities/QuarterDates';

import "../../../styles/common.css";
import "../../../styles/common.charts.css";

// Parent here is the CountySplashScreen, which passes on the selectedYear
class TimeRemainingInQuarterIndicator extends React.Component {

    state = {
        item: {
            title: "",
            timeRemaining: "",
        }
    }


    checkQuarterBasedOnDate = (quarterStart, quarterEnd) => {
        var start = new Date(quarterStart);
        var current = new Date();
        var end = new Date(quarterEnd);
        return (start <= current && current <= end);
    }

    checkWhichQuarter = (selectedYear) => {
        let obj = calcQuarters(selectedYear)


        // Check per quarter basis, which quarter we are in

        if (this.checkQuarterBasedOnDate(obj.quarter1.start, obj.quarter1.end)) {
            return {
                title: "Q1",
                timeRemaining: moment(obj.quarter1.end).diff(moment(), 'days')
            };
        } else if (this.checkQuarterBasedOnDate(obj.quarter2.start, obj.quarter2.end)) {
            return {
                title: "Q2",
                timeRemaining: moment(obj.quarter2.end).diff(moment(), 'days')
            };
        } else if (this.checkQuarterBasedOnDate(obj.quarter3.start, obj.quarter3.end)) {
            return {
                title: "Q3",
                timeRemaining: moment(obj.quarter3.end).diff(moment(), 'days')
            };
        } else if (this.checkQuarterBasedOnDate(obj.quarter4.start, obj.quarter4.end)) {
            return {
                title: "Q4",
                timeRemaining: moment(obj.quarter4.end).diff(moment(), 'days')
            };
        }

        return {
            title: "",
            timeRemaining: ""
        };
    }


    constructor(props, context) {
        super(props, context);

        let item = this.checkWhichQuarter(props.selectedYear);
        if(item.timeRemaining) {
            item.timeRemaining = (item.timeRemaining == 1) ? item.timeRemaining + " day " : (item.timeRemaining + " days ");
        } else {
            item.timeRemaining = "0 days "
        }
        this.state.item = item;
    }

    render() {
        return (

            <div className="pie-chart-container inline-grid">
                <div>
                    <span className="section-title form-margin-16">Time remaining in quarter</span>
                    <div className="height-10"></div>
                    <div className="layout horizontal form-margin-16 title-divider"></div>
                </div>
                <div className="layout horizontal center quarter-time-indicator-container">
                    <div className="layout horizontal center center-justified quarter-title-container">{this.state.item.title}</div>
                    <span className="quarter-time-remaining">{this.state.item.timeRemaining}</span> &nbsp;<span className="quarter-remaining">remaining</span>
                </div>
            </div>
        )
    }

}


export default TimeRemainingInQuarterIndicator;
