import React from 'react';
import { connect } from 'react-redux'
import IconButton from "@material-ui/core/IconButton";
import { MdClear, MdMoreVert } from 'react-icons/md';

import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import { PrimaryButton } from "../../styled-components/Buttons/Buttons.js";
import { NormalTextField } from "../../styled-components/TextFields/TextFields.js";
import { deniedExtensionProgressReporting, approveExtensionFinancialReimbursement } from "../../../redux/actions/index"

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        currentQuarter: state.rootReducer.currentQuarter,
        currentGroupNarrativeM2M: state.rootReducer.currentGroupNarrativeM2M,
        currentExtension: state.rootReducer.currentExtension,
        lempgUserType: state.rootReducer.lempgUserType,
        selectedYear: state.rootReducer.selectedYear
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        deniedExtensionProgressReporting: (payload, callback) => dispatch(deniedExtensionProgressReporting(payload, callback)),
        //deniedExtensionFinancialReimbursement: (payload, callback) => dispatch(deniedExtensionFinancialReimbursement(payload, callback)),

    }
}


class ExtensionsDenied extends React.Component {

    state = {
        openDeniedDialog: false,
        errors: [],
        validate: false,
        reasonForDeniedExtension: "",
        denied: true,
    }

    constructor(props, context) {
        super(props, context);

        if (this.props.currentExtension && this.props.currentExtension.for == "FINANCIAL_REIMBURSEMENT") {
            this.state.quarterTitle = "Q" + parseInt(this.props.currentQuarter.quarter.split("").reverse())
        }
        if (this.props.currentExtension && this.props.currentExtension.for == "PROGRESS_REPORTING") {
            this.state.quarterTitle = "Q" + parseInt(this.props.currentGroupNarrativeM2M.quarter.split("").reverse())
        }

    }

    toUTC = (date) => {
        var newDate = new Date();
        newDate.setTime(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));
        return newDate;
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    goBack = () => {
        this.props.history.goBack()
    }

    handleClose = (variable) => {
        this.setState({
            [variable]: false
        });
    };

    openDialog = (variable) => {
        this.setState({
            [variable]: true
        });
    }

    deniedExtension = () => {
     //   if (this.props.currentExtension && this.props.currentExtension.for == "FINANCIAL_REIMBURSEMENT") {
     //       this.props.approveExtensionFinancialReimbursement(this.state, () => {
     //           console.log(this.props.history)
     //           this.props.history.entries = [];
     //           this.props.history.index = -1;
     //           this.props.history.push("/extensions/")
     //       });
     //   }
        if (this.props.currentExtension && this.props.currentExtension.for == "PROGRESS_REPORTING") {
            this.props.deniedExtensionProgressReporting(this.state, () => {
                console.log(this.props.history)
                this.props.history.entries = [];
                this.props.history.index = -1;
                this.props.history.push("/extensions/")
            });
        }

    }

    _convertToTitleCase = (str) => {
        str = str.toLowerCase();
        return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    }

    componentDidUpdate() { }

    render() {

        return (

            <div className="layout vertical full-height">
                <Dialog
                    open={this.state.openDeniedDialog}
                    onClose={() => this.handleClose("openDeniedDialog")}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div style={{ maxWidth: "320px" }}>
                        <DialogTitle id="alert-dialog-title">Denied extension</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                You are about to <b>denied</b> an extension for <b> {this._convertToTitleCase(this.props.currentExtension && this.props.currentExtension.group)} </b> County. This will denied their extension and will remove their request from the list. Are you sure you want to do this? 
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleClose("openDeniedDialog")} color="primary">
                                CANCEL
                            </Button>
                            <Button onClick={() => this.deniedExtension()} color="primary" autoFocus>
                                DENY
                            </Button>
                        </DialogActions>
                    </div>
                </Dialog>

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdClear className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Denied extension</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}> {this.props.currentExtension && this.props.currentExtension.group} • {this.state.quarterTitle}  • {this.props.selectedYear}</span>}
                />

                <div className="flex form-shell container-overflow-y">
             
                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.reasonForDeniedExtension} id="groupName" onChange={this.handleChange('reasonForDeniedExtension')} label="Reason for denied" placeholder="Enter reason for denied" className="flex" multiline custommargin="true" customtbmargin="true" InputLabelProps={{ shrink: true, }} />
                    </div>

                </div>

                <div className="layout horizontal center-justified form-container " style={{ borderTop: "1px solid #E0E0E0" }}>
                    <div className="form-margin">
                        <PrimaryButton setwidth="true" onClick={() => this.openDialog("openDeniedDialog")}>
                            SUBMIT
                        </PrimaryButton>
                    </div>

                </div>


            </div>

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtensionsDenied);
