import React from 'react';
import { connect } from 'react-redux'
import IconButton from "@material-ui/core/IconButton";
import { MdMenu } from 'react-icons/md';
import ReactTable from "react-table";

// js
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import { CardComponent2R1C } from "../../layout/CardComponent";

import { setMonitoringCounty, } from "../../../redux/actions/index";
import StatusComponent from "../../styled-components/Statuses/StausComponent";

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        token: state.rootReducer.token,
        counties: state.rootReducer.counties,
        selectedYear: state.rootReducer.selectedYear
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMonitoringCounty: (payload, callback) => dispatch(setMonitoringCounty(payload, callback)),
    }
}


class MonitoringStatePage extends React.Component {

    state = {
        selectedTab: 1,
        smallScreen: false,
    }

    constructor(props, context) {
        super(props, context);
        this.selectedCountyCallback = this.selectedCountyCallback.bind(this);
    }

    checkWidth = () => {
        const match = window.matchMedia(`(max-width: 768px)`).matches;
        if (match == this.state.smallScreen) return;
        if (match) {
            this.setState({ smallScreen: true });
        } else {
            this.setState({ smallScreen: false });
        }
    }

    componentDidMount() {
        this.checkWidth();
        window.addEventListener("resize", this.checkWidth);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkWidth);
    }

    setCounty(county) {
        this.props.setMonitoringCounty({ selectedGroupID: parseInt(county.palmettoId) }, this.selectedCountyCallback);
    }

    selectedCountyCallback() {
        // get county monitoring information
    }

    handleMenuClick = (event) => {
        window.dispatchEvent(new CustomEvent("toggle-sidebar"))
    }

    selectTab = (index) => {
        this.setState({ selectedTab: index })
    }

    getStatus(county) {
        return county.grantapplication.items[0].status && (county.grantapplication.items[0].status === "AWARDED") ?
            (county.grantapplication.items[0].awardDocumentsUploadedByCounty && county.grantapplication.items[0].awardDocumentsUploadedByCounty.id ?
                "COMPLETE" : "AWARDED") :
            county.grantapplication && county.grantapplication.items[0] && county.grantapplication.items[0].status;
    }


    _convertToTitleCase = (str) => {
        if(!str) return ''
        str = str.toLowerCase();
        return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    }


    getData = (counties) => {
        var arr = [];
        counties.forEach((county) => {
            
            let status = '';
            if(county.monitoringsitevisitreview ) {
                if(county.monitoringsitevisitreview.status === 'SUBMITTED_WITH_ISSUES') {
                    status = 'SUBMITTED WITH ISSUES'
                } else {
                    status = county.monitoringsitevisitreview.status;
                }
            } 

            arr.push(
                {
                    ...county,
                    ...{
                        updatedGroupName: this._convertToTitleCase(county.groupName),
                        grantInformationEntered: county.monitoringgrantinformation ? 'Yes' : 'No',
                        siteVisitReviewStatus: status
                    },
                }
            )

        });

        return arr;
    }

    onRowClick = (state, rowInfo, column, instance) => {
        return {
            onClick: (e, handleOriginal) => {
                this.props.history.push("/monitoring/county")
                this.setCounty(rowInfo.original);

                if (handleOriginal) {
                    handleOriginal();
                }
            }
        };
    };


    render() {
        return (


            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.handleMenuClick}
                        >
                            <MdMenu className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Monitoring</span>}
                    titleSmall={<span>{window.appHeaderTitle}</span>}
                />



                <div className="flex container-overflow-y">


                    <ReactTable
                        minRows={0}
                        data={this.getData(this.props.counties)}
                        defaultPageSize={100}
                        NoDataComponent={() => null}
                        showPagination={false}
                        onSortedChange={this.onSortChange}
                        defaultSorted={this.state.progressReportingStateCountiesTableSort}
                        getTdProps={this.onRowClick}
                        columns={[

                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">County</div>
                                ),
                                className: "body-cell",
                                accessor: "updatedGroupName"
                            },
                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">Grant information entered</div>
                                ),
                                className: "body-cell",
                                accessor: "grantInformationEntered"
                            },

                            {
                                Header: () => (
                                    <div className="layout horizontal center body-header">Site visit review</div>
                                ),
                                className: "body-cell",
                                accessor: "siteVisitReviewStatus",
                                Cell: row => (
                                    row.value ? <StatusComponent text={this._convertToTitleCase(row.value)} /> : <span></span>
                                )                                

                            },
                        ]}
                    />                    

                    {/* {
                        <>
                            {
                                this.props.counties.map((county) => {
                                    return (
                                        <Link key={county.groupName} to="/monitoring/county" onClick={() => this.setCounty(county)} className="layout horizontal">
                                            <CardComponent2R1C
                                                className="flex no-opacity-important-childs"
                                                title={<span style={{ textTransform: "capitalize" }}>{county.groupName}</span>}
                                                title2={<StatusComponent text={this.getStatus(county)} />}
                                            />
                                        </Link>

                                    )
                                })
                            }
                        </>
                    } */}

                </div>
            </div>
        )
    }
}

// export default MonitoringStatePage;
export default connect(mapStateToProps, mapDispatchToProps)(MonitoringStatePage);
