import React from 'react';
import { Route, Switch } from "react-router-dom";
import { connect } from 'react-redux'
import IconButton from "@material-ui/core/IconButton";
import { MdMenu, MdMoreVert } from 'react-icons/md';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import moment from 'moment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Local imports
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"

// Component imports
import PreGrantOverview from "../pre_grant_awards/PreGrantOverview";
import TermsAndConditionDocuments from "./TermsAndConditionDocuments";

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

import {
    selectQuarterPreFiscalYear
} from "../../../redux/actions/index";


const mapStateToProps = (state) => {
    return {
        loading: state.rootReducer.loading,
        lempgUserType: state.rootReducer.lempgUserType,
        selectedQuarterPreFiscalYear: state.rootReducer.selectedQuarterPreFiscalYear, 
        selectedYear: state.rootReducer.selectedYear,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        selectQuarterPreFiscalYear: (payload) => dispatch(selectQuarterPreFiscalYear(payload)),
    }
};


class PreFiscalYearStatePackage extends React.Component {

    state = {
        selectedQuarter: this.props.selectedQuarterPreFiscalYear || 0,

    }

    constructor(props, context) {
        super(props, context);
    }

    selectTab = (index) => {
        this.state.selectedQuarter = index;
        this.setState({
            "selectedQuarter": this.state.selectedQuarter
        });
        this.props.selectQuarterPreFiscalYear(this.state.selectedQuarter)
    }


    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        return (
            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.handleMenuClick}
                        >
                            <MdMenu className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Pre-fiscal year</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>{window.appHeaderTitle} </span>}
                />


                <Tabs className="layout horizontal center"
                    value={this.state.selectedQuarter}
                    indicatorColor="primary"
                    textColor="primary"
                    classes={{
                        root: "tabs-root",
                        flexContainer: "flex",
                        indicator: "tab-indicator-color",
                    }}
                    // onChange={handleChange}
                    aria-label="disabled tabs example"
                >
                    <Tab
                        className="flex"
                        classes={{
                            root: "tab-root",
                            selected: "tab-selected",
                        }}
                        onClick={() => this.selectTab(0)}
                        label="Pre-Grant"
                    />

                    <Tab
                        className="flex"
                        classes={{
                            root: "tab-root",
                            selected: "tab-selected",
                        }}
                        onClick={() => this.selectTab(1)}
                        label="Terms and Condition"
                    />
                </Tabs>


                <div className="flex container-overflow-y">
                        {
                        this.state.selectedQuarter == 0 ? <PreGrantOverview {...this.props} /> : <TermsAndConditionDocuments {...this.props} />
                        }
                </div>
            </div>

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreFiscalYearStatePackage);
